// @flow
import React, { useState } from "react";
import { Header, Container } from "./styles";
import IconButton from "Components/IconButton";
import Button from "Components/Button";
import TextBox from "Components/TextBox";

type Props = {
  name: String,
  about: String,
  update: Function,
  submit: Function,
  LargePlaceholder: React.Node,
  icons: Object
};

function AboutForm({
  name,
  about,
  update,
  submit,
  LargePlaceholder,
  icons
}: Props) {
  const [editing, toggleEditing] = useState(false);

  return (
    <React.Fragment>
      <Header>
        About {name}
        {editing ? (
          <Button
            size="small"
            onClick={() => {
              submit();
              toggleEditing(false);
            }}
          >
            Save
          </Button>
        ) : (
          <IconButton yellow={!about} onClick={() => toggleEditing(true)}>
            {about?.length ? icons.edit : icons.plus}
          </IconButton>
        )}
      </Header>
      {editing ? (
        <Container>
          <TextBox value={about} onChange={e => update(e.target.value)} />
        </Container>
      ) : about?.length > 0 ? (
        <span>{about}</span>
      ) : (
        <LargePlaceholder />
      )}
    </React.Fragment>
  );
}

export default AboutForm;
