import React from "react";
import { StyledNotification } from "./styles";

const Notification = ({ success, children }) => (
  <StyledNotification success={success}>
    {children && children}
  </StyledNotification>
);

export default Notification;
