// @flow
import styled from "styled-components";
import Text from "Components/Text";

export const Container = styled.div(
  ({ theme, image, fullscreen }) => `
  background-image: ${
    image?.sizes?.medium ? `url(${image.sizes.medium})` : "none"
  };
  background-color: ${theme.colors.black};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  min-height: ${fullscreen ? "90vh" : "200px"};
  position: relative;
  @media screen and (min-width: ${image?.sizes["medium-width"]}px) {
    background-image: url(${image?.sizes?.medium_large});
  }
  @media screen and (min-width: ${image?.sizes["large-width"]}px) {
    min-height: 90vh;
    background-image: url(${image?.sizes["post-thumbnail"]});
  }
  @media screen and (min-width: ${image?.sizes["post-thumbnail-width"]}px) {
    background-image: url(${image?.url});
  }
`
);

export const Wrapper = styled.div(
  ({ theme, displaySignUp }) => `
  width: 100%;
  display: ${displaySignUp ? "none" : "block"};
  padding: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    max-width:${theme.maxWidth}px
    margin:auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0;
  }
`
);

export const MobileContainer = styled.div(
  ({ theme }) => `
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`
);

export const Author = styled.div(
  ({ theme }) => `
  display: none;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.border.radius * 2}px;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    padding: ${theme.spacing / 2}rem
      ${theme.spacing / 0.5}rem;
    gap: ${theme.spacing}rem;
    color: ${theme.colors.white};
    bottom: ${theme.spacing * 2}rem;
    left: ${theme.spacing * 2}rem;
  }
`
);

export const StyledText = styled(Text)(
  ({ theme }) => `
  font-size: ${theme.fonts.size.small};
`
);
