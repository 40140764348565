import React, { useState, useEffect } from 'react';
import { NavLink, WrapIcon, WrapExpandedMenu, StyleNameLock } from './styles';
import IconButton from 'Components/IconButton';
import { Link, useHistory, useLocation } from 'react-router-dom';

function LoggedInLinks({
	borderTop,
	sublink,
	name,
	pathname,
	onClick,
	disabled,
	hideLock,
	show,
}) {
	return (
		<NavLink
			style={{ borderTop: borderTop }}
			to={'/' + sublink}
			onClick={onClick}
			disabled={disabled}
			active={pathname?.includes('/' + sublink)}
			show={show}
		>
			<StyleNameLock>
				<p>{name}</p>
				<div style={{ paddingLeft: '0.5rem' }}>{hideLock}</div>
			</StyleNameLock>
			<WrapIcon>
				<IconButton>
					<img src={require('Assets/Images/arrow.svg')}></img>
				</IconButton>
			</WrapIcon>
		</NavLink>
	);
}

export default LoggedInLinks;
