import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import episodes from './episodes';
import EachEpisode from 'Components/EachEpisode';
import { Wrap } from './styles';

function Episodes({ display }: Props) {
	const [episode, loadEpisodes] = useState({
		episode: episodes,
	});
	const { pathname } = useLocation();
	let history = useHistory();
	useEffect(() => {
		window.scroll(0, 0);
	}, [pathname]);

	return (
		/*'More Episodes' section in the bottom - Based on the URL, choose which episode to display.
    If you are on the main podcasts page, then display all episodes.
    If you are on an individual episode, then don't repeat it in the bottom section.*/
		<>
			<div className="episodes">
				{pathname == '/podcasts/the-fashion-slashie' && (
					<div className="allepisodes">
						{Object.keys(episodes).map((key) => (
							<EachEpisode
								key={key}
								details={episodes[key]}
								display={'vertically'}
							/>
						))}
					</div>
				)}
				{pathname == '/podcasts/the-fashion-slashie/sylvie-macmillan' && (
					<Wrap>
						{Object.keys(episodes)
							.filter((key) => key !== 'episode7')
							.map((key) => (
								<EachEpisode
									key={key}
									details={episodes[key]}
									display={'horizontally'}
								/>
							))}
					</Wrap>
				)}
				{pathname == '/podcasts/the-fashion-slashie/tayler-prince-fraser' && (
					<Wrap>
						{Object.keys(episodes)
							.filter((key) => key !== 'episode6')
							.map((key) => (
								<EachEpisode
									key={key}
									details={episodes[key]}
									display={'horizontally'}
								/>
							))}
					</Wrap>
				)}
				{pathname == '/podcasts/the-fashion-slashie/kate-iorga' && (
					<Wrap>
						{Object.keys(episodes)
							.filter((key) => key !== 'episode5')
							.map((key) => (
								<EachEpisode
									key={key}
									details={episodes[key]}
									display={'horizontally'}
								/>
							))}
					</Wrap>
				)}
				{pathname == '/podcasts/the-fashion-slashie/theo-white' && (
					<Wrap>
						{Object.keys(episodes)
							.filter((key) => key !== 'episode4')
							.map((key) => (
								<EachEpisode
									key={key}
									details={episodes[key]}
									display={'horizontally'}
								/>
							))}
					</Wrap>
				)}
				{pathname == '/podcasts/the-fashion-slashie/eva-losada' && (
					<Wrap>
						{Object.keys(episodes)
							.filter((key) => key !== 'episode3')
							.map((key) => (
								<EachEpisode
									key={key}
									details={episodes[key]}
									display={'horizontally'}
								/>
							))}
					</Wrap>
				)}
				{pathname == '/podcasts/the-fashion-slashie/rob-rusling' && (
					<Wrap>
						{Object.keys(episodes)
							.filter((key) => key !== 'episode2')
							.map((key) => (
								<EachEpisode
									key={key}
									details={episodes[key]}
									display={'horizontally'}
								/>
							))}
					</Wrap>
				)}
				{pathname == '/podcasts/the-fashion-slashie/nassia-matsa' && (
					<Wrap>
						{Object.keys(episodes)
							.filter((key) => key !== 'episode1')
							.map((key) => (
								<EachEpisode
									key={key}
									details={episodes[key]}
									display={'horizontally'}
								/>
							))}
					</Wrap>
				)}
			</div>
		</>
	);
}

export default Episodes;
