// @flow
import React from "react";
import { Helmet } from "react-helmet";
import { useFetch } from "Hooks/useFetch";
import Hero from "Components/Blocks/Hero";
import LoginForm from "Components/LoginForm";

const Login = props => {
  const params = new URLSearchParams(props.location.search);
  const redirect = params.get("redirect");
  const { data } = useFetch({
    url: "/api/pages/login"
  });
  const hero = data?.page?.blocks[0];

  return (
    <>
      <Helmet>
        <title>ClickerMe | Log in</title>
      </Helmet>
      <Hero image={hero?.image} credit={hero?.credit} fullscreen>
        <LoginForm redirect={redirect} />
      </Hero>
    </>
  );
};

export default Login;
