// @flow
import styled from "styled-components";

export const Wrapper = styled.div(
  ({ theme, noMargin }) => `
  width: 100%;
  padding: ${theme.spacing * 2}rem;
  box-shadow: ${theme.shadow};
  background: white;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    height: max-content;
    width: 420px;
    margin: ${theme.spacing * 3}rem;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    margin: ${theme.spacing * 3}rem ${noMargin ? 0 : theme.spacing * 3}rem;
  }
`
);
