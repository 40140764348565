import { createGlobalStyle } from "styled-components";
import "sanitize.css";
import "./Fonts.css";

export const GlobalStyle = createGlobalStyle`
  body {
    color: black;
    font-family: "Raleway", sans-serif;
    background-color: rgb(248, 247, 247);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-ligatures: no-common-ligatures;
  }
  *, *:before, *:after {
    box-sizing: border-box;
  }

  main {
    min-height: 68vh;
  }

  .homepage {
    background-color: #FFF;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  button {
    font-family: "Raleway", sans-serif;
  }

  figure.wp-block-image {
    width: 100%;
    max-width: 590px;

    img {
      /* overriding inline style coming from WordPress */
      width: 100% !important;
      max-width: unset !important;
    }
  }

  input[type="text"], input[type="email"], input[type="password"], textarea {
    -webkit-box-shadow: none;
  }

  p {
   margin-top: 0;
  }
`;
