// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import Button from "Components/Button";

export const Wrapper = styled.div(
  ({ background_color }) => `
  width: 100%;
  background: ${background_color ? background_color : "transparent"};
`
);

export const Container = styled.div(
  ({ theme, background_color, columns }) => `
  background: ${background_color ? background_color : "transparent"};
  margin-bottom: ${theme.spacing}rem;
  padding ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: grid;
    grid-auto-rows: min-content;
    gap: ${parseInt(columns) > 2 ? 2 : theme.spacing}rem;
    grid-template-columns: repeat(${Math.min(2, columns)}, 1fr);
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    max-width: ${theme.maxWidth}px;
    margin: auto;
    grid-template-columns: repeat(${columns ? columns : 3}, 1fr);
  }
  `
);

export const Group = styled.div(
  ({ align, columns, theme, textOnly }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    margin: 0;
    min-height: ${textOnly ? 0 : parseInt(columns) > 2 ? 448 : 559}px;
    align-items: ${
      align === "left"
        ? "flex-start"
        : align === "right"
        ? "flex-end"
        : "center"
    };
    justify-content: ${
      align === "top"
        ? "flex-start"
        : align === "bottom"
        ? "flex-end"
        : "center"
    };
  }
`
);

export const StyledLink = styled(Link)(
  ({ theme, dimension, columns, textOnly, to }) => `
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: ${to ? "pointer" : "default"}
  justify-content: center;
  margin: ${textOnly ? `${theme.spacing}rem 0` : 0};
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    width: ${Math.min(
      dimension,
      theme.breakpoints.medium / columns - columns * 16
    )}px;
    height: ${textOnly ? dimension / 2 : dimension}px;
  }
`
);

export const Image = styled.img(
  ({ theme, dimension, columns, border }) => `
  max-width: 100%;
  max-height: 100%;
  border: ${border ? `1px solid ${theme.colors.grey()}` : "none"};
  @media screen and (min-width: ${theme.breakpoints.xsmall}px) {
    object-fit: cover;
  }
`
);

export const StyledText = styled.div(
  ({ textOnly }) => `
  width: 100%;
  ${textOnly &&
    css`
      font-size: small;
      display: flex;
      flex-direction: row;
      justify-content: center;
      img {
        height: 2.5rem;
        margin-right: 0.5rem;
        margin-top: -1rem;
      }
    `}
`
);

export const Action = styled.div`
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

export const StyledButton = styled(Button)(
  ({ button }) => `
  background-color: ${
    button.color === "#FFF200" ? button.color : "transparent"
  };
  border: 2px solid ${button.color};
  span {
    a {
      color: ${button.color !== "#FFF200" ? button.color : "black"};
    }
  }
  border: ${button?.color === "#FFFFFF" ? "2px solid black" : "none"};
  a {
    border: none;
  }
`
);
