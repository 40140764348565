// @flow
import React from "react";
import {
  StyledSection,
  Content,
  HeadingContainer,
  Heading,
  Line
} from "./styles";

const Section = (props: Props): React.Node => (
  <StyledSection {...props}>
    <HeadingContainer>
      <Heading>{props.heading}</Heading>
      <Line />
    </HeadingContainer>
    <Content>{props.children}</Content>
  </StyledSection>
);

export default Section;
