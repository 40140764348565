// @flow
import * as React from "react";
import parse from "html-react-parser";
import { Text as StyledText } from "./styles";

type Props = {
  text: String,
  padded?: Boolean
};

function Text({ text, padded }: Props): React.Node {
  return <StyledText padded={padded}>{parse(text)}</StyledText>;
}

export default Text;
