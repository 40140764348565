import styled from "styled-components";

export const InboxWrapper = styled.div(
  ({ theme }) => `
  width: 100%;
  max-width: 1040px;
  height: 600px;
  padding: ${theme.spacing}rem 0;
`
);
