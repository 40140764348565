import React from "react";
import Chip from "Components/Chip";
import { ChipSelector } from "./styles";

const chips = [
  { position: "left", label: "Featured" },
  { position: "right", label: "All" }
];

const ToggleChips = ({ toggle, selected }) => {
  return (
    <ChipSelector>
      {chips?.map(({ position, label }) => (
        <Chip
          active={selected === label}
          onClick={() => toggle(label)}
          position={position}
        >
          {label}
        </Chip>
      ))}
    </ChipSelector>
  );
};

export default ToggleChips;
