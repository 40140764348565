// @flow
import React from "react";
import parse from "html-react-parser";
import { Wrapper, Container, StyledText, Group } from "./styles";
import type { ShowcaseItem } from "Types";
import Video from "Components/Blocks/Video";

type Props = {
  background_color: String,
  columns: Number,
  showcaseItems: Array<ShowcaseItem>
};

const VideoShowcase = ({
  background_color,
  items,
  columns
}: Props): React.Node => {
  const hasText = Boolean(items[0].text);

  return (
    <Wrapper background_color={background_color}>
      <Container
        background_color={background_color}
        columns={columns}
        hasText={hasText}
      >
        {items?.map((item, i) => (
          <Group
            key={i}
            align={item?.content_alignment}
            columns={columns}
            textOnly={!item.image}
          >
            {item?.type === "url" && item?.video_url && (
              <Video url={item?.video_url} />
            )}
            {item?.type === "file" && item?.video_file && (
              <Video
                file={item?.video_file}
                cover={item?.video_cover}
                columns={columns}
              />
            )}
            {Boolean(item?.text?.length) && (
              <StyledText textOnly={!item.image}>
                {!item.image && (
                  <img src={require("Assets/Images/collab-slash.png")} />
                )}
                {parse(item?.text)}
              </StyledText>
            )}
          </Group>
        ))}
      </Container>
    </Wrapper>
  );
};

export default VideoShowcase;
