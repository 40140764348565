// @flow
import React from "react";
import parse from "html-react-parser";
import {
  Container,
  Badge,
  BadgeLeft,
  BadgeRight,
  Image,
  Details,
  Action,
  StyledLink as Link,
  Lists,
  List,
  Wrapper,
  StyledButton as Button
} from "./styles";
import type { ButtonType } from "Types";

type Props = {
  background_color: String,
  buttons: Array<ButtonType>,
  image: String,
  image_aligment: String,
  text: String,
  text_color: String
};

const Announcement = ({
  background_color,
  buttons,
  image,
  image_alignment,
  text,
  lists,
  text_color,
  badge
}: Props): React.Node => {
  return (
    <Wrapper background_color={background_color}>
      {badge?.badge_text && (
        <Badge background_colour={badge?.background_colour}>
          <BadgeLeft />
          <BadgeRight />
          {badge?.badge_text}
        </Badge>
      )}
      <Container background_color={background_color} text_color={text_color}>
        <Image src={image} alignment={image_alignment}></Image>
        <Details alignment={image_alignment} badge={Boolean(badge?.badge_text)}>
          {parse(text)}
          {lists && (
            <Lists>
              {lists.map((list, index) => (
                <List key={index}>
                  {list.list.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </List>
              ))}
            </Lists>
          )}
          {buttons && (
            <Action>
              {buttons.map((button, index) => (
                <Button key={index} button={button} padded id={button?.element_id}>
                  <Link
                    id={button?.text.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-")}
                    to={button?.link}
                  >
                    {button?.text}
                  </Link>
                </Button>
              ))}
            </Action>
          )}
        </Details>
      </Container>
    </Wrapper>
  );
};

export default Announcement;
