// @flow
import styled from "styled-components";

export const Card = styled.div(
  ({ theme }) => `
  min-width: 328px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadow};
  animation: fadeIn 0.8s ease-in-out;
  margin: auto;
  @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
    min-width: 100%;
  }
`
);

export const User = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  margin: ${theme.spacing * 1.5}rem;
  &:hover {
    cursor: pointer;
  }
`
);

export const UserDetails = styled.div(
  ({ theme }) => `
  margin-left: ${theme.spacing / 2}rem;
`
);

export const Name = styled.p(
  ({ theme }) => `
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  line-height: ${theme.fonts.size.small};
  margin: 0;
  margin-bottom: ${theme.spacing / 4}rem;
`
);

export const Detail = styled.p(
  ({ theme }) => `
  font-size: ${theme.fonts.size.xsmall};
  margin: 0;
`
);

export const ProjectContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.h3(
  ({ theme }) => `
  font-size: 18px;
  margin: 0 ${theme.spacing * 1.5}rem;
`
);

export const ImageContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 290px;
  margin: ${theme.spacing * 1.5}rem auto;

  @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
   width: auto;
   height: auto;
   padding: ${theme.spacing}rem;
  }
`
);

export const Cover = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  margin: auto;
`;
