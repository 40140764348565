// @flow
import React, { useState } from "react";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { useFetch } from "Hooks/useFetch";
import FormField from "Components/FormField";
import Button from "Components/Button";
import IconButton from "Components/IconButton";
import { Container, StepTitle, ProfileForm, Description } from "./styles";
import { MdClose } from "react-icons/md";
import SearchAutoComplete from "../SearchAutoComplete";
import { RowContainer, StyledTag } from "../VerificationForms/styles";

function SecondarySpecialism({ editing, setEditing, addSecondarySpecialism, secondarySpecialisms, setSecondarySpecialisms }) {
	const { data } = useFetch({ url: "/api/specialisms" });
	const specialisms = data?.specialisms || [];
	const [error, setError] = useState(null);

	const [submitSpecialisms, specialismResponse] = useLazyFetch({
		url: "/api/me/specialisms",
		method: "put",
	});

	function onSuccess() {
		setEditing(false);
	}

	function onError(error) {
		console.log('error', error);
	}

	return (
		<React.Fragment>
			<ProfileForm>
				<StepTitle>Update your secondary specialism</StepTitle>
				<Description>
					Add up to 2 specialisms, you can add secondary specialisms next.
				</Description>
				<FormField style={{ border: "solid 1px red" }}>
					{secondarySpecialisms.length < 2 && (
						<SearchAutoComplete
							placeholder={"Start typing a specialism (eg. Textile designer)…"}
							items={specialisms.map((s) => s.friendly_name)}
							handleSuggestionSelected={(specialism) =>
								addSecondarySpecialism(specialism)
							}
						/>
					)}
					<RowContainer>
						{secondarySpecialisms?.map((specialism, index) => (
							<StyledTag closingIcon={true} key={index}>
								{specialism}
								<IconButton
									style={{
										color: "#a9a9a9",
										fontSize: "11px",
										height: "1.2rem",
										width: "1.2rem",
										margin: 0,
									}}
									onClick={(e) => {
										e.preventDefault();
										setSecondarySpecialisms(
											secondarySpecialisms.filter((s) => s !== specialism)
										);
									}}
								>
									<MdClose />
								</IconButton>
							</StyledTag>
						))}
					</RowContainer>
				</FormField>
				{specialismResponse?.loading ? "Submitting..." :
					<Button
						disabled={secondarySpecialisms.length < 1}
						onClick={(e) => {
							if (secondarySpecialisms.length > 0) {
								submitSpecialisms({
									specialisms: {
										friendly_names: secondarySpecialisms,
										priority: "secondary",
									},
								},
								onSuccess,
								onError
								);
							}
						}}
					>
						Save
					</Button>
				}
			</ProfileForm>
		</React.Fragment>
	);
}

export default SecondarySpecialism;
