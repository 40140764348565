import React from "react";
import { useAuth } from "Hooks/useAuth";
import { API_URL } from "Config";

export const useLazyFetch = ({ url, method = "get" }) => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const { auth } = useAuth();

  const fetchData = async (variables, onSuccess, onError) => {
    setLoading(true);

    try {
      const res = await fetch(API_URL + url, {
        method,
        body: JSON.stringify(variables),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${auth.access_token}`
        }
      });
      if (!res.ok) {
        throw res.statusText;
      } else {
        setLoading(false);
        const result = await res.json();
        if (onSuccess) onSuccess(result);
        return result;
      }
    } catch (error) {
      setError(error);
      if (onError) onError(error);
      setLoading(false);
    }
  };

  const cb = async (variables, onSuccess, onError) =>
    fetchData(variables, onSuccess, onError).then(res => {
      setData(res);
      return res;
    });

  return [cb, { data, error, loading }];
};
