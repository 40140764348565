// @flow
import React from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { formatDate } from "Helpers/Date";
import { Container, Heading, Image, Details, Date, StyledLink } from "./styles";

const BlogPostPreview = ({ post }): React.Node => {
  const url = `/features/${post?.slug}`;

  return (
    <Container>
      {post?.featured_image && (
        <Link to={url}>
          <Image src={post.featured_image?.sizes?.large?.source_url} />
        </Link>
      )}
      <Details>
        <Heading>
          <Link to={url}>{post?.title}</Link>
        </Heading>
        <Date>{formatDate(post?.created_at)}</Date>
        {post?.excerpt && parse(post.excerpt)}
        <StyledLink to={url}>Continue reading</StyledLink>
      </Details>
    </Container>
  );
};

export default BlogPostPreview;
