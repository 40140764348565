import styled from "styled-components";

export const Container = styled.div(
  ({ theme }) => `
  display:flex;
  flex-direction: row-reverse;
  max-width: ${theme.maxWidth}px;
  padding: 0 ${props => props.theme.spacing}rem;
  margin: ${theme.spacing * 2}rem auto;
  margin:auto;
  justify-content:center;
`
);

export const WrapContent = styled.div(
  ({ theme }) => `
  margin: 0 ${theme.spacing * 2}rem;
  padding-top:${theme.spacing * 2}rem;
`
);

export const SideBanner = styled.div(
  ({ theme }) => ` 
  a {
    text-decoration:none;
    color: black;
    font-size: ${theme.fonts.size.normal}
    font-weight:bold;
    word-break: break-word;
  }
  display:block;

  @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
    display: none;
   }

`
);

export const AffiliateWrapper = styled.div(
  ({ theme }) => `
  display:flex;
  flex-direction: row-reverse;
  max-width: ${theme.maxWidth}px;
  padding: 0 ${props => props.theme.spacing}rem;
  margin: ${theme.spacing * 2}rem auto;
  margin:auto;
  justify-content:center;

  @media screen and (min-width: ${theme.breakpoints.xsmall}px) {
    display: none;
   }
`
);
