// @flow

type Palette = {
  default: string,
  disabled?: string,
  light?: string,
  dark?: string
};

const createColorPalette = (palette: Palette) => {
  return (color: string = "default"): string => {
    return palette[`${color}`];
  };
};

export default {
  breakpoints: {
    xsmall: 576,
    small: 730,
    medium: 1150
  },
  maxWidth: 1140,
  spacing: 1, // 1rem
  masterSpacing: 20, // 10rem // @TODO - change name and update
  border: {
    radius: 30
  },
  fonts: {
    default: "Raleway, sans-serif",
    size: {
      large: "2rem",
      medium: "1.5rem",
      normal: "1rem",
      small: "0.875rem",
      xsmall: "0.75rem",
      tiny: "0.625rem"
    }
  },
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    yellow: createColorPalette({
      default: "rgb(255, 242, 0)",
      disabled: "rgba(255, 242, 0, 0.8)"
    }),
    grey: createColorPalette({
      default: "#d8d8d8",
      light: "#f8f7f7",
      lighter: "rgb(248,247,247)",
      mid: "#a9a9a9",
      dark: "#666",
      darker: "rgba(0,0,0,0.9)",
      landing: "#333333"
    })
  },
  avatar: {
    small: 32,
    medium: 48,
    large: 64,
    xlarge: 96,
    xxlarge: 150
  },
  shadow: "0 0 10px 0px rgba(0, 0, 0, 0.05)",
  zIndex: {
    front: 1000,
    middle: 500,
    back: 0
  }
};
