// @flow
import React from "react";
import { useAuth } from "Hooks/useAuth";
import { useHistory } from "react-router-dom";
import { User } from "Types";
import {
  Container,
  Content,
  EditableArea,
  Heading,
  Details,
  PeerDetail,
  Bold,
  ActionButton,
  BoldWrap, Detail
} from "./styles";
import Avatar from "Components/Avatar";

type Props = {
  user: User
};

function Availability({ user: { profile }, currentUser: { business } }) {
  const available = profile?.available;
  const availabilityVisibleFor = profile?.availability_visible_for;

  if (availabilityVisibleFor === "hidden") return null;
  if (availabilityVisibleFor === "business_only" && !business) return null;

  const greenOval = require("Assets/Images/oval.svg");
  const greyOval = require("Assets/Images/oval-grey.svg");

  return (
    <Detail style={{ justifyContent: "center", alignItems: "center" }}>
      <img
        style={{ paddingRight: "0.5rem" }}
        src={available ? greenOval : greyOval}
      />
      <BoldWrap>{available ? "Currently available" : "Not available"}</BoldWrap>
    </Detail>
  );
}

function PeerProfileOverview({ user }: Props) {
  const { auth } = useAuth();
  const currentUser = auth?.user;
  let history = useHistory();

  function messageUser() {
    history.push({
      pathname: "/messaging",
      state: { otherUser: user }
    });
  }

  return (
    <Container>
      <Avatar size="xxlarge" src={user?.profile?.photo} />
      <Content>
        <EditableArea>
          <PeerDetail>
            <Heading>
              {user?.profile?.first_name} {user?.profile?.last_name}
            </Heading>
          </PeerDetail>
        </EditableArea>
        <Details>
          <div>
            <EditableArea>
              <PeerDetail>
                <BoldWrap>{user?.profile?.location}</BoldWrap>
              </PeerDetail>
            </EditableArea>
            <EditableArea>
              <PeerDetail>
                <BoldWrap>
                  {user?.profile?.occupation}
                  {user?.profile?.occupation &&
                    user?.profile?.company &&
                    " at "}
                  {user?.profile?.company}
                </BoldWrap>
              </PeerDetail>
            </EditableArea>
          </div>
          <div>
            { user?.primary_specialisms?.length > 0 &&
              <EditableArea>
                <PeerDetail>
                  <Bold>Primary Specialisms: </Bold>
                  <span>
                    {user?.primary_specialisms
                      ?.map(item => item.friendly_name)
                      .join(", ")}
                  </span>
                </PeerDetail>
              </EditableArea>
            }
            { user?.secondary_specialisms?.length > 0 &&
              <EditableArea>
                <PeerDetail>
                  <Bold>Secondary Specialisms: </Bold>
                  <span>
                    {user?.secondary_specialisms
                      ?.map(item => item.friendly_name)
                      .join(", ")}
                  </span>
                </PeerDetail>
              </EditableArea>
            }
            { !user?.primary_specialisms?.length > 0 &&
              <EditableArea>
                <PeerDetail>
                  <Bold>Specialisms: </Bold>
                  <span>
                    {user?.specialisms
                      ?.map(item => item.friendly_name)
                      .join(", ")}
                  </span>
                </PeerDetail>
              </EditableArea>
            }
            <EditableArea>
              <Availability user={user} currentUser={currentUser} />
            </EditableArea>
          </div>
        </Details>
      </Content>
      {currentUser?.approval_status === "approved" && (
        <ActionButton size="small" variant="black" onClick={messageUser}>
          Message
        </ActionButton>
      )}
    </Container>
  );
}

export default PeerProfileOverview;
