import React, { useState } from "react";
import { useFetch } from "Hooks/useFetch";
import { Filters, ToggleContainer } from "./styles";
import ToggleChips from "Components/ToggleChips";
import Dropdown from "Components/Dropdown";
import SearchField from "Components/SearchField";

const ProjectFilters = ({
  specialism,
  setSpecialism,
  searchValue,
  setSearchValue,
  selectedChip,
  selectChip,
  setFeatured
}) => {
  const { data } = useFetch({ url: "/api/specialisms" });
  const specialisms = data?.specialisms || [];
  const [active, setActive] = useState(null);

  const filterSpecialisms = specialism => {
    setSpecialism(specialism);
  };

  const onSearch = e => {
    setSearchValue(e.target.value);
  };

  const toggleFeatured = label => {
    selectChip(label);
    if (label === "Featured") {
      setFeatured(true);
    } else {
      setFeatured(false);
    }
  };

  const items = specialisms.map(({ id, friendly_name }) => ({
    id,
    label: friendly_name
  }));

  return (
    <Filters>
      <SearchField
        value={searchValue}
        clearValue={() => setSearchValue("")}
        onChange={onSearch}
        placeholder="Search by location"
        active={active === "search"}
        setActive={setActive}
      />
      <Dropdown
        defaultChoice="Filter by Specialism"
        items={items}
        selectedItem={specialism}
        setSelectedItem={filterSpecialisms}
        active={active === "filters"}
        setActive={setActive}
      />
      <ToggleContainer>
        <ToggleChips selected={selectedChip} toggle={toggleFeatured} />
      </ToggleContainer>
    </Filters>
  );
};

export default ProjectFilters;
