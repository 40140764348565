import styled from "styled-components";

export const Filters = styled.div(
  ({ theme }) => `
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing}rem;
  margin: 0;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacing * 4}rem;
    padding: ${theme.spacing * 3}rem ${theme.spacing}rem;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    max-width: ${theme.maxWidth}px;
  }
`
);

export const ToggleContainer = styled.div(
  ({ theme }) => `
  display: none;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: flex;
    justify-content: flex-end;
  }
`
);
