// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import { type User } from 'Types';
import Avatar from 'Components/Avatar';
import {
	Container,
	Item,
	Profile,
	Details,
	Occupation,
	Company,
	StyledButton as Button,
	Empty,
} from './styles';
import { FaPaperPlane } from 'react-icons/fa';

type Props = {
	users: Array<User>,
};

const UserList = ({ users }: Props): React.Node => {
	let history = useHistory();

	function viewProfile(user) {
		history.push(`/profiles/${user?.id}/portfolio`);
	}

	function messageUser(user) {
		history.push({
			pathname: '/messaging',
			state: { otherUser: user },
		});
	}

	return users?.length ? (
		<Container>
			{users.map((user, index) => (
				<Item key={index}>
					<Profile onClick={() => viewProfile(user)}>
						<Avatar
							size="xlarge"
							src={user?.profile?.photo}
							style={{ minWidth: '120px', marginRight: '0.5rem' }}
						/>
						<Details>
							<h3>
								{user?.profile?.first_name} {user?.profile?.last_name}
							</h3>
							<Occupation>{user?.profile?.occupation}</Occupation>
							<Company>{user?.profile?.company}</Company>
						</Details>
					</Profile>
					<Button variant="black" onClick={() => messageUser(user)}>
						<FaPaperPlane style={{ margin: '0.2rem' }} />
						Message
					</Button>
				</Item>
			))}
		</Container>
	) : (
		<Empty>No users found</Empty>
	);
};

export default UserList;
