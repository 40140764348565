// @flow
import React, { useState } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { useLazyFetch } from 'Hooks/useLazyFetch';
import { Helmet } from 'react-helmet';
import TalkJS from 'Components/TalkJS';
import Button from 'Components/Button';
import Avatar from 'Components/Avatar';
import {
	Container,
	Header,
	SearchBar,
	StyledInput,
	SearchIcon,
	Background,
	Modal,
	List,
	Item,
	Profile,
	StyledButton,
	PlainButton,
} from './styles';
import { FaPaperPlane } from 'react-icons/fa';

const Messaging = () => {
	let history = useHistory();
	const location = useLocation();
	const [searchOpen, toggleSearchOpen] = useState(false);
	const [searchText, setSearchText] = useState(null);
	const [users, setUsers] = useState(null);
	const [fetch] = useLazyFetch({
		url: `/api/users?name=${searchText}`,
	});

	const getUsers = async (callback) => {
		const res = await fetch();
		if (res) callback(res);
	};

	function onChange(e) {
		setSearchText(e.target.value === '' ? null : e.target.value);
		if (e.target.value === '') setUsers(null);
	}

	function onSearch(e) {
		e.preventDefault();
		getUsers((data) => setUsers(data?.users));
	}

	function messageUser(user) {
		history.push({
			pathname: '/messaging',
			state: { otherUser: user },
		});
		toggleSearchOpen(false);
	}

	return (
		<>
			<Helmet>
				<title>ClickerMe | Direct Message</title>
				<meta
					name="description"
					content="Send a Direct Message to anyone on ClickerMe"
				/>
			</Helmet>
			<Container>
				<Header>
					<h1>Your Messages</h1>
					<Button
						size="small"
						variant="black"
						onClick={() => toggleSearchOpen(true)}
					>
						Compose new message
					</Button>
				</Header>
				<TalkJS otherUser={location?.state?.otherUser} />
			</Container>
			{searchOpen && (
				<Background>
					<Modal>
						<SearchBar onSubmit={onSearch}>
							<StyledInput
								type="text"
								placeholder="Search names..."
								value={searchText}
								onChange={onChange}
							/>
							<PlainButton type="submit">
								<SearchIcon src={require('Assets/Images/search.png')} />
							</PlainButton>
						</SearchBar>
						{users?.length > 0 ? (
							<List>
								{users.map((user, index) => (
									<Item key={index}>
										<Profile
											onClick={() =>
												history.push(`profiles/${user?.id}/portfolio`)
											}
										>
											<Avatar src={user?.profile?.photo} />
											{user?.profile?.first_name} {user?.profile?.last_name}
										</Profile>
										<StyledButton onClick={() => messageUser(user)}>
											<FaPaperPlane />
											<span>Message</span>
										</StyledButton>
									</Item>
								))}
							</List>
						) : (
							users && searchText && <p>No users found</p>
						)}
						<Button
							size="small"
							variant="black"
							onClick={() => toggleSearchOpen(false)}
						>
							Back to messages
						</Button>
					</Modal>
				</Background>
			)}
		</>
	);
};

export default Messaging;
