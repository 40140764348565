export const positioning = (
  { alignment },
  left,
  right,
  top = left,
  bottom = left,
  other = left
) => {
  switch (alignment) {
    case "left":
      return left;
    case "right":
      return right;
    case "top":
      return top;
    case "bottom":
      return bottom;
    default:
      return other;
  }
};
