// @flow
import React, { useState } from "react";
import { User } from "Types";
import { useClipboard } from "Hooks/useClipboard";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { FILESTACK_API_KEY } from "Config";
import FormField from "Components/FormField";
import ReactFilestack from "filestack-react";
import { FaCopy } from "react-icons/fa";
import CheckboxGroup from "Components/CheckboxGroup";
import PrimarySpecialism from "Components/PrimarySpecialism";
import SecondarySpecialism from "Components/SecondarySpecialism";
import RadioButtonGroup from "../RadioButtonGroup";
import { interests } from "Helpers/Data";
import {
	Container,
	AvatarContainer,
	ActionButton,
	Content,
	EditableArea,
	Heading,
	Details,
	Detail,
	Bold,
	BoldWrap,
	CopyContainer,
	FormModal,
	WrapButtons,
	WrapForm,
	AlignInstaHandle,
	WrapContent,
	Section,
} from "./styles";
import Avatar from "Components/Avatar";
import Button from "Components/Button";
import IconButton from "Components/IconButton";
import Modal from "Components/Modal";
import Input from "Components/Input";
import Notification from "Components/Notification";
import SearchLocationInput from "../SearchLocationInput";

type Props = {
	user: User,
};

function Availability({ userAvailability, availabilityVisibleFor }) {
	const greenOval = require("Assets/Images/oval.svg");
	const greyOval = require("Assets/Images/oval-grey.svg");

	let availabilityDetails = userAvailability ? "Currently available" : "Not available";

	if (availabilityVisibleFor === "hidden")
		availabilityDetails = availabilityDetails + " (hidden)";
	if (availabilityVisibleFor === "business_only")
		availabilityDetails = availabilityDetails + " (visible for business)";

	return (
		<Detail style={{ justifyContent: "center", alignItems: "center" }}>
			<img
				style={{ paddingRight: "0.5rem" }}
				src={userAvailability ? greenOval : greyOval}
			/>
			<BoldWrap>{availabilityDetails}</BoldWrap>
		</Detail>
	);
}

function UserProfileOverview({ user, addSpace }: Props) {
	const shareableLink = `${
		window.location.href.split("/profile")[0]
	}/profiles/${user?.id}/portfolio`;
	const [editing, setEditing] = useState(null);
	const [shareUrl, toggleShareUrl] = useState(false);
	const [copied, toggleCopied] = useState(false);
	const [data, setData] = useState(user);
	const [error, setError] = useState(null);
	const [submitProfile] = useLazyFetch({
		url: "/api/me/profile",
		method: "put",
	});
	useClipboard(() => toggleCopied(true));
	const edit = require("Assets/Images/edit.svg");
	const plus = require("Assets/Images/plus.svg");

	function setProfile(key, value) {
		setData({ ...data, profile: { ...data.profile, [key]: value } });
	}

	function submit(e) {
		e.preventDefault();
		setEditing(null);
		submitProfile(data?.profile, onSuccess, onError);
	}

	function onSuccess() {
		toggleModal();
		setError(null);
	}

	function onError() {
		setData({ ...data, profile: { ...user?.profile } });
		setError("Sorry, we could not update your profile right now");
	}

	function toggleModal() {
	  setEditing(null);
	}

	const [userInterests, setUserInterests] = useState(
		data?.interests?.map((s)=> s.friendly_name)
	);
	const [primarySpecialisms, setPrimarySpecialisms] = useState(
		data?.primary_specialisms?.map((s)=> s.friendly_name)
	);
	const [secondarySpecialisms, setSecondarySpecialisms] = useState(
		data?.secondary_specialisms?.map((s)=> s.friendly_name)
	);
	const [warningMessage, setWarningMessage] = useState(null);
	const [userAvailability, setUserAvailability] = useState(data?.profile?.available);
	const [availabilityVisibleFor, setAvailabilityVisibleFor] = useState(data?.profile?.availability_visible_for);
	const availabilityNames = [
		{ label: "Open to chat", value: true },
		{ label: "Unavailable", value: false },
	];
	const availabilityStatus = [
		{ label: "All ClickerMe members", value: "all" },
		{ label: "Business only", value: "business_only" },
		{ label: "Nobody (hidden)", value: "hidden" },
	];

	const [submitInterests, interestResponse] = useLazyFetch({
		url: "/api/me/interests",
		method: "put",
	});

	function addPrimarySpecialism(specialism) {
		if (primarySpecialisms.includes(specialism)) return;
		setPrimarySpecialisms([...primarySpecialisms, specialism]);
	}

	function addSecondarySpecialism(specialism) {
		if (secondarySpecialisms.includes(specialism)) return;
		setSecondarySpecialisms([...secondarySpecialisms, specialism]);
	}

	function handleInterestsClick() {
		userInterests.length > 0
			? submitInterests(
			{
				interests: {
					friendly_names: userInterests.map(
						(item) => item
					),
					available: userAvailability,
					availability_visible_for: availabilityVisibleFor,
				},
			},
			onSuccess,
			onError
			)
			: setWarningMessage(
			"Please select at least one interest."
			);
	}

	return (
		<Container>
			{error && <Notification>{error}</Notification>}
			<AvatarContainer>
				<Avatar size="xxlarge" src={data?.profile?.photo} />
				<ReactFilestack
					apikey={FILESTACK_API_KEY}
					customRender={({ onPick }) => (
						<IconButton
							yellow
							style={{ position: "absolute", top: "10px", zIndex: 5 }}
							onClick={onPick}
						>
							<img src={data?.profile?.photo ? edit : plus} />
						</IconButton>
					)}
					onSuccess={(res) => {
						if (res?.filesUploaded[0]?.url && !res?.filesFailed.length) {
							setError(null);
							setProfile("photo", res.filesUploaded[0].url);
							submitProfile(
								{
									...data?.profile,
									photo: res.filesUploaded[0].url,
								},
								() => window.location.reload
							);
						} else {
							setError(
								"Sorry, we could not update your profile photo right now"
							);
						}
					}}
					onError={(error) => setError(error)}
				/>
			</AvatarContainer>

			<Content>
				{editing === "name" ? (
					<Modal
						onClose={() => {
							toggleModal();
						}}
					>
						<FormModal onSubmit={(e) => submit(e, "profile")}>
							<h3>Update your name</h3>
							<WrapForm>
								<FormField label="First Name">
									<Input
										value={data?.profile?.first_name}
										placeholder="First Name"
										onChange={(e) => setProfile("first_name", e.target.value)}
									/>
								</FormField>
								<FormField label="Last Name">
									<Input
										value={data?.profile?.last_name}
										placeholder="Last Name"
										onChange={(e) => setProfile("last_name", e.target.value)}
									/>
								</FormField>
								<WrapButtons>
									<Button size="small" onClick={toggleModal} variant={"black"}>
										Cancel
									</Button>
									<Button size="small" type="submit">
										Save
									</Button>
								</WrapButtons>
							</WrapForm>
						</FormModal>
					</Modal>
				) : (
					<EditableArea>
						<Detail>
							<Heading>
								{data?.profile?.first_name} {data?.profile?.last_name}
							</Heading>
						</Detail>
						<IconButton onClick={() => setEditing("name")}>
							<img src={require("Assets/Images/edit.svg")} />
						</IconButton>
					</EditableArea>
				)}
					<Details>
					<div>
						{editing === "location" ? (
							<Modal
								onClose={() => {
									toggleModal();
								}}
							>
								<FormModal onSubmit={(e) => submit(e, "profile")}>
									<h3>Update your location</h3>
									<WrapForm>
										<FormField label="Location">
											<SearchLocationInput
												value={data?.profile?.location}
												placeholder={"Start typing your city..."}
												setLocation={(location) => setProfile("location", location)}									        
											/>
										</FormField>
										<WrapButtons>
											<Button
												size="small"
												onClick={toggleModal}
												variant={"black"}
											>
												Cancel
											</Button>
											<Button size="small" type="submit">
												Save
											</Button>
										</WrapButtons>
									</WrapForm>
								</FormModal>
							</Modal>
						) : (
							<EditableArea>
								<Detail>
									<BoldWrap>{data?.profile?.location}</BoldWrap>
								</Detail>
								<IconButton onClick={() => setEditing("location")}>
									<img src={require("Assets/Images/edit.svg")} />
								</IconButton>
							</EditableArea>
						)}
					</div>
					<div>
						{editing === "instagram_handle" ? (
							<Modal
								onClose={() => {
									toggleModal();
								}}
							>
								<FormModal onSubmit={(e) => submit(e, "profile")}>
									<h3>Update your Instagram handle</h3>
									<WrapForm>
										<FormField label="Instagram Handle">
											<AlignInstaHandle>
										  @
												<Input
													value={data?.profile?.instagram_handle}
													placeholder="Instagram Handle"
													onChange={(e) =>
														setProfile("instagram_handle", e.target.value)
													}
													style={{
														marginLeft: "0px",
														paddingLeft: "0",
														border: "0",
													}}
												/>
											</AlignInstaHandle>
										</FormField>
										<WrapButtons>
											<Button
												size="small"
												onClick={toggleModal}
												variant={"black"}
											>
												Cancel
											</Button>
											<Button size="small" type="submit">
												Save
											</Button>
										</WrapButtons>
									</WrapForm>
								</FormModal>
							</Modal>
						) : (
							<EditableArea>
								<Detail
									style={{ justifyContent: "center", alignItems: "center" }}
								>
									<img
										style={{ paddingRight: "0.5rem" }}
										src={require("Assets/Images/instagram.png")}
									/>                  
									{data?.profile?.instagram_handle ? (
										<BoldWrap>{'@' + data?.profile?.instagram_handle}</BoldWrap>
									) : (
										<div>Add your Instagram handle</div>
									)}
								</Detail>
								<IconButton onClick={() => setEditing("instagram_handle")}>
									<img src={require(`Assets/Images/${data?.profile?.instagram_handle ? "edit.svg" : "add-yellow.svg"}`)} />
								</IconButton>
							</EditableArea>
						)}
					</div>
					<div>
						{editing === "availability" ? (
							<Modal
								onClose={() => {
									toggleModal();
								}}
							>
								<FormModal onSubmit={(e) => submit(e, "profile")}>
									<WrapForm>
										<h2>Update your interests and availability</h2>
										<WrapContent style={{ marginTop: "2rem" }}>
											<Section>
												<h4
													style={{
															textAlign: "left",
															paddingBottom: "1rem",
															paddingTop: "1rem",
														}}
												>
													Choose your work interests
												</h4>
												<CheckboxGroup
													selected={userInterests}
													values={interests}
													onChange={(values) => setUserInterests(values)}
													yellow
													vertical={true}
													fullWidth={true}
												/>
											</Section>
											<Section>
												<h4
													style={{
														  textAlign: "left",
															paddingBottom: "1rem",
															paddingTop: "1rem",
														}}
												>
													Choose your availability
												</h4>
												<RadioButtonGroup
													selectedItem={userAvailability}
													values={availabilityNames}
													setSelectedItem={setUserAvailability}
													yellow
													vertical={true}
												/>
											</Section>
											<Section style={{ border: "0" }}>
												<h4
													style={{
															textAlign: "left",
															paddingBottom: "1rem",
															paddingTop: "1rem",
														}}
												>
													Choose who sees your availability
												</h4>
												<RadioButtonGroup
													selectedItem={availabilityVisibleFor}
													values={availabilityStatus}
													setSelectedItem={setAvailabilityVisibleFor}
													yellow
												/>
											</Section>
										</WrapContent>
										{interestResponse?.loading ? (
											"Submitting..."
										) : (
											<Button
												onClick={ () => { handleInterestsClick() } }
											>
												I'm Done
											</Button>
										)}
									</WrapForm>
								</FormModal>
							</Modal>
						) : (
							<EditableArea>
								<Availability userAvailability={userAvailability} availabilityVisibleFor={availabilityVisibleFor} />
								<IconButton onClick={() => setEditing("availability")}>
									<img src={require("Assets/Images/edit.svg")} />
								</IconButton>
							</EditableArea>
						)}
					</div>
					<div>
					{editing === "primarySpecialism" ? (
						<Modal
							onClose={() => {
								toggleModal();
							}}
						>
							<PrimarySpecialism
								editing={editing}
								setEditing={setEditing}
								addPrimarySpecialism={addPrimarySpecialism}
								primarySpecialisms={primarySpecialisms}
								setPrimarySpecialisms={setPrimarySpecialisms}
							/>
						</Modal>
					) : (
						<EditableArea>
							<Detail>
								<Bold>Primary Specialism: </Bold>
								{primarySpecialisms &&
									primarySpecialisms.join(", ")}
							</Detail>
							<IconButton onClick={() => setEditing("primarySpecialism")}>
								<img src={require("Assets/Images/edit.svg")} />
							</IconButton>
						</EditableArea>
					)}
					</div>
					<div>
					{editing === "secondarySpecialism" ? (
						<Modal
							onClose={() => {
								toggleModal();
							}}
						>
							<SecondarySpecialism
								editing={editing}
								setEditing={setEditing}
								addSecondarySpecialism={addSecondarySpecialism}
								secondarySpecialisms={secondarySpecialisms}
								setSecondarySpecialisms={setSecondarySpecialisms}
							/>
						</Modal>
					) : (
						<EditableArea>
							<Detail>
								<Bold>Secondary Specialism: </Bold>
									{secondarySpecialisms &&
									secondarySpecialisms.join(", ")}
							</Detail>
							<IconButton onClick={() => setEditing("secondarySpecialism")}>
								<img src={require("Assets/Images/edit.svg")} />
							</IconButton>
						</EditableArea>
					)}
					</div>
						<div>
							{editing === "interests" ? (
								<Modal
									onClose={() => {
										toggleModal();
									}}
								>
									<FormModal onSubmit={(e) => submit(e, "profile")}>
										<WrapForm>
											<h2>Update your interests and availability</h2>
											<WrapContent style={{ marginTop: "1rem" }}>
												<Section>
													<h4
														style={{
															textAlign: "left",
															paddingBottom: "1rem",
															paddingTop: "1rem",														
														}}
													>
														Choose your work interests
													</h4>
													<CheckboxGroup
														selected={userInterests || []}
														values={interests}
														onChange={(values) => setUserInterests(values)}
														yellow
														vertical={true}
														fullWidth={true}
													/>
												</Section>
												<Section>
													<h4
														style={{
															textAlign: "left",
															paddingBottom: "1rem",
															paddingTop: "1rem",
														}}
													>
														Choose your availability
													</h4>
													<RadioButtonGroup
														selectedItem={userAvailability}
														values={availabilityNames}
														setSelectedItem={setUserAvailability}
														yellow
														vertical={true}
													/>
												</Section>
												<Section style={{ border: "0" }}>
													<h4
														style={{
															textAlign: "left",
															paddingBottom: "1rem",
															paddingTop: "1rem",
														}}
													>
														Choose who sees your availability
													</h4>
													<RadioButtonGroup
														selectedItem={availabilityVisibleFor}
														values={availabilityStatus}
														setSelectedItem={setAvailabilityVisibleFor}
														yellow
													/>
												</Section>
											</WrapContent>						
												<Button onClick={ () => handleInterestsClick() }>
													I'm Done
												</Button>
										</WrapForm>
									</FormModal>
								</Modal>
							) : (
								<EditableArea>
									<Detail>
									<Bold>Interests: </Bold>
										{userInterests && userInterests.join(", ")}
									<IconButton onClick={() => setEditing("interests")}>
										<img src={require("Assets/Images/edit.svg")} />
									</IconButton>
									</Detail>
								</EditableArea>
							)}
					</div>
				</Details>
			</Content>
			{user?.approval_status === "approved" && (
				<ActionButton
					size="small"
					variant="black"
					onClick={() => toggleShareUrl(true)}
				>
					Share profile
				</ActionButton>
			)}
			{shareUrl && (
				<Modal
					onClose={() => {
						toggleShareUrl(false);
						toggleCopied(false);
					}}
				>
					<CopyContainer>
						<h2>Share your profile link</h2>
						<p>
							Copy and share the link to your profile. Anyone with the link will
							be able to view your profile.
						</p>
						<Input type="text" value={shareableLink} readonly id="share" />
						<Button data-clipboard data-clipboard-target="#share">
							<FaCopy size={20} /> {copied ? "Copied!" : "Copy"}
						</Button>
					</CopyContainer>
				</Modal>
			)}
		</Container>
	);
}

export default UserProfileOverview;
