// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { useFetch } from 'Hooks/useFetch';
import { useAuth } from 'Hooks/useAuth';
import Banner from 'Components/Blocks/Banner';
import Text from 'Components/Blocks/Text';
import Players from 'Components/Players';
import Video from 'Components/Video';
import Episodes from 'Components/Episodes';
import {
	Wrapper,
	MoreEpisodes,
	Heading,
	StyledAvatar as Avatar,
	WrapperQuote,
	Description,
	WrapperPlayers,
} from './styles';
import { withTheme } from 'styled-components';
import Feedback from 'Components/Blocks/Feedback';

function Podcasts() {
	const { data } = useFetch({ url: '/api/pages/podcasts' });
	const banner = data?.page?.blocks[0];
	const text = data?.page?.blocks[1];
	const { auth } = useAuth();

	return (
		<>
			<Helmet>
				<title>The Fashion Slashie | A ClickerMe Podcast</title>
				<meta
					name="description"
					content="The Fashion Slashie: A ClickerMe Podcast is centred around acclaimed members of the fashion industry who take on multiple roles"
				/>
				<htmlAttributes h1="The Fashion Slashie: A ClickerMe Podcast"></htmlAttributes>
			</Helmet>
			{banner && <Banner {...banner} />}
			<Wrapper>{text && <Text {...text} />}</Wrapper>
			<WrapperPlayers>
				<Players
					first={require('Assets/Images/applePodcastButton.svg')}
					appleplayer="apple podcasts"
					linkToApple="https://podcasts.apple.com/podcast/the-fashion-slashie-a-clickerme-podcast/id1515273204"
					second={require('Assets/Images/spotify-button.svg')}
					spotifyplayer="spotify"
					linkToSpotify="https://open.spotify.com/show/7q4XbPZXXyADJ5FXcXa3B2?si=zebSBIn-T8aGvC4SGdWTYQ"
					third={require('Assets/Images/google-play-button.svg')}
					googleplayer="google play"
					linkToGoogle="https://podcasts.google.com/?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8yMGU3Y2JjYy9wb2RjYXN0L3Jzcw%3D%3D"
					maxWidth="30vw"
					title="LISTEN & SUBSCRIBE"
				></Players>
			</WrapperPlayers>
			<MoreEpisodes>
				<Heading>
					<img src={require('Assets/Images/black-slash.svg')} />
					<h3>EPISODES</h3>
				</Heading>
				<Episodes></Episodes>
			</MoreEpisodes>
			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
			<Wrapper>
				<WrapperQuote>
					<Avatar></Avatar>
					<Description>
						<h3>MEET OUR PODCAST HOST </h3>
						<p>
							Lara Johnson-Wheeler is a London-based writer, editor and
							broadcaster. Specialising in arts and fashion criticism, Lara
							writes for the likes of Vogue, LOVE and Twin Magazine. Formerly
							Features Editor at SHOWstudio, she is a Contributing Editor at
							Buffalo Zine, Tatler and Dapper Dan Magazine.
						</p>
					</Description>
				</WrapperQuote>
			</Wrapper>
		</>
	);
}

export default Podcasts;
