// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${theme.colors.grey("light")};
  padding: ${theme.spacing}rem;
  text-align: center;
  z-index: ${theme.zIndex.front};
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    flex-direction: row;
  }
`
);

export const Text = styled.span(
  ({ theme }) => `
  flex: 1;
  text-align: left;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin-left: 100px;
    text-align: center;
  }
`
);

export const StyledLink = styled(Link)(
  ({ theme }) => `
  font-weight: bold;
  color: ${theme.colors.black};
  text-decoration: none;
  margin: 0 ${theme.spacing / 2}rem;
`
);

export const Button = styled.button(
  ({ theme }) => `
  border: none;
  background: ${theme.colors.yellow()};
  width: 100px;
  padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
  border-radius: 5px;
  float: right;
`
);
