// @flow
import React from "react";
import { Experience } from "Types";
import { Container, Title, Line } from "./styles";

type Props = {
  item: Experience
};

const ExperienceItem = ({ item }: Props): React.Node => (
  <Container>
    <Title>{item.title}</Title>
    {item.company && <Line>{item.company}</Line>}
    {item.description && <Line grey>{item.description}</Line>}
    <Line>
      {item.location && item.location}
      {item.location && (item.startDate || item.endDate) && " | "}
      {item.startDate && item.startDate} - {item.endDate && item.endDate}
    </Line>
  </Container>
);

export default ExperienceItem;
