// @flow
import React from 'react';
import { Heading, Logos, Wrap, EachLogo } from './styles';
const logoImages = [
	'rombaut',
	'show-studio',
	'hunger',
	'all-saints',
	'rca',
	'machine-a',
	'both',
	'rankin',
];
function BrandLogos({ background, noMargin }) {
	return (
		<Wrap background={background} noMargin={noMargin}>
			<Heading background={background}>
				Clients we’ve collaborated with 
			</Heading>
			<Logos>
				<>
					{logoImages.map((image) => {
						return (
							<EachLogo src={require(`Assets/Images/brands/${image}.png`)} />
						);
					})}
				</>
			</Logos>
		</Wrap>
	);
}

export default BrandLogos;
