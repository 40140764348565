// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from 'Hooks/useAuth';
import { useFetch } from 'Hooks/useFetch';
import Banner from 'Components/Blocks/Banner';
import Feedback from 'Components/Blocks/Feedback';
import ContentError from 'Components/ContentError';
import ContentLoading from 'Components/ContentLoading';
import BlogPostPreview from 'Components/BlogPostPreview';
import Inner from 'Components/Inner';
import Button from 'Components/Button';
import { Footer, Container, Heading } from 'Screens/BlogPost/styles';

const Blog = () => {
	const { auth } = useAuth();
	const { data: content } = useFetch({ url: '/api/pages/blog' });
	const banner = content?.page?.blocks[0];
	const { data, loading, error } = useFetch({
		url: '/api/posts',
	});

	if (loading) return <ContentLoading />;
	if (error) return <ContentError />;

	return (
		<>
			<Helmet>
				<title>ClickerMe | Features</title>
				<meta
					name="description"
					content="Explore fashion industry news, insights and articles on the fastest growing fashion network"
				/>
				<htmlAttributes h1="Fashion News, Insights & Articles"></htmlAttributes>
			</Helmet>
			{banner && <Banner {...banner} />}
			<Inner>
				{data?.posts.map((post, index) => (
					<BlogPostPreview key={index} post={post} />
				))}
			</Inner>
			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
		</>
	);
};

export default Blog;
