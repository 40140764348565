import styled from "styled-components";

export const ChipSelector = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    justify-content: center;
  }
`
);
