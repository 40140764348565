// @flow
import styled from "styled-components";
import Button from "Components/Button";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing * 2}rem;
`;

export const ImageContainer = styled.div`
  background-image: ${props => props.src};
  background-size: cover;
  width: 50%;
  height: auto;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    width: 100%;
    min-height: min-content;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledButton = styled(Button)`
  z-index: ${props => props.theme.zIndex.front};
`;
