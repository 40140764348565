// @flow
import React from "react";
import { Helmet } from "react-helmet";
import BlockContainer from "Components/BlockContainer";

function Faqs() {
  return (
    <>
      <Helmet>
        <title>FAQs</title>
      </Helmet>
      <BlockContainer name="faqs" />
    </>
  );
}

export default Faqs;
