// @flow
// @flow
import React, { useState } from "react";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { Experience } from "Types";
import { formatAsDate, displayDate } from "Helpers/Date";
import { InputRow, Actions, PlusButton, Header, Container } from "./styles";
import ExperienceSingle from "./ExperienceSingle";
import FormField from "Components/FormField";
import Input from "Components/Input";
import Modal from "Components/Modal";
import Button from "Components/Button";
import TextBox from "Components/TextBox";
import IconButton from "Components/IconButton";
import {plusIcon} from "../../Helpers/Icons";

type Props = {
  LargePlaceholder: React.Node,
  data: Object,
  setError: Function,
  setData: Function
};

function ExperienceForm({ data, setData, LargePlaceholder, setError }: Props) {
  const itemPlaceholder = {
    title: undefined,
    company: undefined,
    description: undefined,
    location: undefined,
    startDate: undefined,
    endDate: undefined
  };
  const dataExperience = data.experience || [];

  const [adding, toggleAdding] = useState(true);
  const [newItem, setNewItem] = useState(itemPlaceholder);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [submit, response] = useLazyFetch({
    url: "/api/me/experience",
    method: "put"
  });

  function setValue(key, value) {
    setNewItem({ ...newItem, [key]: value });
  }

  function onSubmit(e) {
    e.preventDefault();
    submit({ experience: [...dataExperience, newItem] }, onSuccess, onError);
  }

  function onSuccess() {
    if (Boolean(newItem)) setData({...data, experience: [...dataExperience, newItem]});
    setError(null);
    toggleAdding(false);
    setNewItem(null);
  }

  function onSuccessDelete() {
    setError(null);
    toggleAdding(false);
  }

  function onError() {
    setError("Sorry, we could not update your experience right now");
    setData({...data, experience: dataExperience});
  }

  function onDelete() {
    setData({...data, experience: [...dataExperience.filter((item, index) => index !== itemToDelete)]});
    submit(
      { experience: [...dataExperience.filter((item, index) => index !== itemToDelete)] },
      onSuccessDelete,
      onError
    );
    setItemToDelete(null);
  }

  return (
    <React.Fragment>
      {dataExperience?.map((item, index) => (
        <ExperienceSingle
          key={index}
          item={item}
          updateItem={updatedItem =>
            setData({...data, experience: [
              ...dataExperience.map((item, i) => (i === index ? updatedItem : item))
            ]})
          }
          deleteItem={() => setItemToDelete(dataExperience.indexOf(item))}
          submit={() => submit({ experience: dataExperience }, onSuccess, onError)}
        />
      ))}
      {!adding && !dataExperience.length && <LargePlaceholder />}
      {newItem && (
        <Container onSubmit={onSubmit}>
          <InputRow>
            <FormField label="Company">
              <Input
                value={newItem.company}
                onChange={e => setValue("company", e.target.value)}
              />
            </FormField>
            <FormField label="Job title">
              <Input
                value={newItem.title}
                onChange={e => setValue("title", e.target.value)}
              />
            </FormField>
          </InputRow>
          <FormField label="Location">
            <Input
              value={newItem.location}
              onChange={e => setValue("location", e.target.value)}
            />
          </FormField>
          <InputRow>
            <FormField label="Start date">
              <Input
                type="month"
                value={formatAsDate(newItem?.startDate)}
                placeholder="YYYY-MM"
                onChange={e =>
                  setValue("startDate", displayDate(e.target.value))
                }
              />
            </FormField>
            <FormField label="End date">
              <Input
                type="month"
                value={formatAsDate(newItem?.endDate)}
                placeholder="YYYY-MM"
                onChange={e => setValue("endDate", displayDate(e.target.value))}
              />
            </FormField>
          </InputRow>
          <FormField label="Desciption">
            <TextBox
              value={newItem.description}
              onChange={e => setValue("description", e.target.value)}
            />
          </FormField>
          <Actions>
            <Button size="small" padded type="submit">
              {response?.loading ? "Saving" : "Save to profile"}
            </Button>
          </Actions>
        </Container>
      )}
      {itemToDelete !== null && (
        <Modal onClose={() => setItemToDelete(null)}>
          Are you sure you want to delete this item?
          <Button onClick={onDelete}>Yes</Button>
        </Modal>
      )}
      {!adding &&
      <PlusButton
        onClick={() => {
          toggleAdding(true);
          setNewItem(itemPlaceholder);
        }}
      >
        <IconButton yellow>
          <img src={plusIcon} />
        </IconButton>
        Add another
      </PlusButton>
      }
    </React.Fragment>
  );
}

export default ExperienceForm;
