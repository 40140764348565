export const ENV = process.env.NODE_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL;
export const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;
export const TALK_JS_API_KEY = process.env.REACT_APP_TALK_JS_API_KEY;
export const FILESTACK_API_KEY = process.env.REACT_APP_FILESTACK_API_KEY;
export const FILESTACK_WORKFLOW = process.env.REACT_APP_FILESTACK_WORKFLOW;
export const GOOGLE_TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const PIXEL_ID = process.env.REACT_APP_PIXEL_ID;

export default {
  ENV,
  API_URL,
  OAUTH_URL,
  OAUTH_CLIENT_ID,
  TALK_JS_API_KEY,
  FILESTACK_API_KEY,
  FILESTACK_WORKFLOW,
  GOOGLE_TRACKING_ID,
  GOOGLE_API_KEY,
  GTM_ID,
  PIXEL_ID
};
