import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div(
  ({ theme }) => `
  width: 100%;
  min-height: 90vh;
  background: ${theme.colors.white};
`
);

export const Container = styled.div(
  ({ theme }) => `
  max-width: 100%;
  width: ${theme.maxWidth}px;
  margin: auto;
  padding-bottom: ${theme.spacing * 2}rem;
`
);

export const Header = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing * 2}rem  ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    padding: ${theme.spacing * 2}rem 0;
  }
`
);

export const Details = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #000;
`;

export const SwitchButton = styled.button(
  ({ theme, src, active }) => `
  background-image: url(${src});
  background-color: ${theme.colors.grey()};
  background-blend-mode: ${active ? "none" : "screen"};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  width: ${theme.spacing * 2}rem;
  height: ${theme.spacing * 2}rem;
  margin: ${theme.spacing / 2}rem;
  border: none;
  cursor: pointer;
  @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
    display: none;
  }
`
);

export const Name = styled.h2(
  ({ theme }) => `
  font-size: ${theme.fonts.size.medium};
  margin: 0;
`
);

export const Location = styled.p(
  ({ theme }) => `
  font-size: ${theme.fonts.size.small};
  margin: 0;
  font-weight: bold;
`
);

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 590px;
  margin: auto;
  text-align: center;
`;

export const Title = styled.h1``;

export const Description = styled.p(
  ({ theme }) => `
  font-size: ${theme.fonts.size.small};
  text-align: center;
  padding: ${theme.spacing}rem;
  margin-bottom: ${theme.spacing * 3}rem;
  @media screen and (min-width: ${theme.breakpoints.xsmall}px) {
    padding: 0;
    margin-bottom: ${theme.spacing * 5}rem;
  }
`
);
