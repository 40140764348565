// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import BlockContainer from 'Components/BlockContainer';
import Feedback from 'Components/Blocks/Feedback';

function OpenSubmissions() {
	return (
		<>
			<Helmet>
				<title>ClickerMe | Open Call Submissions</title>
				<meta
					name="description"
					content="Create beautiful work for fashion brands – open call submissions for fashion professionals and aspiring creatives"
				/>
				<htmlAttributes h1="Live Briefs For Fashion Professionals"></htmlAttributes>
			</Helmet>
			<BlockContainer name="open-submissions" backgroundColor="#FFF" />
			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
		</>
	);
}

export default OpenSubmissions;
