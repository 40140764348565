// @flow
import React, { useState } from "react";
import { useLazyFetch } from "Hooks/useLazyFetch";
import IconButton from "Components/IconButton";

import {
  StyledTag,
  Container,
  RowContainer,
  SaveButton,
  Actions
} from "./styles";
import { MdClose } from "react-icons/md";
import SearchAutoComplete from "../SearchAutoComplete";
import { useFetch } from "Hooks/useFetch";

type Props = {
  data: Object,
  setData: Function,
  checkIfDone: Function,
  SmallPlaceholders: React.Node,
  setError: Function,
  icons: Object
};

function SkillsForm({ setData, data, SmallPlaceholders, setError, icons, setIsOpen, checkIfDone }: Props) {
  const dataSkills = data.skills || [];
  const [editing, toggleEditing] = useState(true);

  const { data: skillsResult } = useFetch({ url: "/api/predefined_skills" });
  const allSkills = skillsResult?.predefined_skills || [];

  const [submit, response] = useLazyFetch({
    url: "/api/me/skills",
    method: "put"
  });

  function addSkill(skillName) {
    if (dataSkills.find(s => s.text === skillName)) return;

    setData({...data, skills: [...dataSkills, { text: skillName }]});
  }

  function onSubmit() {
    submit({ skills: dataSkills }, onSuccess, onError);
    toggleEditing(false);
    setIsOpen(false);
  }

  function onSuccess() {
    checkIfDone();
    setError(null);
  }

  function onError() {
    setError("Sorry, we could not update your skills right now");
    setData({skills: dataSkills});
  }

  return (
    <React.Fragment>
      {editing ? (
        <Container>
          <SearchAutoComplete
            placeholder={"Start typing a skill (e.g. Pattern cutting, Photoshop)…"}
            items={allSkills.map(s => s.name)}
            handleSuggestionSelected={addSkill}
          />
          <RowContainer>
            {dataSkills?.map((skill, index) => (
              <StyledTag closingIcon={true} key={index}>
                {skill.text}
                <IconButton style={{
                  color: '#a9a9a9',
                  fontSize: '11px',
                  height: '1.2rem',
                  width: '1.2rem',
                  margin: 0
                }}
                  onClick={e => {
                    e.preventDefault();
                    setData({...data, skills: [...dataSkills.filter(item => item.text !== skill.text)]});
                  }}
                >
                  <MdClose />
                </IconButton>
              </StyledTag>
            ))}
          </RowContainer>
        </Container>
      ) : dataSkills?.length > 0 ? (
        <RowContainer>
          { dataSkills.map((skill, index) => <StyledTag key={index}> {skill.text} </StyledTag>)}
        </RowContainer>
      ) : (
        <SmallPlaceholders />
      )}
      <Actions>
        {editing ? (
          <SaveButton
            disabled={!dataSkills.length}
            size="small"
            onClick={onSubmit}
          >
            <span>{response.loading ? "Saving" : "Save to profile"}</span>
          </SaveButton>
        ) : (
          <IconButton yellow={!dataSkills?.length} onClick={() => toggleEditing(true)}>
            {dataSkills?.length ? icons.edit : icons.plus}
          </IconButton>
        )}
      </Actions>
    </React.Fragment>
  );
}

export default SkillsForm;
