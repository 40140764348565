// @flow
import styled from "styled-components";

export const CheckboxContainer = styled.div(
	({ vertical, theme }) => `
  display: ${vertical ? "block" : "grid"};
	grid-auto-flow: column;
	grid-gap: 1px;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: repeat(2, auto);
  align-items: center;
  max-width: 600px;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    display: flex;
		flex-direction: column;
    align-items: flex-start;
    max-width: 100vw;
    transform: translateX(0%);
  }

`
);
