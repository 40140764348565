// @flow
import React from 'react';
import { WrapAffiliateSingle } from './styles';
import { Description } from '../VerificationSteps/styles';
import Notification from 'Components/Notification';
import SectionHeader from '../SectionHeader';
import Step from '../Step';

type Props = {
	children: React.Node,
};

function ApprovedPage(props: { error: null, userName: string }) {
	return (
		<>
			<WrapAffiliateSingle>
				{props.error && <Notification>{props.error}</Notification>}
				<SectionHeader
					text={'Explore new ways to connect, collaborate and create'}
				/>
				<Description style={{ height: 'auto', maxWidth: '590px' }}>
					<p>
						The ClickerMe team have reviewed your profile and approved your
						work. Start re-shaping the industry now, by exploring new ways to
						connect with and be discovered by our community of fashion
						professionals.
					</p>
					<p>New features available to you include:</p>
				</Description>
				<Step
					text={'PUBLISH YOUR PROJECTS'}
					isDone={true}
					linkTo={'/profile/portfolio'}
				/>
				<Step text={'EXPLORE THE NEWS FEED'} isDone={true} linkTo={'/feed'} />
				<Step
					text={'SEND DIRECT MESSAGES'}
					isDone={true}
					linkTo={'/messaging'}
				/>
				<Step
					text={'SHARE YOUR PROFILE WITH EVERYONE'}
					isDone={true}
					linkTo={'/profile'}
				/>
				<Step
					text={'ENTER COMPETITIONS'}
					isDone={true}
					linkTo={'/competitions'}
				/>
				<Step
					text={'APPLY TO LIVE BRIEFS'}
					isDone={true}
					linkTo={'/open-submissions'}
				/>
			</WrapAffiliateSingle>
		</>
	);
}
export default ApprovedPage;
