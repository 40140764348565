import styled from "styled-components";

export const Wrapper = styled.div(
  ({ theme, centre }) => `
  padding: ${theme.spacing / 2}rem 0;
  width: 100%;
  max-width: ${centre ? "590px" : "initial"};
`
);

export const Label = styled.span(
  ({ theme, color }) => `
  font-size: ${theme.fonts.size.small};
  color: ${color}
`
);

export const OptionalLabel = styled.span(
  ({ theme }) => `
  width: 69px;
  height: 19px;
  font-family: Raleway;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #767676;
`
);

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Error = styled.div(
  ({ theme, centre }) => `
  font-size: ${theme.fonts.size.small};
  color: red;
  text-align: ${centre ? "center" : "left"};
`
);

export const Wrap = styled.div(
  ({ theme, centre }) => `
  display: flex;
  justify-content: space-between;
`
);
