import React from "react";
import theme from "Styles/Theme";
import { useState, useEffect } from "react";
import {
  StyledImage,
  Container,
  Details,
  WrapProviders,
  StyledDescription,
  Title,
  Date,
  Heading,
  VideoWrapper,
  Wrapper
} from "./styles";
import Players from "Components/Players";
import SocialShares from "Components/SocialShares";
import Video from "Components/Video";
import { GlobalWrapper } from "../../Screens/FashionSlashie/styles";

function IndividualPodcast({ details }: Props) {
  const percentOfViewport = (80 / 100) * window.innerWidth;
  const [viewportWidth, setWidth] = useState(window.innerWidth);
  const heightYoutube = percentOfViewport / 1.78;
  const height =
    viewportWidth > theme.breakpoints.small ? "426px" : heightYoutube;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <>
      <GlobalWrapper>
        <Container>
          <Title>{details.name}</Title>
          <Date>
            {details.number} | {details.date}
          </Date>
          <StyledDescription>{details.description}</StyledDescription>
          <iframe
            src={details.spotifyEmbedded}
            width="100%"
            height="232"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <WrapProviders>
            <Players
              first={require("Assets/Images/applePodcastButton.svg")}
              appleplayer="apple podcasts"
              linkToApple={details.linkToApple}
              second={require("Assets/Images/spotify-button.svg")}
              spotifyplayer="spotify"
              linkToSpotify={details.linkToSpotify}
              third={require("Assets/Images/google-play-button.svg")}
              googleplayer="google play"
              linkToGoogle={details.linkToGoogle}
              title="LISTEN & SUBSCRIBE"
            ></Players>
            <SocialShares
              first={require("Assets/Images/twitter-share.svg")}
              second={require("Assets/Images/facebook-share.svg")}
              third={require("Assets/Images/linkedin-share.svg")}
              titleOfContainer="SHARE EPISODE"
              url={details.SocialShareUrl}
              facebookTags={details.facebookTags}
              twitterTitle={details.twitterTitle}
              via={details.via}
              twitterTags={details.twitterTags}
              related={details.related}
              summary={details.summary}
              source={details.source}
            ></SocialShares>
          </WrapProviders>
          <Heading>
            <img src={require("Assets/Images/black-slash.svg")} />
            <h3 style={{ color: "black" }}>{details.number} notes</h3>
          </Heading>
          <Details>{details.notes}</Details>
        </Container>
      </GlobalWrapper>
      {details.additionalVideo == "true" && (
        <Wrapper>
          <VideoWrapper>
            <Heading>
              <img src={require("Assets/Images/white-slash.svg")} />
              <h3 style={{ color: "white" }}>
                behind the scenes with {details.authorName}
              </h3>
            </Heading>
            <Video url={details.videoURL} height={height}></Video>
          </VideoWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default IndividualPodcast;
