// @flow
import React, { useState } from "react";
import { connect } from "react-redux";
import ActionCreator from "Actions";
import { useHistory } from "react-router-dom";
import { useAuth } from "Hooks/useAuth";
import { useValidation, useTextInput } from "Hooks";
import Input from "Components/Input";
import Button from "Components/Button";
import FormField from "Components/FormField";
import Notification from "Components/Notification";
import { StyledForm, ForgotPassword, Actions } from "./styles";

type Props = {
  redirect?: String,
  setId: Function
};

const LoginForm = ({ redirect, setId }: Props) => {
  let history = useHistory();
  const { login } = useAuth();
  const email = useTextInput();
  const password = useTextInput();
  const [loginError, setLoginError] = useState(null);
  const [validate, errors] = useValidation(
    { email: email.value, password: password.value },
    {
      email: {
        email: true,
        presence: {
          allowEmpty: false
        }
      },
      password: {
        presence: {
          allowEmpty: false
        }
      }
    }
  );

  async function submit(e) {
    e.preventDefault();
    const valid = validate();
    if (valid) {
      const user = {
        username: email.value,
        password: password.value,
        grant_type: "password"
      };

      const success = ({ user }) => {
        setId(user?.id);
        const defaultRedirect =
          user.approval_status === "approved" ? "/feed" : "/projects";
        history.push(redirect || defaultRedirect);
      };

      const error = ({ error }) => {
        setLoginError(error);
      };

      await login(user, success, error);
    }
  }
  return (
    <>
      <h2>Welcome Back</h2>
      {loginError && (
        <Notification>Sorry, we could not log you in</Notification>
      )}
      <StyledForm onSubmit={submit}>
        <FormField errors={errors?.email}>
          <Input type="email" placeholder="Email" {...email} />
        </FormField>
        <FormField errors={errors?.password}>
          <Input type="password" placeholder="Password" {...password} />
        </FormField>
        <Actions>
          <Button type="submit">Log in</Button>
          <ForgotPassword to="/reset-password">
            Forgot your passsword?
          </ForgotPassword>
        </Actions>
      </StyledForm>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  setId(userId: Number | String) {
    dispatch(ActionCreator.setUserId(userId));
  }
});

export default connect(null, mapDispatchToProps)(LoginForm);
