import styled from "styled-components";
import Link from "Components/Link";

export const Wrapper = styled.div(
  ({ theme }) => `
  margin: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    max-width: ${theme.maxWidth}px;
    margin: ${theme.spacing}rem auto;
    padding: ${theme.spacing * 4}rem;
  }
`
);

export const Container = styled.div(
  ({ theme }) => `
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 5fr;
  }
`
);

export const Logo = styled.img(
  ({ theme }) => `
  width: 100px;
  height: 100px;
  box-shadow: ${theme.shadow};
  position: relative;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    width: 75px;
    height: 75px;
    margin-left: ${theme.spacing * 2}rem;
    margin-bottom: -${theme.spacing * 2}rem;
    z-index: ${theme.zIndex.middle};
  }
`
);

export const Description = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing * 2}rem;
  background: ${theme.colors.white};
  box-shadow: ${theme.shadow};
  position: relative;
  z-index: ${theme.zIndex.back};
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    padding-top: ${theme.spacing * 3}rem;
  }
`
);

export const JobDetails = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: ${theme.spacing * 2}rem;
`
);

export const Date = styled.span(
  ({ theme }) => `
  color: ${theme.colors.grey("dark")};
  position: absolute;
  top: 0;
  right: 0;
  padding: ${theme.spacing}rem;
  text-align: right;
  max-width: 70%;
`
);

export const Apply = styled.div(
  ({ theme }) => `
  border-top: 1px solid ${theme.colors.black};
  border-bottom: 1px solid ${theme.colors.black};
  padding ${theme.spacing * 2}rem 0;
`
);

export const JobDescription = styled.div(
  ({ theme }) => `
  padding ${theme.spacing}rem 0;
`
);

export const Button = styled.a(
  ({ theme }) => `
  border-radius: ${theme.border.radius}px;
  min-width: 100px;
  padding: ${theme.spacing / 2}rem
    ${theme.spacing * 1.5}rem;
  margin: ${theme.spacing}rem 0;
  background-color: ${theme.colors.yellow()};
  text-transform: uppercase;
  color: ${theme.colors.black};
  text-decoration: none;
  font-weight:bold;
  font-size: 0.875rem;
  letter-spacing:0.5;
  `
);

export const StyledLink = styled(Link)`
  font-weight: bold;
`;
