// @flow
import styled from "styled-components";

export const CheckboxContainer = styled.div(
	({ vertical, theme }) => `
	display: ${false ? "block" : "flex"};
	flex-direction: column;
	flex-flow: column wrap;
	justify-content: left;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
		flex-direction: column;
    align-items: flex-start;
    max-width: 100vw;
    transform: translateX(0%);
  }
`
);
