// @flow
import styled from "styled-components";

export const StyledInput = styled.textarea(
  ({ theme }) => `
  padding: ${theme.spacing}rem;
  margin: ${theme.spacing / 2}rem 0;
  border-color: ${theme.colors.grey()};
  font-family: ${theme.fonts.default};
  font-size: ${theme.fonts.size.small};
  width: 100%;
  resize: none;
`
);
