import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledForm = styled.form`
  width: 100%;
`;

export const ForgotPassword = styled(Link)`
  text-decoration: underline;
  color: black;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
