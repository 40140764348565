import styled, { css } from 'styled-components';

export const StyleSocial = styled.div(
	({ theme }) => `
  display: flex;
  width: 100%;
  justify-content: left;
  padding-top: ${theme.spacing}rem;
  padding-bottom: ${theme.spacing}rem;
`
);

export const SocialLink = styled.img`
	margin: ${(props) => props.theme.spacing / 2}rem;
`;
