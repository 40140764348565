// @flow
import React from "react";
import { MdClose } from "react-icons/md";
import { Background, StyledModal, Icon } from "./styles";
import IconButton from "Components/IconButton";
import { createPortal } from "react-dom";

type Props = {
  children: React.Node,
  onClose: Function,
  black?: Boolean,
  maxWidth?: Number
};

const modalRoot = document.getElementById("modal");

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.element = document.createElement("div");
  }
  componentDidMount() {
    modalRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.element);
  }
  render() {
    const { black, maxWidth, onClose, children } = this.props;
    return createPortal(
      <Background>
        <StyledModal black={black} maxWidth={maxWidth}>
          {onClose && (
            <IconButton style={{ position: "absolute" }} onClick={onClose}>
              <MdClose />
            </IconButton>
          )}
          {black && <Icon />}
          {children}
        </StyledModal>
      </Background>,
      this.element
    );
  }
}
export default Modal;
