// @flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Screens from 'Screens';
import { useAuth } from 'Hooks/useAuth';

export const PrivateRoute = ({ component: Component, ...route }) => {
	const { auth } = useAuth();

	const redirectUrl = route.private.redirect
		? route.private.redirect + '?redirect=' + route.location.pathname
		: '/login';
	return (
		<Route
			{...route}
			render={(props) =>
				auth.access_token ? (
					<Component {...props} />
				) : (
					<Redirect to={redirectUrl} />
				)
			}
		/>
	);
};

export const RestrictedRoute = ({ component: Component, ...route }) => {
	const { auth } = useAuth();
	const approved = auth?.user?.approval_status === 'approved';

	return (
		<Route
			{...route}
			render={(props) => {
				if (approved) return <Component {...props} />;
				const redirectUrl = auth.access_token
					? '/'
					: 'login?redirect=' + route.location.pathname;
				return <Redirect to={redirectUrl} />;
			}}
		/>
	);
};

const routes = [
	{
		path: '/',
		exact: true,
		component: Screens.Main,
	},
	{
		path: '/about',
		exact: true,
		component: Screens.About,
	},
	{
		path: '/features',
		exact: true,
		component: Screens.Blog,
	},
	{
		path: '/features/:slug',
		exact: true,
		component: Screens.BlogPost,
	},
	{
		path: '/gallery',
		exact: true,
		component: Screens.Gallery,
	},
	{
		path: '/gallery/:slug',
		exact: true,
		component: Screens.SingleCollaboration,
	},
	{
		path: '/competitions',
		exact: true,
		component: Screens.Competitions,
	},
	{
		path: '/faqs',
		exact: true,
		component: Screens.Faqs,
	},
	{
		path: '/how-it-works',
		exact: true,
		component: Screens.HowItWorks,
	},
	{
		path: '/jobs',
		exact: true,
		component: Screens.Jobs,
	},
	{
		path: '/jobs/:id',
		exact: true,
		private: {
			redirect: '/login',
		},
		component: Screens.SingleJob,
	},
	{
		path: '/login',
		exact: true,
		component: Screens.Login,
	},
	{
		path: '/reset-password',
		exact: true,
		component: Screens.RequestResetPassword,
	},
	{
		path: '/admin/password/edit',
		exact: true,
		component: Screens.ResetPassword,
	},
	{
		path: '/onboarding',
		exact: true,
		component: Screens.Onboarding,
	},
	{
		path: '/messaging',
		exact: true,
		restricted: true,
		component: Screens.Messaging,
	},
	// This route is a duplicate, to ensure old bookmarks/emails sent by old API still work now Collaborations has been renamed to News Feed
	{
		path: '/collaborations',
		exact: true,
		restricted: true,
		component: Screens.NewsFeed,
	},
	{
		path: '/feed',
		exact: true,
		restricted: true,
		component: Screens.NewsFeed,
	},
	{
		path: '/profile/:tab?',
		exact: true,
		private: {
			redirect: '/login',
		},
		component: Screens.UserProfile,
	},
	{
		path: '/profiles/:id/:tab?',
		exact: true,
		component: Screens.SingleProfile,
	},
	{
		path: '/projects',
		exact: true,
		component: Screens.Projects,
	},
	{
		path: '/projects/:id',
		exact: true,
		component: Screens.SingleProject,
	},
	{
		path: '/projects/:id/edit',
		exact: true,
		private: {
			redirect: '/login',
		},
		component: Screens.EditProject,
	},
	{
		path: '/verification',
		exact: true,
		private: {
			redirect: '/verification-process',
		},
		component: Screens.Verification,
	},
	{
		path: '/verification-process',
		exact: true,
		component: Screens.VerificationProcess,
	},
	{
		path: '/terms-and-conditions',
		exact: true,
		component: Screens.Terms,
	},
	{
		path: '/privacy',
		exact: true,
		component: Screens.Privacy,
	},
	{
		path: '/acceptable-use-policy',
		exact: true,
		component: Screens.AcceptableUse,
	},
	{
		path: '/cookies',
		exact: true,
		component: Screens.Cookies,
	},
	{
		path: '/open-submissions',
		exact: true,
		component: Screens.OpenSubmissions,
	},
	{
		path: '/podcasts/the-fashion-slashie',
		exact: true,
		component: Screens.Podcasts,
	},
	{
		path: '/podcasts/the-fashion-slashie/sylvie-macmillan',
		exact: true,
		component: Screens.FashionSlashie,
	},
	{
		path: '/podcasts/the-fashion-slashie/tayler-prince-fraser',
		exact: true,
		component: Screens.FashionSlashie,
	},
	{
		path: '/podcasts/the-fashion-slashie/kate-iorga',
		exact: true,
		component: Screens.FashionSlashie,
	},
	{
		path: '/podcasts/the-fashion-slashie/eva-losada',
		exact: true,
		component: Screens.FashionSlashie,
	},
	{
		path: '/podcasts/the-fashion-slashie/nassia-matsa',
		exact: true,
		component: Screens.FashionSlashie,
	},
	{
		path: '/podcasts/the-fashion-slashie/rob-rusling',
		exact: true,
		component: Screens.FashionSlashie,
	},
	{
		path: '/podcasts/the-fashion-slashie/theo-white',
		exact: true,
		component: Screens.FashionSlashie,
	},
	{
		path: '/search',
		exact: true,
		component: Screens.Search,
	},
	{
		path: '/feedback',
		exact: true,
		component: Screens.Feedback,
	},
	{
		path: '/the-fashion-network',
		exact: true,
		component: Screens.LandingPage,
	},
	{
		path: '/support-grants',
		exact: true,
		component: Screens.SupportGrants,
	},
	{
		path: '/mentoring',
		exact: true,
		component: Screens.Mentoring,
	},
	{
		path: '/request-a-call-back',
		exact: true,
		component: Screens.Brands,
	},
	{
		path: '/thank-you',
		exact: true,
		component: Screens.BrandSubmitted,
	},
	{
		path: '*',
		component: Screens.NotFound,
	},
	process.env.NODE_ENV === 'development' && {
		path: '/styleguide',
		exact: true,
		component: Screens.Styleguide,
	},
];

export default routes;
