// @flow
import React from "react";
import { Education } from "Types";
import { Container, Title, Line } from "./styles";

type Props = {
  item: Education
};

const EducationItem = ({ item }: Props): React.Node => (
  <Container>
    <Title>{item.qualification}</Title>
    {item.institution && <Line>{item.institution}</Line>}
    <Line>
      {item.location && item.location}
      {item.location && (item.startDate || item.endDate) && " | "}
      {item.startDate && item.startDate} - {item.endDate && item.endDate}
    </Line>
  </Container>
);

export default EducationItem;
