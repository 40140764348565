// @flow
import * as React from "react";
import { VideoContainer, WrapYoutube } from "./styles";
import { Player, BigPlayButton } from "video-react";
import ReactPlayer from "react-player";
import "./video-react.css";
import theme from "Styles/Theme";
import { useState, useEffect } from "react";

type Props = {
  url: String,
  file: any,
  cover: String,
  columns: Number
};

function Video({ url, file, cover, columns }: Props): React.Node {
  const ratio = file?.height / file?.width;
  const percentOfViewport = (80 / 100) * window.innerWidth;
  const heightYoutube = percentOfViewport / 1.78;
  const [viewportWidth, setWidth] = useState(window.innerWidth);
  const height =
    viewportWidth > theme.breakpoints.small ? "100%" : heightYoutube;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <VideoContainer ratio={ratio}>
      {file ? (
        <Player src={file?.url} poster={cover?.url}>
          <BigPlayButton position="center" />
        </Player>
      ) : (
        <WrapYoutube>
          <ReactPlayer url={url} width={"auto"} height={height} />
        </WrapYoutube>
      )}
    </VideoContainer>
  );
}

export default Video;
