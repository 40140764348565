// @flow
import React from 'react';
import { useFetch } from 'Hooks/useFetch';
import ContentLoading from 'Components/ContentLoading';
import VerificationSteps from '../../Components/VerificationSteps';
import SuccessPage from '../../Components/SuccessPage';
import ApprovedPage from '../../Components/ApprovedPage';
import { Helmet } from 'react-helmet';

const Verification = () => {
	const { data, loading, error } = useFetch({ url: '/api/me' });
	const { data: pageData, loading: pageLoading } = useFetch({
		url: '/api/pages/verification',
	});

	const affiliateSingle = pageData?.page?.blocks.find(
		(block) => block.type === 'affiliate_single'
	);
	const user = data?.user || {};

	if (loading || pageLoading) return <ContentLoading />;

	if (user.approval_status === 'approved') {
		return (
			<>
				<Helmet>
					<title>ClickerMe | Become a Verified Member</title>
					<meta
						name="description"
						content="Our verification process ensures that our fashion community is of the highest quality. As a verified member you’ll get full access to ClickerMe"
					/>
					<htmlAttributes h1="Get Full Access To ClickerMe"></htmlAttributes>
				</Helmet>
				<ApprovedPage error={error} userName={user?.profile?.first_name} />
			</>
		);
	}

	if (user.approval_status !== 'unapproved') {
		return (
			<>
				<Helmet>
					<title>ClickerMe | Become a Verified Member</title>
					<meta
						name="description"
						content="Our verification process ensures that our fashion community is of the highest quality. As a verified member you’ll get full access to ClickerMe"
					/>
					<htmlAttributes h1="Get Full Access To ClickerMe"></htmlAttributes>
				</Helmet>
				<SuccessPage error={error} affiliateSingle={affiliateSingle} />
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>ClickerMe | Become a Verified Member</title>
				<meta
					name="description"
					content="Our verification process ensures that our fashion community is of the highest quality. As a verified member you’ll get full access to ClickerMe"
				/>
				<htmlAttributes h1="Get Full Access To ClickerMe"></htmlAttributes>
			</Helmet>
			<VerificationSteps user={user} />
		</>
	);
};

export default Verification;
