import styled from "styled-components";

export const Container = styled.div(
	(props) => `
  width: ${props.theme.maxWidth}px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props.theme.spacing * 2}rem;
  @media screen and (max-width: ${props.theme.breakpoints.medium}px) {
    width: 100vw;
  }
`
);

export const ProgressHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${(props) => props.theme.colors.grey("darker")};
	width: 100%;
`;

export const BackButton = styled.button(
	({ theme, step }) => `
  background-color: transparent;
  border: none;
  font-size: 1rem;
  color: white;
  text-transform: uppercase;
  margin: ${theme.spacing}rem;
  visibility: ${step === 0 ? "hidden" : "visible"};
  img {
    margin-right ${theme.spacing / 2}rem;
  }
`
);

export const StepLabels = styled.div(
	({ theme, step }) => `
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    &:before {
      content: '${step + 1}/4';
      color: #FFF;
    }
  }
`
);

export const StepLabel = styled.span(
	(props) => `
  text-transform: uppercase;
  color: ${
		props.completed
			? props.theme.colors.yellow()
			: props.active
			? props.theme.colors.white
			: props.theme.colors.grey("dark")
	};
  padding: ${props.theme.spacing * 2}rem ${props.theme.spacing}rem;
  font-weight: bold;
  font-size: ${props.theme.fonts.size.xsmall};
  &:before {
    content: ">";
    color: ${props.theme.colors.grey("dark")};
    padding-right: ${props.theme.spacing * 2}rem;
  }

  ${StepLabels} &:first-child:before {
    content: none;
  }
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
    display: none;
  }
`
);

export const StepTitle = styled.span(
	(props) => `
  font-family: Raleway;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: .7rem;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
    font-size: 18px;
    line-height: 1.17;
    text-align: left;
  }
`
);

export const StepDescription = styled.span(
	(props) => `
  font-family: Raleway;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07;
  letter-spacing: normal;
  text-align: center;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
    font-family: Raleway;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
  }
`
);

export const ProgressContainer = styled.div(
	(props) => `
  height: ${props.theme.spacing / 2}rem;
  width: 100%;
  background-color: ${props.theme.colors.grey()};
`
);

export const ProgressBar = styled.div(
	(props) => `
  height: ${props.theme.spacing / 2}rem;
  width: ${((props.step + 1) / 3) * 100}%;
  background-color: ${props.theme.colors.yellow()};
`
);

export const ProfileForm = styled.div(
	({ theme }) => `
	width: 450px;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media screen and (max-width: ${theme.breakpoints.small}px) {
		width: 80vw;
  }
`
);

export const Description = styled.p`
	font-size: 14px;
`;

export const AlignInstaHandle = styled.div(
	({ theme }) => `
width: 100%;
display: flex;
background-color: white;
margin: auto;
align-items: center;
border: 1px solid ${theme.colors.grey()};
padding-left: 10px;
margin: ${theme.spacing / 4}rem 0;

`
);

export const WrapContent = styled.div(
	(props) => `
  max-width: ${props.theme.maxWidth / 2}px;
`
);

export const EditableArea = styled.div(
	({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${theme.spacing / 2}rem;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    justify-content: space-between;
    background-color: ${theme.colors.white};
  }
`
);

export const Detail = styled.div(
	({ theme }) => `
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    background-color: ${theme.colors.white};
    padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
    margin-right: 0;
  }
`
);

export const Bold = styled.span`
	font-weight: bold;
	white-space: nowrap;
	margin-right: ${(props) => props.theme.spacing / 2}rem;
`;
