import styled, { css } from "styled-components";
import Button from "Components/Button";

export const Wrapper = styled.div(
  ({ background_color }) => `
    width: 100%;
    margin:auto;
    background: ${background_color ? background_color : "transparent"};
  `
);

export const Container = styled.div(
  ({ theme }) => `
    margin-bottom: ${theme.spacing}rem;
    padding:${theme.spacing}rem;
    display:grid;
    grid-template-columns: repeat(2, 2fr);  
    gap: ${theme.spacing}rem;  
    grid-auto-rows: minmax(auto);
    @media screen and (min-width: ${theme.breakpoints.small}px) {
      margin: auto;
      padding: ${theme.spacing * 2}rem;
    }
    @media screen and (min-width: ${theme.breakpoints.medium}px) {
      max-width: ${theme.maxWidth}px;
      margin: auto;
      grid-template-columns: 5fr 5fr 0.1fr 5fr 0.1fr 5fr;
    }
    `
);

export const Content = styled.div(
  ({ theme }) => `
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    position:relative;
    line-height:1.2;
    font-size:16px;
    padding-bottom:${theme.spacing / 3}rem;
  }
    p{
      font-size:16px;
      line-height:1.2;
      margin:0;
    }
  }
    `
);

export const StaticImage = styled.div(
  ({ theme }) => `
    background-color: black;
    color:white;
    font-size:${theme.fonts.size.medium};
    font-weight:bold;
    padding-left:${theme.spacing * 1.5}rem;
    padding-top:${theme.spacing * 1.5}rem;
    padding-right:${theme.spacing * 5}rem;
    @media screen and (max-width: ${theme.breakpoints.small}px) {
      padding-right:${theme.spacing}rem;
      font-size: ${theme.fonts.size.normal};
    }
  `
);

export const Image = styled.img(
  ({ theme }) => `
  width:100%;
  min-height:141px;
  object-fit:cover;
  max-height:141px;
  min-width:100%;
  margin-bottom: ${theme.spacing / 2}rem;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    width:100%;
    min-height:92px;
    max-height:92px;
    min-width:100%;
  }
  `
);

export const Nest = styled.div(
  ({ theme }) => `
  width: 100%;
  height: 141px;
  object - fit: cover;
  position: relative;
  margin-bottom: ${theme.spacing / 2}rem;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    height:92px;
    max-height:100%;
    width:100%;
    min-width:auto;
    min-height:auto;  
  }
  img{
    position: absolute;
    right:0;
    index-z:1;
    height:72px;
    width:72px;
  }
  `
);
export const WrapButtonText = styled.div(
  ({ theme }) => ` 
  position:relative;
    @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 568px)
    and (orientation: portrait)  {
      height:20vh;
    }
    @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 568px)
    and (orientation: landscape)  {
      height:28vh;
    }
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 667px)
    and (orientation: portrait)
     {
     height:17vh;
    }
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 667px)
    and (orientation: landscape)
     {
     height:28vh;
    }
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 812px) 
    and (-webkit-device-pixel-ratio : 3)
    and (orientation : portrait) {
      height:15vh;
    }
    @media only screen 
    and (min-device-width : 280px) 
    and (max-device-width : 653px)
    and (orientation: landscape)  {
      height:34vh;
    }
    @media only screen 
    and (min-device-width : 450px) 
    and (max-device-width : 600px)
    and (orientation: portrait)  {
      height:18vh;
    }
 
  `
);
export const StyledButton = styled(Button)(
  ({ theme }) => ` 
  padding-top:${theme.spacing / 2}rem;
  padding-bottom:${theme.spacing / 2}rem;
  text-align:center;
   @media screen and (max-width: ${theme.breakpoints.small}px) {
      padding:${theme.spacing / 1.2}rem;
      padding-top:${theme.spacing / 2}rem;
      padding-bottom:${theme.spacing / 2}rem;
      font-size: ${theme.fonts.size.small};
      position:absolute;
      bottom:0;
    }
    @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
      font-size: ${theme.fonts.size.xsmall};
    }
  img{
    padding-left:${theme.spacing / 3}rem;
    @media screen and (max-width: ${theme.breakpoints.small}px) {
      padding-left:${theme.spacing / 4}rem;
      width:18px;
    }
    
  }
  `
);
export const Border = styled.div(
  ({ theme }) => `
    width:1px;
    background-color:black;
    height:100%;
    opacity:0.2;
    @media screen and (max-width: ${theme.breakpoints.medium}px) {
      display: none;     
    }
  `
);

export const ButtonAndTitle = styled.div(
  ({ theme }) => `
    display:flex;
    flex-drection: row;
    background-color:white;
    padding-top ${theme.spacing}rem;
    p{
      margin-right:${theme.spacing / 3}rem;
    }   
    @media screen and (max-width: ${theme.breakpoints.small}px) {
      display: flex;
      flex-direction:column;
      padding:0;      
    }
    h4{
      padding-right:${theme.spacing / 3}rem;
    }
  `
);

export const WrapImages = styled.div(
  ({ theme }) => `
  width:100%;
  height:145px;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    width:100%;
    height:95px;  
    min-width:100%;
  }
  object-fit: cover;
  position: relative;
  img{
    position: absolute;
    right:0;
    index-z:1;
    width:72px;
    height:72px;
    @media screen and (max-width: ${theme.breakpoints.small}px) {
    width:62px;
    height:62px;
    }
  }
`
);
