// @flow
import styled from "styled-components";
import Text from "Components/Text";

export const Container = styled.div(
  props =>
    `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: ${props.theme.colors.black};

`
);
export const StyledText = styled(Text)(
  props => `
color: ${props.theme.colors.white}`
);
