// @flow
import styled from "styled-components";

export const StyledInput = styled.input(
  ({ theme }) => `
  display: block;
  padding: ${theme.spacing / 2}rem
    ${theme.spacing * 0.75}rem;
  margin: ${theme.spacing / 4}rem 0;
  font-size: ${theme.fonts.size.normal};
  font-family: ${theme.fonts.default};
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid ${theme.colors.grey()};
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    font-size: ${theme.fonts.size.small};
  }
`
);
