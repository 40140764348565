// @flow
import styled from "styled-components";

export const WrapAffiliateSingle = styled.div(
  props => `
  display: block;
  max-width: ${props.theme.maxWidth}px;
  margin: auto;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
   text-align: left;
   padding: ${props.theme.spacing}rem;
  }
`
);

export const CTA = styled.div`
  margin-top: 1.5rem;
`;
