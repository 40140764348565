// @flow
import styled from "styled-components";

export const Card = styled.div(
  ({ theme }) => `
  display: flex;
  padding: ${theme.spacing}rem;
  box-shadow: ${theme.shadow};
  background: ${theme.colors.white};
  margin-bottom: ${theme.spacing / 2}rem;
  position: relative;
`
);

export const ImageContainer = styled.div(
  ({ theme }) => `
  margin-right: ${theme.spacing}rem;
`
);

export const Name = styled.span(
  ({ theme }) => `
  font-weight: bold;
  margin-right: ${theme.spacing}rem;
  cursor: pointer;
`
);

export const CommentText = styled.span(
  ({ theme }) => `
  white-space: pre-wrap;
`
);

export const GreyText = styled.p(
  ({ theme }) => `
  color: ${theme.colors.grey("dark")};
  font-size: ${theme.fonts.size.xsmall};
  margin-bottom: 0;
`
);

export const DeleteButton = styled.button(
  ({ theme }) => `
  border: 2px solid ${theme.colors.black};
  font-family: ${theme.fonts.default};
  font-size: ${theme.fonts.size.normal};
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 ${theme.spacing / 2}rem 0 0;
  position: absolute;
  top: ${theme.spacing * 2}rem;
  right: ${theme.spacing}rem;
  z-index: ${theme.zIndex.middle};
`
);
