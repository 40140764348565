import Announcement from "Components/Blocks/Announcement";
import Heading from "Components/Blocks/Heading";
import Hero from "Components/Blocks/Hero";
import Quote from "Components/Blocks/Quote";
import Feedback from "Components/Blocks/Feedback";
import Showcase from "Components/Blocks/Showcase";
import VideoShowcase from "Components/Blocks/VideoShowcase";
import Text from "Components/Blocks/Text";
import Image from "Components/Blocks/Image";
import Banner from "Components/Blocks/Banner";
import Team from "Components/Blocks/Team";
import AffiliateContents from "Components/Blocks/AffiliateContents";
import AffiliateSingle from "Components/Blocks/AffiliateSingle";
import Video from "Components/Blocks/Video";

export const blocks = {
  announcement: Announcement,
  heading: Heading,
  hero: Hero,
  quote: Quote,
  feedback: Feedback,
  showcase: Showcase,
  video_showcase: VideoShowcase,
  text: Text,
  image: Image,
  banner: Banner,
  team: Team,
  affiliate_single: AffiliateSingle,
  affiliate_content: AffiliateContents,
  video: Video
};
