// @flow
import React, { useState } from "react";
import { type Project } from "Types";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { FILESTACK_API_KEY } from "Config";
import { FILESTACK_WORKFLOW } from "Config";
import ReactFilestack from "filestack-react";
import IconButton from "Components/IconButton";
import NewProjectImage from "Components/NewProjectImage";
import ReactSortable from "react-sortablejs";
import Lightbox from "Components/Lightbox";
import Modal from "Components/Modal";
import Input from "Components/Input";
import Button from "Components/Button";

import {
  ItemContainer,
  AddButton,
  ReorderButton,
  ImageContainer,
  ButtonLabel,
  ModalContainer,
  StyledUploadButtons,
  WrapButtons,
  WrapText,
  Description
} from "./styles";

type Props = {
  project: Project,
  setImages: Function,
  setError: Function,
  updateCoverImage: Function,
  unpublish: Function,
  user: Object
};

const ProjectImages = ({
  project,
  setImages,
  setError,
  unpublish,
  updateCoverImage
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(null);
  const [reordering, toggleReordering] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [positionChange, setPositionChange] = useState(null);
  const [urlModal, toggleUrlModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [validLink, setValidLink] = useState(false);
  const [resourceType, setResourceType] = useState(null);
  const { images } = project;

  const [addImages] = useLazyFetch({
    url: `/api/projects/${project?.id}/images`,
    method: "post"
  });

  function inputChange(e) {
    let value = e.target.value;

    if (value.includes("vimeo.com")) {
      setResourceType("vimeo");
      setValidLink(true);
    } else if (value.includes("youtube.com")) {
      setValidLink(true);
      setResourceType("youtube");
    } else {
      setValidLink(false);
    }
    setVideoLink(value);
  }

  function submitVideoLink() {
    addImages(
      {
        images: [
          {
            url: videoLink,
            resource_type: resourceType
          }
        ]
      },
      onSuccess,
      onError
    );
    toggleUrlModal(false);
  }

  function submit(res) {
    if (res?.filesUploaded[0]?.url && !res?.filesFailed.length) {
      addImages({ images: res.filesUploaded }, onSuccess, onError);
    } else {
      onError();
    }
  }

  function submitCover(res) {
    if (res?.filesUploaded[0]?.url && !res?.filesFailed.length) {
      updateCoverImage(res.filesUploaded[0].url, onSuccess);
    } else {
      onError();
    }
  }

  function onSuccess(result) {
    setError(null);
    setImages([...images, ...result.images]);
  }

  function onError() {
    setError("Sorry, we could not add this image right now");
  }

  function reorder(order) {
    const newOrder = order.map((id, index) => {
      const image = images?.find(image => image?.id === id);
      const prev = image?.position;
      if (prev !== index) {
        setPositionChange([id, index]);
        return { ...image, position: index };
      }
      return image;
    });
    setImages(newOrder);
  }

  function updateImages(image) {
    setImages(
      images.map(i => {
        return i?.id === image?.id ? image : i;
      })
    );
  }

  function removeImage(id) {
    setImages(images.filter(i => i.id !== id));
  }

  const pickerCoverOptions = {
    imageMax: [2016, 1260],
    fromSources: ["local_file_system", "instagram"],
    accept: ["image/*"]
  };

  const pickerOptions = {
    maxFiles: 20,
    imageMax: [2016, 1260],
    videoResolution: "320x240",
    fromSources: ["local_file_system", "instagram"],
    accept: ["image/*", "video/*"],
    ...(FILESTACK_WORKFLOW && {
      storeTo: { workflows: [FILESTACK_WORKFLOW] }
    })
  };

  return (
    <React.Fragment>
      {urlModal && (
        <Modal
          maxWidth={645}
          onClose={() => {
            toggleUrlModal(false);
          }}
        >
          <ModalContainer>
            <h2>Enter the website address of your video</h2>
            <p>You can embed videos from YouTube or Vimeo</p>
            <Input
              type="text"
              placeholder="Enter website URL…"
              value={videoLink}
              onChange={e => inputChange(e)}
            />
            <Button onClick={() => submitVideoLink()} disabled={!validLink}>
              Embed video
            </Button>
          </ModalContainer>
        </Modal>
      )}
      <ReorderButton onClick={() => toggleReordering(!reordering)}>
        <img
          src={require(`Assets/Images/${reordering ? "done" : "reorder"}.svg`)}
          alt={reordering ? "Done" : "Reorder"}
        />
      </ReorderButton>
      {reordering ? (
        <ReactSortable
          style={{
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            justifyContent: "center"
          }}
          onChange={reorder}
        >
          {images.map((image, index) => (
            <ImageContainer key={index} data-id={image?.id}>
              <NewProjectImage
                projectId={project?.id}
                image={image}
                reordering
                newPosition={
                  positionChange && positionChange[0] === image?.id
                    ? positionChange[1]
                    : null
                }
                setError={setError}
                unpublish={unpublish}
                imagesCount={project.images.length}
              />
            </ImageContainer>
          ))}
        </ReactSortable>
      ) : (
        <ItemContainer>
          <StyledUploadButtons>
            <ReactFilestack
              apikey={FILESTACK_API_KEY}
              actionOptions={pickerOptions}
              customRender={({ onPick }) => (
                <>
                  <AddButton onClick={onPick}>
                    <WrapButtons>
                      <IconButton yellow>
                        <img src={require("Assets/Images/plus.svg")} />
                      </IconButton>
                      <WrapText>
                        <ButtonLabel>Upload image or video</ButtonLabel>
                        <Description>JPEG, PNG, MOV or MP4</Description>
                      </WrapText>
                    </WrapButtons>
                  </AddButton>
                  <AddButton onClick={() => toggleUrlModal(true)}>
                    <WrapButtons>
                      <IconButton yellow>
                        <img
                          src={require("Assets/Images/embed-black-yellow.svg")}
                        />
                      </IconButton>
                      <WrapText>
                        <ButtonLabel>Embed video from url</ButtonLabel>
                        <Description>Vimeo or YouTube</Description>
                      </WrapText>
                    </WrapButtons>
                  </AddButton>
                </>
              )}
              onSuccess={res => submit(res)}
            ></ReactFilestack>
            <ReactFilestack
              apikey={FILESTACK_API_KEY}
              actionOptions={pickerCoverOptions}
              customRender={({ onPick }) => (
                <>
                  <AddButton onClick={onPick}>
                    <WrapButtons>
                      {project.cover_image_url ? (
                        <img
                          src={project.cover_image_url}
                          style={{ maxWidth: "40px", maxHeight: "40px" }}
                        />
                      ) : (
                        <IconButton yellow>
                          <img
                            src={require("Assets/Images/star-black-yellow.svg")}
                          />
                        </IconButton>
                      )}
                      <WrapText>
                        {project.cover_image_url ? (
                          <>
                            <ButtonLabel style={{ cursor: "pointer" }}>
                              Edit cover image
                            </ButtonLabel>
                            <Description></Description>
                          </>
                        ) : (
                          <>
                            <ButtonLabel>Upload cover image</ButtonLabel>
                            <Description>JPEG or PNG</Description>
                          </>
                        )}
                      </WrapText>
                    </WrapButtons>
                  </AddButton>
                </>
              )}
              onSuccess={res => submitCover(res)}
            ></ReactFilestack>
          </StyledUploadButtons>
          {images
            ?.sort((a, b) => a.position - b.position)
            .map((image, index) => (
              <NewProjectImage
                key={index}
                projectId={project?.id}
                image={image}
                menuOpen={menuOpen === index}
                openMenu={() => setMenuOpen(index)}
                closeMenu={() => setMenuOpen(null)}
                removeImage={removeImage}
                setLightboxImage={() => setLightboxImage(index)}
                imagesCount={project.images.length}
                unpublish={unpublish}
                setError={setError}
                newPosition={
                  positionChange && positionChange[0] === image?.id
                    ? positionChange[1]
                    : null
                }
              />
            ))}
          {lightboxImage !== null && (
            <Lightbox
              onClose={() => setLightboxImage(null)}
              images={images}
              lightboxIndex={lightboxImage}
              projectId={project?.id}
              updateImages={updateImages}
            />
          )}
        </ItemContainer>
      )}
    </React.Fragment>
  );
};

export default ProjectImages;
