import React, { useEffect } from "react";
import debounce from "lodash.debounce";

const ScrollLoad = ({ children, loadMore }) => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = debounce(() => {
    const windowHeight = window.innerHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom + 500 >= docHeight) {
      loadMore();
    }
  }, 50);

  return <div>{children}</div>;
};

export default ScrollLoad;
