// @flow
import React from "react";
import { useAuth } from "Hooks/useAuth";
import Button from "Components/Button";
import { StyledFooterNotification, Heading, Text } from "./styles";

const FooterNotification = (): React.Node => {
  const {
    auth: { user }
  } = useAuth();

  return (
    <StyledFooterNotification>
      <Heading>
        {user?.approval_status === "submitted_for_approval"
          ? "Your profile is under review"
          : "Become a verified member"}
      </Heading>
      <Text>
        {user?.approval_status === "submitted_for_approval"
          ? "Keep an eye out for an email within the next few days. In the meantime you can carry on using ClickerMe."
          : "Get full access to ClickerMe in 3 quick steps. "}
      </Text>
      {user?.approval_status !== "submitted_for_approval" && (
        <div>
          <Button variant="white" to="/verification" padded>
            Find out more
          </Button>
        </div>
      )}
    </StyledFooterNotification>
  );
};

export default FooterNotification;
