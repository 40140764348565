// @flow
import React from "react";
import { Helmet } from "react-helmet";
import { Container, Logo, Block, Message } from "./styles";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>ClickerMe | Page not found</title>
      </Helmet>
      <Container>
        <Logo />
        <Block>
          <Message>Oops, something went wrong. 404 - page not found.</Message>
        </Block>
      </Container>
    </>
  );
};

export default NotFound;
