// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useFetch } from 'Hooks/useFetch';
import { blocks } from 'Helpers/Blocks';
import ContentError from 'Components/ContentError';
import ContentLoading from 'Components/ContentLoading';
import Fade from 'Components/Fade';
import Masterclass from 'Components/Masterclass';
import BrandsHome from 'Components/BrandsHome';
import BrandLogos from 'Components/BrandLogos';

function Main(): React.Node {
	const [show, setShow] = React.useState(false);
	const { data, loading, error } = useFetch({
		url: '/api/pages/homepage',
	});

	React.useEffect(() => {
		setShow(true);
	}, [data]);

	if (loading) return <ContentLoading />;
	if (error) return <ContentError />;

	if (data) {
		return (
			<>
				<Helmet>
					<title>ClickerMe | The Fashion Network</title>
					<meta
						name="description"
						content="ClickerMe is the fashion network. As a community, we provide support and experience for fashion creatives, ensuring you can connect and collaborate better."
					/>
					<htmlAttributes h1="The Fashion Network"></htmlAttributes>
				</Helmet>
				<div className="homepage">
					{data?.page?.blocks.slice(0, 8).map((content, i) => {
						const Block = blocks[content.type];
						return (
							<Fade show={show} key={i}>
								<Block {...content} />
							</Fade>
						);
					})}
					<BrandLogos background={'white'} noMargin={true}></BrandLogos>
					<BrandsHome />
					{data?.page?.blocks.slice(8, 10).map((content, i) => {
						const Block = blocks[content.type];
						return (
							<Fade show={show} key={i}>
								<Block {...content} />
							</Fade>
						);
					})}
					<Masterclass id={'anna-wintour'} />
					{data?.page?.blocks.slice(10, blocks.length).map((content, i) => {
						const Block = blocks[content.type];
						return (
							<Fade show={show} key={i}>
								<Block {...content} />
							</Fade>
						);
					})}
				</div>
			</>
		);
	} else {
		return <div>Error</div>;
	}
}

export default Main;
