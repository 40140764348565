// @flow
import React from "react";
import { Wrapper } from "./styles";

type Props = {
  children: React.Node
};

const Card = ({ children, noMargin }: Props) => (
  <Wrapper noMargin={noMargin}>{children}</Wrapper>
);

export default Card;
