// @flow
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'Components/Button';

export const Container = styled.div`
	display: grid;
	width: 100%;
	padding: ${(props) => props.theme.spacing}rem;
	${(props) => props.theme.spacing * 10}rem;
	background: ${(props) =>
		props.background_color ? props.background_color : 'white'};
	color: ${(props) =>
		props.background_color === 'black'
			? props.theme.colors.white
			: props.theme.colors.black};
	align-items: center;
	justify-content: center;
	text-align: center;
	grid-template-rows: repeat(2, 1fr);
	grid-template-columns: 1fr;

	@media screen and (max-width: ${(props) =>
			props.theme.breakpoints.medium}px) {
		padding: ${(props) => props.theme.spacing}rem;
		align-items: flex-start;
	}
`;

export const StyledText = styled.div`
	text-align: center;
`;

export const Action = styled.div`
	text-align: center;
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${(props) => props.theme.colors.black};
`;

export const StyledButton = styled(Button)(
	({ button }) => `
  background-color: ${
		button.color === '#FFF200' ? button.color : 'transparent'
	};
  border: 2px solid ${button.color};
  span {
    a {
      color: ${button.color !== '#FFF200' ? button.color : 'black'};
    }
  }
`
);
