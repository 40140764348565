// @flow
import styled from "styled-components";

const size = props =>
  props.theme.avatar[props.size]
    ? props.theme.avatar[props.size]
    : props.theme.avatar.medium;

export const StyledAvatar = styled.div(
  props => `
  background-image: url(${
    props?.src ? props.src : require("Assets/Images/no-avatar.svg")
  });
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  clip-path: polygon(36% 0%, 100% 0%, 64% 100%, 0% 100%);
  width: ${size(props) * 1.25}px;
  height: ${size(props)}px;
`
);
