// @flow
import React from 'react';
import parse from 'html-react-parser';
import { formatDate } from 'Helpers/Date';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useAuth } from 'Hooks/useAuth';
import { useFetch } from 'Hooks/useFetch';
import ContentError from 'Components/ContentError';
import ContentLoading from 'Components/ContentLoading';
import { MdChevronLeft } from 'react-icons/md';
import Button from 'Components/Button';
import Feedback from 'Components/Blocks/Feedback';
import {
	Body,
	Image,
	Date,
	Footer,
	Container,
	Heading,
	StyledLink as Link,
} from './styles';

const BlogPost = () => {
	const { auth } = useAuth();
	const { slug } = useParams();
	const { data, loading, error } = useFetch({
		url: `/api/posts/${slug}`,
	});
	const post = data?.post || {};

	if (loading) return <ContentLoading />;
	if (error) return <ContentError />;

	return (
		<>
			<Helmet>
				<title>{post?.title}</title>
			</Helmet>
			<Body>
				<Link to="/features">
					<MdChevronLeft size={24} /> View all articles
				</Link>
				{post?.featured_image && (
					<Image src={post.featured_image?.sizes?.large?.source_url} />
				)}
				<h1>{post?.title}</h1>
				<Date>{formatDate(post?.created_at)}</Date>
				{post?.content && parse(post.content)}
			</Body>
			{!auth.access_token && (
				<Feedback
					hide_if_logged_in
					background_color="#dadada"
					text="<h2>Connect and collaborate with fashion creatives.</h2>"
					buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
				/>
			)}
		</>
	);
};

export default BlogPost;
