import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'Components/Button';

export const NavLink = styled(Link)(
	({ theme, active, disabled, show, expandedValue }) => `
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    position: relative;
    display: ${show === 'notShown' ? 'none' : 'flex'};
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    min-height: 50px !important;
    border-top: 1px solid ${theme.colors.grey('dark')};
  }
  text-decoration: none;
  padding: 0 ${theme.spacing}rem;
  color: ${active ? theme.colors.yellow() : theme.colors.white};
  &:hover {
    color: ${theme.colors.yellow()};
  }
  ${disabled &&
		css`
			opacity: 0.2;
			cursor: default;
		`}
`
);

export const WrapIcon = styled.div(
	({ theme }) => `
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    display:none;
  }
}
`
);

export const WrapExpandedMenu = styled.div(
	({ theme }) => `
  position:relative;
  padding-bottom: ${theme.spacing}rem;
  padding-left: ${theme.spacing / 2}rem;
  align-items:left;
  width:100%;

`
);
