// @flow
import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ToggleContainer = styled.label(
  props => `
	display: flex;
	flex-direction: row;
	align-items: center;
  font-size: ${props.theme.spacing}rem;
  color: ${
    props.checked ? props.theme.colors.black : props.theme.colors.grey()
  };
`
);

export const Toggle = styled.div(
  props => `
	width: 30px;
	height: 18px;
	border-radius: 9px;
	background-color: ${
    props.checked ? props.theme.colors.black : props.theme.colors.grey()
  };
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 5px;
	justify-content: ${props.checked ? "flex-end" : "flex-start"};
`
);

export const Hidden = styled.input`
  display: none;
`;

export const Slider = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 1px;
  background-color: ${props => props.theme.colors.white};
`;

export const CopyContainer = styled.div`
  align-self: flex-start;
  text-align: left;
`;

export const Icon = styled.img`
  width: 18px;
`;
