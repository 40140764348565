// @flow
import React from "react";
import { StyledTag, StyledLabel } from "./styles";

type Props = {
  label?: string,
  children?: React.node
};

const Tag = (props: Props): React.Node => (
  <StyledTag withIcon={Boolean(props.children)}>
    <StyledLabel withIcon={Boolean(props.children)}>{props.label}</StyledLabel>
    {props.children}
  </StyledTag>
);

export default Tag;
