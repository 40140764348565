// @flow
import React, { useEffect, useRef } from "react";
import Talk from "talkjs";

import { InboxWrapper } from "./styles";
import type { User } from "Types";
import { TALK_JS_API_KEY } from "Config";
import { useAuth } from "Hooks/useAuth";

type Props = {
  otherUser: User
};

const Messaging = ({ otherUser }: Props) => {
  const container = useRef();
  const { auth } = useAuth();

  useEffect(() => {
    let inbox;
    const { user } = auth;

    Talk.ready
      .then(() => {
        const me = new Talk.User({
          id: user?.legacy_id ? user.legacy_id : user.id,
          name: `${user?.profile?.first_name} ${user?.profile?.last_name}`,
          photoUrl: user?.profile?.photo || null,
          role: "user"
        });

        window.talkSession = new Talk.Session({
          appId: TALK_JS_API_KEY,
          me: me
        });

        window.talkSession.syncThemeForLocalDev("TalkJS.css");
        if (otherUser) {
          const other = new Talk.User({
            id: otherUser?.legacy_id ? otherUser.legacy_id : otherUser.id,
            name: `${otherUser?.profile?.first_name} ${otherUser?.profile?.last_name}`,
            photoUrl: otherUser?.profile?.photo || null,
            role: "user"
          });

          const conversationId = Talk.oneOnOneId(me, other);

          const conversation = window.talkSession.getOrCreateConversation(
            conversationId
          );
          conversation.setParticipant(other);
          conversation.setParticipant(me);

          inbox = window.talkSession.createInbox({
            selected: otherUser ? conversation : null
          });
        } else {
          inbox = window.talkSession.createInbox();
        }
        inbox.mount(container.current);
      })
      .catch(e => console.error(e));
    return () => {
      if (inbox) {
        inbox.destroy();
      }
    };
  }, [otherUser]);

  return (
    <span>
      <InboxWrapper ref={container}></InboxWrapper>
    </span>
  );
};

export default React.memo(Messaging);
