// @flow
import styled from "styled-components";
import TextBox from "Components/TextBox";

export const Wrapper = styled.form(
  ({ theme }) => `
  background: ${theme.colors.white};
  padding: ${theme.spacing}rem;
  box-shadow: ${theme.shadow};
  
`
);

export const Container = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  padding-bottom: ${theme.spacing}rem;
`
);

export const PostContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  flex: 1;
`
);

export const Actions = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${theme.colors.grey()};
  padding-top: ${theme.spacing}rem;
`
);

export const StyledInput = styled(TextBox)`
  border: none;
  outline: none;
`;

export const UploadButton = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
`
);

export const ImagePreview = styled.img(
  ({ theme }) => `
  width: 200px;
  height: auto;
  margin: ${theme.spacing}rem;
`
);
