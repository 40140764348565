import styled from "styled-components";

export const ItemContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

export const AddButton = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.grey("light")};
  border: none;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin: ${theme.spacing}rem;
  }
  margin: ${theme.spacing / 2}rem;
  width: ${theme.spacing * 17}rem;
  height: ${theme.spacing * 3.5}rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: ${theme.spacing}rem;
  justify-content: center;
  color: ${theme.colors.black};
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    height: ${theme.spacing * 5}rem;
  }
`
);

export const WrapButtons = styled.div(
  ({ theme }) => `
  display:flex;
  justify-content: left;
  align-items: center;
`
);

export const WrapText = styled.div(
  ({ theme }) => `
  display:flex;
  flex-direction:column;
  padding-left: ${theme.spacing / 2}rem;
`
);

export const Description = styled.div(
  ({ theme }) => `
  font-size: ${theme.fonts.size.tiny};
  color: ${theme.colors.grey("mid")}
  text-align:left;
`
);

export const ReorderButton = styled.button`
  background-color: transparent;
  border: none;
  align-self: flex-end;
  margin-bottom: ${props => props.theme.spacing / 2}rem;
`;

export const ImageContainer = styled.div`
  border: 2px solid ${props => props.theme.colors.yellow()};
  margin: ${props => props.theme.spacing}rem;
`;

export const ButtonLabel = styled.p(
  ({ theme }) => `
  margin-bottom: 0;
  font-size: 13px;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    font-size: 14px;
  }
`
);

export const ModalContainer = styled.div`
  padding: 1.5rem 2rem 1.5rem 2rem;
  width: 100%;
  align-self: flex-start;
  text-align: left;
`;

export const StyledUploadButtons = styled.div`
  flex-direction: row;
`;
