// @flow
import * as React from "react";
import validatejs from "validate.js";

function useTextInput(initialValue: any) {
  const [value, setValue]: [any, (any) => void] = React.useState(initialValue);
  return { value, onChange: e => setValue(e.target.value) };
}

function useCheckboxInput(initialValue: any) {
  const [checked, setChecked]: [any, (any) => void] = React.useState(
    initialValue
  );
  return { checked, onChange: value => setChecked(value) };
}

function useValidation(
  attributes: Object,
  constraints: Object
): [() => boolean, void | Object] {
  const [errors, setErrors] = React.useState({});

  function validate() {
    const validationErrors = validatejs(attributes, constraints);
    setErrors(validationErrors);
    return validationErrors ? false : true;
  }

  return [validate, errors];
}

export { useTextInput, useCheckboxInput, useValidation };
