// @flow
import React, { useState } from "react";
import { useValidation, useTextInput } from "Hooks";
import { useLazyFetch } from "Hooks/useLazyFetch";
import Input from "Components/Input";
import Card from "Components/Card";
import Button from "Components/Button";
import FormField from "Components/FormField";
import Notification from "Components/Notification";
import { Actions, StyledLink } from "./styles";

const RequestResetPasswordForm = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const email = useTextInput();
  const [validate, errors] = useValidation(
    { email: email.value },
    {
      email: {
        email: true,
        presence: {
          allowEmpty: false
        }
      }
    }
  );

  const [submit, response] = useLazyFetch({
    url: "/api/users/password_reset",
    method: "post"
  });

  function request(e) {
    e.preventDefault();
    const valid = validate();
    if (valid) {
      submit({ user: { email: email.value } }, onSuccess, onError);
    }
  }

  function onSuccess() {
    setSuccess(true);
    setError(null);
  }

  function onError() {
    setError("Sorry, we could not complete this request right now");
  }

  return (
    <>
      <h1>Password Reset</h1>
      {error && <Notification>{error}</Notification>}
      {success ? (
        <React.Fragment>
          <p>
            A message has been sent to {email.value} with instructions on how to
            reset your password.
          </p>
          <Actions>
            <StyledLink to="/login">Back</StyledLink>
          </Actions>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            Enter the email address you used to register. We’ll send you a link
            to reset your password via email.
          </p>
          <form onSubmit={request}>
            <FormField errors={errors?.email}>
              <Input placeholder="Email address" {...email} />
            </FormField>
            <Actions>
              <StyledLink to="/login">Cancel</StyledLink>
              <Button type="submit">
                {response?.loading ? "Submitting" : "Submit"}
              </Button>
            </Actions>
          </form>
        </React.Fragment>
      )}
    </>
  );
};

export default RequestResetPasswordForm;
