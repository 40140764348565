// @flow
import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ConnectedRouter } from "connected-react-router";
import objectFitImages from "object-fit-images";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { GOOGLE_TRACKING_ID, GTM_ID, PIXEL_ID } from "Config";
import { runSagaMiddleware } from "Store/Middleware/Saga";
import { configureStore } from "Store";
import { history } from "Store/Middleware/Router";
import { ThemeProvider } from "styled-components";
import { AuthProvider } from "Hooks/useAuth";
import Theme from "Styles/Theme";
import { GlobalStyle } from "Styles/Global";
import App from "Components/App";

const { persistor, store } = configureStore();

export default class AppProvider extends Component {
  constructor(props) {
    super(props);
    if (GOOGLE_TRACKING_ID) {
      ReactGA.initialize(GOOGLE_TRACKING_ID);
    }
    if (GTM_ID) {
      TagManager.initialize({ gtmId: GTM_ID });
    }
    if (PIXEL_ID) {
      ReactPixel.init(PIXEL_ID);
      ReactPixel.pageView();
    }
    objectFitImages();
  }

  render(): React.Node {
    return (
      <AuthProvider>
        <ThemeProvider theme={Theme}>
          <Provider store={store}>
            <PersistGate
              loading={null}
              onBeforeLift={runSagaMiddleware}
              persistor={persistor}
            >
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
            </PersistGate>
          </Provider>
          <GlobalStyle />
        </ThemeProvider>
      </AuthProvider>
    );
  }
}
