// @flow
import React, { useEffect } from "react";
import { useFetch } from "Hooks/useFetch";
import { blocks } from "Helpers/Blocks";
import ContentError from "Components/ContentError";
import Fade from "Components/Fade";

type Props = {
  name: String,
  backgroundColor?: String
};

function BlockContainer({ name, backgroundColor }: Props) {
  const [show, setShow] = React.useState(false);
  const { data, error } = useFetch({
    url: `/api/pages/${name}`
  });

  useEffect(() => {
    setShow(true);
  }, [data]);

  if (error) return <ContentError />;

  if (data) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: backgroundColor
        }}
      >
        {data?.page?.blocks.map((content, i) => {
          const Block = blocks[content.type];
          if (Block) {
            return (
              <Fade show={show}>
                <Block key={i} {...content} />
              </Fade>
            );
          }
          return null;
        })}
      </div>
    );
  }
  return null;
}

export default BlockContainer;
