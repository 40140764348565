// @flow
import styled from "styled-components";
import Link from "Components/Link";

export const Hero = styled.div(
  ({ image, theme }) => `
  background-image: url(${image?.sizes?.medium});
  background-color: ${theme.colors.black};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 75vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media screen and (min-width: ${image?.sizes["medium-width"]}px) {
    background-image: url(${image?.sizes?.medium_large});
  }
  @media screen and (min-width: ${image?.sizes["large-width"]}px) {
    background-image: url(${image?.sizes["post-thumbnail"]});
    height: 90vh;
  }
  @media screen and (min-width: ${image?.sizes["post-thumbnail-width"]}px) {
    background-image: url(${image?.url});
  }
`
);

export const ScrollButton = styled.a(
  ({ theme }) => `
  width: 60px;
  height: 60px;
  background-color: ${theme.colors.black};
  padding: ${theme.spacing}rem;
`
);

export const Container = styled.div(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 ${theme.spacing}rem;
  background: ${theme.colors.white};
`
);

export const Heading = styled.h1(
  ({ theme }) => `
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 2px;
  margin-top: ${theme.spacing * 3.5}rem;
  text-align: center;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    margin-top: ${theme.spacing * 6}rem;
  }
`
);

export const Description = styled.p`
  max-width: 590px;
  margin: auto;
`;

export const Credits = styled.p(
  ({ theme }) => `
  max-width: ${theme.maxWidth}px;
  margin-left: auto;
  margin-right: auto;
  font-size: ${theme.fonts.size.xsmall};
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: ${theme.spacing * 2}rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    padding-bottom: ${theme.spacing * 6}rem;
  }
`
);

export const Credit = styled.span(
  ({ theme }) => `
  &:before {
    content: "/";
    padding: 0 ${theme.spacing / 2}rem;
  }

  ${Credits} &:first-child:before {
    content: none;
  }
`
);

export const StyledLink = styled.a(
  ({ theme }) => `
  padding: 0;
  color: ${theme.colors.black};
  }
`
);

export const More = styled.div(
  ({ theme }) => `
  width: 100%;
  background-color: ${theme.colors.grey("light")};
  padding: ${theme.spacing * 2}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: normal
`
);

export const MoreTitle = styled.h2(
  ({ theme }) => `
  font-size: 26px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 2px;
  text-align: center;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    margin: ${theme.spacing * 4}rem 0;
  }
`
);

export const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;

export const Item = styled(Link)(
  ({ theme }) => `
  letter-spacing: 1px;
  padding: ${theme.spacing}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media screen and (max-width: ${theme.breakpoints.small}px){
  width: 100%;
  }
`
);

export const Image = styled.div(
  ({ image, theme }) => `
  width: 100%;
  height: 45vw;
  background-image: url(${image});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  margin-bottom: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    width: 20vw;
    height: 15vw;
  }
`
);

export const AllLink = styled(Link)`
  text-decoration: underline;
`;
