// @flow
import styled, { keyframes } from "styled-components";
import Input from "Components/Input";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.form`
  width: 100%;
  animation: ${fadeIn} 0.8s ease-in-out;
`;

export const InputRow = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: ${props => props.theme.spacing}rem;
  width: 100%;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    display: flex;
    flex-flow: column wrap;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: ${props => props.theme.spacing}rem;
`;

export const RowForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.theme.spacing}rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin-bottom: ${props => props.theme.spacing * 0.75}rem;
  font-size: 18px;
  font-weight: bold;
`;
