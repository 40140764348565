// @flow
import styled, { css } from "styled-components";

export const Header = styled.div(props => `
  margin-top: 1.5rem;
  cursor: pointer;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
    margin-bottom: .5rem;
  }
`)
;

export const Title = styled.span`
  margin-left: .5rem;
  font-family: Raleway;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1px;
`
;

export const OpenContainer = styled.div(props => `
  margin-left: 40px;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
    margin-left: 0;
  }
`)
;

export const Description = styled.div(props => `
  width: 50%;
  padding-bottom: 1.5rem;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
    width: 100%;
    margin-top: 1rem;
  }
`)
;