import React, { useState, useEffect } from 'react';
import { useFetch } from 'Hooks/useFetch';
import { useLazyFetch } from 'Hooks/useLazyFetch';
import { Helmet } from 'react-helmet';
import Inner from 'Components/Inner';
import PostForm from 'Components/PostForm';
import Post from 'Components/Post';
import ContentLoading from 'Components/ContentLoading';
import Notification from 'Components/Notification';
import ScrollLoad from 'Components/ScrollLoad';
import AffiliateSingle from 'Components/Blocks/AffiliateSingle';
import { Container, WrapContent, SideBanner, AffiliateWrapper } from './styles';
import InfiniteLoading from '../../Components/InfiniteLoading';

const NewsFeed = () => {
	const [page, setPage] = useState(1);
	const [posts, setPosts] = useState([]);
	const [error, setError] = useState(null);
	const [allPosts, setAllPosts] = useState(false);
	const [getPosts, response] = useLazyFetch({
		url: `/api/collaboration_posts?page=${page}&per_page=5`,
	});
	const { data } = useFetch({ url: '/api/pages/news-feed' });
	const affiliate_single = data?.page?.blocks.find(
		(block) => block.type === 'affiliate_single'
	);

	const fetch = async (callback) => {
		const res = await getPosts();
		if (!res?.collaboration_posts?.length) setAllPosts(true);
		if (res) callback(res);
	};

	function addPost(post) {
		setPosts([post, ...posts]);
	}

	function updatePost(postId, newPost) {
		setPosts(posts.map((post) => (post?.id === postId ? newPost : post)));
	}

	function removePost(postId) {
		setPosts(posts.filter((post) => post?.id !== postId));
	}

	useEffect(() => {
		if (!allPosts)
			fetch((data) => setPosts([...posts, ...data?.collaboration_posts]));
	}, [page]);

	return (
		<>
			{error && <Notification>{error}</Notification>}
			{response?.loading && <ContentLoading />}
			<Helmet>
				<title>ClickerMe | News Feed</title>
				<meta
					name="description"
					content="Post your question or opportunity to the community"
				/>
			</Helmet>
			<Container>
				{affiliate_single && (
					<SideBanner>
						<AffiliateSingle {...affiliate_single} />
					</SideBanner>
				)}
				<WrapContent>
					<ScrollLoad loadMore={() => setPage((p) => p + 1)}>
						<PostForm addPost={addPost} setError={setError} />
						{posts.slice(0, 2).map((post, index) => (
							<Post
								key={index}
								post={post}
								updatePost={updatePost}
								removePost={removePost}
								setError={setError}
							/>
						))}
						{affiliate_single && (
							<AffiliateWrapper>
								<AffiliateSingle
									{...affiliate_single}
									sliceStart={0}
									sliceEnd={1}
								/>
							</AffiliateWrapper>
						)}
						{posts.slice(2, 5).map((post, index) => (
							<Post
								key={index}
								post={post}
								updatePost={updatePost}
								removePost={removePost}
								setError={setError}
							/>
						))}
						{affiliate_single && (
							<AffiliateWrapper>
								<AffiliateSingle
									{...affiliate_single}
									sliceStart={1}
									sliceEnd={2}
								/>
							</AffiliateWrapper>
						)}
						{posts.slice(5, 7).map((post, index) => (
							<Post
								key={index}
								post={post}
								updatePost={updatePost}
								removePost={removePost}
								setError={setError}
							/>
						))}
						{affiliate_single && (
							<AffiliateWrapper>
								<AffiliateSingle
									{...affiliate_single}
									sliceStart={2}
									sliceEnd={3}
								/>
							</AffiliateWrapper>
						)}
						{posts.slice(7, posts.length).map((post, index) => (
							<Post
								key={index}
								post={post}
								updatePost={updatePost}
								removePost={removePost}
								setError={setError}
							/>
						))}
						{response?.loading && <InfiniteLoading noMargin />}
					</ScrollLoad>
				</WrapContent>
			</Container>
		</>
	);
};

export default NewsFeed;
