// @flow
import React from "react";
import { useHistory } from "react-router-dom";
import { type Project } from "Types";
import { formatSrc } from "Helpers/Image";
import {
  Card,
  User,
  UserDetails,
  Name,
  Detail,
  ProjectContainer,
  Title,
  ImageContainer,
  Cover
} from "./styles";
import Avatar from "Components/Avatar";

type Props = {
  project: Project
};

const ProjectCard = ({ project }: Props): React.Node => {
  let history = useHistory();
  const {
    profile: { id, photo, first_name, last_name, location, occupation },
    title,
    cover_image_url
  } = project;

  function goToProfile() {
    history.push(`/profiles/${id}/portfolio`);
  }

  function goToProject() {
    history.push(`/projects/${project.id}`);
  }

  return (
    <Card>
      <User onClick={goToProfile}>
        <Avatar src={photo} />
        <UserDetails>
          <Name>{`${first_name} ${last_name} `}</Name>
          <Detail>{location}</Detail>
          <Detail>{occupation}</Detail>
        </UserDetails>
      </User>
      <ProjectContainer onClick={goToProject}>
        <Title>{title}</Title>
        <ImageContainer>
          <Cover src={formatSrc(cover_image_url, 500)} />
        </ImageContainer>
      </ProjectContainer>
    </Card>
  );
};

export default ProjectCard;
