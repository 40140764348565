// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledFooter = styled.footer(
  ({ theme }) => `
  width: 100%;
  padding: ${theme.spacing * 2}rem 0 ${theme.spacing}rem;
  background-color: ${theme.colors.black};
  min-height: min-content;
  z-index: ${theme.zIndex.front};
`
);

export const Container = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidth}px;
  margin: auto;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;
  gap: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 6fr 2fr;
    gap: ${theme.spacing * 3}rem;
  }
`
);

export const Image = styled.img`
  width: 100px;
`;

export const Logo = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0, -1rem);
  padding-top: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    justify-content: flex-start;
    padding:0;
  }
`
);

export const List = styled.ul(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: ${theme.spacing / 2}rem;
`
);

export const ListItem = styled.li(
  ({ theme }) => `
  margin: ${theme.spacing / 2}rem 0;
  &:before {
    content: "/";
    color: white;
    padding: 0 ${theme.spacing}rem;
  }
  ${List} &:first-child:before {
    content: none;
  }
`
);

export const NavLink = styled(Link)(
  ({ theme }) => `
  color: ${theme.colors.white};
  font-size: ${theme.fonts.size.small};
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
`
);

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.medium}px) {
    justify-content: flex-end;
  }
`;

export const SocialLink = styled.img`
  margin: ${props => props.theme.spacing / 2}rem;
`;

export const Policies = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${theme.spacing / 2}rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    grid-column: 2 / 4;
    justify-content: flex-end;
  }
`
);

export const StyledLink = styled(Link)(
  ({ theme }) => `
  color: ${theme.colors.white};
  font-size: ${theme.fonts.size.xsmall};
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: ${theme.spacing / 2}rem 0;
  &:before {
    content: "/";
    color: white;
    padding: 0 ${theme.spacing}rem;
  }
  ${Policies} &:first-child:before {
    content: none;
  }
`
);

export const Text = styled.p(
  ({ theme }) => `
  color: ${theme.colors.white};
  font-size: ${theme.fonts.size.xsmall};
  margin-left: ${theme.spacing * 2}rem;
  margin-bottom: 0;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    width: 100%;
    margin: 0;
  }
`
);
