import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const ImageContainer = styled.div(
  ({ columns, theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
  margin-bottom: ${columns > 1 ? theme.spacing * 2 : theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    width: ${columns === 1 ? 590 : theme.maxWidth / columns - 16}px;
    height: ${columns === 1 ? 590 : theme.maxWidth / columns - 16}px;
  }
`
);

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover
  cursor: pointer;
`;
