// @flow
import React from "react";
import { Helmet } from "react-helmet";

import Link from "Components/Link";
import Header from "Components/Header";
import Footer from "Components/Footer";
import Section from "Components/Section";
import Button from "Components/Button";
import ProjectCard from "Components/ProjectCard";

const styles = {
  styleguideContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    margin: "0.5rem",
    gap: "0.5rem"
  },
  styleguideSection: {
    margin: "3rem 0",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.12)",
    padding: "1rem"
  },
  styleguideList: {
    listStyleType: "none",
    padding: 0,
    margin: 0
  },
  styleguideListItem: {
    textDecoration: "none"
  }
};

export default function StyleguideIndex() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Styleguide</title>
      </Helmet>
      <Link to="/">Back home</Link>
      <div style={styles.styleguideContainer}>
        <div>
          <ul style={styles.styleguideList}>
            <li style={styles.styleguideListItem}>
              <a href="#global">Global</a>
            </li>
            <li style={styles.styleguideListItem}>
              <a href="#homepage">Homepage</a>
            </li>
          </ul>
        </div>
        <div>
          <section id="global" style={styles.styleguideSection}>
            <h2>Global</h2>
            <h4>Header (logged out)</h4>
            <Header />
            <h4>Header (logged in, unapproved)</h4>
            <Header loggedIn />
            <h4>Header (logged in, approved)</h4>
            <Header loggedIn approved />
            <h4>Footer</h4>
            <Footer />
          </section>
          <section id="homepage" style={styles.styleguideSection}>
            <h2>Homepage</h2>
            <Section heading="How it works">
              <div>
                <p>
                  ClickerMe is a free network for people who live and breathe
                  fashion — a place for fashion creatives to connect,
                  collaborate and showcase their work.
                </p>
                <h4>Who is ClickerMe for?</h4>
                <Button variant="black">Find out more</Button>
              </div>
            </Section>
            <Section heading="Portfolios of the month">
              <ProjectCard
                image="https://picsum.photos/300/200"
                title="Graduate Project"
                name="Rebecca Langebæk"
                location="London"
                specialism="Junior Menswear Designer"
              />
            </Section>
          </section>
          <section id="profile" style={styles.styleguideSection}>
            <h2>Profile</h2>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}
