// @flow
import styled from 'styled-components';

export const Container = styled.label`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const HiddenCheckbox = styled.input`
	display: none;
`;

export const StyledCheckbox = styled.div(
	({ theme, checked, yellow, label }) => `
  width: ${theme.spacing * 1.5}rem;
  height: ${theme.spacing * 1.5}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  border: 1px solid ${theme.colors.grey()};
  margin: ${label ? theme.spacing / 2 : 0}rem;
  margin-left: 0;
  color: ${yellow ? theme.colors.grey('dark') : theme.colors.white};
  &:hover {
    border: 2px solid ${theme.colors.grey('dark')};
  }
  .checkmark {
 	top: 11px;
	left: 11px;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	 background-color: ${
			checked
				? yellow
					? theme.colors.yellow()
					: theme.colors.grey('dark')
				: theme.colors.white
		};
}
`
);
