// @flow
import styled from "styled-components";
import Button from "Components/Button";
import Input from "Components/Input";
import IconButton from "Components/IconButton";

export const Container = styled.div(
	({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing * 2}rem ${theme.spacing / 2}rem;
  margin-bottom: ${theme.spacing * 2}rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    flex-direction: row;
    align-self: center;
    align-items: flex-start;
    max-width: ${theme.maxWidth}px;
    background-color: transparent;
    padding: 0;
    margin: ${theme.spacing * 3.5}rem auto ${theme.spacing * 5}rem;
    position: relative;
  }
`
);

export const AvatarContainer = styled.div`
	position: relative;
`;

export const ActionButton = styled(Button)(
	(props) => `
  @media screen and (min-width: ${props.theme.breakpoints.medium}px) {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
`
);

export const Heading = styled.h1(
	({ theme }) => `
  margin: 0;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    font-size: ${theme.fonts.size.small};
  }
`
);

export const EditableArea = styled.div(
	({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${theme.spacing / 2}rem;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    justify-content: space-between;
    background-color: ${theme.colors.white};
  }
`
);

export const Form = styled.form`
	padding-left: 1rem;

	@media screen and (min-width: ${(props) =>
			props.theme.breakpoints.medium}px) {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
`;

export const Content = styled.div(
	({ theme }) => `
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 40px;
  font-size: ${theme.fonts.size.small};
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    margin: 0;
    margin-top: ${theme.spacing}rem;
    width: 100%;
  }
`
);

export const Details = styled.div`
	display: flex;
	flex-flow: column wrap;
	@media screen and (min-width: ${(props) =>
			props.theme.breakpoints.medium}px) {
		display: grid;
		grid-auto-flow: column;
		grid-gap: 1px;
		grid-template-columns: repeat(1, 0.6fr);
		grid-template-rows: repeat(3, auto);
	}
`;

export const Detail = styled.div(
	({ theme }) => `
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    background-color: ${theme.colors.white};
    padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
    margin-right: 0;
  }
`
);

export const PeerDetail = styled.div`
	margin: ${(props) => props.theme.spacing / 2}rem;
	@media screen and (min-width: ${(props) =>
			props.theme.breakpoints.medium}px) {
		margin-left: 0;
	}
`;

export const Bold = styled.span`
	font-weight: bold;
	white-space: nowrap;
	margin-right: ${(props) => props.theme.spacing / 2}rem;
`;

export const BoldWrap = styled.span`
	font-weight: bold;
	margin-right: ${(props) => props.theme.spacing / 2}rem;
`;

export const InputRow = styled.div`
	display: flex;
	flex-flow: row wrap;
`;

export const LineInput = styled(Input)(
	(props) => `
  height: ${props.theme.spacing * 3}rem;
  align-self: center;
  padding: 0;
  margin-right: ${props.theme.spacing / 2}rem;
  font-size: ${props.theme.fonts.size.small};
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${props.theme.colors.grey()};
  outline: none;
  `
);

export const CopyContainer = styled.div`
	align-self: flex-start;
	text-align: left;
`;

export const MobilePaddedWrapper = styled.div`
	@media screen and (max-width: ${(props) => props.theme.breakpoints.small}px) {
		padding: 1rem;
	}
`;

export const FormModal = styled.form`
	display: flex;
	flex-direction: column;
`;

export const WrapButtons = styled.div(
	({ theme }) => `
  padding-top: ${theme.spacing * 2}rem;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing}rem;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {

`
);

export const WrapForm = styled.div(
	({ theme }) => `
  padding-top: 2rem;
  
`
);

export const AlignInstaHandle = styled.div(
	({ theme }) => `
width: 100%;
display: flex;
background-color: white;
margin: auto;
align-items: center;
border: 1px solid ${theme.colors.grey()};
padding-left: 10px;
margin: ${theme.spacing / 4}rem 0;

`
);

export const AlignIcon = styled.div(
	({ theme }) => `
display: flex;
z-index: 1000;
`
);

export const WrapContent = styled.div(
	({ theme }) => `
display: flex;
flex-direction: row;
@media screen and (max-width: ${theme.breakpoints.medium}px) {
display: block;
}
`
);

export const Section = styled.div(
	({ theme }) => `
display: flex;
flex-direction: column;
padding-left: ${theme.spacing * 2}rem;
padding-right: ${theme.spacing * 2}rem;
border-right: 1px solid #dadada;
@media screen and (max-width: ${theme.breakpoints.medium}px) {

}
`
);
