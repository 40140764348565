// @flow
import styled from "styled-components";

export const Container = styled.div(
  ({ noMargin, theme }) =>
    `
  display: flex;
  height: 5vh;
  flex-direction: column;
  margin: ${noMargin ? 0 : theme.spacing}rem;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.white};
`
);
