// @flow
import React, { useState } from "react";
import { CheckboxContainer } from "./styles";
import Checkbox from "Components/Checkbox";

type Props = {
	selected?: Array<string>,
	values: Array<string>,
	onChange?: Function,
	yellow?: boolean,
};

function CheckboxGroup({
	selected,
	values,
	onChange,
	yellow,
	vertical,
}: Props) {
	
	const [items, setItems] = useState(selected);

	return (
		<CheckboxContainer vertical={vertical}>
			{values.map((value, index) => (
				<Checkbox
					key={index}
					label={value}
					checked={items.includes(value)}
					onChange={() => {
						const newItems = items.includes(value)
							? items.filter((item) => item !== value)
							: [...items, value];
						setItems(newItems); /*needs a change*/
						if (onChange) onChange(newItems);
					}}
					yellow={yellow}
				/>
			))}
		</CheckboxContainer>
	);
}

export default CheckboxGroup;
