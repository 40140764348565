// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import BlockContainer from 'Components/BlockContainer';
import Feedback from 'Components/Blocks/Feedback';

function Competitions() {
	return (
		<>
			<Helmet>
				<title>ClickerMe | Fashion Competitions</title>
				<meta
					name="description"
					content="Enter our fashion competition to win a fiscal contribution towards an unrealised fashion project"
				/>
				<htmlAttributes h1="Competitions & Grants For Fashion Creatives"></htmlAttributes>
			</Helmet>
			<BlockContainer name="competitions" backgroundColor="#FFF" />
			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
		</>
	);
}

export default Competitions;
