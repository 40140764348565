// @flow
import styled from "styled-components";

export const Container = styled.div(
  props => `
  display: flex;
  flex-flow: row wrap;
  min-height: 500px;
  padding: ${props.theme.spacing}rem;
  margin: 0 ${props.theme.spacing / 2}rem ${props.theme.spacing * 2}rem;
  background-color: ${props.theme.colors.white};
  position: relative;
  @media screen and (min-width: ${props.theme.breakpoints.medium}px) {
    width: ${props.theme.maxWidth}px;
    margin: auto;
  }
`
);

export const Column = styled.div(
  ({ theme }) => `
  width: 100%;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    width: 50%;
    padding: ${theme.spacing}rem;
  }
`
);

export const Section = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-bottom: ${props => props.theme.spacing * 2}rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin-bottom: ${props => props.theme.spacing}rem;
`;

export const Placeholder = styled.div`
  height: ${props => props.theme.spacing * 0.75}rem;
  background-color: ${props => props.theme.colors.grey("light")};
  margin: ${props => props.theme.spacing / 2}rem 0;
  width: ${props => (props.half ? "45%" : "100%")};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.theme.spacing}rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;
