import styled from 'styled-components';
import Input from 'Components/Input';
import TextBox from 'Components/TextBox';
import Button from 'Components/Button';

export const Container = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	min-height: 100vh;
`;

export const Actions = styled.div(
	({ theme }) => `
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding: ${theme.spacing}rem;
  background-color: ${theme.colors.black};
  z-index: ${theme.zIndex.front};
`
);

export const ButtonContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
`;

export const StyledLink = styled.button(
	({ theme }) => `
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: ${theme.colors.white};
  font-size: 1rem;
  padding: 0;
  display: flex;
  align-items: center;
  img {
    margin-right: ${theme.spacing / 2}rem;
  }
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    padding: ${theme.spacing}rem;
  }
`
);

export const BackButton = styled.button(
	({ theme }) => `
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: ${theme.colors.white};
  font-size: 1rem;
  padding: 0;
  display: flex;
  align-items: center;

`
);

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: ${(props) => props.theme.spacing * 2}rem;
	max-width: ${(props) => props.theme.maxWidth}px;
	margin: auto;
`;

export const InputContainer = styled.form`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: ${(props) => props.theme.spacing}rem;
`;

export const TextArea = styled(TextBox)(
	({ theme, large }) => `
  border: none;
  text-align: center;
  outline: none;
  font-size: ${large ? theme.fonts.size.large : theme.fonts.size.normal};
  font-weight: ${large ? 'bold' : 'normal'};
`
);

export const ItemContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	margin-top: ${(props) => props.theme.spacing / 2}rem;
`;

export const AddButton = styled.div(
	({ theme }) => `
  background-color: ${theme.colors.grey()};
  border: none;
  margin: ${theme.spacing}rem;
  width: ${theme.spacing * 16}rem;
  height: ${theme.spacing * 16}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.black};
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
`
);

export const ReorderButton = styled.button`
	background-color: transparent;
	border: none;
	align-self: flex-end;
`;

export const ImageContainer = styled.div`
	border: 2px solid ${(props) => props.theme.colors.yellow()};
	margin: ${(props) => props.theme.spacing / 2}rem;
`;

export const Placeholder = styled.div(
	({ theme }) => `
  background-color: ${theme.colors.grey()};
  width: ${theme.spacing * 18}rem;
  height: ${theme.spacing * 18}rem;
`
);

export const StyledButton = styled(Button)(
	({ theme }) => `
  min-width: 0;
  padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    margin: ${theme.spacing / 4}rem;
  }
`
);

export const Icon = styled.img`
	@media screen and (min-width: ${(props) => props.theme.breakpoints.small}px) {
		display: none;
	}
`;

export const WidthDependentText = styled.span(
	({ theme }) => `
  font-family: ${theme.fonts.default};
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`
);

export const BackButtonText = styled.div(
	({ theme }) => `
  display:flex;
`
);
