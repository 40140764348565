// @flow
import React, { useState } from "react";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { Education } from "Types";
import { formatAsDate, displayDate } from "Helpers/Date";
import { InputRow, Actions, Container, Header } from "./styles";
import EducationSingle from "./EducationSingle";
import FormField from "Components/FormField";
import Input from "Components/Input";
import Modal from "Components/Modal";
import Button from "Components/Button";
import IconButton from "Components/IconButton";

type Props = {
  education: Array<Education>,
  LargePlaceholder: React.Node,
  setError: Function
};

function EducationForm({ education, LargePlaceholder, setError }: Props) {
  const [data, setData] = useState(education);
  const [adding, toggleAdding] = useState(false);
  const [newItem, setNewItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [submit, response] = useLazyFetch({
    url: "/api/me/education",
    method: "put"
  });

  function setValue(key, value) {
    setNewItem({ ...newItem, [key]: value });
  }

  function onSubmit(e) {
    e.preventDefault();
    submit({ education: [...data, newItem] }, onSuccess, onError);
  }

  function onSuccess() {
    if (Boolean(newItem)) setData([...data, newItem]);
    setError(null);
    toggleAdding(false);
    setNewItem(null);
  }

  function onError() {
    setError("Sorry, we could not update your education right now");
    setData(education);
  }

  function onDelete() {
    setData([...data.filter((item, index) => index !== itemToDelete)]);
    submit(
      { education: [...data.filter((item, index) => index !== itemToDelete)] },
      onSuccess,
      onError
    );
    setItemToDelete(null);
  }

  return (
    <React.Fragment>
      <Header>
        Education
        <IconButton
          yellow
          onClick={() => {
            toggleAdding(true);
            setNewItem({
              qualification: undefined,
              institution: undefined,
              location: undefined,
              startDate: undefined,
              endDate: undefined
            });
          }}
        >
          <img src={require("Assets/Images/plus.svg")} />
        </IconButton>
      </Header>
      {data?.map((item, index) => (
        <EducationSingle
          key={index}
          item={item}
          updateItem={updatedItem =>
            setData([
              ...data.map((item, i) => (i === index ? updatedItem : item))
            ])
          }
          deleteItem={() => setItemToDelete(data.indexOf(item))}
          submit={() => submit({ education: data }, onSuccess, onError)}
        />
      ))}
      {!adding && !data.length && <LargePlaceholder />}
      {adding && newItem && (
        <Container onSubmit={onSubmit}>
          <InputRow>
            <FormField label="Institution">
              <Input
                value={newItem.institution}
                onChange={e => setValue("institution", e.target.value)}
              />
            </FormField>
            <FormField label="Qualification">
              <Input
                value={newItem.qualification}
                onChange={e => setValue("qualification", e.target.value)}
              />
            </FormField>
          </InputRow>
          <FormField label="Location">
            <Input
              value={newItem.location}
              onChange={e => setValue("location", e.target.value)}
            />
          </FormField>
          <InputRow>
            <FormField label="Start date">
              <Input
                type="month"
                placeholder="YYYY-MM"
                value={formatAsDate(newItem?.startDate)}
                onChange={e =>
                  setValue("startDate", displayDate(e.target.value))
                }
              />
            </FormField>
            <FormField label="End date">
              <Input
                type="month"
                placeholder="YYYY-MM"
                value={formatAsDate(newItem?.endDate)}
                onChange={e => setValue("endDate", displayDate(e.target.value))}
              />
            </FormField>
          </InputRow>
          <Actions>
            <Button size="small" padded type="submit">
              {response?.loading ? "Saving" : "Save"}
            </Button>
            <Button
              size="small"
              variant="black"
              padded
              onClick={() => {
                setNewItem(null);
                toggleAdding(false);
              }}
            >
              Cancel
            </Button>
          </Actions>
        </Container>
      )}
      {itemToDelete !== null && (
        <Modal onClose={() => setItemToDelete(null)}>
          Are you sure you want to delete this item?
          <Button onClick={onDelete}>Yes</Button>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default EducationForm;
