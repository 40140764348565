// @flow
import styled from "styled-components";

export const StyledFooterNotification = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: ${props => props.theme.spacing}rem;
  background-color: ${props => props.theme.colors.grey("darker")};
  min-height: min-content;
  color: ${props => props.theme.colors.white};
  z-index: ${props => props.theme.zIndex.front};
  @media screen and (min-width: ${props => props.theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 5fr 2fr;
    padding: ${props => props.theme.spacing}rem
      ${props => props.theme.spacing * 5}rem;
  }
`;

export const Heading = styled.h2`
  margin: 0;
`;

export const Text = styled.p`
  margin: 0;
`;
