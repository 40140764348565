// @flow
import React, { useState, useEffect } from 'react';
import { useAuth } from 'Hooks/useAuth';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
	StyledHeader,
	Container,
	LogoContainer,
	MenuButton,
	MenuIcon,
	StyledLink,
	Logo,
	Inner,
	NavLink,
	SearchBar,
	SearchBarMobile,
	StyledInput,
	SearchIcon,
	DropDownButton,
	DropDownContainer,
	DropDownArrow,
	DropDown,
	SubmittedNotification,
	PlainButton,
	AvatarLink,
	Wrap,
	ButtonJoin,
	ButtonDesktop,
	ButtonMobile,
	WrapLogoAndMenu,
	WrapIcon,
	WrapSearch,
	NotificationBar,
	WrapNotification,
	WrapPlainButton,
	SearchBackground,
	WrapStyledHeader,
	BackButton,
	WidthDependentText,
	BackButtonText,
	WrapBrands,
} from './styles';
import { FaLock } from 'react-icons/fa';
import { MdArrowDropDown } from 'react-icons/md';
import Button from 'Components/Button';
import Avatar from 'Components/Avatar';
import IconButton from 'Components/IconButton';
import SocialLinks from 'Components/SocialLinks';
import theme from 'Styles/Theme';
import AccessibleLinks from 'Components/AccessibleLinks';
import LoggedInLinks from 'Components/LoggedInLinks';

type Props = {
	menuOpen?: Boolean,
	toggleMenuOpen?: Function,
};

function Header({
	menuOpen,
	toggleMenuOpen,
	notShown,
	showMargin,
	makeSmaller,
}: Props) {
	const { pathname } = useLocation();
	const { auth, logout } = useAuth();
	const { user } = auth;
	const status = user?.approval_status;
	let history = useHistory();
	const [dropdownOpen, toggleDropdownOpen] = useState(false);
	const [searchText, setSearchText] = useState(null);
	const mobileButtonText = pathname?.includes('/login') ? 'sign up' : 'log in';
	const [width, setWidth] = React.useState(window.innerWidth);
	const [isMobile, setIsMobile] = React.useState(
		window.innerWidth < theme.breakpoints.medium
	);
	const [expanded, setExpanded] = useState({});
	const [searchOpen, toggleSearch] = useState(false);
	const [triggerSearch, setTriggerSearch] = useState(false);
	const [beforeSearchRoute, setBeforeSearchRoute] = useState(null);

	const closeIcon = require('Assets/Images/close-icon.svg');
	const menuIcon = require('Assets/Images/menu.png');
	const searchIcon = require('Assets/Images/search-icon.svg');
	const searchIconBlack = require('Assets/Images/search.png');
	const logOutIcon = require('Assets/Images/log-out-icon.svg');
	const searchGoIcon = require('Assets/Images/search-go-icon.svg');
	const arrowNotificationIcon = require('Assets/Images/arrownotification.svg');

	function wrappedIcon(icon) {
		return icon;
	}

	useEffect(() => {
		window.addEventListener('resize', () => setWidth(window.innerWidth));
		setIsMobile(width < theme.breakpoints.medium);
	}, []);

	function preventDisabledClick(e) {
		if (status !== 'approved') e.preventDefault();
	}

	function joinForFree(e) {
		history.push('/');
		window.location.reload(false);
		window.scrollTo(0, 0);
	}

	function onSearch(e) {
		e.preventDefault();
		const redirect = pathname;
		history.push(`/search?name=${searchText}`);
		setSearchText(null);
		setTriggerSearch(true);
		toggleSearch(!searchOpen);
	}
	function onButtonMobileClick(e) {
		history.push(pathname?.includes('/login') ? '/' : '/login');
		toggleMenuOpen(menuOpen);
	}

	function handleToggleMenu() {
		toggleMenuOpen(!menuOpen);
	}

	function handleExpanded(name) {
		setExpanded({ [name]: !expanded[name] });
	}

	function toggleSearchBar() {
		toggleSearch(!searchOpen);
		if (!searchOpen) {
			setBeforeSearchRoute(pathname);
		}
		if (searchOpen && beforeSearchRoute) {
			history.push(beforeSearchRoute);
		}
	}

	function closeMenus() {
		toggleMenuOpen(false);
		toggleSearch(false);
		handleExpanded(false);
	}

	function TopButton() {
		if (status === 'submitted_for_approval') {
			return (
				<SubmittedNotification onClick={() => history.push('/verification')}>
					Profile under review
				</SubmittedNotification>
			);
		}

		return (
			<Button
				onClick={() => history.push('/verification')}
				style={
					pathname?.includes('verification')
						? { margin: '10px', visibility: 'hidden' }
						: { margin: '10px' }
				}
			>
				Become a verified member
			</Button>
		);
	}

	return (
		<WrapStyledHeader>
			{['/request-a-call-back', '/thank-you'].includes(pathname) ? (
				<StyledHeader>
					<WrapBrands>
						<StyledLink to="/" style={{ textDecoration: 'none' }}>
							<BackButtonText>
								<BackButton>
									<img src={require('Assets/Images/arrow-left.svg')} />
								</BackButton>
								<WidthDependentText>BACK TO MAIN SITE</WidthDependentText>
							</BackButtonText>
						</StyledLink>
						<StyledLink style={{ justifyContent: 'center' }} to="/">
							<Logo small />
						</StyledLink>
					</WrapBrands>
				</StyledHeader>
			) : (
				<StyledHeader>
					{auth.access_token ? (
						<Container menuOpen={menuOpen}>
							<LogoContainer menuOpen={menuOpen}>
								<MenuButton onClick={() => toggleMenuOpen(!menuOpen)}>
									<MenuIcon
										onClick={closeMenus}
										src={
											menuOpen ? wrappedIcon(closeIcon) : wrappedIcon(menuIcon)
										}
									/>
								</MenuButton>

								{status === 'approved' && (
									<WrapPlainButton onClick={() => toggleMenuOpen(false)}>
										<PlainButton
											type="submit"
											onClick={toggleSearchBar}
											makeSmaller={'makeSmaller'}
										>
											<SearchIcon
												onClick={() => setTriggerSearch(false)}
												src={
													searchOpen
														? wrappedIcon(closeIcon)
														: wrappedIcon(searchIcon)
												}
											/>
										</PlainButton>
									</WrapPlainButton>
								)}
								<StyledLink
									to="/"
									onClick={() => toggleMenuOpen(false)}
									small={'true'}
									loggedIn={auth.access_token}
								>
									<Logo onClick={closeMenus} small />
								</StyledLink>
							</LogoContainer>
							<Inner menuOpen={menuOpen} onClick={() => toggleMenuOpen(false)}>
								<LoggedInLinks
									name="Profile"
									borderTop={'0'}
									sublink="profile"
								/>
								<LoggedInLinks name="Discover" sublink="projects" />
								<LoggedInLinks
									name="Inbox"
									sublink="messaging"
									onClick={(e) => preventDisabledClick(e)}
									disabled={status !== 'approved'}
									hideLock={status !== 'approved' && <FaLock />}
								/>
								<LoggedInLinks
									name="News Feed"
									sublink="feed"
									onClick={(e) => preventDisabledClick(e)}
									disabled={status !== 'approved'}
									hideLock={status !== 'approved' && <FaLock />}
								/>
								<LoggedInLinks name="Jobs" sublink="jobs" show={'notShown'} />
							</Inner>
							<Inner menuOpen={menuOpen} showMargin={'noMargin'}>
								{isMobile && (
									<AccessibleLinks
										expanded={expanded}
										handleExpanded={handleExpanded}
										pathname={pathname}
										handleToggleMenu={handleToggleMenu}
										loggedIn={true}
									/>
								)}
								{status === 'approved' ? (
									<SearchBar onSubmit={onSearch}>
										<StyledInput
											type="text"
											placeholder="Search names..."
											value={searchText || ''}
											onChange={(e) => setSearchText(e.target.value)}
										/>

										<PlainButton type="submit">
											<SearchIcon src={wrappedIcon(searchIconBlack)} />
										</PlainButton>
									</SearchBar>
								) : (
									!isMobile && TopButton()
								)}
								<AvatarLink
									to="/profile/portfolio"
									active={pathname?.includes('/profile/portfolio').toString()}
								>
									<Avatar
										size="large"
										src={auth?.user?.profile?.photo}
									></Avatar>
								</AvatarLink>

								<DropDownButton
									onClick={() => toggleDropdownOpen(!dropdownOpen)}
								>
									<MdArrowDropDown size={34} color="white" />
								</DropDownButton>
								<DropDownContainer dropdownOpen={dropdownOpen}>
									<DropDownArrow dropdownOpen={dropdownOpen} />
									<DropDown
										onClick={() => {
											toggleDropdownOpen(false);
											toggleMenuOpen(!menuOpen);
										}}
									>
										<NavLink
											style={{
												flexDirection: 'row-reverse',
												justifyContent: 'flex-end',
												paddingLeft: '0',
											}}
											to="/"
											onClick={() => logout(history.push('/'))}
										>
											Log out
											<WrapIcon>
												<IconButton>
													<img src={wrappedIcon(logOutIcon)}></img>
												</IconButton>
											</WrapIcon>
										</NavLink>
										{isMobile && <SocialLinks />}
									</DropDown>
								</DropDownContainer>
							</Inner>
						</Container>
					) : (
						<Container menuOpen={menuOpen}>
							{isMobile ? (
								<>
									<WrapLogoAndMenu>
										<LogoContainer menuOpen={!menuOpen}>
											<MenuButton onClick={() => toggleMenuOpen(!menuOpen)}>
												<MenuIcon
													onClick={closeMenus}
													src={
														menuOpen
															? wrappedIcon(closeIcon)
															: wrappedIcon(menuIcon)
													}
												/>
											</MenuButton>
											<Wrap onClick={closeMenus}>
												<ButtonJoin
													variant="yellow"
													to="/"
													onClick={() => window.scrollTo(0, 0)}
												>
													Join for free
												</ButtonJoin>
												<ButtonDesktop
													variant="white"
													to="/login"
													style={{ minWidth: '0' }}
												>
													login
												</ButtonDesktop>
												<ButtonMobile
													variant="white"
													onClick={onButtonMobileClick}
												>
													{mobileButtonText}
												</ButtonMobile>
											</Wrap>
											<StyledLink
												to="/"
												onClick={() => toggleMenuOpen(false)}
												small
											>
												<Logo onClick={closeMenus} small />
											</StyledLink>
										</LogoContainer>
									</WrapLogoAndMenu>
									<Inner menuOpen={menuOpen}>
										{isMobile && (
											<>
												<AccessibleLinks
													firstRow={0}
													expanded={expanded}
													handleExpanded={handleExpanded}
													pathname={pathname}
													handleToggleMenu={handleToggleMenu}
													toLink={''}
													title={''}
												/>
												<SocialLinks />
												<ButtonMobile
													variant="yellow"
													onClick={() => toggleMenuOpen(false)}
													to="/"
													style={{
														position: 'relative',
														top: '5vh',
														width: '95%',
													}}
												>
													Join for free
												</ButtonMobile>
											</>
										)}
									</Inner>
								</>
							) : (
								<>
									<Link to="/" style={{ paddingTop: '1rem' }}>
										<Logo />
									</Link>
									<Wrap>
										<ButtonJoin
											variant="yellow"
											to="/"
											onClick={() => window.scrollTo(0, 0)}
										>
											Join for free
										</ButtonJoin>
										<ButtonDesktop
											variant="white"
											to="/login"
											style={{ minWidth: '0' }}
										>
											login
										</ButtonDesktop>
										<ButtonMobile variant="white" onClick={onButtonMobileClick}>
											{mobileButtonText}
										</ButtonMobile>
									</Wrap>
								</>
							)}
						</Container>
					)}
					{searchOpen && (
						<WrapSearch>
							<SearchBarMobile onSubmit={onSearch}>
								<StyledInput
									type="text"
									placeholder="Search names..."
									value={searchText || ''}
									onChange={(e) => setSearchText(e.target.value)}
								/>
								<PlainButton type="submit">
									<SearchIcon
										makeSmaller={'makeSmaller'}
										style={{ width: '30px', marginRight: '1rem' }}
										src={wrappedIcon(searchGoIcon)}
									/>
								</PlainButton>
							</SearchBarMobile>
							{!triggerSearch && <SearchBackground />}
						</WrapSearch>
					)}
					{!auth.access_token ? (
						<React.Fragment>
							{pathname !== '/login' && (
								<WrapNotification>
									<NotificationBar onClick={joinForFree}>
										JOIN FOR FREE
									</NotificationBar>
									<img
										src={wrappedIcon(arrowNotificationIcon)}
										style={{ paddingLeft: '0.5rem' }}
									></img>
								</WrapNotification>
							)}
						</React.Fragment>
					) : (
						<>
							{status === 'submitted_for_approval' ? (
								<WrapNotification>
									<NotificationBar
										style={{ color: 'white' }}
										onClick={() => history.push('/verification')}
									>
										PROFILE UNDER REVIEW
									</NotificationBar>
								</WrapNotification>
							) : (
								status !== 'approved' && (
									<WrapNotification
										style={
											pathname?.includes('verification')
												? { display: 'none' }
												: { margin: '0px' }
										}
									>
										<NotificationBar
											onClick={() => history.push('/verification')}
										>
											BECOME A VERIFIED MEMBER
										</NotificationBar>
										<img
											src={wrappedIcon(arrowNotificationIcon)}
											style={{ paddingLeft: '0.5rem' }}
										></img>
									</WrapNotification>
								)
							)}
						</>
					)}
				</StyledHeader>
			)}
		</WrapStyledHeader>
	);
}

export default Header;
