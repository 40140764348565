// @flow
import React, { useState } from "react";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { Container, Column, Section, Placeholder } from "./styles";
import AboutForm from "Components/ProfileForms/AboutForm";
import EducationForm from "Components/ProfileForms/EducationForm";
import SkillsForm from "Components/ProfileForms/SkillsForm";
import ExperienceForm from "Components/ProfileForms/ExperienceForm";
import Notification from "Components/Notification";
import { useFetch } from "Hooks/useFetch";

const LargePlaceholder = () => (
  <React.Fragment>
    <Placeholder />
    <Placeholder />
    <Placeholder half />
  </React.Fragment>
);

const SmallPlaceholders = () => (
  <React.Fragment>
    <Placeholder half />
    <Placeholder half />
    <Placeholder half />
    <Placeholder half />
    <Placeholder half />
    <Placeholder half />
  </React.Fragment>
);

function UserAboutTab() {
  const { data, loading, error: loadingError, setData } = useFetch({ url: '/api/me' });
  const [error, setError] = useState(null);
  const [submitProfile] = useLazyFetch({
    url: "/api/me/profile",
    method: "put"
  });
  const edit = require("Assets/Images/edit.svg");
  const plus = require("Assets/Images/plus.svg");
  const icons = {
    edit: <img src={edit} />,
    plus: <img src={plus} />
  };
  const user = data?.user || {};

  function submit() {
    submitProfile(data.user?.profile, onSuccess, onError);
  }

  function onSuccess() {
    setError(null);
  }

  function onError() {
    setError("Sorry, we could not update your profile right now");
    setData(user);
  }

  if (!data) return "";

  return (
    <Container>
      {error && <Notification>{error}</Notification>}
      <Column>
        <Section>
          <AboutForm
            name={user?.profile?.first_name}
            about={user?.profile?.about_me}
            update={value =>{
              setData({user: {
                ...user,
                profile: { ...user.profile, about_me: value }}
              })}
            }
            submit={submit}
            LargePlaceholder={LargePlaceholder}
            icons={icons}
          />
        </Section>
        <Section>
          <EducationForm
            education={user.education || []}
            LargePlaceholder={LargePlaceholder}
            setError={setError}
          />
        </Section>
      </Column>
      <Column>
        <Section>
          <SkillsForm
            skills={user.skills || []}
            SmallPlaceholders={SmallPlaceholders}
            setError={setError}
            icons={icons}
          />
        </Section>
        <Section>
          <ExperienceForm
            experience={user.experience || []}
            LargePlaceholder={LargePlaceholder}
            setError={setError}
          />
        </Section>
      </Column>
    </Container>
  );
}

export default UserAboutTab;
