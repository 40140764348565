import styled, { css } from "styled-components";
import Button from "Components/Button";

export const Wrapper = styled.div(
  ({ theme }) => `
    margin:auto;
    justify-content:center;
  `
);

export const Container = styled.div(
  ({ theme, layout }) => `
    display: ${layout === "horizontal" ? "inline-flex" : "block"};
    padding: ${layout === "horizontal" ? "2rem 0" : theme.spacing * 2 + "rem"};
    @media screen and (max-width: ${theme.breakpoints.small}px) {
      padding:${theme.spacing}rem; 
    }
    `
);

export const Image = styled.img(
  ({ theme }) => `
    width: 100%;
    height:100%;
    overflow: hidden;
    margin: auto;
    object-fit:cover;
    height: 100%;
    min-height:141px;
    width: 100%;
    min-width: 250px;
    overflow:hidden;
  `
);

export const Content = styled.div(
  ({ theme, layout }) => `
    height:auto;
    width:250px;
    background-size: cover;
    margin-bottom:${theme.spacing * 2}rem;
    margin-right: ${layout === "horizontal" ? theme.spacing * 4 + "rem" : 0 };
    @media screen and (max-width: ${theme.breakpoints.small}px) {
      margin: ${layout === "horizontal" ? theme.spacing * 3 + "rem auto" : 0 };
    }
  `
);

export const StyledButton = styled(Button)(
  ({ theme }) => `
  padding-top:${theme.spacing / 2}rem;
  padding-bottom:${theme.spacing / 2}rem;
  text-align:center;
  margin:0;
  img{
    padding-left:${theme.spacing / 4}rem;
    margin:auto;
    display:inline;
  }
  p{
    font-size:${theme.fonts.size.small}
    margin:auto;
    display:inline;
  }
  
  `
);
export const Border = styled.div(
  ({ theme }) => `
    width:1px;
    background-color:black;
    height:100%;
    opacity:0.2;
  `
);
export const WrapImages = styled.div(
  ({ theme }) => `
    width:100%;
    height:141px;
    object-fit: cover;
    position: relative;
    img{
      position: absolute;
      right:0;
      index-z:1;
      width:72px;
      height:72px;
    }
  `
);
export const ButtonAndTitle = styled.div(
  ({ theme }) => `
    display:flex;
    flex-drection: row;
    align-items:center;
    background-color:white;
    padding: ${theme.spacing}rem ${theme.spacing}rem ${theme.spacing * 1.5}rem;
    justify-content:center;
    h4{
      padding-right:${theme.spacing / 2}rem;
    }
  
  `
);

export const CenterContent = styled.div(
  ({ theme }) => `
    display:block;
    text-align:center;
    background-color:white;
    padding: ${theme.spacing}rem ${theme.spacing}rem ${theme.spacing * 1.5}rem;
    justify-content:center;
  `
);
