import styled from "styled-components";

export const Wrapper = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidth}px;
  margin: auto;
`
);

export const Grid = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: ${theme.spacing * 4}rem;
  margin: ${theme.spacing}rem;
  max-width: 100%;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    grid-template-columns: repeat(3, 1fr);
    max-width: ${theme.maxWidth}px;
  }
`
);

export const Empty = styled.p`
  width: 100%;
  min-height: 60vh;
  text-align: center;
`;
