import styled from 'styled-components';

import { positioning } from 'Helpers/Positioning';

export const GlobalWrapper = styled.div(
	({ theme, image }) => `
 background-color: ${theme.colors.grey('landing')};
 width: 100%;
 `
);

export const BrandCaseStudies = styled.div(
	({ theme, image, src }) => `
max-width: ${theme.maxWidth}px;
margin: auto;
padding: ${theme.spacing * 5}rem 0;
@media screen and (max-width: ${theme.breakpoints.small}px) {
	padding: ${theme.spacing}rem;
 }
 `
);

export const Title = styled.div(
	({ theme, image, src, largerFont, isHeading }) => `
color: white;
font-size: ${largerFont ? theme.fonts.size.large : `20px`};
font-weight: bold;
text-align: ${isHeading ? `center` : `left`};
margin-top: ${theme.spacing}rem;
 `
);

export const Description = styled.div(
	({ theme, image, src, isHeading }) => `
	color: white;
	font-size: ${theme.fonts.size.normal};
	text-align: ${isHeading ? `center` : `left`};
	margin-top: ${theme.spacing / 2}rem;
	margin-bottom: ${theme.spacing * 1.5}rem;
 `
);

export const EachCase = styled.div(
	({ theme, image, src }) => `
max-width: 390px;
margin: ${theme.spacing * 3}rem;
@media screen and (max-width: ${theme.breakpoints.small}px) {
	margin: ${theme.spacing * 3}rem 0;
 }
text-align: left;
 `
);

export const Image = styled.img(
	({ theme, image, src }) => `
height: 234px;
width: 390px;
@media screen and (max-width: ${theme.breakpoints.small}px) {
	height: auto;
	width: 100%;
 }
 `
);

export const Cases = styled.div(
	({ theme, image, src }) => `
display: flex;
margin: auto;
justify-content: center;
align-items: center;
@media screen and (max-width: ${theme.breakpoints.small}px) {
  display: block;
 }

 `
);

export const FlashSale = styled.div(
	({ theme, image, src }) => `
display: flex;
 min-height: 70vh;
flex-direction: column;
justify-content: center;
align-items: center;
margin: auto;
background-color: #222222;
z-index: 0;
padding: ${theme.spacing * 5}rem 0;
p{
	margin: ${theme.spacing * 2}rem;
	color: white;
	font-weight: bold;
	font-size: ${theme.fonts.size.normal};
	text-align: center;
	max-width: 590px;
}
@media screen and (max-width: ${theme.breakpoints.medium}px) {
padding: ${theme.spacing * 3}rem ${theme.spacing}rem;
height: 35rem;
 }
 `
);

export const Text = styled.div(
	({ theme, image, src }) => `
	color: white;
	font-size: ${theme.fonts.size.large};
	font-weight: bold;
	margin-bottom: 1rem;
}
 `
);

export const Icon = styled.img(
	({ theme }) => `
    width:20px;
    height:20px;
    border : 0;
    text-decoration: none;
    outline: none;
  `
);

export const SlashImage = styled.div(
	({ theme, src }) => `
  position: absolute;
	background-size: cover;
	background-color: black;
	opacity: 0.39;
	z-index: 1;
	 min-height: 70vh;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  clip-path: polygon(0% 0%, 56% 0%, 44% 100%, 0% 100%);
  width: 100%;
  height: 30rem;
    @media screen and (max-width: ${theme.breakpoints.medium}px) {
			height: 35rem;    
      clip-path: polygon(0% 0%, 56% 0%, 44% 100%, 0% 100%);
	}
	 @media screen and (max-width: ${theme.breakpoints.small}px) {
      position: absolute;
      background-size: initial;
      background-position: right;
      left: 0;
      padding-top: 25%;
			width: 300px;
			height: 35rem;    
      clip-path: polygon(0% 0%, 86% 0%, 34% 100%, 0% 100%);
  }
`
);

export const Container = styled.div(
	(props) => `
  display: block;
  z-index: ${props.theme.zIndex.front};
  max-width: ${props.theme.maxWidth}px;
  margin: auto;
  align-items: center;
  flex-direction: ${positioning(props, 'row', 'row-reverse')}
  width: 100%;
  padding: ${props.theme.spacing * 2}rem ${props.theme.spacing}rem;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
   text-align: left;
   padding-bottom: ${props.theme.spacing}rem;
  }
`
);

export const EnquiryForm = styled.div(
	(props, theme) => `
  display: block;
  z-index: ${props.theme.zIndex.front};
  align-items: left;
  margin-top: 2rem;
  flex-direction: ${positioning(props, 'row', 'row-reverse')}
  padding: .2rem 4rem;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
   text-align: left;
   padding: 0;
  }
`
);

export const SectionTitle = styled.h2`
	font-size: 26px;
	font-weight: bold;
	padding-right: 1.5rem;
	text-align: center;
	color: ${(props) => props.theme.colors.white};
	@media screen and (max-width: ${(props) => props.theme.breakpoints.small}px) {
		font-size: 1.8rem;
	}
`;

export const FormColumn = styled.div`
	width: 50%;
	padding: 3rem;
	@media screen and (max-width: ${(props) => props.theme.breakpoints.small}px) {
		width: 100%;
		padding: 0;
	}
`;

export const Form = styled.form`
	width: 100%;
	display: inline-flex;
	padding: 0 3rem;
	@media screen and (max-width: ${(props) => props.theme.breakpoints.small}px) {
		padding: 0;
		display: block;
	}
`;
