// @flow
import React, { useState, useEffect } from "react";
import { useLazyFetch } from "Hooks/useLazyFetch";
import Button from "Components/Button";
import { Form, StyledInput } from "./styles";

const CaptionForm = ({
  innerProps,
  images,
  currentIndex,
  projectId,
  onSuccess
}) => {
  const [caption, setCaption] = useState(images[currentIndex]?.caption);

  const [updateImage] = useLazyFetch({
    url: `/api/projects/${projectId}/images/${images[currentIndex]?.id}`,
    method: "put"
  });

  function captionImage(e) {
    e.preventDefault();
    const updatedImage = { ...images[currentIndex], caption };
    updateImage({ image: updatedImage }, () => onSuccess(updatedImage));
  }

  useEffect(() => {
    setCaption(images[currentIndex]?.caption);
  }, [currentIndex]);

  return (
    <Form onSubmit={captionImage} {...innerProps}>
      <StyledInput
        placeholder="Caption your image..."
        value={caption || ""}
        onChange={e => setCaption(e.target.value)}
        caption={caption}
        maxLength={50}
      />
      <Button size="small" type="submit" padded>
        Save
      </Button>
    </Form>
  );
};

export default CaptionForm;
