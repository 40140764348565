import styled from "styled-components";

const CMFullLogoWhite = require("Assets/Images/CMFullLogoWhite.svg");

export const Container = styled.div`
  background-color: #000000;
  min-height: 85vh;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img.attrs({
  width: "176",
  src: CMFullLogoWhite
})`
  margin-bottom: 80px;

  @media screen and (min-width: ${props => props.theme.breakpoints.medium}px) {
    width: 252px;
    margin-bottom: 130px;
  }
`;

export const Block = styled.div`
  max-width: 550px;
  background-color: #ffffff;
  padding: 44px 22px;

  @media screen and (min-width: ${props => props.theme.breakpoints.medium}px) {
    padding: 55px 60px;
  }
`;

export const Message = styled.p`
  font-size: 20px;
  line-height: 29px;
  font-weight: bold;
  text-align: center;
  margin: 0;
`;
