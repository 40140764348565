// @flow
import React, { useState } from 'react';
import { CheckboxContainer } from './styles';
import RadioButton from 'Components/RadioButton';

type Props = {
	values: Array<string>,
	selected?: string,
	setSelectedItem?: Function,
	vertical?: boolean,
	yellow?: boolean,
};

function RadioButtonGroup({ values, selectedItem, setSelectedItem, yellow, vertical = false}: Props) {
	return (
		<CheckboxContainer vertical={vertical}>
			{values.map((element, index) => (
				<RadioButton
					key={ index }
					label={ element.label }
					halfWidth
					checked={ selectedItem === element.value }
					updateSelectedItem={ () => {
						setSelectedItem(element.value);
					}}
					yellow={yellow}
				/>
			))}
		</CheckboxContainer>
	);
}

export default RadioButtonGroup;
