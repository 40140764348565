import React, { useState } from "react";
import { formatSrc } from "Helpers/Image";
import ScrollLoad from "Components/ScrollLoad";
import Lightbox from "Components/Lightbox";
import { Grid, ImageContainer, Image } from "./styles";
import ProjectVideo from "../ProjectVideo";

const Gallery = ({ images, ...options }) => {
  const [lightboxIndex, setLightboxIndex] = useState(null);
  const [page, setPage] = useState(1);
  function imageOrVideo(image, index, videoWidth, videoHeight) {
    if (["video", "youtube", "vimeo"].includes(image?.resource_type)) {
      return (
        <ProjectVideo
          resourceType={image.resource_type}
          url={image.url}
          videoWidth={590}
          videoHeight={videoHeight}
        />
      );
    }

    return (
      <Image
        key={index}
        src={formatSrc(image.transformed_url || image.url, 590)}
        onClick={() => setLightboxIndex(index)}
      />
    );
  }

  return (
    <ScrollLoad loadMore={() => setPage(p => p + 1)}>
      <Grid>
        {lightboxIndex !== null ? (
          <Lightbox
            onClose={() => setLightboxIndex(null)}
            lightboxIndex={lightboxIndex}
            images={images}
          />
        ) : (
          images
            ?.slice(0, page * 8)
            ?.map((image, index) => (
              <ImageContainer {...options}>
                {imageOrVideo(image, index)}
              </ImageContainer>
            ))
        )}
      </Grid>
    </ScrollLoad>
  );
};

export default Gallery;
