// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import BlockContainer from 'Components/BlockContainer';
import Feedback from 'Components/Blocks/Feedback';
function VerificationProcess() {
	return (
		<>
			<Helmet>
				<title>ClickerMe | Become a Verified Member</title>
				<meta
					name="description"
					content="Our verification process ensures that our fashion community is of the highest quality. As a verified member you’ll get full access to ClickerMe"
				/>
				<htmlAttributes h1="Get Full Access To ClickerMe"></htmlAttributes>
			</Helmet>
			<BlockContainer name="verification-process" backgroundColor="#FFF" />
			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
		</>
	);
}

export default VerificationProcess;
