import React from "react";
import ReactPlayer from "react-player";
import theme from "Styles/Theme";
import { useState, useEffect } from "react";
import { VideoContainer } from "./styles";

function Video({ className, url, width, height }: Props) {
  return (
    <ReactPlayer className="player" url={url} width="100%" height={height} />
  );
}

export default Video;
