// @flow
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'Components/Button';

export const WrapStyledHeader = styled.div(
	({ theme, menuOpen }) => `
  background-color: #000;
  width:100%;
  display:flex;
  position: sticky;
  top: 0;
  margin:auto;
  min-height: ${theme.spacing * 4}rem;
  z-index: ${theme.zIndex.front};
   ${menuOpen &&
			css`
				height: 100vh;
				position: relative;
			`}

`
);
export const StyledHeader = styled.header(
	({ theme, menuOpen }) => `
  background-color: #000;
  max-width: 1140px;
  width:100%;
  position: sticky;
  top: 0;
  margin:auto;
  min-height: ${theme.spacing * 4}rem;
  z-index: ${theme.zIndex.front};
   ${menuOpen &&
			css`
				height: 100vh;
				position: relative;
			`}
`
);

export const Container = styled.div(
	({ theme, menuOpen }) => `
  max-width: ${theme.maxWidth}px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: top;
  padding-left: ${theme.spacing}rem;
  padding-right:${theme.spacing}rem;
  justify-content: space-between;

  ${menuOpen &&
		css`
			flex-direction: column;
			justify-content: flex-start;
			height: 100vh;
			overflow: scroll;
		`}
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    flex-direction: row;
    justify-content: space-between;
    height: initial;
    padding: 0;
    width:100%;
  }
`
);

export const LogoContainer = styled.div(
	({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: ${theme.spacing}rem 0;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    flex-direction:row-reverse;
      outline: none;
      width:100%;
      min-height: 40px !important;
  }
`
);

export const WrapBrands = styled.div(
	({ theme }) => `
  display: grid;
  grid-template-columns: repeat(3, 33%);
  justify-content: space-between;
  margin: ${theme.spacing}rem 0;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
      min-height: 40px !important;
      grid-template-columns: repeat(3, 40%);
  }
  @media screen and (max-width: ${theme.breakpoints.small}px) {
      min-height: 40px !important;
      grid-template-columns: repeat(3, 33%);
  }
`
);

export const MenuButton = styled.button(
	({ theme }) => `
  background-color: transparent;
  color: ${theme.colors.black};
  border: none;
  display: none;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    display: flex;
     outline: none;
     width: 15%;
     }
`
);

export const MenuIcon = styled.img(
	({ theme }) => `
  width: ${theme.spacing * 1.5}rem;
  height: ${theme.spacing * 1.5}rem;
  margin: ${theme.spacing / 2}rem;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    width: ${theme.spacing * 30}%;
    height: ${theme.spacing * 45}%;
    outline: none;
    margin:0;
    right:0;
  }
   @media screen and (max-width: ${theme.breakpoints.small}px) {
    width: 30px;
    outline: none;
    margin:0;
    right:0;
  }

`
);

export const StyledLink = styled(Link)(
	({ theme, small, status, loggedIn }) => `
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: ${small ? theme.spacing * 1 : 0}rem;
  border: none;
  background: transparent;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    ${loggedIn ? `margin-right: 32vw;` : `margin-right: 5vw;`}
    margin-left: 3vw;
  }
`
);

export const Logo = styled.div(
	({ theme, small, status }) => `
  background-image: url(${require('Assets/Images/CMSmallLogoWhite.svg')});
  background-repeat: no-repeat no-repeat;
  width: 32vw;
  height: 2rem;
  margin-right:0;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    background-image: url(${
			small
				? require('Assets/Images/CMSmallLogoWhite.svg')
				: require('Assets/Images/CMFullLogoWhite.svg')
		});
    width: ${small ? '100px' : '200px'};
    height: ${small ? '32px' : '45px'};
    
  }
`
);

export const Inner = styled.div(
	({ theme, menuOpen }) => `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    display: ${menuOpen ? 'flex' : 'none'};
    min-height:200px;
    width: 100%;
    flex-direction: column;
    align-items: left;
  }
`
);

export const WrapLogoAndMenu = styled.div(
	({ theme, menuOpen }) => `
  display: none;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    display: ${menuOpen ? 'flex' : 'none'};
    display: flex;
    flex-direction:row;
    align-items:top;
    justify-content:top;
    width:100%;
  }
`
);
export const NavLink = styled(Link)(
	({ theme, active, disabled, show }) => `
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    display: ${show === 'notShown' ? 'none' : 'flex'};
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing / 2}rem;
    border-top: 1px solid ${theme.colors.grey('dark')};
  }
  text-decoration: none;
  padding: 0 ${theme.spacing}rem;
  color: ${active ? theme.colors.yellow() : theme.colors.white};
  &:hover {
    color: ${theme.colors.yellow()};
  }
  ${disabled &&
		css`
			opacity: 0.2;
			cursor: default;
		`}
`
);

export const WrapIcon = styled.div(
	({ theme }) => `

  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    display:none;
  }
}
`
);

export const SearchBar = styled.form(
	({ theme }) => `
  background-color: #fff;
  display: none;
  align-items: center;
  padding-left: ${theme.spacing}rem;
  padding-right: ${theme.spacing * 2}rem;
  min-height: ${theme.spacing * 4}rem;
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    display: flex;
  }
`
);

export const WrapSearch = styled.div(
	({ theme }) => `
  display:flex;
  flex-direction: column;
  position:relative;
  width:100%;
`
);

export const SearchBarMobile = styled.form(
	({ theme }) => `
  display:none;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
  background-color: #fff;
  display:flex;
  flex-direction:inline-block;
  align-items: center;
  padding-left: ${theme.spacing}rem;
  position:absolute;
  right:0;
  width:100%;
  transform: translate(0, -10%);
  min-height: ${theme.spacing * 4}rem;
  clip-path: polygon(-20% 100%, 100% 100%, 100% 8%, 80vw 8%, 77vw 0%, 74vw 8%, 0 8%);
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    clip-path: polygon(-20% 100%, 100% 100%, 100% 8%, 77vw 8%, 75.5vw 0%, 74vw 8%, 0 8%);
  }
}
`
);

export const StyledInput = styled.input(
	({ theme }) => `
  border: none;
  padding: ${theme.spacing}rem;
  font-size: ${theme.fonts.size.normal};
  font-family: ${theme.fonts.default};
  outline: none;
  width:90%;
`
);

export const SearchIcon = styled.img(
	({ theme, makeSmaller }) => `
	width: 30px;
	@media screen and (max-width: ${theme.breakpoints.small}px) {
     width: ${makeSmaller === 'makeSmaller' ? '90%' : '30px'}    
     outline: none;
  }
  `
);

export const DropDownButton = styled.div(
	({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`
);

export const DropDownContainer = styled.div(
	({ theme, dropdownOpen }) => `
  width: 100%;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    display: ${dropdownOpen ? 'flex' : 'none'};
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: ${theme.spacing * 4}rem;
    right: 1rem;
    max-width:1140px;
    margin:auto;
    min-height: ${theme.spacing * 4}rem;
  }
`
);

export const DropDownArrow = styled.div(
	({ theme, dropdownOpen }) => `
  display: none;
  width: ${theme.spacing}rem;
  height: ${theme.spacing / 2}rem;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: ${theme.colors.black};
  margin: 0 ${theme.spacing / 2}rem;
  
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    display: ${dropdownOpen ? 'flex' : 'none'};
  }
`
);

export const DropDown = styled.div(
	({ theme }) => `
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.colors.black};
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    padding: ${theme.spacing}rem;
    width: ${theme.spacing * 8}rem;
  }
`
);

export const SubmittedNotification = styled.div(
	({ theme }) => `
  border-radius: ${theme.border.radius}px;
  min-width: 100px;
  padding: ${theme.spacing / 2}rem
    ${theme.spacing * 1.5}rem;
  margin: ${theme.spacing * 0.625}rem ${theme.spacing}rem;
  background-color: ${theme.colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.black};
  color: ${theme.colors.black};
  text-transform: uppercase;
  text-align: center;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }  
`
);

export const PlainButton = styled.button(
	({ theme, makeSmaller }) => `
	background: none;
	border: none;
	padding: 0;
	margin-right: 0.5rem;
	appearance: none;
  outline: none;
  ${makeSmaller === 'makeSmaller' && 'width:90%'}
`
);

export const AvatarLink = styled(Link)(
	({ theme }) => `
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`
);

export const Wrap = styled.div(
	({ theme }) => `
  display: inline-grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: ${theme.spacing}rem;
  }
`
);

export const ButtonJoin = styled(Button)(
	({ theme }) => `
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    display: none;
  } 
`
);

export const ButtonDesktop = styled(Button)(
	({ theme }) => `
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }  
`
);

export const ButtonMobile = styled(Button)(
	({ theme }) => `
  text-align:center;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: none;
  }  
`
);

export const NotificationBar = styled.div(
	({ theme }) => ` 
  color: ${theme.colors.yellow('default')};
  text-align: center;
  font-size: ${theme.fonts.size.small};
  line-height: 1;
  font-weight: bold;
`
);

export const WrapNotification = styled.div(
	({ theme }) => `
  display:flex;
  padding:${theme.spacing}rem;
  background-color: ${theme.colors.grey('landing')};
  justify-content:center;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: none;
  }  
`
);

export const WrapPlainButton = styled.div(
	({ theme }) => `
  width:15%;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    display: none;
  } 
`
);

export const SearchBackground = styled.div(
	({ theme }) => `
  height:100vh;
  background-color: ${theme.colors.grey('dark')}
`
);

export const BackButton = styled.button(
	({ theme }) => `
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: ${theme.colors.white};
  font-size: 1rem;
  padding: 0;
  display: flex;
  align-items: center;
`
);

export const WidthDependentText = styled.span(
	({ theme }) => `
  font-family: ${theme.fonts.default};
  font-weight: bold;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  color: ${theme.colors.white};
  font-size: 1rem;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`
);

export const BackButtonText = styled.div(
	({ theme }) => `
  display:flex;
`
);
