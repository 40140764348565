import React, { useState } from "react";
import { useAuth } from "Hooks/useAuth";
import { useFetch } from "Hooks/useFetch";
import { formatDate, isPast } from "Helpers/Date";
import ContentError from "Components/ContentError";
import ContentLoading from "Components/ContentLoading";
import Modal from "Components/Modal";
import LoginForm from "Components/LoginForm";

import {
  Heading,
  Background,
  List,
  ListItem,
  Logo,
  Details,
  Container,
  Title,
  Info,
  StyledLink,
  Date,
  Summary,
  ActionContainer,
  StyledButton as Button,
  Badge
} from "./styles";

const JobList = () => {
  const { auth } = useAuth();
  const { data, loading, error } = useFetch({
    url: "/api/jobs"
  });
  const jobs = data?.jobs || [];
  const [loginPrompt, setLoginPrompt] = useState(null);

  //const sideContent = data?.page?.blocks[0];
  const affiliate_content = data?.page?.blocks[1];

  function authorise(e, url) {
    if (!auth.access_token) {
      e.preventDefault();
      setLoginPrompt(url);
    }
  }

  if (loading) return <ContentLoading />;
  if (error) return <ContentError />;

  return (
    <>
      <Heading>Featured jobs</Heading>
      <List featured>
        {jobs
          .filter(job => job.featured)
          .map((job, key) => {
            const {
              id,
              title,
              created_at,
              logo,
              company,
              location,
              type,
              summary,
              deadline
            } = job;
            return (
              <ListItem key={key} featured>
                <Logo src={logo?.url} />
                <Container featured>
                  <Badge>
                    <img
                      src={require("Assets/Images/top-job.png")}
                      alt="Top Job"
                      width={72}
                      height={72}
                    />
                  </Badge>
                  <Details>
                    <Title>{title}</Title>
                    <Info>{`${company} (${location})`}</Info>
                    <Info>{type}</Info>
                    {summary && <Summary>{summary}</Summary>}
                    <Date>Posted: {formatDate(created_at)}</Date>
                  </Details>
                  <ActionContainer>
                    {isPast(deadline) ? (
                      <div>POSITION CLOSED</div>
                    ) : (
                      <Button
                        onClick={e => authorise(e, `/jobs/${id}`)}
                        to={`/jobs/${id}`}
                      >
                        More Details
                      </Button>
                    )}
                  </ActionContainer>
                </Container>
              </ListItem>
            );
          })}
      </List>
      <Background>
        <Heading>All jobs</Heading>
        <List>
          {jobs
            .filter(job => !job.featured)
            .map((job, key) => {
              const { id, title, company, location, type, deadline } = job;
              return (
                <ListItem key={key}>
                  <Container>
                    <Details>
                      <Title>{title}</Title>
                      <Info>{`${company} (${location})`}</Info>
                      <Info>{type}</Info>
                    </Details>
                    <ActionContainer>
                      {isPast(deadline) ? (
                        <div>POSITION CLOSED</div>
                      ) : (
                        <Button
                          onClick={e => authorise(e, `/jobs/${id}`)}
                          to={`/jobs/${id}`}
                        >
                          More Details
                        </Button>
                      )}
                    </ActionContainer>
                  </Container>
                </ListItem>
              );
            })}
        </List>
      </Background>
      {loginPrompt && (
        <Modal onClose={() => setLoginPrompt(null)} maxWidth={455}>
          <LoginForm redirect={loginPrompt} />
          <StyledLink to="/">Have you not registered yet?</StyledLink>
        </Modal>
      )}
    </>
  );
};

export default JobList;
