// @flow
import React, { useState, useEffect } from "react";
import {Route, withRouter, useLocation, Switch, useHistory} from "react-router-dom";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { useAuth } from "Hooks/useAuth";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import { ENV } from "Config";
import routes, { PrivateRoute, RestrictedRoute } from "Routes";

import Header from "Components/Header";
import Footer from "Components/Footer";
import FooterNotification from "Components/FooterNotification";
import CookieNotification from "Components/CookieNotification";
import Environment from "Components/Utilities/Environment";

const App = () => {
  const { auth, refetchUser } = useAuth();
  const [fetch] = useLazyFetch({ url: "/api/me" });
  const { pathname } = useLocation();
  let history = useHistory();
  const [menuOpen, toggleMenuOpen] = useState(false);

  const GlobalStyle = createGlobalStyle`
    body {
      overflow: hidden;
    }
  `;

  const getUserData = async callback => {
    const res = await fetch();
    if (res) callback(res);
  };

  useEffect(() => {
    getUserData(data => refetchUser(data.user));
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>ClickerMe</title>
      </Helmet>
      {pathname !== "/onboarding" &&
        !(pathname.includes("/projects/") && pathname.includes("/edit")) && (
          <Header
            menuOpen={menuOpen}
            toggleMenuOpen={toggleMenuOpen}
          />
        )}
      <main>
        <Switch>
          {routes.map((route, index) => {
            if (route.private) return <PrivateRoute key={index} {...route} />;
            if (route.restricted)
              return <RestrictedRoute key={index} {...route} />;
            return <Route key={index} {...route} />;
          })}
        </Switch>
      </main>
      <CookieNotification />
      {auth.access_token &&
        auth?.user?.approval_status !== "approved" &&
        !["/onboarding", "/projects", "/verification"].includes(pathname) && !pathname.match(/edit/) && (
          <FooterNotification onClick={() => history.push('/verification')} />
        )}
      <Footer sticky={pathname === "/projects"} />
      {menuOpen && <GlobalStyle />}
      <Environment env={ENV} />
    </>
  );
};

export default withRouter(App);
