import styled from 'styled-components';
import IconButton from 'Components/IconButton';

export const Background = styled.div(
	({ theme }) => `
   background-color: ${theme.colors.grey('landing')};
   margin: auto;
`
);

export const Wrap = styled.div(
	({ theme }) => `
   display: flex;
	 margin-bottom: ${theme.spacing * 2}rem;
	 margin-top: ${theme.spacing * 2}rem;
`
);

export const LeftSide = styled.div(
	({ theme }) => `
	 display: flex;
	 flex-direction: column;
	 width: 50%;
	  margin: auto;
	 align-items: center;
	 justify-content: center;
	 @media screen and (max-width: ${theme.breakpoints.small}px) {
		width:100%;
		padding: ${theme.spacing * 2}rem;
	}
	
`
);

export const Title = styled.div(
	({ theme }) => `
   color: white;
   font-weight: bold;
	 font-size: ${theme.fonts.size.large};
	 text-align: center;
	 margin-bottom: 2rem;
	 
`
);

export const Description = styled.div(
	({ theme }) => `
   color: white;
   font-weight: bold;
	 font-size: ${theme.fonts.size.normal};
	 text-align: center;
	 margin-bottom: 2rem;
	 	@media screen and (min-width: ${theme.breakpoints.small}px) {
		max-width: 30vw;
	}
`
);

export const Image = styled.img(
	({ theme, image, src, largerImage }) => `
	height: 413px;
	width: 50%;
	object-fit: cover;
	@media screen and (max-width: ${theme.breakpoints.small}px) {
		display:none;
	}
 `
);
