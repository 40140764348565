// @flow
import React from "react";
import { useCookies } from "react-cookie";
import { formatDate } from "Helpers/Date";
import { Container, Text, StyledLink, Button } from "./styles";
import { MdClose } from "react-icons/md";

const CookieNotification = () => {
  const [cookies, setCookie] = useCookies(["cookiesAccepted"]);

  function onClose() {
    setCookie("cookiesAccepted", formatDate(new Date()));
  }

  if (cookies.cookiesAccepted) return null;

  return (
    <Container>
      <Text>
        We use cookies to enhance your experience. By continuing to visit this
        site you agree to our use of cookies.
        <StyledLink to="/cookies">Learn more</StyledLink>
      </Text>
      <Button onClick={onClose}>
        <MdClose size={18} />
      </Button>
    </Container>
  );
};

export default CookieNotification;
