// @flow
import React, { useState } from "react";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { formatAsDate, displayDate } from "Helpers/Date";
import { InputRow, Actions, Container, Header, PlusButton } from "./styles";
import EducationSingle from "./EducationSingle";
import FormField from "Components/FormField";
import Input from "Components/Input";
import Modal from "Components/Modal";
import Button from "Components/Button";
import IconButton from "Components/IconButton";
import {plusIcon} from '../../Helpers/Icons';

type Props = {
  LargePlaceholder: React.Node,
  data: Object,
  setData: Function,
  setError: Function
};

function EducationForm({ data, setData, LargePlaceholder, setError }: Props) {
  const itemPlaceholder = {
    qualification: undefined,
    institution: undefined,
    location: undefined,
    startDate: undefined,
    endDate: undefined
  };
  const dataEducation = data.education || [];
  const [adding, toggleAdding] = useState(true);
  const [newItem, setNewItem] = useState(itemPlaceholder);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [submit, response] = useLazyFetch({
    url: "/api/me/education",
    method: "put"
  });

  function setValue(key, value) {
    setNewItem({ ...newItem, [key]: value });
  }

  function onSubmit(e) {
    e.preventDefault();
    submit({ education: [...dataEducation, newItem] }, onSuccess, onError);
  }

  function onSuccess() {
    if (Boolean(newItem)) setData({...data, education: [...dataEducation, newItem]});
    setError(null);
    toggleAdding(false);
    setNewItem(null);
  }

  function onSuccessDelete() {
    setError(null);
    toggleAdding(false);
  }

  function onError(e) {
    setError("Sorry, we could not update your education right now");
    setData({...data, education: dataEducation});
  }

  function onDelete() {
    setData({...data, education: [...dataEducation.filter((item, index) => index !== itemToDelete)]});
    submit(
      { education: [...dataEducation.filter((item, index) => index !== itemToDelete)] },
      onSuccessDelete,
      onError
    );
    setItemToDelete(null);
  }

  return (
    <React.Fragment>
      {dataEducation?.map((item, index) => (
        <EducationSingle
          key={index}
          item={item}
          updateItem={updatedItem =>
            setData({...data, education: [
              ...dataEducation.map((item, i) => (i === index ? updatedItem : item))
            ]})
          }
          deleteItem={() => setItemToDelete(dataEducation.indexOf(item))}
          submit={() => submit({ education: dataEducation }, onSuccess, onError)}
        />
      ))}
      {!adding && !dataEducation.length && <LargePlaceholder />}
      {adding && newItem && (
        <Container onSubmit={onSubmit}>
          <InputRow>
            <FormField label="Institution">
              <Input
                value={newItem.institution}
                onChange={e => setValue("institution", e.target.value)}
              />
            </FormField>
            <FormField label="Qualification">
              <Input
                value={newItem.qualification}
                onChange={e => setValue("qualification", e.target.value)}
              />
            </FormField>
          </InputRow>
          <FormField label="Location">
            <Input
              value={newItem.location}
              onChange={e => setValue("location", e.target.value)}
            />
          </FormField>
          <InputRow>
            <FormField label="Start date">
              <Input
                type="month"
                placeholder="YYYY-MM"
                value={formatAsDate(newItem?.startDate)}
                onChange={e =>
                  setValue("startDate", displayDate(e.target.value))
                }
              />
            </FormField>
            <FormField label="End date">
              <Input
                type="month"
                placeholder="YYYY-MM"
                value={formatAsDate(newItem?.endDate)}
                onChange={e => setValue("endDate", displayDate(e.target.value))}
              />
            </FormField>
          </InputRow>
          <Actions>
            <Button size="small" padded type="submit">
              {response?.loading ? "Saving" : "Save to profile"}
            </Button>
          </Actions>
        </Container>
      )}
      {itemToDelete !== null && (
        <Modal onClose={() => setItemToDelete(null)}>
          Are you sure you want to delete this item?
          <Button onClick={onDelete}>Yes</Button>
        </Modal>
      )}
      {!adding &&
      <PlusButton onClick={() => {
        toggleAdding(true);
        setNewItem(itemPlaceholder);
      }}>
        <IconButton yellow>
          <img src={plusIcon} />
        </IconButton>
        Add another
      </PlusButton>
      }
    </React.Fragment>
  );
}

export default EducationForm;
