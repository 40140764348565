import React from "react";
import { Container, Overlap, Icon, EachColumn } from "./styles";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton
} from "react-share";

function SocialShares({
  first,
  second,
  third,
  maxWidth,
  minWidth,
  quote,
  titleOfContainer,
  url,
  via,
  twitterTags,
  related,
  facebookTags,
  summary,
  source,
  twitterTitle
}: Props) {
  return (
    <Overlap>
      <h3>
        <mark>{titleOfContainer}</mark>
      </h3>
      <Container style={({ maxWidth }, { minWidth })}>
        <EachColumn>
          <TwitterShareButton
            url={url}
            title={twitterTitle}
            via={via}
            hashtags={twitterTags}
            style={{ outline: "none" }}
          >
            <Icon src={first}></Icon>
          </TwitterShareButton>
        </EachColumn>
        <EachColumn>
          <FacebookShareButton
            url={url}
            quote={quote}
            hashtag={facebookTags}
            style={{ outline: "none" }}
          >
            <Icon src={second}></Icon>
          </FacebookShareButton>
        </EachColumn>
        <EachColumn>
          <LinkedinShareButton
            url={url}
            title={twitterTitle}
            summary={summary}
            source={source}
            style={{ outline: "none" }}
          >
            <Icon src={third}></Icon>
          </LinkedinShareButton>
        </EachColumn>
      </Container>
    </Overlap>
  );
}
export default SocialShares;
