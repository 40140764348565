// @flow
import React from "react";
import { Wrapper, Label, Labels, OptionalLabel, Error } from "./styles";

type Props = {
  children: React.Node,
  label?: string,
  labelColor?: string,
  optionalLabel?: string,
  errors: {
    message: Errors
  },
  centre?: Boolean
};

const FormField = ({ children, label, optionalLabel, errors, centre, labelColor }: Props) => {
  return (
    <Wrapper centre={centre}>
      <Labels>
        {label && <Label color={labelColor || "black"}>{label}</Label>}
        {optionalLabel && <OptionalLabel>{optionalLabel}</OptionalLabel>}
      </Labels>
      {children}
      {errors && <Error centre={centre}>{errors}</Error>}
    </Wrapper>
  );
};

export default FormField;
