// @flow
import styled from "styled-components";

export const VideoContainer = styled.div(
  ({ theme, ratio }) => `
  width: 100%;
  margin-bottom: ${theme.spacing}rem;
    @media screen and (min-width: ${theme.breakpoints.medium}px) {
      margin:auto;
      max-width:${ratio > 1 ? "300px" : "949.33px"};
      margin-bottom: ${theme.spacing * 4}rem;
    }
  `
);

export const WrapYoutube = styled.div(
  ({ theme }) => `  
     @media screen and (min-width: ${theme.breakpoints.small}px) {
      height:534px;
     }    
  `
);
