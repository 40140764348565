// @flow
import React from "react";

import {
  Line,
  SectionTitle
} from "./styles";

const SectionHeader = ({text} : Props): React.Node => {
  return <Line>
    <SectionTitle>
      {text}
    </SectionTitle>
  </Line>;
};

export default SectionHeader;
