// @flow
import styled from "styled-components";

export const CenterView = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  padding: ${theme.spacing}rem ${theme.masterSpacing}rem;
  justify-content: center;
  width: 100%;
  background-color: ${props =>
    props.grey ? props.theme.colors.grey("lighter") : props.theme.colors.white};

    @media screen and (max-width: ${theme.breakpoints.medium}px) {
      padding: 0
    }

`
);

export const CenterItem = styled.div``;

export const PaddedView = styled.div`
  padding-top: ${props => props.theme.spacing * props.scale}rem;
  padding-right: ${props => props.theme.spacing * props.scale}rem;
  padding-bottom: ${props => props.theme.spacing * props.scale}rem;
  padding-left: ${props => props.theme.spacing * props.scale}rem;
`;
