// @flow
import React from "react";
import { Container, Overlap, Icon, EachColumn } from "./styles";

function Players({
  first,
  second,
  third,
  appleplayer,
  spotifyplayer,
  googleplayer,
  maxWidth,
  minWidth,
  linkToApple,
  linkToSpotify,
  linkToGoogle,
  title
}: Props) {
  return (
    <Overlap>
      <h3>
        <mark>{title}</mark>
      </h3>
      <Container style={({ maxWidth }, { minWidth })}>
        <EachColumn>
          <a href={linkToApple} target="_blank">
            <Icon src={first}></Icon>
          </a>
          <p>{appleplayer}</p>
        </EachColumn>
        <EachColumn>
          <a href={linkToSpotify} target="_blank">
            <Icon src={second}></Icon>
          </a>
          <p>{spotifyplayer}</p>
        </EachColumn>
        <EachColumn>
          <a href={linkToGoogle} target="_blank">
            <Icon src={third}></Icon>
          </a>
          <p>{googleplayer}</p>
        </EachColumn>
      </Container>
    </Overlap>
  );
}
export default Players;
