// @flow
import React, { useState } from "react";
import { useAuth } from "Hooks/useAuth";
import { useFetch } from "Hooks/useFetch";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { useHistory } from "react-router-dom";
import { useValidation, useTextInput, useCheckboxInput } from "Hooks";
import Text from "Components/Blocks/Text";
import Input from "Components/Input";
import Button from "Components/Button";
import FormField from "Components/FormField";
import Checkbox from "Components/Checkbox";
import Notification from "Components/Notification";
import { StyledLink as Link, HeaderWrapper } from "./styles";

const SignUpFormGrey = () => {
  let history = useHistory();
  const { login, refetchUser } = useAuth();
  const { data } = useFetch({ url: "/api/pages/signup" });
  const signupCopy = data?.page?.blocks[0]?.text;
  const testCopy = "Hi there";
  const [error, setError] = useState(null);
  const first_name = useTextInput();
  const last_name = useTextInput();
  const email = useTextInput();
  const password = useTextInput();
  const terms = useCheckboxInput();
  const [validate, errors] = useValidation(
    {
      first_name: first_name.value,
      last_name: last_name.value,
      email: email.value,
      password: password.value,
      terms: terms.checked
    },
    {
      first_name: {
        presence: {
          allowEmpty: false
        }
      },
      last_name: {
        presence: {
          allowEmpty: false
        }
      },
      email: {
        email: true,
        presence: {
          allowEmpty: false
        }
      },
      password: {
        presence: {
          allowEmpty: false
        },
        length: {
          minimum: 6
        }
      },
      terms: {
        presence: {
          message: "^Please accept the Terms and Conditions"
        },
        inclusion: {
          within: [true],
          message: "^Please accept the Terms and Conditions"
        }
      }
    }
  );

  const [submit, response] = useLazyFetch({
    url: "/api/users",
    method: "post"
  });

  function signUp(e) {
    e.preventDefault();
    const valid = validate();
    if (valid)
      submit(
        {
          user: {
            first_name: first_name.value,
            last_name: last_name.value,
            email: email.value,
            password: password.value,
            terms: terms.checked
          }
        },
        onSuccess,
        onError
      );
  }

  function onSuccess() {
    setError(null);
    authorise();
  }

  function onError() {
    setError("Sorry, we could not sign you up");
  }

  async function authorise() {
    const user = {
      username: email.value,
      password: password.value,
      grant_type: "password"
    };
    const success = ({ user }) => {
      refetchUser(user);
      history.push("/onboarding");
    };
    const error = ({ error }) => setError(error);
    await login(user, success, error);
  }

  return (
    <>
      {error && <Notification>{error}</Notification>}
      <HeaderWrapper>
        <h2>Create your profile and join the fashion network.</h2>
      </HeaderWrapper>
      <form onSubmit={signUp}>
        <FormField errors={errors?.first_name}>
          <Input placeholder="First Name" {...first_name} />
        </FormField>
        <FormField errors={errors?.last_name}>
          <Input placeholder="Last Name" {...last_name} />
        </FormField>
        <FormField errors={errors?.email}>
          <Input type="email" placeholder="Email" {...email} />
        </FormField>
        <FormField errors={errors?.password}>
          <Input type="password" placeholder="Password" {...password} />
        </FormField>
        <FormField errors={errors?.terms}>
          <Checkbox
            {...terms}
            label={
              <Link to="/terms-and-conditions" style={{ color: "white" }}>
                I accept ClickerMe’s Terms and Conditions
              </Link>
            }
          />
        </FormField>
        <Button type="submit">
          {response?.loading ? "Submitting..." : "JOIN FOR FREE"}
        </Button>
      </form>
    </>
  );
};

export default SignUpFormGrey;
