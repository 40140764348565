// @flow
import styled, { keyframes } from "styled-components";
import Input from "Components/Input";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 50%;
  animation: ${fadeIn} 0.8s ease-in-out;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    width: 100%;
  }
`;

export const InputRow = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: ${props => props.theme.spacing}rem;
  width: 100%;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    display: flex;
    flex-flow: column wrap;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: ${props => props.theme.spacing}rem;
`;

export const RowForm = styled.form`
  display: flex;
  width: 17rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    width: 100%;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  width: 50%;
  border-top: solid 1px;
  padding-top: 1rem;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: left;
`;

export const PlusButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 9rem;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing * 0.75}rem;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

export const Header = styled.div`
  width: 50%;
  margin-bottom: ${props => props.theme.spacing * 0.75}rem;
  border-bottom: 1px solid black;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    width: 100%;
  }
`;

export const StyledTag = styled.div(
  ({ theme, closingIcon}) => `
  height: 1.3rem;
  background-color: ${theme.colors.white};
  padding-left: .7rem;
  padding-right: ${closingIcon ? "0" : ".7rem"};
  margin-bottom: ${theme.spacing}rem;
  margin-right: ${theme.spacing}rem;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
);

export const SaveButton = styled.button(
  ({ size, disabled, theme }) => `
  min-width: ${size === "small" ? 50 : 120}px || ${size === "big" ? 250 : 420};
  padding: 0.5rem 1.5rem;
  margin: 0.75rem 0rem;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: theme.colors.black;
  text-transform: uppercase;
  background-color: ${theme.colors.yellow()};
  border-radius: 33.4px;
  opacity: ${disabled ? 0.3 : 1};
  border-width: 2px;
  border-style: solid;
  border-color: ${
   theme.colors.yellow()
  };
  &:hover {
    cursor: pointer;
  }
`
);