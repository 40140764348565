// @flow
import styled, { css } from "styled-components";
import Text from "Components/Text";
import { positioning } from "Helpers/Positioning";
import { Link } from "react-router-dom";

export const Background = styled.div(
  ({ theme, image }) => `
  background-image: ${`url(${image})`};
  background-color: ${theme.colors.black};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 350px;
  min-height: 280px;
  display: flex;

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    min-height: 200px;
  }
`
);

export const Wrapper = styled.div(
  ({ background_color }) =>
    background_color &&
    css`
      background-color: ${background_color};
    `
);

export const StepsContainer = styled.div`
  margin-top: 1px;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    margin-top: 1rem;
  }
`
;

export const ProjectsContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  margin-bottom: ${theme.spacing * 2}rem;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    margin-bottom: 0;
  }
`
);

export const Container = styled.div(
  props => `
  display: block;
  z-index: ${props.theme.zIndex.front};
  max-width: ${props.theme.maxWidth}px;
  margin: auto;
  background-color: ${props.background_color || props.theme.colors.white};
  align-items: center;
  flex-direction: ${positioning(props, "row", "row-reverse")}
  width: 100%;
  padding: ${props.theme.spacing * 2}rem ${props.theme.spacing}rem;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
   text-align: left;
   padding-bottom: ${props.theme.spacing}rem;
  }
`
);

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid black;
  margin: ${props =>
  positioning(
    props,
    `0 0 0 ${props.theme.spacing * 2.5}rem`,
    `0 ${props.theme.spacing * 2.5}rem 0 0`
  )};

  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    display: none;
  }
`;

export const StyledText = styled(Text)`
  font-size: ${props => props.theme.fonts.size.large};
  font-weight: bold;
  flex-shrink: 0;
  color: ${props =>
  props.text_color ? props.text_color : props.theme.colors.black};
  max-width: 100%;
  word-wrap: break-word;
  line-height: 1.25;
`;

export const Description = styled(Text)`
  max-width: 100%;
  height: 24px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: ${props => props.theme.colors.black};
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 4rem;
  }
`;

export const Rectangle = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  height: 30px;
  border-radius: 15px;
  border: solid 2px black;
  background-color: #ffffff;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    margin-top: 2rem;
  }
`;

export const Progress = styled.div`
  width: ${props => `${props.value - 1}%`};
  margin-left: .5%;
  margin-top: 3px;
  height: 20px;
  border-radius: ${props => 
  props.value === 100 ? '11px' : '11px 0 0 11px'} ;
  background-color: #fff200;
`
;

export const AddButton = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.black};
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  margin-right: 1rem;
  letter-spacing: 0.5px;
  padding: 1rem;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  border: none;
  width: ${theme.spacing * 12}rem;
  height: ${theme.spacing * 13.5}rem;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    width: 100%;
    margin-right: 0;
  }
`
);

export const Cover = styled.img(
  ({ theme }) => `
  width: ${theme.spacing * 10}rem;
  height: ${theme.spacing * 10}rem;
  object-fit: cover;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    width: ${theme.spacing * 16}rem;
    height: ${theme.spacing * 16}rem;
  }
`
);

export const LinkWrapper = styled(Link)(
  ({ theme }) => `
  background-color: transparent;
  text-decoration: none;
  color: ${theme.colors.black};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: start;
`
);

export const ProjectItem = styled.div(
  ({ theme, published }) => `
  width: ${theme.spacing * 12}rem;
  height: ${theme.spacing * 13.5}rem;
  background-color: ${theme.colors.white};
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid ${published ? theme.colors.black : theme.colors.grey()};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    width: 100%;
    height: ${theme.spacing * 21.5}rem;
    margin: ${theme.spacing}rem auto;
  }
`
);

export const ProjectHeader = styled.div(({theme}) =>`
  display: inline-flex;
  height: 26px;
  margin-top: .5rem;
  margin-bottom: .3rem;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    height: 2.9rem;
    width: 100%;
  }
`)
;

export const ProjectTitle = styled.div(
  ({ theme }) => `
  width: 7.2rem;
  font-family: Raleway-Bold;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    width: 12rem;
    text-overflow: ellipsis;
    margin-top: .3rem;
    margin-bottom: ${theme.spacing / 2}rem;
    font-size: 1.3em;
    font-weight: bold;
    justify-content: space-between;
  }
`
);

export const ButtonLabel = styled.p`
  margin-top: .3rem;
`;

export const ProjectIcon = styled.img(({theme}) =>`
  height: 20px; 
  width: 23px;
  margin-left: 1.4rem;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    height: 2rem;
    width: 2rem;
    margin-top: .6rem;
    margin-left: 2rem;
    font-size: 1.3em;
    font-weight: bold;
  }
`)
;

export const StyledButton = styled.button(
  ({ padded, size, variant, disabled, theme }) => `
  min-width: ${size === "small" ? 50 : 120}px || ${size === "big" ? 250 : 420};
  padding: 1rem 4rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  letter-spacing: 0.5px;
  color: theme.colors.black;
  text-transform: uppercase;
  background-color: ${theme.colors.yellow()};
  border-radius: 33.4px;
  opacity: ${disabled ? 0.3 : 1};
  border-width: 2px;
  border-style: solid;
  border-color: ${
    disabled
      ? theme.colors.grey()
      : variant === "black"
      ? theme.colors.black
      : variant === "white"
        ? theme.colors.white
        : theme.colors.yellow()
  };
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${theme.breakpoints.small}px) {
   width: 96%;
   margin-left: 2%;
   margin-top: 2rem;
  }
`
);
