import styled from 'styled-components';

export const Wrap = styled.div(
	({ theme, image, background, noMargin }) => `
	background-color: ${background ? 'white' : '#222222'};
	padding-top: ${noMargin ? '0' : '4rem'};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: ${theme.breakpoints.small}px) {
		padding-bottom: 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	`
);

export const Heading = styled.div(
	({ theme, image, background }) => `
 text-align: center;
 color: ${background ? 'black' : 'white'};
 font-size: ${theme.fonts.size.large};
 font-weight: bold;
 padding-bottom: 1rem;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 0rem;
 }
 `
);

export const Logos = styled.div(
	({ theme }) => `
 @media screen and (max-width: ${theme.breakpoints.medium}px) {
 grid-template-columns: repeat(auto-fill, 126px);
 max-width: 100%;
 }
 	@media screen and (max-width: ${theme.breakpoints.small}px) {
	grid-template-columns: repeat(auto-fill, 90px);
  grid-template-rows: repeat(3, 80px);
	grid-gap: 5px;
	max-width: 100%;
	justify-content: center;
	padding-bottom: 4rem;
	transform: translateX(-6vw);
 }
	display: grid;
	grid-gap: 5px;
	padding: ${theme.spacing * 2}rem 0;
	max-width: ${theme.maxWidth * 1.4}px;
	grid-template-columns: repeat(8, 126px);
	margin: auto;
	padding: 1rem;
	transform: translateX(-1vw);
	padding-bottom: 5rem;
 
 
 `
);

export const EachLogo = styled.div(
	({ theme, image, src }) => `
 width: 174px;
 height: 109px;
 background-color: white;
 clip-path: polygon(34% 0%, 100% 0%, 66% 100%, 0% 100%);
 background-image: url(${src});
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
 margin: auto;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
		 transform: translateX(-10%) scale(0.7, 0.7);
		 width: 174px;
     height: 109px;
 }
 `
);
