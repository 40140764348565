// @flow
import About from 'Screens/About';
import AcceptableUse from 'Screens/AcceptableUse';
import Blog from 'Screens/Blog';
import BlogPost from 'Screens/BlogPost';
import NewsFeed from 'Screens/NewsFeed';
import Competitions from 'Screens/Competitions';
import Cookies from 'Screens/Cookies';
import EditProject from 'Screens/EditProject';
import Faqs from 'Screens/Faqs';
import Gallery from 'Screens/Gallery';
import HowItWorks from 'Screens/HowItWorks';
import Jobs from 'Screens/Jobs';
import Login from 'Screens/Login';
import Main from 'Screens/Main';
import Messaging from 'Screens/Messaging';
import NotFound from 'Screens/NotFound';
import Onboarding from 'Screens/Onboarding';
import OpenSubmissions from 'Screens/OpenSubmissions';
import Privacy from 'Screens/Privacy';
import Projects from 'Screens/Projects';
import RequestResetPassword from 'Screens/RequestResetPassword';
import ResetPassword from 'Screens/ResetPassword';
import Search from 'Screens/Search';
import SingleCollaboration from 'Screens/SingleCollaboration';
import SingleJob from 'Screens/SingleJob';
import SingleProfile from 'Screens/SingleProfile';
import SingleProject from 'Screens/SingleProject';
import Styleguide from 'Screens/Styleguide/Container';
import Terms from 'Screens/Terms';
import UserProfile from 'Screens/UserProfile';
import Verification from 'Screens/Verification';
import VerificationProcess from 'Screens/VerificationProcess';
import Feedback from 'Screens/Feedback';
import Podcasts from 'Screens/Podcasts';
import FashionSlashie from 'Screens/FashionSlashie';
import LandingPage from 'Screens/LandingPage';
import SupportGrants from 'Screens/SupportGrants';
import Mentoring from 'Screens/Mentoring';
import Brands from 'Screens/Brands';
import BrandSubmitted from 'Screens/BrandSubmitted';

export default {
	About,
	AcceptableUse,
	Blog,
	BlogPost,
	BrandSubmitted,
	NewsFeed,
	Competitions,
	Cookies,
	EditProject,
	Faqs,
	Gallery,
	HowItWorks,
	Jobs,
	Login,
	Main,
	Messaging,
	NotFound,
	Onboarding,
	OpenSubmissions,
	Privacy,
	Projects,
	RequestResetPassword,
	ResetPassword,
	Search,
	SingleCollaboration,
	SingleJob,
	SingleProfile,
	SingleProject,
	Styleguide,
	Terms,
	UserProfile,
	Verification,
	VerificationProcess,
	Feedback,
	Podcasts,
	FashionSlashie,
	LandingPage,
	Mentoring,
	SupportGrants,
	Brands,
};
