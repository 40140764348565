// @flow
import React from "react";
import { StyledButton, StyledLink } from "./styles";

type Props = {
  size?: "small" | "normal" | "big",
  variant?: "white" | "black",
  to?: string,
  children: string,
  onClick?: Function,
  padded?: Boolean
};

const Button = (props: Props): React.Node =>
  props.to ? (
    <StyledLink {...props}>{props.children}</StyledLink>
  ) : (
    <StyledButton {...props}>
      <span>{props.children}</span>
    </StyledButton>
  );

export default Button;
