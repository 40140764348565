import React from "react";
import { Wrapper } from "./styles";

const Chip = ({ children, ...options }) => {
  return (
    <Wrapper {...options}>
      <span>{children}</span>
    </Wrapper>
  );
};

export default Chip;
