// @flow
import React, { useState } from "react";
import { useAuth } from "Hooks/useAuth";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { formatSrc } from "Helpers/Image";
import { FILESTACK_API_KEY } from "Config";
import ReactFilestack from "filestack-react";
import Avatar from "Components/Avatar";
import Button from "Components/Button";
import IconButton from "Components/IconButton";

import {
  Wrapper,
  Container,
  PostContainer,
  Actions,
  StyledInput as Input,
  UploadButton,
  ImagePreview
} from "./styles";

type Props = {
  addPost: Function,
  setError: Function
};

const PostForm = ({ addPost, setError }: Props): React.Node => {
  const {
    auth: { user }
  } = useAuth();
  const [text, setText] = useState(null);
  const [image, setImage] = useState(null);
  const [submit] = useLazyFetch({
    url: "/api/collaboration_posts",
    method: "post"
  });

  function onSubmit(e) {
    e.preventDefault();
    const post = {
      collaboration_post: { text, image }
    };
    submit(post, onSuccess, onError);
  }

  function onSuccess(res) {
    if (res?.collaboration_post) addPost(res.collaboration_post);
    setError(null);
    setText(null);
    setImage(null);
  }

  function onError() {
    setError("Sorry, we could not share this post right now");
  }

  return (
    <Wrapper onSubmit={onSubmit}>
      <Container>
        <Avatar src={user?.profile?.photo} size="large" />
        <PostContainer>
          <Input
            placeholder="Post a question or opportunity..."
            value={text || ""}
            onChange={e => setText(e.target.value)}
          />
          {image && <ImagePreview src={formatSrc(image, 360)} />}
        </PostContainer>
      </Container>
      <Actions>
        <ReactFilestack
          apikey={FILESTACK_API_KEY}
          customRender={({ onPick }) => (
            <UploadButton onClick={onPick}>
              <IconButton yellow>
                <img src={require("Assets/Images/plus.svg")} />
              </IconButton>
              <span>{image ? "Change image" : "Upload image"}</span>
            </UploadButton>
          )}
          onSuccess={res => {
            if (res?.filesUploaded[0]?.url && !res?.filesFailed.length) {
              setImage(res?.filesUploaded[0]?.url);
            } else {
              setError("Sorry, we could not upload this photo");
            }
          }}
        />
        <Button size="small" type="submit" disabled={!text}>
          Share post
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default PostForm;
