// @flow
export const TRACK_NEW_USER = "ClickerMe/TRACK_NEW_USER";
export const TRACK_SUBMITTED_PROFILE = "ClickerMe/TRACK_SUBMITTED_PROFILE";
export const SET_USER_ID = "ClickerMe/SET_USER_ID";

export type TrackNewUser = {
  type: "ClickerMe/TRACK_NEW_USER",
  id: Number | String
};

export type TrackSubmittedProfile = {
  type: "ClickerMe/TRACK_SUBMITTED_PROFILE"
};

export type SetUserId = {
  type: "ClickerMe/SET_USER_ID",
  userId: Number | String
};

export const trackNewUser = (id): TrackNewUser => ({
  type: TRACK_NEW_USER,
  id
});

export const trackSubmittedProfile = (): TrackSubmittedProfile => ({
  type: TRACK_SUBMITTED_PROFILE
});

export const setUserId = (userId): SetUserId => ({
  type: SET_USER_ID,
  userId
});
