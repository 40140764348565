// @flow
import React from "react";
import { User } from "Types";
import { Container, Tabs, Tab } from "./styles";
import UserAboutTab from "Components/AboutTab/UserAboutTab";
import PeerAboutTab from "Components/AboutTab/PeerAboutTab";
import PortfolioTab from "Components/PortfolioTab";

type Props = {
  id: string,
  user: User,
  tab?: string,
  isCurrentUser: boolean
};

const ProfileTabs = ({ id, user, tab, isCurrentUser }: Props): React.Node => (
  <Container>
    <Tabs>
      <Tab
        name="portfolio"
        tab={tab || "about"}
        to={isCurrentUser ? "/profile/portfolio" : `/profiles/${id}/portfolio`}
      >
        Portfolio
      </Tab>
      <Tab
        name="about"
        tab={tab || "about"}
        to={isCurrentUser ? "/profile" : `/profiles/${id}`}
      >
        About
      </Tab>
    </Tabs>
    {tab === "portfolio" ? (
      <PortfolioTab
        isCurrentUser={isCurrentUser}
        isApproved={user.approval_status === "approved"}
        projects={user?.projects}
      />
    ) : isCurrentUser ? (
      <UserAboutTab user={user} />
    ) : (
      <PeerAboutTab user={user} />
    )}
  </Container>
);

export default ProfileTabs;
