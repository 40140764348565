import React from 'react';
import { Helmet } from 'react-helmet';
import BlockContainer from 'Components/BlockContainer';
import FeedbackComponent from 'Components/Blocks/Feedback';

function Feedback() {
	return (
		<>
			<Helmet>
				<title>ClickerMe | Share Your Feedback</title>
				<meta
					name="description"
					content="Share your feedback and help to shape the future of our fashion network "
				/>
				<htmlAttributes h1="Help To Shape Our Fashion Network"></htmlAttributes>
			</Helmet>
			<BlockContainer name="feedback" backgroundColor="#FFF" />
			<FeedbackComponent
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
		</>
	);
}

export default Feedback;
