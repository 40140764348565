// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div``;

export const Tabs = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  padding: 0 ${theme.spacing / 2}rem;
  background-color: transparent;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    max-width: ${theme.maxWidth}px;
    margin: auto;
    padding: 0;
  }
`
);

export const Tab = styled(Link)(
  ({ theme, name, tab }) => `
  clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
  height: ${theme.spacing * 3}rem;
  width: ${theme.spacing * 10}rem;
  background-color: ${
    name === tab ? theme.colors.yellow() : theme.colors.grey()
  };
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${name === tab ? "bold" : "normal"};
  color: ${theme.colors.black};
  text-decoration: none;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    width: 50%;
  }
`
);
