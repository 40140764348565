import styled from "styled-components";
import Link from "Components/Link";

export const StyledLink = styled(Link)`
  font-weight: bold;
`;

export const Container = styled.div(
  ({ theme, image }) => `
 max-width: ${theme.maxWidth}px;
 margin:auto;`
);

export const GlobalWrapper = styled.div(
  ({ theme, image }) => `
 padding:${theme.spacing}rem;
 background-color:white;
 @media screen and (min-width: ${theme.breakpoints.small}px) {
  width:100%;
 }
 `
);

export const MoreEpisodes = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidth / 1.2}px;
  margin:auto;
  height: auto;
  padding-top: ${theme.spacing * 2}rem;
  padding-bottom: ${theme.spacing}rem;
  padding-left: ${theme.spacing}rem;
  padding-right: ${theme.spacing}rem;
`
);
