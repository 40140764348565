// @flow
import * as React from "react";
import { Helmet } from "react-helmet";
import BlockContainer from "Components/BlockContainer";

function Cookies() {
  return (
    <>
      <Helmet>
        <title>Cookies</title>
      </Helmet>
      <BlockContainer name="cookies" />
    </>
  );
}

export default Cookies;
