// @flow
import React from "react";
import Clipboard from "clipboard";

const noop = () => {};

export function useClipboard(callback = noop, selector = "[data-clipboard]") {
  React.useEffect(() => {
    const clipboard = new Clipboard(selector);
    clipboard.on("success", callback);
    return () => {
      clipboard.destroy();
    };
  }, []);
}
