// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { useFetch } from 'Hooks/useFetch';
import ContentLoading from 'Components/ContentLoading';
import UserProfileOverview from 'Components/ProfileOverview/UserProfileOverview';
import ProfileTabs from 'Components/ProfileTabs';

const UserProfile = () => {
	let history = useHistory();
	const { tab } = useParams();
	const { data, loading, error } = useFetch({ url: '/api/me' });
	const user = data?.user || {};

	if (loading) return <ContentLoading />;
	if (error) history.push('/login');

	return (
		<React.Fragment>
			<Helmet>
				<title>ClickerMe | Your Profile</title>
				<meta name="description" content="Build your ClickerMe portfolio" />
			</Helmet>
			<UserProfileOverview user={user} />
			<ProfileTabs tab={tab} user={user} isCurrentUser />
		</React.Fragment>
	);
};

export default UserProfile;
