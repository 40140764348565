// @flow
import styled, { css } from "styled-components";
import Text from "Components/Text";
import { positioning } from "Helpers/Positioning";

export const Wrapper = styled.div(
  ({ background_color }) =>
    background_color &&
    css`
      background-color: ${background_color};
    `
);

export const Container = styled.div(
  props =>
    `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props.theme.spacing}rem;
  @media screen and (min-width: ${props.theme.breakpoints.small}px) {
    display: grid;
    gap: 1rem;
    padding: ${props.theme.spacing * 5}rem 0;
    flex-direction: ${positioning(props, "row", "row-reverse")};
    max-width: ${props.theme.maxWidth}px;
    margin: auto;
    background: ${props.background_color ? props.background_color : "white"};
    color: ${
      props.background_color === "black"
        ? props.theme.colors.white
        : props.theme.colors.black
    };
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`
);

export const StyledText = styled.blockquote(
  props => `
  font-size: ${props.theme.fonts.size.medium};
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  @media screen and (min-width: ${props.theme.breakpoints.small}px) {
    grid-column: ${positioning(props, 2, 1)};
    grid-row: 1;
    flex-direction: row;
  }
`
);

export const QuoteMark = styled.div(
  ({ theme, align }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-${align};
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    flex-direction: row;
  }
`
);

export const Name = styled(Text)(
  props =>
    `grid-column: ${positioning(props, 1, 2)};
  grid-row: 1;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
    grid-column: 1;
    grid-row: 2;
  }
`
);

export const StyledAvatar = styled.div(
  props => `
  border-radius: 75px;
  height: 150px;
  width: 150px;
  background-image: url(${props.credit.image});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  grid-column: ${positioning(props, 2, 1)};
  grid-row: 1;
  margin: auto;
`
);

export const Credit = styled.div(
  props =>
    `
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: 1fr;
  justify-content: center;
  grid-column: ${positioning(props, 1, 2)};
  grid-row: 1
  align-items: center;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
    grid-column: 1;
    grid-row: 2;
    justify-content: center;
  }
`
);
