// @flow
import React from "react";
import { useAuth } from "Hooks/useAuth";
import { useLocation } from "react-router-dom";
import type { Credit } from "Types";
import Card from "Components/Card";
import Avatar from "Components/Avatar";
import SignUpForm from "Components/SignUpForm";
import {
  Container,
  Wrapper,
  MobileContainer,
  Author,
  StyledText as Text
} from "./styles";

type Props = {
  credit?: Credit,
  image: Object,
  children?: React.Node
};

const Hero = ({ credit, image, children, fullscreen }: Props): React.Node => {
  const { auth } = useAuth();
  const { pathname } = useLocation();
  const displaySignUp = pathname === "/" && !auth.access_token;

  return (
    <>
      <Container image={image} fullscreen={fullscreen}>
        <Wrapper displaySignUp={displaySignUp}>
          {displaySignUp ? (
            <Card noMargin>
              <SignUpForm />
            </Card>
          ) : (
            children && <Card noMargin>{children}</Card>
          )}
        </Wrapper>
        {credit?.image && credit?.text && (
          <Author>
            <Avatar size="small" src={credit?.image}></Avatar>
            <Text>Photo by {credit?.text}</Text>
          </Author>
        )}
      </Container>
      <MobileContainer>
        {displaySignUp && (
          <Card>
            <SignUpForm />
          </Card>
        )}
      </MobileContainer>
    </>
  );
};

export default Hero;
