import React from "react";
import {
  Columns,
  StyledImage,
  Title,
  Icon,
  Date,
  Text,
  Wrap,
  LinkThat as Link
} from "./styles";

function EachEpisode({ details, display }: Props) {
  return (
    <Columns display={display}>
      <Link to={details.link}>
        <StyledImage src={details.thumbnail}></StyledImage>
      </Link>
      <rightSide>
        <Title display={display}>
          <h3>
            <span>
              <Icon src={require("Assets/Images/speaker-icon.svg")}></Icon>
            </span>
            <Link
              to={details.link}
              style={{ color: "black", textDecoration: "none" }}
            >
              {details.name}
            </Link>
          </h3>
        </Title>
        <Date>{details.date}</Date>
        <Text display={display}>{details.description}</Text>
        <Link to={details.link} style={{ color: "black" }} display={display}>
          Listen now
        </Link>
      </rightSide>
    </Columns>
  );
}

export default EachEpisode;
