import styled from "styled-components";

export const Wrapper = styled.div(
  ({ theme }) => `
  margin: auto;
  box-sizing: content-box;
  background-color: white;
  padding-bottom: ${theme.spacing * 2}rem;
`
);
export const WrapperPlayers = styled.div(
  ({ theme }) => `
  margin: auto;
  box-sizing: content-box;
  background-color: white;
  padding-bottom: ${theme.spacing * 3}rem;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    padding-left:${theme.spacing}rem;
    padding-right:${theme.spacing}rem;
    padding-bottom: ${theme.spacing * 3}rem;
   }
`
);

export const MoreEpisodes = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidth / 1.5}px;
  margin:auto;
  height: auto;
  padding-top: ${theme.spacing * 2}rem;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
  padding-top: ${theme.spacing * 2}rem;
  padding-bottom: ${theme.spacing}rem;
  padding-left: ${theme.spacing}rem;
  padding-right: ${theme.spacing}rem;
   }
`
);

export const Heading = styled.div(
  ({ theme }) => `
  margin:auto;
  display: flex;
  align-items:center;
  padding-bottom: ${theme.spacing * 2}rem;
  h3{
   padding-left: ${theme.spacing / 2}rem; 
   max-width: 70%;
  }
`
);

export const StyledAvatar = styled.div(
  ({ theme }) => `
  border-radius: 75px;
  height: 150px;
  width: 150px;
  background-image: url(${require("Assets/Images/lara-johnson.jpg")});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding:0
  margin:auto;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    padding-left: ${theme.spacing * 2}rem;
    text-align:left;
    margin:0;
   }

`
);
export const Description = styled.div(
  ({ theme }) => `
  flex: column;
  align-items:center;
  justify-content:center;
  text-align:center;
  h3{
    line-height: 1.5;
    padding-top:${theme.spacing * 2}rem;
  }
  p{
    padding-top:${theme.spacing / 2}rem;
    line-height: 1.5;
    
  }
   
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    padding-left: ${theme.spacing * 2}rem;
    text-align:left;
    max-width: ${theme.maxWidth / 2}px;
    h3{
      padding-top:0;
    }
   }
`
);

export const WrapperQuote = styled.div(
  ({ theme }) => `
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display:flex;
   }
  margin:auto;
  display:block;
  align-items:center;
  justify-content:center;
  background-color: white;
  padding: ${theme.spacing * 2}rem;
`
);
