// @flow
import styled from 'styled-components';

export const Container = styled.ul(
	({ theme }) => `
  max-width: ${theme.maxWidth}px;
  background-color: ${theme.colors.white};
  padding: ${theme.spacing * 2}rem;
  margin: ${theme.spacing * 3}rem auto;
  list-style-type: none;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    padding: ${theme.spacing}rem;
  }
`
);

export const Item = styled.li(
	({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing}rem;
  border-bottom: 2px dotted ${theme.colors.grey()};
  animation: fadeIn 0.8s ease-in-out;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    padding: ${theme.spacing}rem 0;
  }
`
);

export const Profile = styled.div(
	({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.colors.black};
  text-decoration: none;
`
);

export const Details = styled.div(
	({ theme }) => `
  display: flex;
  flex-direction: column;
  h3{
    @media screen and (max-width: ${theme.breakpoints.small}px) {
     font-size: ${theme.fonts.size.normal};
  }
  }
`
);

export const Occupation = styled.span(
	({ theme }) => `
  margin: ${theme.spacing / 4}rem 0;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    font-size: ${theme.fonts.size.small}
  }
`
);

export const Company = styled.span(
	({ theme }) => `
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    font-size: ${theme.fonts.size.xsmall}
  }
  font-size: ${theme.fonts.size.small}
  color: ${theme.colors.grey('dark')};
`
);

export const StyledButton = styled.button(
	({ theme }) => `
  border: none;
  display: flex;
  flex-direction: row;
    @media screen and (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
    font-size: ${theme.fonts.size.xsmall};
    }
  min-width:75px;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing / 2}rem
  font-family: ${theme.fonts.default};
  font-size: ${theme.fonts.size.normal};
  font-weight: bold;
  text-transform: uppercase;
`
);

export const Empty = styled.div(
	({ theme }) => `
  width: 100%;
  text-align: center;
  padding: ${theme.spacing}rem;
`
);
