// @flow
import React, { useState } from 'react';
import { useAuth } from 'Hooks/useAuth';
import { useFetch } from 'Hooks/useFetch';
import { useLazyFetch } from 'Hooks/useLazyFetch';
import { useHistory } from 'react-router-dom';
import { useValidation, useTextInput, useCheckboxInput } from 'Hooks';
import Text from 'Components/Blocks/Text';
import Input from 'Components/Input';
import Button from 'Components/Button';
import FormField from 'Components/FormField';
import Checkbox from 'Components/Checkbox';
import Notification from 'Components/Notification';
import { StyledLink as Link } from './styles';

const SignUpForm = () => {
	let history = useHistory();
	const { login, refetchUser } = useAuth();
	const { data } = useFetch({ url: '/api/pages/signup' });
	const signupCopy = data?.page?.blocks[0]?.text;
	const [error, setError] = useState(null);
	const email = useTextInput();
	const password = useTextInput();
	const terms = useCheckboxInput();
	const [validate, errors] = useValidation(
		{
			email: email.value,
			password: password.value,
			terms: terms.checked,
		},
		{
			email: {
				email: true,
				presence: {
					allowEmpty: false,
				},
			},
			password: {
				presence: {
					allowEmpty: false,
				},
				length: {
					minimum: 6,
				},
			},
			terms: {
				presence: {
					message: '^Please accept the Terms and Conditions',
				},
				inclusion: {
					within: [true],
					message: '^Please accept the Terms and Conditions',
				},
			},
		}
	);

	const [submit, response] = useLazyFetch({
		url: '/api/users',
		method: 'post',
	});

	function signUp(e) {
		e.preventDefault();
		const valid = validate();
		if (valid)
			submit(
				{
					user: {
						email: email.value,
						password: password.value,
						terms: terms.checked,
					},
				},
				onSuccess,
				onError
			);
	}

	function onSuccess() {
		setError(null);
		authorise();
	}

	function onError() {
		setError('Sorry, we could not sign you up');
	}

	async function authorise() {
		const user = {
			username: email.value,
			password: password.value,
			grant_type: 'password',
		};
		const success = ({ user }) => {
			refetchUser(user);
			history.push('/onboarding');
		};
		const error = ({ error }) => setError(error);
		await login(user, success, error);
	}

	return (
		<>
			{error && <Notification>{error}</Notification>}
			{signupCopy && <Text text={signupCopy} />}
			<form onSubmit={signUp}>
				<FormField errors={errors?.email}>
					<Input type="email" placeholder="Email" {...email} />
				</FormField>
				<FormField errors={errors?.password}>
					<Input type="password" placeholder="Password" {...password} />
				</FormField>
				<FormField errors={errors?.terms}>
					<Checkbox
						{...terms}
						label={
							<Link to="/terms-and-conditions">
								I accept ClickerMe’s Terms and Conditions
							</Link>
						}
					/>
				</FormField>
				<Button type="submit">
					{response?.loading ? 'Submitting...' : 'JOIN FOR FREE'}
				</Button>
			</form>
		</>
	);
};

export default SignUpForm;
