// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledButton = styled.button(
  ({ padded, size, variant, disabled, theme }) => `
  min-width: ${size === "small" ? 50 : 120}px || ${size === "big" ? 250 : 420};
  padding: ${size === "small" ? theme.spacing / 2 : theme.spacing * 0.75}rem
    ${theme.spacing * 1.5}rem;
  margin: ${theme.spacing * 0.75}rem ${padded ? theme.spacing : 0}rem;
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${
    disabled
      ? theme.colors.grey("dark")
      : variant === "white"
      ? theme.colors.white
      : theme.colors.black
  };
  text-transform: uppercase;
  background-color: ${
    disabled
      ? theme.colors.grey()
      : variant === "black" || variant === "white"
      ? "transparent"
      : theme.colors.yellow()
  };
  border-radius: ${theme.border.radius}px;
  border-width: 2px;
  border-style: solid;
  border-color: ${
    disabled
      ? theme.colors.grey()
      : variant === "black"
      ? theme.colors.black
      : variant === "white"
      ? theme.colors.white
      : theme.colors.yellow()
  };
  &:hover {
    cursor: pointer;
  }
`
);

export const StyledLink = styled(Link)(
  ({ padded, size, variant, disabled, theme }) => `
  text-decoration: none;
  display: inline-block;
  min-width: ${size === "small" ? 50 : 120}px;
  padding: ${size === "small" ? theme.spacing / 2 : theme.spacing * 0.75}rem
    ${theme.spacing * 1.5}rem;
  margin: ${theme.spacing * 0.75}rem ${padded ? theme.spacing : 0}rem;
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${
    disabled
      ? theme.colors.grey("dark")
      : variant === "white"
      ? theme.colors.white
      : theme.colors.black
  };
  text-transform: uppercase;
  background-color: ${
    disabled
      ? theme.colors.grey()
      : variant === "black" || variant === "white"
      ? "transparent"
      : theme.colors.yellow()
  };
  border-radius: ${theme.border.radius}px;
  border-width: 2px;
  border-style: solid;
  border-color: ${
    disabled
      ? theme.colors.grey()
      : variant === "black"
      ? theme.colors.black
      : variant === "white"
      ? theme.colors.white
      : theme.colors.yellow()
  };
  &:hover {
    cursor: pointer;
  }
`
);
