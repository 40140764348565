// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div(
  ({ theme }) => `
  width: 100vw;
  display: flex;
  flex-flow: row wrap;
  min-height: 500px;
  padding: ${theme.spacing}rem;
  margin-bottom: ${theme.spacing * 2}rem;
  background-color: ${theme.colors.white};
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    width: ${theme.maxWidth}px;
    margin: auto;
    padding: ${theme.spacing * 2}rem;
  }
`
);

export const AddButton = styled.button(
  ({ theme }) => `
  background-color: ${theme.colors.grey("light")};
  width: 90vw;
  height: ${theme.spacing * 8}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing}rem auto;
  color: ${theme.colors.black};
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  border: none;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    width: 40%;
    height: ${theme.spacing * 24}rem;
    margin: 5%;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    width: 30%;
    margin: ${theme.spacing}rem;
  }
`
);

export const ProjectItem = styled.div(
  ({ theme, published }) => `
  width: 100%;
  height: ${theme.spacing * 24}rem;
  padding: ${theme.spacing}rem;
  margin: ${theme.spacing}rem auto;
  border: 1px solid ${published ? theme.colors.black : theme.colors.grey()};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    width: 40%;
    margin: 5%;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    width: 30%;
    margin: ${theme.spacing}rem;
  }
`
);

export const LinkWrapper = styled(Link)(
  ({ theme }) => `
  background-color: transparent;
  text-decoration: none;
  color: ${theme.colors.black};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`
);

export const Title = styled.h3(
  ({ theme }) => `
  width: 100%;
  text-overflow: ellipsis;
  margin-bottom: ${theme.spacing / 2}rem;
`
);

export const Cover = styled.img(
  ({ theme }) => `
  width: ${theme.spacing * 16}rem;
  height: ${theme.spacing * 16}rem;
  object-fit: cover;
`
);

export const WrapThumbnail = styled.div(
  ({ theme }) => `
  width: ${theme.spacing * 16}rem;
  height: ${theme.spacing * 16}rem;
  object-fit: cover;
`
);

export const ButtonLabel = styled.p`
  margin: 0;
`;
