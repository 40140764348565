import styled from "styled-components";

export const Wrapper = styled.div(
  ({ theme, active, position }) => `
  background: ${active ? theme.colors.black : theme.colors.white};
  border: 2px solid ${theme.colors.black};
  color: ${active ? theme.colors.yellow() : theme.colors.black};
  padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
  font-weight: bold;
  font-size: ${theme.fonts.size.small};
  text-transform: uppercase;
  display flex;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  border-top-left-radius: ${position === "left" ? theme.spacing * 2 : 0}rem;
  border-bottom-left-radius: ${position === "left" ? theme.spacing * 2 : 0}rem;
  border-top-right-radius: ${position === "right" ? theme.spacing * 2 : 0}rem;
  border-bottom-right-radius: ${
    position === "right" ? theme.spacing * 2 : 0
  }rem;
  &:hover {
    cursor: pointer;
  }
  &:first-child {
    border-right: 0;
  }
`
);
