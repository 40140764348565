// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import BlockContainer from 'Components/BlockContainer';

function About() {
	return (
		<>
			<Helmet>
				<title>ClickerMe | About Us</title>
				<meta
					name="description"
					content="Our mission is to help fashion creatives and brands connect and collaborate better"
				/>
				<htmlAttributes h1="Meet The Team Behind ClickerMe"></htmlAttributes>
			</Helmet>
			<BlockContainer name="about" />
		</>
	);
}

export default About;
