// @flow
import { persistCombineReducers } from "redux-persist";
import { connectRouter } from "connected-react-router";
import storage from "redux-persist/es/storage";
import { ENV } from "Config";

const config = {
  key: ENV,
  debug: ENV === "development",
  storage
};

const reducers = history =>
  persistCombineReducers(config, {
    router: connectRouter(history)
  });

export default reducers;
