// @flow
import React from "react";
import { User } from "Types";
import { Container, Column, Section, Header, Placeholder } from "./styles";
import Tag from "Components/Tag";
import EducationItem from "Components/EducationItem";
import ExperienceItem from "Components/ExperienceItem";

type Props = {
  user: User
};

const LargePlaceholder = () => (
  <React.Fragment>
    <Placeholder />
    <Placeholder />
    <Placeholder half />
  </React.Fragment>
);

function PeerAboutTab({ user }: Props) {
  return (
    <Container>
      <Column>
        <Section>
          <Header>
            <h3>About {user?.first_name}</h3>
          </Header>
          {user?.profile?.about_me ? (
            <span>{user.profile.about_me}</span>
          ) : (
            <LargePlaceholder />
          )}
        </Section>
        <Section>
          <Header>
            <h3>Education</h3>
          </Header>
          {user?.education?.length > 0 ? (
            user.education.map((item, index) => (
              <EducationItem key={index} item={item} />
            ))
          ) : (
            <LargePlaceholder />
          )}
        </Section>
      </Column>
      <Column>
        <Section>
          <Header>
            <h3>Skills</h3>
          </Header>
          {!user?.skills?.length > 0 ? (
            <React.Fragment>
              <Placeholder half />
              <Placeholder half />
              <Placeholder half />
              <Placeholder half />
              <Placeholder half />
              <Placeholder half />
            </React.Fragment>
          ) : (
            user.skills.map((skill, index) => (
              <Tag key={index} label={skill.text} />
            ))
          )}
        </Section>
        <Section>
          <Header>
            <h3>Experience</h3>
          </Header>
          {user?.experience?.length > 0 ? (
            user.experience.map((item, index) => (
              <ExperienceItem key={index} item={item} />
            ))
          ) : (
            <LargePlaceholder />
          )}
        </Section>
      </Column>
    </Container>
  );
}

export default PeerAboutTab;
