import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useFetch } from 'Hooks/useFetch';
import { useAuth } from 'Hooks/useAuth';
import { Helmet } from 'react-helmet';
import BlockContainer from 'Components/BlockContainer';
import Video from 'Components/Video';
import TinyBanner from 'Components/TinyBanner';
import Feedback from 'Components/Blocks/Feedback';
import Heading from 'Components/Blocks/Heading';
import Text from 'Components/Blocks/Text';
import SignUpFormGrey from 'Components/SignUpFormGrey';
import Announcement from 'Components/Blocks/Announcement';

import {
	VideoContainer,
	SignUpContainer,
	SignUpWrapper,
	Wrapper,
	FAQText,
	Banner,
} from './styles';

function LandingPage() {
	const { data } = useFetch({ url: '/api/pages/the-fashion-network' });
	const heading = data?.page?.blocks[1];
	const text = data?.page?.blocks[0];
	const { auth } = useAuth();

	const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
	const myRef = useRef(null);

	return (
		<>
			<Wrapper>
				<Helmet>
					<title>
						The Fashion Network | Join The Fashion Community | ClickerMe
					</title>
					<meta
						name="description"
						content="ClickerMe is a free network for fashion creatives — a place for the fashion community to connect, collaborate and create"
					/>
					<htmlAttributes h1="Join The Fashion Network"></htmlAttributes>
				</Helmet>
				<Banner>
					<p>CONNECT & COLLABORATE WITH FASHION PROFESSIONALS FAST</p>
					<img
						src={require('Assets/Images/200520-clickerme-homepage-block-02.jpg')}
					></img>
				</Banner>
				<TinyBanner
					text={'WATCH OUR VIDEO TO FIND OUT MORE'}
					onClick={() => {
						scrollToRef(myRef);
					}}
				></TinyBanner>
				{text && <Text {...text} />}
				<VideoContainer ref={myRef}>
					<Video
						url={'https://www.youtube.com/watch?v=gWbsY_LmRYE'}
						height="100%"
					></Video>
				</VideoContainer>
				<SignUpContainer>
					<SignUpWrapper>
						<SignUpFormGrey />
					</SignUpWrapper>
				</SignUpContainer>
				{heading && <Heading {...heading} />}
				<FAQText>
					<div className="column">
						<p>
							<b>1. Who is ClickerMe for?</b>
							<br></br>
							ClickerMe is for fashion creatives and professionals. Anyone with
							anything to do with fashion will benefit from networking contacts
							through ClickerMe- from fashion designers to illustrators, pattern
							cutters to seamstresses, photographers, set designers, MUA's and
							more.
							<br></br>
							<br></br>
							<b>2. Can students join?</b>
							<br></br>
							Yes! Joining before you graduate could help you collaborate
							quickly and find freelance work or permanent fashion jobs in the
							industry.
							<br></br>
							<br></br>
							<b>3. Who can view my profile?</b>
							<br></br>
							ClickerMe members can view other member’s full profiles. They can
							also view all projects that have been set to public.
						</p>
					</div>
					<div className="column">
						<p>
							<b>4. What are skills?</b>
							<br></br>
							Skills are anything that’s relevant to your career. You’re a great
							illustrator? You can speak another language? You can use the Adobe
							Suite? Let us know! Show off your fashion portfolio.
							<br></br>
							<br></br>
							<b>5. How can I connect and collaborate on ClickerMe?</b>
							<br></br>
							ClickerMe members are verified to ensure the work of our fashion
							creatives is of the highest quality. Head to our News Feed to ask
							for (or give!) feedback and advice from our community. Or, you can
							slide into a member's DMs to connect directly.
							<br></br>
							<br></br>
							<b>6. How much does ClickerMe cost to use ?</b>
							<br></br>
							It’s completely free.
						</p>
					</div>
				</FAQText>
				<Feedback
					hide_if_logged_in
					background_color="#dadada"
					text="<h2>Connect and collaborate with fashion creatives.</h2>"
					buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
				/>
			</Wrapper>
		</>
	);
}

export default LandingPage;
