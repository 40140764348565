import styled from "styled-components";

export const Container = styled.div(
  ({ theme }) => `
    max-width:${theme.maxWidth / 1.5}px;
    margin:auto;
    .h3{
      color:red;
    }
  `
);
export const Title = styled.h3(
  ({ theme }) => `
  font-size: ${theme.fonts.size.medium};
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    font-size: ${theme.fonts.size.large};
    line-height: 1.15;
  }   
  `
);

export const Details = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.spacing * 3}rem;
    max-width: ${theme.maxWidth / 1.8}px;
    font-size: ${theme.fonts.size.normal};
  `
);

export const StyledImage = styled.img(
  ({ theme }) => `
  width:285px;
  height:285px;
  padding-right: ${theme.spacing * 2}rem;
  padding-bottom: ${theme.spacing * 2}rem;
`
);

export const WrapProviders = styled.div(
  ({ theme }) => `
  padding-bottom: ${theme.spacing * 3}rem;
  display: grid;
  grid-template-columns: 1fr 1fr 200px;
  grid-template-rows: auto;
  grid-gap: 2.5rem;
  padding-top: ${theme.spacing}rem;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
  display:block;
   }
`
);
export const Heading = styled.div(
  ({ theme }) => `
  margin:auto;
  display: flex;
  align-items:center;
  padding-bottom: ${theme.spacing * 2}rem;
  h3{
   padding-left: ${theme.spacing / 2}rem; 
   max-width: 70%;
   text-transform: uppercase;
  }
`
);

export const StyledDescription = styled.p(
  ({ theme }) => `
  font-weight: bold;
  padding-bottom: ${theme.spacing}rem;
  padding-top: ${theme.spacing}rem;
  `
);

export const Date = styled.p(
  ({ theme }) => `
  font-size: ${theme.fonts.size.small};
  color: ${theme.colors.grey("dark")};
  line-height: 1.83;
  `
);

export const VideoWrapper = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidth / 1.5}px;
  padding-top: ${theme.spacing * 2}rem;
  padding-bottom: ${theme.spacing * 2}rem;
  margin:auto;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
  height:100%;
  padding-left: ${theme.spacing}rem;
  padding-right: ${theme.spacing}rem;
  padding-top: ${theme.spacing * 2}rem;
  padding-bottom: ${theme.spacing * 2}rem;
  } 
`
);

export const Wrapper = styled.div(
  ({ theme }) => `
  margin: auto;
  background-color: black;
  padding-bottom: ${theme.spacing * 3}rem;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
  min-height:43vh;
  }
`
);
