// @flow
import React, { useState } from "react";
import {SaveButton, Container, Actions} from "./styles";
import TextBox from "Components/TextBox";

type Props = {
  data: String,
  setData: Function,
  setError: Function,
  checkIfDone: Function,
  submitProfile: Function,
  LargePlaceholder: React.Node,
  icons: Object
};

function BioForm({
  data,
  setData,
  LargePlaceholder,
  checkIfDone,
  setError,
  submitProfile,
  setIsOpen,
  icons
}: Props) {
  const [editing, toggleEditing] = useState(true);
  const about = data?.profile?.about_me;

  function submitBio(e) {
    submitProfile(data?.profile, onSuccess, onError);
  }

  function onSuccess() {
    checkIfDone();
    setError(null);
  }

  function update(value) {
    setData({
      ...data,
      profile: {...data.profile, about_me: value}
    })
  }

  function onError() {
    setData({ ...data, profile: { ...data?.profile } });
    setError("Sorry, we could not update your profile right now");
  }
  return (
    <React.Fragment>
      {editing ? (
        <Container>
          <TextBox
            value={about}
            placeholder={"Add a short bio..."}
            onChange={e => update(e.target.value)} />
        </Container>
      ) : about?.length > 0 ? (
        <span>{about}</span>
      ) : (
        <LargePlaceholder />
      )}
      <Actions>
        <SaveButton
          disabled={!data?.profile?.about_me}
          size="small"
          onClick={() => {
            submitBio();
            setIsOpen(false);
          }}
        >
          <span>Save to profile</span>
        </SaveButton>
      </Actions>
    </React.Fragment>
  );
}

export default BioForm;
