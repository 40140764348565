import React from "react";
import { StyledBanner, Heading } from "./styles";
import IconButton from "Components/IconButton";

const TinyBanner = ({ text, onClick }) => (
  <StyledBanner onClick={onClick}>
    <IconButton>
      <img src={require("Assets/Images/arrowdown.svg")}></img>
    </IconButton>
    <Heading>{text}</Heading>
  </StyledBanner>
);

export default TinyBanner;
