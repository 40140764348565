// @flow
import styled from "styled-components";

export const StyledSection = styled.section`
  max-width: 1040px;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  margin-bottom: 40px;
  padding: 20px;
  text-align: left;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: auto;
  max-width: 1022px;
  margin-top: 30px;
`;

export const Heading = styled.h2`
  font-weight: normal;
`;

export const Line = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  flex: 1;
  flex-flow: column nowrap;
  border-bottom: 1px solid grey;
  margin-left: 30px;
`;

export const PortfoliosContainer = styled.div`
  display: grid;
  grid-template-rows: 300px;
  grid-template-columns: repeat(4, 1fr);
`;

export const PortfolioCard = styled.div`
  max-width: 250px;
`;

export const PortfolioCover = styled.div`
  width: 100%;
  height: auto;
  padding: 10px;
`;
