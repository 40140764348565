import styled from "styled-components";

export const Container = styled.div(
  ({ theme }) => `
  border: 2px solid black;
  margin:auto;
  align-items: center;
  width:100%;
  display:flex;
  flex-direction: row;
  flex:end;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    width: -webkit-fit-content;
   }
 `
);
export const Overlap = styled.div(
  ({ theme }) => `
  margin:auto;
  text-align:center;
  dislay:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
     h3{
    position:relative;
    top:15px;
    }
    mark{
      background: white;
      padding-right: 10px;
    padding-left: 10px;
    }    
    @media screen and (min-width: ${theme.breakpoints.small}px) {
      padding: 0;
     }
    
`
);
export const Icon = styled.img(
  ({ theme }) => `
    width:60px;
    height:60px;
    padding:10px;
    margin:auto;
    border : 0;
    text-decoration: none;
    outline: none;
  `
);

export const EachColumn = styled.div(
  ({ theme }) => `
   display:block;
   padding:${theme.spacing}rem;
   align-items:center;
   margin:auto;
   p{
     font-weight:bold;
     text-transform:uppercase;
     word-break:keep-all;
     font-size: ${theme.fonts.size.xsmall};
     margin:auto;
   }
   @media screen and (min-width: ${theme.breakpoints.small}px) {
   padding-top:${theme.spacing}rem;
   padding-bottom:${theme.spacing}rem;
   padding-left:${theme.spacing}rem;
   padding-right:${theme.spacing}rem;
   display:flex;
   flex-direction:row;   
  }
   
  `
);
