// @flow
import React from "react";
import { Helmet } from "react-helmet";
import { useFetch } from "Hooks/useFetch";
import Hero from "Components/Blocks/Hero";
import ResetPasswordForm from "Components/ResetPasswordForm";

function ResetPassword() {
  const { data } = useFetch({
    url: "/api/pages/reset-password"
  });
  const page = data?.page?.blocks[0];

  return (
    <React.Fragment>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Hero image={page?.image} credit={page?.credit} fullscreen>
        <ResetPasswordForm />
      </Hero>
    </React.Fragment>
  );
}

export default ResetPassword;
