import React from "react";
import IconButton from "Components/IconButton";
import {
  Wrapper,
  Container,
  Image,
  StaticImage,
  StyledButton,
  Border,
  ButtonAndTitle,
  Nest,
  WrapImages,
  Content,
  WrapButtonText
} from "./styles";
import {blackArrowIcon, circleArrowIcon, topIcon} from "Helpers/Icons";

type Props = {
  image: Object,
  text: String,
  title: String,
  description: String,
  button: String,
  badge: Boolean,
  arrow: Boolean
};

const AffiliateContents = ({
  cards,
  background_color,
  title
}: Props): React.Node => {
  return (
    <Wrapper background_color={background_color}>
      <Container>
        <StaticImage>{title}</StaticImage>
        {cards?.map((card, i) => {
          const { image, badge, description, title, button, arrow } = card;
          return (
            <>
              <a
                href={button.link}
                style={{ textDecoration: "none", color: "black" }}
                key={`a-${i}`}
                target="_blank"
                id={button.link}
              >
                <Content key={i}>
                  <WrapImages>
                    <Image src={image} id={button.link}/>
                    {badge && (
                      <img src={topIcon} />
                    )}
                  </WrapImages>
                  {description !== "" ? (
                    <>
                      <h4>{title}</h4>
                      <WrapButtonText>
                        <p>{description}</p>
                        <StyledButton>
                          {button.text}
                          {arrow && <img src={blackArrowIcon}/>}
                        </StyledButton>
                      </WrapButtonText>
                    </>
                  ) : (
                    <ButtonAndTitle>
                      <h4>{title}</h4>
                      <IconButton>
                        <img src={circleArrowIcon}/>
                      </IconButton>
                    </ButtonAndTitle>
                  )}
                </Content>
              </a>
              <Border/>
            </>
          );
        })}
      </Container>
    </Wrapper>
  );
};

export default AffiliateContents;
