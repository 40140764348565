// @flow
import React, { useState } from "react";
import Gallery from "Components/Gallery";
import Avatar from "Components/Avatar";
import {
  Wrapper,
  Container,
  Header,
  Details,
  Name,
  Location,
  Content,
  Title,
  Description,
  SwitchButton
} from "./styles";

const ProjectView = ({ project }: Props) => {
  const [columnView, toggleColumnView] = useState(false);
  const { profile, title, description, images } = project;

  return (
    <Wrapper>
      <Container>
        <Header>
          <Details to={`/profiles/${profile?.id}/portfolio`}>
            <Avatar size="large" src={profile?.photo} />
            <div>
              <Name>
                {profile?.first_name} {profile?.last_name}
              </Name>
              <Location>{profile?.location}</Location>
            </div>
          </Details>
          <div>
            <SwitchButton
              active={!columnView}
              onClick={() => toggleColumnView(false)}
              src={require("Assets/Images/column-view.svg")}
            />
            <SwitchButton
              active={columnView}
              onClick={() => toggleColumnView(true)}
              src={require("Assets/Images/grid-view.svg")}
            />
          </div>
        </Header>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
        <Gallery columns={columnView ? 4 : 1} images={images} />
      </Container>
    </Wrapper>
  );
};

export default ProjectView;
