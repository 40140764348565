// @flow
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "Hooks/useAuth";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { timeAgo } from "Helpers/Date";
import { formatSrc } from "Helpers/Image";
import Linkify from "react-linkify";
import {
  Card,
  Container,
  Details,
  Image,
  GreyText,
  PostText,
  Content,
  CommentContainer,
  DeleteButton,
  ToggleButton,
  CommentExpandButton,
  Name
} from "./styles";
import { Post as PostType } from "Types";
import Avatar from "Components/Avatar";
import Comment from "Components/Comment";
import CommentForm from "Components/CommentForm";
import IconButton from "Components/IconButton";
import { MdMoreHoriz } from "react-icons/md";

type Props = {
  post: PostType,
  updatePost: Function,
  removePost: Function,
  setError: Function
};

const Post = ({
  post,
  updatePost,
  removePost,
  setError
}: Props): React.Node => {
  const { id, text, image, user, comments, created_at } = post;
  const { auth } = useAuth();
  const currentUser = auth?.user;
  let history = useHistory();
  const [menuOpen, toggleMenuOpen] = useState(false);
  const [fullText, toggleFullText] = useState(text?.length <= 1000);
  const [allComments, toggleAllComments] = useState(comments?.length <= 2);
  const [showComments, toggleShowComments] = useState(false);
  const [deletePost, response] = useLazyFetch({
    url: `/api/collaboration_posts/${id}`,
    method: "delete"
  });

  function addComment(comment) {
    updatePost(id, { ...post, comments: [...post?.comments, comment] });
  }

  function removeComment(commentId) {
    updatePost(id, {
      ...post,
      comments: post?.comments.filter(comment => comment?.id !== commentId)
    });
  }

  function onDelete() {
    deletePost(null, onSuccess, () =>
      setError("Sorry, we could not delete this post")
    );
  }

  function onSuccess() {
    removePost(id);
    setError(null);
    toggleMenuOpen(false);
  }

  return (
    <>
      {post && (
        <Card>
          <Container>
            <Avatar
              src={user?.profile?.photo}
              size="large"
              onClick={() => history.push(`/profiles/${user?.id}/portfolio`)}
              style={{ cursor: "pointer " }}
            />
            <Details>
              <Name
                onClick={() => history.push(`/profiles/${user?.id}/portfolio`)}
              >
                {user?.profile?.first_name} {user?.profile?.last_name}
              </Name>
              <GreyText>{user?.profile?.occupation}</GreyText>
              <GreyText>{timeAgo(created_at)}</GreyText>
            </Details>
            {menuOpen && (
              <DeleteButton onClick={onDelete}>
                <img src={require("Assets/Images/trash-alt.svg")} />
                {response?.loading ? "Deleting" : "Delete Post"}
              </DeleteButton>
            )}
          </Container>
          {user?.id === currentUser?.id && (
            <IconButton
              style={{ position: "absolute" }}
              onClick={() => toggleMenuOpen(!menuOpen)}
            >
              <MdMoreHoriz color="#a9a9a9" size="24" />
            </IconButton>
          )}
          <Content>
            <PostText>
              <Linkify>{fullText ? text : `${text.slice(0, 1000)}...`}</Linkify>
            </PostText>
            {text?.length > 1000 && (
              <ToggleButton onClick={() => toggleFullText(!fullText)}>
                See {fullText ? "less" : "more"}
              </ToggleButton>
            )}
            {image && <Image src={formatSrc(image, 360)} />}
          </Content>
          <CommentExpandButton
            onClick={() => toggleShowComments(!showComments)}
          >
            {showComments
              ? "Hide comments"
              : comments.length
              ? `${comments.length} comment${comments?.length > 1 ? "s" : ""}`
              : "Write a comment"}
          </CommentExpandButton>
        </Card>
      )}
      <CommentContainer showComments={showComments}>
        {allComments
          ? comments?.map((comment, index) => (
              <Comment
                key={index}
                comment={comment}
                postId={id}
                removeComment={removeComment}
                setError={setError}
              />
            ))
          : comments
              ?.slice(0, 2)
              ?.map((comment, index) => (
                <Comment
                  key={index}
                  comment={comment}
                  postId={id}
                  removeComment={removeComment}
                  setError={setError}
                />
              ))}
        {comments?.length > 2 && (
          <ToggleButton onClick={() => toggleAllComments(!allComments)}>
            View{" "}
            {allComments
              ? "fewer comments"
              : `${comments.length - 2} more comment${
                  comments?.length === 3 ? "" : "s"
                }`}
          </ToggleButton>
        )}{" "}
        <CommentForm
          postId={post?.id}
          addComment={addComment}
          setError={setError}
        />
      </CommentContainer>
    </>
  );
};

export default Post;
