// @flow
import React from "react";
import { Helmet } from "react-helmet";
import { useFetch } from "Hooks/useFetch";
import Hero from "Components/Blocks/Hero";
import RequestResetPasswordForm from "Components/RequestResetPasswordForm";

function RequestResetPassword() {
  const { data } = useFetch({
    url: "/api/pages/reset-password"
  });
  const page = data?.page?.blocks[0];

  return (
    <React.Fragment>
      <Helmet>
        <title>New Password</title>
      </Helmet>
      <Hero image={page?.image} credit={page?.credit} fullscreen>
        <RequestResetPasswordForm />
      </Hero>
    </React.Fragment>
  );
}

export default RequestResetPassword;
