// @flow
import React from "react";
import { Helmet } from "react-helmet";
import BlockContainer from "Components/BlockContainer";

function Terms() {
  return (
    <>
      <Helmet>
        <title>Ts & Cs</title>
      </Helmet>
      <BlockContainer name="terms-and-conditions" />
    </>
  );
}

export default Terms;
