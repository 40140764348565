// @flow
import React, { useState, useEffect } from "react";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { formatSrc } from "Helpers/Image";
import {
  ImageContainer,
  Overlay,
  Option,
  MenuButton,
  ImageItem,
  VideoItem,
  Image
} from "./styles";
import ProjectVideo from "../ProjectVideo";

type Props = {
  projectId: String | Number,
  imagesCount: Number,
  image: { url: String, caption: String },
  reordering?: Boolean,
  menuOpen?: Boolean,
  openMenu?: Function,
  closeMenu?: Function,
  removeImage?: Function,
  unpublish?: Function,
  setLightboxImage?: Function,
  setError?: Function,
  newPosition?: Number | null
};

const NewProjectImage = ({
  projectId,
  image,
  imagesCount,
  reordering,
  menuOpen,
  openMenu,
  closeMenu,
  removeImage,
  unpublish,
  setLightboxImage,
  setError,
  newPosition
}: Props) => {
  const [deleting, toggleDeleting] = useState(false);

  const [updateImage] = useLazyFetch({
    url: `/api/projects/${projectId}/images/${image?.id}`,
    method: "put"
  });

  const [deleteImage] = useLazyFetch({
    url: `/api/projects/${projectId}/images/${image?.id}`,
    method: "delete"
  });

  function onSuccess() {
    removeImage(image?.id);
    setError(null);
    toggleDeleting(false);
    if (imagesCount === 1) unpublish();
    closeMenu();
  }

  function onDelete() {
    deleteImage(null, onSuccess, () =>
      setError("Sorry, we could not delete this image right now")
    );
  }

  useEffect(() => {
    if (newPosition !== null) {
      updateImage({ image: { ...image, position: newPosition } });
    }
  }, [newPosition]);

  function imageOrVideo() {
    if (!image?.url && !image?.transformed_url) {
      return <p>Loading...</p>;
    }

    if (["video", "youtube", "vimeo"].includes(image?.resource_type)) {
      return (
        <VideoItem>
          <ProjectVideo
            resourceType={image.resource_type}
            url={image.url}
            fluid={false}
            videoWidth={270}
            videoHeight={270}
          />
        </VideoItem>
      );
    }

    if (image?.resource_type === "image") {
      return (
        <ImageItem>
          <Image
            src={formatSrc(image.transformed_url || image.url, 540)}
            draggable={false}
          />
        </ImageItem>
      );
    }
  }

  return (
    <ImageContainer onBlur={closeMenu} reordering={reordering}>
      {menuOpen && (
        <Overlay>
          <div>
            {!["video", "vimeo", "youtube"].includes(image?.resource_type) && (
              <Option onMouseDown={setLightboxImage}>
                <img src={require("Assets/Images/alphabet.svg")} />
                Add Caption
              </Option>
            )}
            <Option onMouseDown={onDelete}>
              <img src={require("Assets/Images/trash-alt.svg")} />
              Delete
            </Option>
          </div>
        </Overlay>
      )}
      <MenuButton menuOpen={menuOpen} onClick={menuOpen ? closeMenu : openMenu}>
        {reordering ? (
          <img src={require("Assets/Images/drag-button.svg")} />
        ) : (
          <img
            src={require(`Assets/Images/dot-menu${
              menuOpen ? "-white" : ""
            }.svg`)}
          />
        )}
      </MenuButton>
      <ImageItem>{imageOrVideo()}</ImageItem>
    </ImageContainer>
  );
};

export default NewProjectImage;
