// @flow
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import ActionCreator from "Actions";

const options = {};

const trackPage = page => {
  ReactGA.set({
    page,
    ...options
  });

  ReactGA.pageview(page);
};

const setId = userId => {
  ReactGA.set({ userId });
  ReactGA.set({ dimension1: userId });
};

const trackNewUser = id => {
  setId(id);
  ReactGA.event({
    category: "New User",
    action: "Completed Onboarding"
  });
  ReactPixel.track("Completed Onboarding");
};

const trackSubmittedProfile = () => {
  ReactGA.event({
    category: "Profile",
    action: "Submitted For Approval"
  });
};

let currentPage = "";

const Analytics = store => next => action => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;

      if (currentPage !== nextPage) {
        currentPage = nextPage;
        trackPage(nextPage);
      }
      break;

    case ActionCreator.TRACK_NEW_USER:
      trackNewUser(action.id.toString());
      break;

    case ActionCreator.TRACK_SUBMITTED_PROFILE:
      trackSubmittedProfile();
      break;

    case ActionCreator.SET_USER_ID:
      setId(action.userId.toString());
      break;

    default:
      break;
  }

  return next(action);
};

export default Analytics;
