// @flow
import React from "react";
import {
  StyledFooter,
  Container,
  Logo,
  NavLink,
  StyledLink,
  SocialLinks,
  SocialLink,
  Image,
  Text,
  Policies,
  List,
  ListItem
} from "./styles";
import { Link } from "react-router-dom";

type Props = {
  sticky?: Boolean
};

const Footer = ({ sticky }: Props): React.Node => (
  <StyledFooter sticky={sticky}>
    <Container>
      <Logo>
        <Link to="/">
          <Image src={require("Assets/Images/CMSmallLogoWhite.svg")} />
        </Link>
      </Logo>
      <List>
        <ListItem>
          <NavLink to="/jobs">Jobs</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/gallery">Gallery</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/features">Features</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/podcasts/the-fashion-slashie">Podcasts</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/competitions">Competitions</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/open-submissions">Live Briefs</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/feedback">Feedback</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/verification">Verification</NavLink>
        </ListItem>
      </List>
      <SocialLinks>
        <a href="https://www.facebook.com/ClickerMeFashion/" target="_blank">
          <SocialLink src={require("Assets/Images/footer-facebook-icon.svg")} />
        </a>
        <a href="https://www.instagram.com/clickerme/" target="_blank">
          <SocialLink
            src={require("Assets/Images/footer-instagram-icon.svg")}
          />
        </a>
        <a href="https://www.linkedin.com/company/11701566" target="_blank">
          <SocialLink src={require("Assets/Images/footer-linkedin-icon.svg")} />
        </a>
      </SocialLinks>
      <Policies>
        <StyledLink to="/about">About</StyledLink>
        <StyledLink to="/faqs">FAQs</StyledLink>
        <StyledLink to="/terms-and-conditions">Terms & Conditions</StyledLink>
        <StyledLink to="/privacy">Privacy Policy</StyledLink>
        <StyledLink to="/acceptable-use-policy">
          Acceptable Use Policy
        </StyledLink>
        <StyledLink to="/cookies">Cookies</StyledLink>
        <Text>© {new Date().getFullYear()} CLICKERME</Text>
      </Policies>
    </Container>
  </StyledFooter>
);

export default Footer;
