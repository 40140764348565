// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import Button from "Components/Button";

export const Wrapper = styled.div(
  ({ background_color }) => `
  width: 100%;
  background: ${background_color ? background_color : "transparent"};
`
);

export const Container = styled.div(
  ({ theme, background_color, columns, items, hasText }) => `
  background: ${background_color ? background_color : "transparent"};
  margin-bottom: ${theme.spacing}rem;
  padding: ${theme.spacing}rem;
  display:flex;
  flex-direction: ${hasText ? "column-reverse" : "column"};
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: grid;
    grid-auto-rows: min-content;
    gap: ${parseInt(columns) > 2 ? 2 : theme.spacing}rem;
    grid-template-columns: repeat(${Math.min(2, columns)}, 1fr);
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    max-width: ${theme.maxWidth / 1.2}px;
    margin: auto;
    grid-template-columns: repeat(${columns ? columns : 3}, 1fr);
  }
  `
);

export const Group = styled.div(
  ({ align, columns, theme, textOnly }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing}rem;
`
);

export const StyledText = styled.div(
  ({ textOnly, theme }) => `
  width: 100%;
  padding:0;
  text-align: left;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    width: 80%;
  }
  ${textOnly &&
    css`
      font-size: small;
      display: flex;
      flex-direction: row;
      justify-content: center;
      img {
        height: 2.5rem;
        margin-right: 0.5rem;
        margin-top: -1rem;
      }
    `}
`
);
