// @flow
import styled from "styled-components";

export const Container = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: ${theme.maxWidth}px;
  margin: auto;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    justify-content: flex-start;
  }
`
);

export const Card = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  box-shadow: ${theme.boxShadow};
  width: 100%;
  margin: ${theme.spacing * 2}rem;
  padding: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    width: calc(50% - ${theme.spacing * 4}rem);
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    max-width: calc(${theme.maxWidth / 3}px - ${theme.spacing * 4}rem);
  }
`
);

export const Name = styled.h2(
  ({ theme }) => `
  margin: ${theme.spacing / 2}rem 0 ;
`
);

export const Title = styled.p(
  ({ theme }) => `
  text-transform: uppercase;
`
);

export const IconLink = styled.a`
  color: #000;
  font-size: 20px;
`;
