import React from "react";
import { Container, WrapContent } from "./styles";
import Button from "Components/Button";
import RadioButtonGroup from "../RadioButtonGroup";
import CheckboxGroup from 'Components/CheckboxGroup';
import { interests } from "Helpers/Data";

function InterestsStep(props: { userInterests: any[], onChange: (values?: any) => *, selectedItem: boolean, values: [{ label: string, value: boolean }, { label: string, value: boolean }], selectedItem1: boolean, selectedItem2: string, values1: [{ label: string, value: string }, { label: string, value: string }, { label: string, value: string }], selectedItem3: string, interestResponse: any, onClick: () => * }) {
  return <Container>
    <h2>Finally, choose the opportunities that you’re interested in</h2>
    <WrapContent style={{marginTop: '2rem'}}>
      <CheckboxGroup
        selected={props.userInterests || []}
        values={interests}
        onChange={props.onChange}
        yellow
      />
      <h3 style={{marginTop: "2rem", marginBottom: "1rem"}}>
        What’s your current availability?
      </h3>
      <RadioButtonGroup
        selectedItem={props.selectedItem}
        values={props.values}
        setSelectedItem={props.selectedItem1}
        yellow
      />
      <h3 style={{marginTop: "2rem", marginBottom: "1rem"}}>
        Who can see your availability?
      </h3>
      <RadioButtonGroup
        selectedItem={props.selectedItem2}
        values={props.values1}
        setSelectedItem={props.selectedItem3}
        yellow
      />
    </WrapContent>
    {props.interestResponse?.loading ? "Submitting..." :
      <Button
        style={{marginTop: '2rem'}}
        onClick={props.onClick
        }
      >
        I'm Done
      </Button>
    }
  </Container>;
}

export default InterestsStep;