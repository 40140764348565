import moment from "moment";

export const formatAsDate = value =>
  value
    ? value
        .split("/")
        .reverse()
        .join("-")
    : null;

export const displayDate = value =>
  value
    ? value
        .split("-")
        .reverse()
        .join("/")
    : null;

export const formatDate = value => moment(value).format("DD MMMM YYYY");

export const isPast = value =>
  moment(value, "DD/MM/YYYY").isBefore(moment(new Date()));

export const timeAgo = value => moment(value).fromNow();
