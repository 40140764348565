// @flow
import React from "react";
import { Helmet } from "react-helmet";
import { useFetch } from "Hooks/useFetch";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { blocks } from "Helpers/Blocks";
import ContentError from "Components/ContentError";
import ContentLoading from "Components/ContentLoading";
import Link from "Components/Link";
import {
  Hero,
  ScrollButton,
  Container,
  Heading,
  Description,
  More,
  MoreTitle,
  List,
  Item,
  Image,
  Credits,
  Credit,
  StyledLink,
  AllLink
} from "./styles";

const SingleCollaboration = () => {
  const { slug } = useParams();
  let history = useHistory();
  const { data, error, loading } = useFetch({
    url: `/api/collaborations`
  });
  const collaboration = data?.collaborations?.find(item => item?.slug === slug);
  const others = data?.collaborations.filter(item => item?.slug !== slug);

  function visitOther(otherId) {
    history.push(`/gallery/${otherId}`);
  }

  if (loading) return <ContentLoading />;
  if (error) return <ContentError />;

  if (collaboration) {
    const { title, details } = collaboration;
    return (
      <>
        <Helmet>
          <title>ClickerMe | Community Gallery</title>
          <meta
            name="description"
            content="Featured collaborations from the ClickerMe community | Connect and collaborate on the fashion network"
          />
          <htmlAttributes h1="Featured Fashion Collaborations"></htmlAttributes>
        </Helmet>
        <Hero image={details?.cover_image}>
          <ScrollButton href="#main">
            <img src={require("Assets/Images/down-arrow-white.svg")} />
          </ScrollButton>
        </Hero>
        <Container id="main">
          <Heading>
            {details?.collaborators && (
              <span>
                {details.collaborators.map(person => person?.name).join(" × ")}
              </span>
            )}
          </Heading>
          <Description>{details?.description}</Description>
          {details?.content?.length && (
            <React.Fragment>
              {details.content.map((content, i) => {
                const Block = blocks[content.acf_fc_layout];
                if (Block) {
                  return <Block key={i} {...content} padded />;
                }
              })}
            </React.Fragment>
          )}
          {details?.credits?.length && (
            <Credits>
              {details.credits.map((credit, i) => (
                <Credit>
                  {credit?.role ? `${credit.role}: ` : ""}
                  <StyledLink key={i} href={credit?.url || "#"}>
                    {credit?.name}
                  </StyledLink>
                </Credit>
              ))}
            </Credits>
          )}
        </Container>
        {others && (
          <More>
            <MoreTitle>More Collaborations</MoreTitle>
            <List>
              {others?.slice(0, 3).map((item, index) => (
                <Item key={index} onClick={() => visitOther(item?.slug)}>
                  <Image image={item?.details?.cover_image?.url} />
                  {item?.title}
                </Item>
              ))}
            </List>
            <AllLink to="/gallery">View All</AllLink>
          </More>
        )}
      </>
    );
  } else return <div>Error</div>;
};

export default SingleCollaboration;
