import styled from "styled-components";

export const Wrapper = styled.div(
  ({ theme }) => `
  background: none;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    width: 50%;
    padding-right: ${theme.spacing}rem;
  }
`
);

export const SearchWrapper = styled.div(
  ({ theme, active }) => `
  width: 100%;
  height: 100%;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    display: ${active ? "flex" : "none"};
    position: absolute;
    top: ${theme.spacing * 3}rem;
    width: calc(100vw - ${theme.spacing * 2}rem);
    z-index: 999;
    min-height: ${theme.spacing * 5}rem;
    background: ${theme.colors.grey("light")};
    padding: ${theme.spacing}rem;
    border-top: 2px solid ${theme.colors.black};
    box-shadow: ${theme.shadow};
  }
`
);

export const SearchContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${theme.colors.black};
  border-bottom: 1px solid ${theme.colors.black};
  padding-right: ${theme.spacing}rem;
  width: 100%;
  height: 100%;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    background: ${theme.colors.white};
    border: none;
    border-radius: ${theme.spacing * 2}rem;
    padding: ${theme.spacing}rem;
  }
`
);

export const SearchInput = styled.input(
  ({ theme }) => `
  border: none;
  font-family: ${theme.fonts.default};
  padding: ${theme.spacing * 0.75}rem;
  outline: none;
  width: 100%;
  flex: 1;
  font-size: ${theme.fonts.size.normal};
  background: none;
  ::placeholder {
    color: ${theme.colors.black};
    opacity: 1;
  }
`
);

export const SearchButton = styled.div(
  ({ theme, active }) => `
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
  text-transform: uppercase;
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  border: 2px solid ${theme.colors.black};
  border-radius: ${theme.spacing * 2}rem;
  background: ${active ? theme.colors.yellow() : "none"};
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`
);

export const Icon = styled.img`
  width: 20px;
`;
