import styled from 'styled-components';
import IconButton from 'Components/IconButton';

export const Background = styled.div(
	({ theme }) => `
   background-color: #191c21;
   margin-bottom: ${theme.spacing * 3}rem;
`
);

export const SlashImage = styled.div(
	({ theme, src }) => `
  position: absolute;
  background-image: url(${src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  clip-path: polygon(36% 0%, 100% 0%, 64% 100%, 0% 100%);
  width: 550px;
  height: 450px;
    @media screen and (max-width: ${theme.breakpoints.medium}px) {
      position: absolute;
      background-size: initial;
      background-position: right;
      left: 0;
      padding-top: 25%;
      width: 300px;
      clip-path: polygon(0% 0%, 66% 0%, 42% 70%, 0% 70%);
  }
`
);

export const Wrap = styled.div(
	({ theme }) => `
   max-width: ${theme.maxWidth * 1.3}px;
   margin: auto;
   display: flex;
   justify-content: left;
   align-items: left;
   margin: left;
   height: 450px;
`
);

export const Content = styled.div(
	({ theme }) => `
   position: relative;
   margin: auto;
   justify-content: center;
   align-items: right;
     @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
      left: 0;
      padding: ${theme.spacing}rem;
      width: 100%;
      margin:auto;
  }
`
);

export const Description = styled.div(
	({ theme }) => `
  color: white;
  width:100%;
  font-size: ${theme.fonts.size.large};
  display: flex;
  align-items: right;
  justify-content: flex-end;
    p{
    max-width: 400px;
    text-align: right;
    font-weight: bold;
    right:0;
    align-self: flex-end;
      @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
        max-width: 200px;
        font-size: ${theme.fonts.size.medium};
      }
  }
  span{
     color: ${theme.colors.yellow()};
  }
`
);

export const Image = styled.div(
	({ theme, src }) => `
background-image: url(${src});
background-size: cover;
background-position: center center;
background-repeat: no-repeat no-repeat;
padding-top:21%;
width: 600px;
  @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
    padding-top:21%;
    height:auto;
    width: 100%;
 }
`
);

export const WrapTextButton = styled.div(
	({ theme, src }) => `
display: flex;
font-size: ${theme.fonts.size.medium};
color: ${theme.colors.yellow()};
margin:auto;
padding-top: ${theme.spacing * 2}rem;
font-weight: bold;
align-items: center;
justify-content: flex-end;
 @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
  align-items: center;
  justify-content: center;
  font-size: 20px;
 }
`
);
