import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)(
  ({ theme }) => `
  font-size: ${theme.fonts.size.small};
  color: ${theme.colors.black};
  text-decoration: none;
`
);
export const HeaderWrapper = styled.h2(
  ({ theme }) => `
  font-size: ${theme.fonts.size.normal};
  color: ${theme.colors.white};
  text-decoration: none;
  text-align:center;
  padding: ${theme.spacing * 2}rem;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    text-align:left;
    padding:0;
   }
   `
);
