// @flow
import styled from "styled-components";

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
`;

export const StyledModal = styled.div(
  props => `
  padding: ${props.theme.spacing * 2}rem;
  z-index: 1000;
  width: 50vw;
  max-width: ${props.maxWidth ? `${props.maxWidth}px` : "100%"};
  max-height: 80vh;
  overflow: scroll;
  background-color: ${
    props.black ? props.theme.colors.black : props.theme.colors.white
  };
  color: ${props.black ? props.theme.colors.white : props.theme.colors.black};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: ${props.theme.zIndex.front};
  @media screen and (max-width: ${props.theme.breakpoints.medium}px) {
    width: 80vw;
  }
  @media screen and (max-width: ${props.theme.breakpoints.xsmall}px) {
    padding: ${props.theme.spacing / 2}rem;
    min-width: 100%;
    min-height: 100%;
    z-index: 2001;
    justify-content: center;
    background-color: ${
      props.black ? props.theme.colors.black : `rgba(255,255,255, 0.95)`
    };
    color: ${props.black ? props.theme.colors.white : props.theme.colors.black};
  }
`
);

export const Icon = styled.div(
  ({ theme }) => `
  width: ${theme.spacing * 2}rem;
  height: ${theme.spacing * 2}rem;
  background-color: ${theme.colors.white};
  clip-path: polygon(50% 0%, 100% 0%, 50% 100%, 0% 100%);
  margin-bottom: ${theme.spacing}rem;
`
);
