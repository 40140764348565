// @flow
import styled from "styled-components";

export const StyledInput = styled.input(
	({ theme }) => `
  display: block;
  padding: ${theme.spacing / 2}rem
    ${theme.spacing * 0.75}rem;
  font-size: ${theme.fonts.size.normal};
  font-family: ${theme.fonts.default};
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  -webkit-box-shadow: none;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    font-size: ${theme.fonts.size.small};
  }
}
 
`
);

export const StyledImage = styled.img(
	({ theme }) => `
height:20px;
width: 20px;
`
);

export const AlignIcon = styled.div(
	({ theme }) => `
width: 100%;
display: flex;
background-color: white;
margin: auto;
align-items: center;
border: 1px solid ${theme.colors.grey()};
padding-left: 10px;
margin: ${theme.spacing / 4}rem 0;

`
);
