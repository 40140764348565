import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { StyledImage, AlignIcon } from './styles';
import './styles.css';

function escapeRegexCharacters(str) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestionValue(suggestion) {
	return suggestion;
}

function renderSuggestion(suggestion, { query }) {
	const matches = AutosuggestHighlightMatch(suggestion, query);
	const parts = AutosuggestHighlightParse(suggestion, matches);

	return (
		<span>
			{parts.map((part, index) => {
				const className = part.highlight
					? 'react-autosuggest__suggestion-match'
					: null;

				return (
					<span className={className} key={index}>
						{part.text}
					</span>
				);
			})}
		</span>
	);
}

function SearchAutoComplete({ placeholder, items, handleSuggestionSelected }) {
	const [value, setValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);

	function getSuggestions(value) {
		const escapedValue = escapeRegexCharacters(value.trim());

		if (escapedValue === '') {
			return [];
		}

		const regex = new RegExp('^' + escapedValue, 'i');
		const result = items.filter((item) => {
			const words = item.split(' ');
			return (
				regex.test(item) ||
				regex.test(words[0]) ||
				regex.test(words[1]) ||
				regex.test(words[2]) ||
				regex.test(words[3])
			);
		});
		return result.concat("Other");
	}

	const onSuggestionsFetchRequested = ({ value }) => {
		setSuggestions(getSuggestions(value));
	};

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const inputProps = {
		placeholder: placeholder,
		value,
		onChange: (event, { newValue, _ }) => {
			setValue(newValue);
		},
	};

	function onSuggestionSelected(event, { suggestion, method }) {
		handleSuggestionSelected(suggestion);
		setValue('');
	}

	return (
		<AlignIcon>
			<StyledImage src={require('Assets/Images/search.png')} />
			<Autosuggest
				suggestions={suggestions.slice(0, 15)}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				onSuggestionSelected={onSuggestionSelected}
				renderSuggestion={renderSuggestion}
				inputProps={inputProps}
			/>
		</AlignIcon>
	);
}

export default SearchAutoComplete;
