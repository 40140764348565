// @flow
import React, { useState } from "react";
import { type Project } from "Types";
import { useAuth } from "Hooks/useAuth";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { useClipboard } from "Hooks/useClipboard";
import IconButton from "Components/IconButton";
import Button from "Components/Button";
import Modal from "Components/Modal";
import Input from "Components/Input";
import {
  ButtonContainer,
  Toggle,
  Hidden,
  Slider,
  ToggleContainer,
  CopyContainer,
  Icon
} from "./styles";
import { FaCopy } from "react-icons/fa";

type Props = {
  project: Project,
  isApproved?: Boolean,
  removeProject?: Function,
  updateProject?: Function,
  setError?: Function
};

function ProjectButtons({
  project,
  isApproved,
  removeProject,
  updateProject,
  setError
}: Props) {
  const {
    auth: { user },
    refetchUser
  } = useAuth();
  const [deleting, toggleDeleting] = useState(false);
  const shareableLink = `${
    window.location.href.split("/profile")[0]
  }/projects/${project?.id}`;
  const [sharing, toggleSharing] = useState(false);
  const [copied, toggleCopied] = useState(false);
  const [publishModal, togglePublishModal] = useState(false);
  const [noCoverModal, toggleNoCoverModal] = useState(false);
  useClipboard(() => toggleCopied(true));

  const [deleteProject] = useLazyFetch({
    url: `/api/projects/${project.id}`,
    method: "delete"
  });

  const [updatePublished] = useLazyFetch({
    url: `/api/projects/${project.id}`,
    method: "put"
  });

  function onDelete() {
    const projectId = project.id;
    deleteProject(
      null,
      () => {
        removeProject(projectId);
        setError(null);
        toggleDeleting(false);
        refetchUser({
          ...user,
          projects: user?.projects.filter(project => project?.id !== projectId)
        });
      },
      onError
    );
  }

  function togglePublished(e) {
    e.persist();
    if (e.target.checked && !project.cover_image_url) {
      toggleNoCoverModal(true);
    } else if (e.target.checked && !project.has_images) {
      togglePublishModal(true);
    } else {
      updatePublished(
        {
          project: {
            title: project?.title,
            description: project?.description,
            is_published: e.target.checked,
            cover_image_id: project?.cover_image_id,
            cover_image_url: project?.cover_image_url
          }
        },
        res => {
          updateProject(res?.project);
          setError(null);
        },
        onError
      );
    }
  }

  function onError() {
    setError("Sorry, we could not update this project right now");
  }

  return (
    <ButtonContainer>
      <IconButton onClick={() => toggleDeleting(true)}>
        <Icon src={require("Assets/Images/trash.svg")} />
      </IconButton>
      {isApproved && (
        <IconButton onClick={() => toggleSharing(true)}>
          <Icon src={require("Assets/Images/share.svg")} />
        </IconButton>
      )}
      {sharing && (
        <Modal
          onClose={() => {
            toggleSharing(false);
            toggleCopied(false);
          }}
        >
          <CopyContainer>
            <h2>Share your project link</h2>
            <p>
              Copy and share the link to your project. Anyone with the link will
              be able to view your project.
            </p>
            <Input type="text" value={shareableLink} readonly id="share" />
            <Button data-clipboard data-clipboard-target="#share">
              <FaCopy size={20} /> {copied ? "Copied!" : "Copy"}
            </Button>
          </CopyContainer>
        </Modal>
      )}
      {isApproved && (
        <ToggleContainer checked={project?.published}>
          <Hidden type="checkbox" onChange={togglePublished} />
          <Toggle checked={project?.published}>
            <Slider />
          </Toggle>
          Published
        </ToggleContainer>
      )}
      {publishModal && (
        <Modal onClose={() => togglePublishModal(false)}>
          <h2>Before you publish...</h2>
          <span>
            Projects need to contain images before you publish them to your
            profile. It only takes a minute, just click the button below to add
            images to your project.
          </span>
          <ButtonContainer>
            <Button
              variant="black"
              size="small"
              onClick={() => togglePublishModal(false)}
            >
              Cancel
            </Button>
            <Button size="small" to={`/projects/${project?.id}/edit`}>
              Add images now
            </Button>
          </ButtonContainer>
        </Modal>
      )}
      {noCoverModal && (
        <Modal onClose={() => toggleNoCoverModal(false)}>
          <h2>Before you publish...</h2>
          <span>Don't forget to add a cover image for your project.</span>
          <Button size="small" to={`/projects/${project?.id}/edit`}>
            Add cover image now
          </Button>
        </Modal>
      )}
      {deleting && (
        <Modal onClose={() => toggleDeleting(false)}>
          Are you sure you want to delete this project?
          <Button onClick={onDelete}>Yes</Button>
        </Modal>
      )}
    </ButtonContainer>
  );
}

export default ProjectButtons;
