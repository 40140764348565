// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFetch } from 'Hooks/useFetch';
import { Helmet } from 'react-helmet';
import ContentError from 'Components/ContentError';
import ContentLoading from 'Components/ContentLoading';
import UserList from 'Components/UserList';

function Search() {
	const { search } = useLocation();
	const { data, loading, error } = useFetch({
		url: `/api/users${search}`,
	});
	const users = data?.users || [];

	if (loading) return <ContentLoading />;
	if (error) return <ContentError />;

	return (
		<React.Fragment>
			<Helmet>
				<title>Search</title>
			</Helmet>
			<UserList users={users} />
		</React.Fragment>
	);
}

export default Search;
