import React from "react";
import { Container, Description, FormWrapper, StepTitle } from "./styles";
import FormField from "Components/FormField";
import Button from "Components/Button";
import SearchAutoComplete from "../SearchAutoComplete";
import { RowContainer } from "../VerificationForms/styles";

function SecondarySpecialismsStep(props: {
  errors: any,
  secondarySpecialisms: any[],
  specialismTags: () => any,
  specialisms: any,
  handleSuggestionSelected: () => any,
  specialismResponse: any,
  onClick: () => any }) {
  return (
    <Container>
    <FormWrapper>
      <StepTitle>Next, let’s add your secondary specialisms</StepTitle>
      <Description>Add up to 2 secondary specialisms, or skip this section.</Description>
      <FormField errors={props.errors?.secondarySpecialism} style={{border: "solid 1px red"}}>
        {props.secondarySpecialisms.length < 2 &&
        <SearchAutoComplete
          placeholder={"Start typing a specialism (eg. Textile designer)…"}
          items={props.specialisms.map(s => s.friendly_name)}
          handleSuggestionSelected={props.handleSuggestionSelected}
        />
        }
        <RowContainer>
          {props.specialismTags(props.secondarySpecialisms, props.setSecondarySpecialisms)}
        </RowContainer>
      </FormField>
      {props.specialismResponse?.loading ? "Submitting..." :
        <Button
          onClick={props.onClick}
        >
          {props.secondarySpecialisms.length < 1 ? "Skip" : "Next"}
        </Button>
      }
    </FormWrapper>
  </Container>
  );
}

export default SecondarySpecialismsStep;
