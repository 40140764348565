// @flow
import React, { useEffect, useState } from 'react';
import Feedback from 'Components/Blocks/Feedback';
import ActionCreator from 'Actions';

import {
	AddButton,
	ProjectIcon,
	Container,
	ProjectsContainer,
	Rectangle,
	Cover,
	ProjectItem,
	Progress,
	Description,
	ProjectTitle,
	ProjectHeader,
	ButtonLabel,
	LinkWrapper,
	StyledButton,
	StepsContainer,
} from './styles';
import { editIcon, plusIcon } from '../../Helpers/Icons';
import theme from 'Styles/Theme';

import Notification from 'Components/Notification';
import { useLazyFetch } from 'Hooks/useLazyFetch';
import { useAuth } from 'Hooks/useAuth';

import { AvatarContainer } from '../ProfileOverview/styles';

import { FILESTACK_API_KEY } from 'Config';
import ReactFilestack from 'filestack-react';
import Avatar from 'Components/Avatar';
import IconButton from 'Components/IconButton';
import SectionHeader from 'Components/SectionHeader';
import Step from 'Components/Step';
import { useHistory } from 'react-router-dom';
import { Placeholder } from '../AboutTab/styles';
import BioForm from '../VerificationForms/BioForm';
import { FaLock } from 'react-icons/fa';
import EducationForm from '../VerificationForms/EducationForm';
import ExperienceForm from '../VerificationForms/ExperienceForm';
import SkillsForm from '../VerificationForms/SkillsForm';
import {Actions, SaveButton} from "../VerificationForms/styles";
import {connect} from "react-redux";

const LargePlaceholder = () => (
	<React.Fragment>
		<Placeholder />
		<Placeholder />
		<Placeholder half />
	</React.Fragment>
);

const SmallPlaceholders = () => (
	<React.Fragment>
		<Placeholder half />
		<Placeholder half />
		<Placeholder half />
		<Placeholder half />
		<Placeholder half />
		<Placeholder half />
	</React.Fragment>
);

const icons = {
	edit: <img src={editIcon} />,
	plus: <img src={plusIcon} />,
};

type Props = {
	user: Object,
	trackSubmittedProfile: Function,
};

const VerificationSteps = ({ user, trackSubmittedProfile }: Props) => {
	const { auth, refetchUser } = useAuth();
	const [error, setError] = useState(null);
	const [data, setData] = useState(user);
	const [isDone, setIsDone] = useState({
		step1: !!user?.profile?.photo,
		step2: !!user?.profile?.about_me,
		step3: user?.projects?.length > 0 && anyValidProject(),
		step4: user?.education?.length > 0,
		step5: user?.experience?.length > 0,
		step6: user?.skills?.length > 0,
	});
	const [isOpen, setIsOpen] = useState({
		step1: false,
		step2: false,
		step3: false,
		step4: false,
		step5: false,
		step6: false,
	});

	const [width, setWidth] = React.useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', () => setWidth(window.innerWidth));
	}, []);


	const [submitProfile] = useLazyFetch({
		url: '/api/me/profile',
		method: 'put',
	});

	let history = useHistory();

	const [submitProject] = useLazyFetch({
		url: '/api/projects',
		method: 'post',
	});

	function anyValidProject() {
		return user.projects.map(p => p.has_images).includes(true);
	}

	function addNewProject() {
		submitProject(
			{
				project: {
					title: '',
					description: '',
				},
			},
			onSuccessProject,
			onErrorProject
		);
	}

	function onSuccessProject(res) {
		if (res?.project?.id) {
			setError(null);
			history.push(`/projects/${res.project.id}/edit?redirect=/verification`);
		}
	}

	function handleProjectClick(project) {
		history.push(`/projects/${project.id}/edit?redirect=/verification`);
	}

	function onErrorProject() {
		setError('Sorry, we could could create a new project right now');
	}

	function setProfile(key, value) {
		setData({ ...data, profile: { ...data.profile, [key]: value } });
	}

	function onError() {
		setError('Sorry, we could not submit your profile for approval right now');
	}

	function progress(user) {
		let progressValue = 3;
		if (user?.projects?.length > 0 && anyValidProject() || isDone.step3) progressValue += 99;
		return progressValue;
	}

	function submitDisabled(user) {
		return progress(user) < 100;
	}

	const [submitForApproval, response] = useLazyFetch({
		url: '/api/me/submit_for_approval',
		method: 'post',
	});

	function submit(e) {
		submitForApproval({}, onSuccess, onError);
	}

	function onSuccess({ user }) {
		trackSubmittedProfile();
		setError(null);
		refetchUser(user);
	}

	return (
		<>
			<Container background_color={'#f8f7f7'}>
				{error && <Notification>{error}</Notification>}
				<SectionHeader text={'Get verified'} />
				<Description>
					Quickly update your profile for full member features.
				</Description>
				<StepsContainer>
					<Step
						text={'CREATE YOUR FIRST PROJECT'}
						description={
							'Projects are the basis of your portfolio. Ensure you include your most recent eye-catching work that showcases your skills, style, and professionalism.'
						}
						isDone={isDone.step3}
						isOpen={isOpen.step3}
						setIsOpen={value => setIsOpen({ ...isOpen, step3: value })}
					>
						<ProjectsContainer>
							<AddButton onClick={addNewProject}>
								<IconButton yellow>
									<img src={plusIcon} />
								</IconButton>
								<ButtonLabel>Add a new project</ButtonLabel>
							</AddButton>
							{user?.projects?.map((project) => {
								return (
									<ProjectItem key={project.id}>
										<LinkWrapper
											to={`/projects/${project.id}/edit?redirect=/verification`}
										>
											<ProjectHeader>
												<ProjectTitle>{project?.title}</ProjectTitle>
												<ProjectIcon src={editIcon} />
											</ProjectHeader>
											{project?.cover_image_url ? (
												<Cover src={project?.cover_image_url} />
											) : (
												<Cover
													src={require('Assets/Images/default-cover-image.svg')}
												/>
											)}
										</LinkWrapper>
									</ProjectItem>
								);
							})}
						</ProjectsContainer>
					</Step>
				</StepsContainer>

				<StyledButton
					size={'big'}
					disabled={submitDisabled(user)}
					onClick={submit}
				>
					{submitDisabled(user) && (
						<FaLock
							style={{
								paddingBottom: '0.15rem',
								marginRight: '.7rem',
								height: '1rem',
							}}
						/>
					)}
					Submit for approval
				</StyledButton>

				<SectionHeader text={'Increase your reach'} />

				<Description>
					Enhance your profile by adding additional information.
				</Description>

				<StepsContainer>
					<Step
						text={'ADD A PROFILE PICTURE'}
						description={
							'Put a face to the name. A professional photo will increase engagement across the platform, so make sure yours will be noticed.'
						}
						isDone={isDone.step1}
						isOpen={isOpen.step1}
						setIsOpen={value => setIsOpen({ ...isOpen, step1: value })}
					>
						<div style={{ maxWidth: '190px'}}>
							<AvatarContainer>
								<Avatar size="xxlarge" src={data?.profile?.photo} style={{marginLeft: "1rem"}}/>
								<ReactFilestack
									apikey={FILESTACK_API_KEY}
									customRender={({ onPick }) => (
										<IconButton
											yellow
											style={{ position: 'absolute', top: '10px', zIndex: 5 }}
											onClick={onPick}
										>
											<img src={data?.profile?.photo ? editIcon : plusIcon} />
										</IconButton>
									)}
									onSuccess={(res) => {
										if (
											res?.filesUploaded[0]?.url &&
											!res?.filesFailed.length
										) {
											setError(null);
											setProfile('photo', res.filesUploaded[0].url);
											setIsDone({ ...isDone, step1: true });
											submitProfile(
												{
													...data?.profile,
													photo: res.filesUploaded[0].url,
												},
												() => window.location.reload
											);
										} else {
											setError(
												'Sorry, we could not update your profile photo right now'
											);
										}
									}}
									onError={(error) => setError(error)}
								/>
								<Actions style={{marginTop: "1rem"}}>
									<SaveButton
										disabled={!data?.profile?.photo}
										size="small"
										onClick={() => {
											setIsOpen({ ...isOpen, step1: false })
										}}
									>
										<span>Save to profile</span>
									</SaveButton>
								</Actions>
							</AvatarContainer>
						</div>
					</Step>
					<Step
						text={'ADD A SHORT BIO'}
						description={''}
						isDone={isDone.step2}
						isOpen={isOpen.step2}
						setIsOpen={value => setIsOpen({ ...isOpen, step2: value })}
					>
						<BioForm
							data={data}
							setData={setData}
							checkIfDone={() =>
								setIsDone({ ...isDone, step2: data?.profile?.about_me })
							}
							setError={setError}
							submitProfile={submitProfile}
							LargePlaceholder={LargePlaceholder}
							icons={icons}
							setIsOpen={value => setIsOpen({ ...isOpen, step2: value })}
						/>
					</Step>
					<Step
						text={'ADD RELEVANT EDUCATION'}
						description={
							'Help add context to your profile by telling people where you’ve studied. Keep it relevant to your career to make your profile focused.'
						}
						isDone={isDone.step4}
						checkIfDone={() =>
							setIsDone({ ...isDone, step4: data?.education?.length > 0 })
						}
						isOpen={isOpen.step4}
						setIsOpen={value => setIsOpen({ ...isOpen, step4: value })}
					>
						<EducationForm
							data={data}
							setData={setData}
							LargePlaceholder={LargePlaceholder}
							setError={setError}
						/>
					</Step>
					<Step
						text={'ADD RELEVANT EXPERIENCE'}
						description={
							'Highlighting any relevant, professional experience you’re proud of helps your profile stand out.'
						}
						isDone={isDone.step5}
						checkIfDone={() =>
							setIsDone({ ...isDone, step5: data?.experience?.length > 0 })
						}
						isOpen={isOpen.step5}
						setIsOpen={value => setIsOpen({ ...isOpen, step5: value })}
					>
						<ExperienceForm
							data={data}
							setData={setData}
							LargePlaceholder={LargePlaceholder}
							setError={setError}
						/>
					</Step>
					<Step
						text={'ADD YOUR SKILLS'}
						description={
							'Add as many relevant skills as you need to help the right collaborators find you.'
						}
						isDone={isDone.step6}
						checkIfDone={() =>
							setIsDone({ ...isDone, step6: data?.skills?.length > 0 })
						}
						isOpen={isOpen.step6}
						setIsOpen={value => setIsOpen({ ...isOpen, step6: value })}
					>
						<SkillsForm
							data={data}
							setData={setData}
							SmallPlaceholders={SmallPlaceholders}
							setError={setError}
							icons={icons}
							checkIfDone={() =>
								setIsDone({ ...isDone, step6: data?.skills?.length > 0 })
							}
							setIsOpen={value => setIsOpen({ ...isOpen, step6: value })}
						/>
					</Step>
				</StepsContainer>
				{width < theme.breakpoints.medium && (
					<StyledButton
						size={'big'}
						disabled={submitDisabled(user)}
						onClick={submit}
					>
						{submitDisabled(user) && (
							<FaLock
								style={{
									paddingBottom: '0.15rem',
									marginRight: '.7rem',
									height: '1rem',
								}}
							/>
						)}
						Submit for approval
					</StyledButton>
				)}
			</Container>

			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
		</>
	);
}

const mapDispatchToProps = (dispatch) => ({
	trackSubmittedProfile() {
		dispatch(ActionCreator.trackSubmittedProfile());
	},
});

export default connect(null, mapDispatchToProps)(VerificationSteps);
