// @flow
import React, { useState } from "react";
import { useValidation, useTextInput } from "Hooks";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { useLocation } from "react-router-dom";
import Input from "Components/Input";
import Card from "Components/Card";
import Button from "Components/Button";
import FormField from "Components/FormField";
import Notification from "Components/Notification";
import { Actions } from "./styles";

const ResetPasswordForm = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { search } = useLocation();
  const token = search.replace("?reset_password_token=", "");
  const password = useTextInput();
  const confirmPassword = useTextInput();
  const [validate, errors] = useValidation(
    { password: password.value, confirmPassword: confirmPassword.value },
    {
      password: {
        presence: {
          allowEmpty: false
        }
      },
      confirmPassword: {
        equality: {
          attribute: "password",
          message: "^Passwords do not match"
        },
        presence: {
          allowEmpty: false
        }
      }
    }
  );

  const [submit, response] = useLazyFetch({
    url: "/api/users/password_reset",
    method: "put"
  });

  function request(e) {
    e.preventDefault();
    const valid = validate();
    if (valid) {
      submit(
        {
          user: {
            password: password.value,
            password_confirmation: confirmPassword.value,
            reset_password_token: token
          }
        },
        onSuccess,
        onError
      );
    }
  }

  function onSuccess() {
    setSuccess(true);
    setError(null);
  }

  function onError() {
    setError("Sorry, we could not update your password");
  }

  return (
    <>
      {error && <Notification>{error}</Notification>}
      <h1>Update Password</h1>
      {success ? (
        <p>Thanks, your password has been successfully updated!</p>
      ) : (
        <React.Fragment>
          <p>
            Enter a new password. Please use a combination of letters, numbers
            and symbols.
          </p>
          <form onSubmit={request}>
            <FormField errors={errors?.password}>
              <Input type="password" placeholder="Password" {...password} />
            </FormField>
            <FormField errors={errors?.confirmPassword}>
              <Input
                type="password"
                placeholder="Confirm password"
                {...confirmPassword}
              />
            </FormField>
            <Actions>
              <Button type="submit">
                {response?.loading ? "Submitting" : "Submit"}
              </Button>
            </Actions>
          </form>
        </React.Fragment>
      )}
    </>
  );
};

export default ResetPasswordForm;
