// @flow
import { compose, applyMiddleware } from "redux";

import Analytics from "Store/Middleware/Analytics";
import Router from "Store/Middleware/Router";
import Buffer from "Store/Middleware/Buffer";
import Logger from "Store/Middleware/Logger";
import Saga from "Store/Middleware/Saga";

// === Middleware
const middlewares = [Analytics, Buffer, Router, Saga];

if (process.env.NODE_ENV === "development") {
  middlewares.push(Logger);
}

// === Enhancers
const enhancers = [];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const middleware = compose(applyMiddleware(...middlewares), ...enhancers);

export default middleware;
