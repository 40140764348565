// @flow
import styled, { css } from "styled-components";
import Text from "Components/Text";
import { positioning } from "Helpers/Positioning";

export const Wrapper = styled.div(
  ({ background_color }) =>
    background_color &&
    css`
      background-color: ${background_color};
    `
);

export const Container = styled.div(
  props => `
  display: flex;
  z-index: ${props.theme.zIndex.front};
  max-width: ${props.theme.maxWidth}px;
  margin: auto;
  background-color: ${props.background_color || props.theme.colors.white};
  align-items: center;
  flex-direction: ${positioning(props, "row", "row-reverse")}
  width: 100%;
  padding: ${props.theme.spacing * 2}rem ${props.theme.spacing}rem;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
   text-align: left;
   padding-bottom: ${props.theme.spacing}rem;
  }
`
);

export const Line = styled.div`
  width: 100%;
  border: 1px solid #dadada;
  margin: ${props =>
    positioning(
      props,
      `0 0 0 ${props.theme.spacing * 2.5}rem`,
      `0 ${props.theme.spacing * 2.5}rem 0 0`
    )};

  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    display: none;
  }
`;

export const StyledText = styled(Text)`
  font-size: ${props => props.theme.fonts.size.large};
  font-weight: bold;
  flex-shrink: 0;
  color: ${props =>
    props.text_color ? props.text_color : props.theme.colors.black};
  max-width: 100%;
  word-wrap: break-word;
  line-height: 1.25;
`;
