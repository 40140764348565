// @flow
import React from 'react';
import { Container, HiddenCheckbox, StyledCheckbox } from './styles';
import { MdDone } from 'react-icons/md';

type Props = {
	checked?: boolean,
	label: string,
	updateSelectedItem?: Function,
	halfWidth?: boolean,
	yellow?: boolean,
};

function RadioButton({
	checked,
	label,
	updateSelectedItem,
	halfWidth,
	yellow,
}: Props) {
	return (
		<Container
			onChange={(event) => {
				if (updateSelectedItem) {
					updateSelectedItem(event.target.checked);
				}
			}}
		>
			<HiddenCheckbox type="radio" type="radio" name="radio" />
			<StyledCheckbox checked={checked} yellow={yellow} label={label}>
				<span class="checkmark"></span>
			</StyledCheckbox>
			{label}
		</Container>
	);
}

export default RadioButton;
