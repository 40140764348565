// @flow
import React from "react";
import { StyledButton } from "./styles";

type Props = {
  children: React.Node,
  onClick: Function,
  yellow?: boolean,
  style?: Object
};

const IconButton = ({
  children,
  onClick,
  yellow,
  style
}: Props): React.Node => (
  <StyledButton style={style} onClick={onClick} yellow={yellow}>
    {children}
  </StyledButton>
);

export default IconButton;
