// @flow
import styled from 'styled-components';

export const StyledButton = styled.button(
	({ theme, yellow }) => `
  background-color: ${yellow ? theme.colors.yellow() : 'transparent'};
  border: none;
  padding: 0;
  top: 0;
  right: 0;
  margin: ${theme.spacing / 4}rem;
  width: ${theme.spacing * 2}rem;
  height: ${theme.spacing * 2}rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  &:hover {
    cursor: pointer;
  }
`
);
