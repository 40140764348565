// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const base = css(
  ({ theme }) => `
  background-color: transparent;
  font-family: ${theme.fonts.default};
  text-decoration: none;
  text-transform: uppercase;
  color: ${theme.colors.black};
  padding: ${theme.spacing * 2}rem 0;
  display: flex;
  align-items: center;
`
);

export const StyledLink = styled(Link)`
  ${base}
`;
