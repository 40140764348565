import styled from "styled-components";

export const VideoContainer = styled.div(
  ({ theme }) => `
   @media screen and (min-width: ${theme.breakpoints.medium}px) {
    height:95vh;
    margin:auto;
  }
  height:40vh;
`
);
export const Wrapper = styled.div(
  ({ theme }) => `
   background-color:white;
`
);

export const SignUpContainer = styled.div(
  ({ theme }) => `
width: 100%;
background: ${theme.colors.grey("landing")}
padding: ${theme.spacing * 2}rem ${theme.spacing}rem;
@media screen and (min-width: ${theme.breakpoints.small}px) {
 margin:auto;
 width: 100%;
}`
);

export const SignUpWrapper = styled.div(
  ({ theme }) => `
width: 100%;
background: ${theme.colors.grey("landing")}
padding: ${theme.spacing * 2}rem ${theme.spacing}rem;
@media screen and (min-width: ${theme.breakpoints.small}px) {
 margin:auto;
 width: 420px;
}`
);

export const FAQText = styled.div(
  ({ theme }) => `
   max-width:${theme.maxWidth}px;
   display:flex;
   flex-diretion: column;
   margin:auto;
   padding-bottom:${theme.spacing * 2}rem;
   p{
     padding:${theme.spacing}rem;
     max-width:95%;
   }
   @media screen and (max-width: ${theme.breakpoints.small}px) {
    display:block;
    p{
    padding-bottom:0;
    }
   }
`
);

export const Banner = styled.div(
  ({ theme }) => `
  width:100%;
  margin:auto;
  align-items:center;
  justify-content:center;
  min-height:40vh;
  display:flex;
  flex-direction:row;
  background-color:#181818;
  img{
    max-width:25vw;
    max-height:auto;
  }
  p{
    padding:${theme.spacing * 4}rem;
    color:white;
    max-width:590px;
    font-weight:500;
    font-size: ${theme.fonts.size.large};
    word-break:normal;
  }
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    flex-direction:column;
    height:auto;
    min-height:40vh;
    padding:${theme.spacing}rem;
    p{
      font-size:${theme.fonts.size.medium};
      width:100%;
      padding:0;
      text-align:center;
    }
    img{
      min-width:90%;
    }
   }
`
);
