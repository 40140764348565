// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";
import { positioning } from "Helpers/Positioning";
import Button from "Components/Button";

export const Wrapper = styled.div(
  ({ background_color, theme }) => `
  position: relative;
  background-color: ${background_color};
  margin-bottom: ${theme.spacing * 3}rem;
`
);

export const Badge = styled.div(
  ({ background_colour, theme }) => `
  position: absolute;
  top: 0;
  left: 0;
  color: ${background_colour !== "white" ? "white" : "black"};
  background-color: ${
    background_colour === "red" ? "#EE3B2B" : background_colour
  };
  width: 180px;
  height: 90px;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${theme.fonts.size.xsmall};
  word-break: normal;
  transform-origin: bottom left;
  transform: translateY(37px) rotate(-45deg);
`
);

export const BadgeLeft = styled.div`
  width: 50%;
  height: 100%;
  shape-outside: polygon(100% 0, 0 0, 0 100%);
  float: left;
`;

export const BadgeRight = styled.div`
  width: 50%;
  height: 100%;
  shape-outside: polygon(100% 0, 0 0, 100% 100%);
  float: right;
`;

export const Container = styled.div(
  ({ theme, background_color, text_color }) => `
  color: ${
    text_color
      ? text_color
      : background_color === "black"
      ? theme.colors.white
      : theme.colors.black
  };
  padding: 0;
  @media screen and (min-width: ${theme.breakpoints.xsmall}px) {
    width: 100%;
    max-width: ${theme.maxWidth}px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding-top: 1rem;
  }

`
);

export const Image = styled.img(
  props => `
  width: 100%;
  max-height: 375px;
  object-fit: cover;
  justify-self: ${positioning(props, "flex-end", "flex-start")};
  padding-left: ${props.theme.spacing}rem;
  padding-right: ${props.theme.spacing}rem;
  @media screen and (min-width: ${props.theme.breakpoints.small}px) {
    grid-column: ${positioning(props, "1", "2")};
    padding-left: ${positioning(props, `${props.theme.spacing}rem`, 0)};
    padding-right: ${positioning(props, 0, `${props.theme.spacing}rem`)};
  }
`
);

export const Details = styled.div(
  props => `
  grid-row: 1;
  grid-column: 1;
  padding: ${props.theme.spacing}rem;
  text-align: left;
  height: 100%;
  @media screen and (min-width: ${props.theme.breakpoints.small}px) {
    grid-column: ${positioning(props, "2", "1")};
    text-align: left;
    padding: ${
      props.badge ? props.theme.spacing * 3.5 : props.theme.spacing
    }rem;
    padding-right: ${props.theme.spacing / 2}rem;
    padding-top: 0rem;
  }
  `
);

export const Action = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    justify-content: flex-start;
  }
`
);

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const Lists = styled.div`
  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(2, 1fr);
`;

export const List = styled.ul`
  text-align: left;
`;

export const StyledButton = styled(Button)(
  ({ theme, button }) => `
  background-color: ${
    button.color === "#FFF200" ? button.color : "transparent"
  };
  border: 2px solid ${button.color};
  span {
    a {
      color: ${button.color !== "#FFF200" ? button.color : "black"};
    }
  }
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin-left: 0;
  }
`
);
