// @flow
import React from "react";
import parse from "html-react-parser";
import { Background, TextContainer, Text } from "./styles";

type Props = {
  image: Object,
  text: String,
  text_alignment: String
};

const Banner = ({ image, text, text_alignment }: Props): React.Node => {
  return (
    <Background image={image}>
      <TextContainer text_alignment={text_alignment}>
        <Text>{parse(text)}</Text>
      </TextContainer>
    </Background>
  );
};

export default Banner;
