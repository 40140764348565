import styled from "styled-components";
import Link from "Components/Link";

export const Body = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidth}px;
  padding: ${theme.spacing * 2}rem;
  margin: auto;
`
);

export const Image = styled.div(
  ({ src, theme }) => `
  background-image: url(${src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  width: 100%;
  height: 280px;
  margin-bottom: ${theme.spacing}rem;
`
);

export const Date = styled.p(
  ({ theme }) => `
  color: ${theme.colors.grey("dark")};
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  margin-top: 0;
`
);

export const Footer = styled.div(
  ({ theme }) => `
  width: 100%;
  background-color: ${theme.colors.grey()};
  padding: ${theme.spacing * 2}rem;
  display: flex;
`
);

export const Container = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidth * 0.75}px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
   flex-direction: column;
   align-items: center;
  }
`
);

export const Heading = styled.h2(
  ({ theme }) => `
  margin: ${theme.spacing}rem;
  @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
    text-align: center;
   }
`
);

export const StyledLink = styled(Link)`
  font-weight: bold;
`;
