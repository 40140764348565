export const formatSrc = (src, height) => {
  if (src) {
    const source = src.split("/");
    const id = source.pop();
    source.shift();
    return `https://${source.join("")}/${
      height ? `resize=height:${height},fit:max` : ""
    }/${id}`;
  } else return null;
};
