// @flow
import styled from "styled-components";
import Input from "Components/Input";
import Button from "Components/Button";
import TextBox from "Components/TextBox";

export const Wrapper = styled.form(
  ({ theme }) => `
  background: ${theme.colors.white};
  padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
  box-shadow: ${theme.shadow};
  margin-bottom: ${theme.spacing / 2}rem;
  padding-bottom: ${theme.spacing * 1.1}rem;
`
);

export const Container = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
`
);

export const StyledInput = styled(TextBox)(
  ({ theme }) => `
  border: none;
  outline: none;

  @media screen and (max-width: ${theme.breakpoints.small}px) {
    font-size: ${theme.fonts.size.small}
  }
`
);

export const StyledButton = styled(Button)`
  min-width: 110px;
  margin: 0;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;

export const StyledIcon = styled.button(
  ({ theme, visible }) => `
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    padding: 10px;
    visibility: ${visible ? "visible" : "hidden"};
    background-color: ${theme.colors.yellow()};
    border: none;
  }
`
);

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;
