import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)(
  ({ theme }) => `
  font-size: ${theme.fonts.size.small};
  color: ${theme.colors.black};
  text-decoration: none;
`
);
