import React from "react";
import IconButton from "Components/IconButton";
import { useEffect } from "react";
import {
  Wrapper,
  Container,
  Image,
  Content,
  StyledButton,
  WrapImages,
  ButtonAndTitle,
  CenterContent
} from "./styles";
import theme from "Styles/Theme";
import {circleArrowIcon, blackArrowIcon, topIcon} from "Helpers/Icons";

type Props = {
  image: String,
  text: String,
  title: String,
  layout: String,
  description: String,
  button: String,
  linkButton: String,
  badge: Boolean,
  arrow: Boolean
};

const AffiliateSingle = ({
  cards,
  sliceStart,
  sliceEnd,
  layout
}: Props): React.Node => {
  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <Wrapper>
      {width > theme.breakpoints.xsmall ? (
        <Container layout={layout}>
          {cards?.map((card, i) => (
            <Contents key={`card-${i}`} card={card} layout={layout} />
          ))}
        </Container>
      ) : (
        <Container>
          {cards?.slice(sliceStart, sliceEnd).map((card, i) => (
            <Contents key={`card-${i}`} card={card} layout={layout} />
          ))}
        </Container>
      )}
    </Wrapper>
  );
};

const Contents = ({ card, layout }) => {
  const { image, badge, title, description, button, arrow } = card;
  return (
    <a href={button.link} style={{ textDecoration: "none", color: "black" }}>
      <Content layout={layout}>
        <WrapImages>
          <Image src={image}></Image>
          {badge && <img src={topIcon}></img>}
        </WrapImages>
        {description !== "" ? (
          <CenterContent>
            <h4>{title}</h4>
            <p>{description}</p>
            <StyledButton to={button?.link} target="_blank">
              <p>{button.text}</p>
              {arrow && (
                <img src={blackArrowIcon}></img>
              )}
            </StyledButton>
          </CenterContent>
        ) : (
          <ButtonAndTitle>
            <h4 style={{ textDecoration: "none" }}>{title}</h4>
            <IconButton>
              <img src={circleArrowIcon}/>
            </IconButton>
          </ButtonAndTitle>
        )}
      </Content>
    </a>
  );
};

export default AffiliateSingle;
