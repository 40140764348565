// @flow
import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding-bottom: ${props => props.theme.spacing}rem;
  animation: ${fadeIn} 1s linear;
`;

export const Title = styled.p`
  font-weight: bold;
  line-height: 1.6;
  margin: 0;
`;

export const Line = styled.p`
  line-height: 1.6;
  margin: 0;
`;
