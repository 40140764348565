import React from "react";
import { useAuth } from "Hooks/useAuth";
import { API_URL } from "Config";

export const useFetch = ({ url, method = "get", variables }) => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { auth } = useAuth();

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(API_URL + url, {
          method,
          body: JSON.stringify(variables),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${auth.access_token}`
          }
        });
        const data = await res.json();
        setData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [auth, method, url, variables]);

  return { data, error, loading, setData };
};
