// @flow
import React, { useState } from "react";
import { Experience } from "Types";
import { formatAsDate, displayDate } from "Helpers/Date";
import {
  Container,
  InputRow,
  ItemContainer,
  ButtonContainer,
  Actions
} from "./styles";
import FormField from "Components/FormField";
import Input from "Components/Input";
import Button from "Components/Button";
import IconButton from "Components/IconButton";
import ExperienceItem from "Components/ExperienceItem";
import TextBox from "Components/TextBox";

type Props = {
  item: Experience,
  updateItem: Function,
  deleteItem: Function,
  submit: Function
};

function ExperienceSingle({ item, updateItem, deleteItem, submit }: Props) {
  const [editing, toggleEditing] = useState(false);

  function setValue(key, value) {
    updateItem({ ...item, [key]: value });
  }

  function onSubmit(e) {
    e.preventDefault();
    submit();
    toggleEditing(false);
  }

  return (
    <React.Fragment>
      {editing ? (
        <Container>
          <form onSubmit={onSubmit}>
            <InputRow>
              <FormField label="Company">
                <Input
                  value={item.company}
                  onChange={e => setValue("company", e.target.value)}
                />
              </FormField>
              <FormField label="Job title">
                <Input
                  value={item.title}
                  onChange={e => setValue("title", e.target.value)}
                />
              </FormField>
            </InputRow>
            <FormField label="Location">
              <Input
                value={item.location}
                onChange={e => setValue("location", e.target.value)}
              />
            </FormField>
            <InputRow>
              <FormField label="Start date">
                <Input
                  type="month"
                  value={formatAsDate(item?.startDate)}
                  onChange={e =>
                    setValue("startDate", displayDate(e.target.value))
                  }
                />
              </FormField>
              <FormField label="End date">
                <Input
                  type="month"
                  value={formatAsDate(item?.endDate)}
                  onChange={e =>
                    setValue("endDate", displayDate(e.target.value))
                  }
                />
              </FormField>
            </InputRow>
            <FormField label="Desciption">
              <TextBox
                value={item.description}
                onChange={e => setValue("description", e.target.value)}
              />
            </FormField>
            <Actions>
              <Button size="small" padded type="submit">
                Save
              </Button>
              <Button
                size="small"
                variant="black"
                padded
                onClick={() => toggleEditing(false)}
              >
                Cancel
              </Button>
            </Actions>
          </form>
        </Container>
      ) : (
        <ItemContainer>
          <ExperienceItem item={item} />
          <ButtonContainer>
            <IconButton onClick={() => toggleEditing(true)}>
              <img src={require("Assets/Images/edit.svg")} />
            </IconButton>
            <IconButton onClick={deleteItem}>
              <img src={require("Assets/Images/trash.svg")} />
            </IconButton>
          </ButtonContainer>
        </ItemContainer>
      )}
    </React.Fragment>
  );
}

export default ExperienceSingle;
