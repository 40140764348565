// @flow
import React from 'react';
import IconButton from 'Components/IconButton';
import {
	Background,
	SlashImage,
	Wrap,
	Content,
	Description,
	Image,
	WrapTextButton,
	StyledButton,
} from './styles';

function Masterclass({ id }) {
	const Link =
		'https://shareasale.com/r.cfm?b=1418888&u=2340400&m=62509&urllink=&afftrack=';
	const backgroundSlash = require('Assets/Images/annawintour.png');
	const banner = require('Assets/Images/masterclass-affiliate-banner.png');
	const icon = require('Assets/Images/group-4.svg');

	return (
		<Background id={`${id}-background`}>
			<a
				target="_blank"
				style={{ textDecoration: 'none' }}
				href={Link}
				id={`${id}-a`}
			>
				<Wrap id={`${id}-wrap`}>
					<SlashImage src={backgroundSlash} id={`${id}-slash-image`} />
					<Content id={`${id}-content`}>
						<Description id={`${id}-description`}>
							<p id={`${id}-p`}>
								<span id={`${id}-span`}>Anna Wintour</span> Teaches Creativity
								and Leadership
							</p>
						</Description>
						<Image src={banner} id={`${id}-banner`} />
						<WrapTextButton id={`${id}-wrap-button`}>
							Learn from an icon
							<IconButton
								style={{ marginLeft: '20px', width: '50px', height: '50px' }}
								id={`${id}-button`}
							>
								<img src={icon} id={`${id}-icon`} />
							</IconButton>
						</WrapTextButton>
					</Content>
				</Wrap>
			</a>
		</Background>
	);
}
export default Masterclass;
