import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  max-width: ${theme.maxWidth}px;
  margin: ${theme.spacing * 4}rem auto 0;
  align-items: flex-start;

  @media screen and (min-width: ${theme.breakpoints.small}px) {
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: ${theme.spacing * 4}rem;
  }
`
);

export const Heading = styled.h2`
  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
  }
`;

export const Image = styled.img(
  ({ theme }) => `
  width: 100%;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    max-width: 390px;
  }
`
);

export const Details = styled.div(
  ({ theme }) => `
  padding: 0 ${theme.spacing * 2}rem;
  flex: 0.6;
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    padding:  0 ${theme.spacing * 2}rem;
    flex: 1;
  }
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    padding: ${theme.spacing * 2}rem 0;
    flex: 1;
  }

`
);

export const Date = styled.p(
  ({ theme }) => `
  color: ${theme.colors.grey("dark")};
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  margin-top: 0;
`
);

export const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.black};
`;
