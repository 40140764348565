// @flow
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useFetch } from "Hooks/useFetch";
import ContentLoading from "Components/ContentLoading";
import ContentError from "Components/ContentError";
import PeerProfileOverview from "Components/ProfileOverview/PeerProfileOverview";
import ProfileTabs from "Components/ProfileTabs";

const SingleProfile = () => {
  const { id, tab } = useParams();
  const { data, loading, error } = useFetch({ url: `/api/users/${id}` });
  const user = data?.user || {};

  if (loading) return <ContentLoading />;
  if (error) return <ContentError />;

  return (
    <React.Fragment>
      <Helmet>
        <title>ClickerMe | The Fashion Network</title>
      </Helmet>
      <PeerProfileOverview user={user} />
      <ProfileTabs id={id} tab={tab} user={user} />
    </React.Fragment>
  );
};

export default SingleProfile;
