// @flow
import React from "react";
import { Container, Line, StyledText as Text, Wrapper } from "./styles";

type Props = {
  alignment: String,
  bgColor: String,
  children: React.Node,
  textColor: String
};

const Heading = ({
  background_color,
  text,
  text_alignment,
  text_color
}: Props): React.Node => {
  return (
    <Wrapper background_color={background_color}>
      <Container alignment={text_alignment} background_color={background_color}>
        <Text alignment={text_alignment} text_color={text_color}>
          {text}
        </Text>
        <Line alignment={text_alignment} text_color={text_color}></Line>
      </Container>
    </Wrapper>
  );
};

export default Heading;
