// @flow
import React from 'react';
import Button from 'Components/Button';
import {
	Background,
	LeftSide,
	Title,
	Description,
	Image,
	Wrap,
} from './styles';

function BrandsHome() {
	return (
		<Background>
			<Wrap>
				<LeftSide>
					<Title>Are you looking to hire creatives?</Title>
					<Description>
						Find the world’s best emerging fashion talent at the click of a
						button. Go to our client portal to request a call back today.
					</Description>
					<Button to={'/request-a-call-back'}>Get in touch</Button>
				</LeftSide>
				<Image src={require('Assets/Images/fashion-image-1@3x.jpg')} />
			</Wrap>
		</Background>
	);
}
export default BrandsHome;
