// @flow
import React, { useState } from "react";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { useFetch } from "Hooks/useFetch";
import IconButton from "Components/IconButton";
import Button from "Components/Button";
import Tag from "Components/Tag";
import {
  Header,
  Container,
  RowContainer,
} from "./styles";
import { MdClose } from "react-icons/md";
import SearchAutoComplete from "../SearchAutoComplete";

type Props = {
  skills: Array<String>,
  SmallPlaceholders: React.Node,
  setError: Function,
  icons: Object
};

function SkillsForm({ skills, SmallPlaceholders, setError, icons }: Props) {
  const [editing, toggleEditing] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState(skills);
  const { data: skillsResult } = useFetch({ url: "/api/predefined_skills" });
  const allSkills = skillsResult?.predefined_skills || [];
  const [submit, response] = useLazyFetch({
    url: "/api/me/skills",
    method: "put"
  });

  function addSkill(skillName) {
    if (selectedSkills.find(s => s.text === skillName)) return;

    setSelectedSkills([...selectedSkills, { text: skillName }]);
  }

  function onSubmit() {
    submit({ skills: selectedSkills }, onSuccess, onError);
    toggleEditing(false);
  }

  function onSuccess() {
    setError(null);
  }

  function onError() {
    setError("Sorry, we could not update your skills right now");
    setSelectedSkills(skills || []);
  }

  return (
    <React.Fragment>
      <Header>
        Skills
        {editing ? (
          <Button size="small" onClick={onSubmit}>
            {response.loading ? "Saving" : "Save"}
          </Button>
        ) : (
          <IconButton yellow={!selectedSkills.length} onClick={() => toggleEditing(true)}>
            {selectedSkills?.length ? icons.edit : icons.plus}
          </IconButton>
        )}
      </Header>
      {editing ? (
        <Container>
          <span>Add professional skills...</span>
          <SearchAutoComplete
            placeholder={"Start typing a skill (e.g. Pattern cutting, Photoshop)…"}
            items={allSkills.map(s => s.name)}
            handleSuggestionSelected={addSkill}
          />

          <RowContainer>
            { selectedSkills?.map((skill, index) => (
              <Tag key={index} label={skill.text}>
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    setSelectedSkills([...selectedSkills.filter(item => item.text !== skill.text)]);
                  }}
                >
                  <MdClose />
                </IconButton>
              </Tag>
            ))}
          </RowContainer>
        </Container>
      ) : selectedSkills?.length > 0 ? (
        selectedSkills.map((skill, index) => <Tag key={index} label={skill.text} />)
      ) : (
        <SmallPlaceholders />
      )}
    </React.Fragment>
  );
}

export default SkillsForm;
