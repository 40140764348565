// @flow
import styled from "styled-components";
import Link from "Components/Link";

export const Container = styled.div(
  ({ theme }) => `
  width: 100%;
  background: ${theme.colors.white};
`
);

export const Heading = styled.h1(
  ({ theme }) => `
  font-weight: normal;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  padding: ${theme.spacing * 3}rem ${theme.spacing / 2}rem ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    padding: ${theme.spacing * 6}rem ${theme.spacing / 2}rem ${theme.spacing *
    3}rem;
  }
  @media screen and (max-width: ${theme.breakpoints.xsmall}px) {
   letter-spacing: 0px;
  }

`
);

export const Description = styled.p(
  ({ theme }) => `
  font-size: ${theme.fonts.size.xsmall};
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  padding: ${theme.spacing / 2}rem;
  margin-bottom: ${theme.spacing * 4}rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: ${theme.spacing * 6}rem;
  }
`
);

export const List = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    max-width: ${theme.maxWidth}px;
    margin: auto;
  }
`
);

export const Item = styled(Link)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: ${theme.spacing * 3}rem;
  max-width: ${theme.maxWidth};
  padding: 0;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    padding: 0 ${theme.spacing * 2}rem;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: ${theme.spacing * 6}rem;
  }
`
);

export const Image = styled.div(
  ({ src, theme }) => `
  width: 80vw;
  height: 50vw;
  background-image: url(${src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    width: 40vw;
    height: 30vw;
    max-width: ${theme.maxWidth * 0.4}px;
    max-height: ${theme.maxWidth * 0.3}px;
  }
`
);

export const Title = styled.p`
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
  margin: 9px;
`;
