// @flow
import React, { useState } from "react";
import { useAuth } from "Hooks/useAuth";
import { useLazyFetch } from "Hooks/useLazyFetch";
import Avatar from "Components/Avatar";
import {
  Wrapper,
  Container,
  StyledInput as Input,
  StyledButton as Button,
  StyledIcon,
  ButtonContainer
} from "./styles";
import { FaPaperPlane } from "react-icons/fa";

type Props = {
  postId: Number | String,
  addComment: Function,
  setError: Function
};

const CommentForm = ({ postId, addComment, setError }: Props): React.Node => {
  const {
    auth: { user }
  } = useAuth();
  const [text, setText] = useState(null);
  const [submit] = useLazyFetch({
    url: `/api/collaboration_posts/${postId}/comments`,
    method: "post"
  });

  function onSubmit(e) {
    e.preventDefault();
    const comment = {
      collaboration_post_comment: { text }
    };
    submit(comment, onSuccess, onError);
  }

  function onSuccess(res) {
    if (res?.collaboration_post_comment)
      addComment(res.collaboration_post_comment);
    setError(null);
    setText(null);
  }

  function onError() {
    setError("Sorry, we could not post this comment right now");
  }

  return (
    <Wrapper onSubmit={onSubmit}>
      <Container>
        <div>
          <Avatar src={user?.profile?.photo} size="medium" />
        </div>
        <Input
          placeholder="Write a comment..."
          value={text || ""}
          onChange={e => setText(e.target.value)}
        />
      </Container>
      <ButtonContainer>
        {window.innerWidth < 576 ? (
          <StyledIcon visible={text} type="submit">
            <FaPaperPlane size={14} />
          </StyledIcon>
        ) : (
          <Button size="small" type="submit" visible={text}>
            Share
          </Button>
        )}
      </ButtonContainer>
    </Wrapper>
  );
};

export default CommentForm;
