import styled from "styled-components";

export const Wrapper = styled.div(
  ({ theme }) => `
  display: flex;
  height: 100%;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    width: 50%;
    padding-left: ${theme.spacing}rem;
  }`
);

export const ListContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  background: ${theme.colors.grey("light")};
  z-index: 999;
  margin-top: ${theme.spacing * 3}rem;
  padding: ${theme.spacing / 2}rem;
  box-shadow: ${theme.shadow};
  min-width: 328px;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    border-top: 2px solid ${theme.colors.black};
    left: ${theme.spacing}rem;
    right: ${theme.spacing}rem;
    min-width: 0;
  }
`
);

export const List = styled.ul(
  ({ theme }) => `
  list-style-type: none;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  ::-webkit-scrollbar {
    background: ${theme.colors.grey()};
    width: 5px;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    width: 5px;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.black};
    width: 5px;
    border-radius: 3px;
  }
`
);

export const ListItem = styled.li(
  ({ theme, selected, item }) => `
  background: ${
    selected?.id === item?.id ? theme.colors.yellow() : theme.colors.white
  };
  border-radius: ${theme.border.radius}px;
  font-weight: ${selected?.id === item?.id ? "bold" : "normal"};
  padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
  margin: ${theme.spacing / 2}rem;
`
);

export const Selected = styled.div(
  ({ theme }) => `
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  border-top: 1px solid ${theme.colors.black};
  border-bottom: 1px solid ${theme.colors.black};
  padding: ${theme.spacing / 2}rem;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`
);

export const FilterButton = styled.div(
  ({ theme, active }) => `
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
  text-transform: uppercase;
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  border: 2px solid ${theme.colors.black};
  border-radius: ${theme.spacing * 2}rem;
  background: ${active ? theme.colors.yellow() : "none"};
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`
);

export const Icon = styled.img`
  width: 20px;
`;

export const ClearButton = styled.div(
  ({ theme }) => `
  font-weight: bold;
  text-align: right;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`
);
