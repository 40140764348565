// @flow
import React from "react";
import Avatar from "Components/Avatar";
import { Container, Card, Name, Title, IconLink } from "./styles";
import {
  FaInstagram,
  FaLinkedinIn,
  FaFacebook,
  FaTwitter
} from "react-icons/fa";

type Props = {
  image: Object,
  text: String
};

const Team = ({ members }: Props): React.Node => {
  function getIcon(network) {
    switch (network) {
      case "instagram":
        return <FaInstagram />;
      case "linkedin":
        return <FaLinkedinIn />;
      case "facebook":
        return <FaFacebook />;
      case "twitter":
        return <FaTwitter />;
      default:
        return null;
    }
  }

  return (
    <Container>
      {members?.map((item, index) => {
        const { image, name, job_title, text, links } = item;
        return (
          <Card key={index}>
            <Avatar
              size="xxlarge"
              src={
                image?.url ||
                "https://cdn.filestackcontent.com/lLsLsbtUTDW8xkeU59Zb"
              }
            />
            <Name>{name}</Name>
            <Title>{job_title}</Title>
            <p>{text}</p>
            {links &&
              links.map((link, index) => (
                <IconLink key={index} href={link?.url} target="_blank">
                  {getIcon(link?.network)}
                </IconLink>
              ))}
          </Card>
        );
      })}
    </Container>
  );
};

export default Team;
