import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const animation = () =>
  css`
    animation: ${fadeIn} 0.4s ease-in;
  `;

export const StyledNotification = styled.div(
  ({ theme, success }) => `
  box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.5);
  background: ${success ? "hsl(141, 71%, 48%)" : "hsl(348, 100%, 61%)"};
  padding: ${theme.spacing}rem;
  animation: ${animation};
  z-index: 999;
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    font-size: ${theme.fonts.size.normal};
    padding: ${theme.spacing * 1.5}rem;
  }
`
);
