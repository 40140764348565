import React from "react";
import {Container, Description, FormWrapper, StepTitle} from "./styles";
import FormField from "Components/FormField";
import Button from "Components/Button";
import SearchAutoComplete from "../SearchAutoComplete";
import { RowContainer } from "../VerificationForms/styles";

function PrimarySpecialismsStep(props: {
  errors: any,
  primarySpecialisms: any[],
  setPrimarySpecialisms: () => any,
  specialisms: { friendly_names: any; priority: string },
  handleSuggestionSelected: (specialism?: any) => undefined,
  specialismResponse: any,
  onClick: () => any }) {
  return (
    <Container>
      <FormWrapper>
        <StepTitle>Next, please add your primary specialisms</StepTitle>
        <Description>Add up to 2 specialisms, you can add secondary specialisms next.</Description>
        <FormField errors={props.errors?.primarySpecialism} style={{border: "solid 1px red"}}>
          {props.primarySpecialisms.length < 2 &&
          <SearchAutoComplete
            placeholder={"Start typing a specialism (eg. Textile designer)…"}
            items={props.specialisms.map(s => s.friendly_name)}
            handleSuggestionSelected={props.handleSuggestionSelected}
          />
          }
          <RowContainer>
            {props.specialismTags(props.primarySpecialisms, props.setPrimarySpecialisms)}
          </RowContainer>
        </FormField>
        {props.specialismResponse?.loading ? "Submitting..." :
          <Button
            disabled={props.primarySpecialisms.length < 1}
            onClick={props.onClick}
          >
            Next
          </Button>
        }
    </FormWrapper>
  </Container>
  )
}

export default PrimarySpecialismsStep;