import styled from "styled-components";

export const Wrap = styled.div(
  ({ theme }) => `
  display:block;
  grid-gap: 1rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3rem;
  }
`
);
