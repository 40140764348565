import * as React from 'react';
import { Helmet } from 'react-helmet';
import BlockContainer from 'Components/BlockContainer';
import Feedback from 'Components/Blocks/Feedback';
function Mentoring() {
	return (
		<>
			<Helmet>
				<title>Mentoring</title>
			</Helmet>
			<BlockContainer name="mentoring" backgroundColor="#FFF" />
			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
		</>
	);
}

export default Mentoring;
