// @flow
import React, { useState, useEffect } from 'react';
import { useValidation } from 'Hooks';
import { useAuth } from 'Hooks/useAuth';
import { useLazyFetch } from 'Hooks/useLazyFetch';
import { useHistory } from 'react-router-dom';
import Button from 'Components/Button';
import FormField from 'Components/FormField';
import ProjectView from 'Components/ProjectView';
import ProjectImages from 'Components/ProjectImages';
import Modal from 'Components/Modal';
import Notification from 'Components/Notification';
import {
	Container,
	FormContainer,
	TextArea,
	Actions,
	StyledLink,
	StyledButton,
	BackButton,
	Icon,
	WidthDependentText,
	BackButtonText,
} from './styles';

const ProjectForm = ({ project, redirect }) => {
	let history = useHistory();
	const {
		auth: { user },
		refetchUser,
	} = useAuth();
	const [previewing, togglePreviewing] = useState(false);
	const [publishModal, togglePublishModal] = useState(false);
	const [noCoverModal, toggleNoCoverModal] = useState(false);
	const [error, setError] = useState(null);
	const [title, setTitle] = useState(project?.title);
	const [description, setDescription] = useState(project?.description);
	const [images, setImages] = useState(project?.images || []);
	const [coverImage, setCoverImage] = useState(project?.cover_image_url);
	const [validate, errors] = useValidation(
		{ title, description },
		{
			title: {
				presence: {
					allowEmpty: false,
				},
			},
			description: {
				presence: {
					allowEmpty: false,
				},
			},
		}
	);

	const [updateProject] = useLazyFetch({
		url: `/api/projects/${project?.id}`,
		method: 'put',
	});

	const [deleteProject] = useLazyFetch({
		url: `/api/projects/${project?.id}`,
		method: 'delete',
	});

	function submit() {
		if (!title && !description && !images.length)
			return deleteProject(null, onSuccess, onError);
		updateProject(
			{
				project: {
					title,
					description,
					is_published: project?.published ? true : false,
					cover_image_url: coverImage,
				},
			},
			onSuccess,
			onError
		);
	}

	function publish() {
		const valid = validate();
		if (!images.length) return togglePublishModal(true);
		if (!coverImage && !project.cover_image_url)
			return toggleNoCoverModal(true);
		if (valid) {
			updateProject(
				{
					project: {
						title,
						description,
						is_published: true,
						cover_image_url: coverImage,
					},
				},
				onSuccess,
				onError
			);
		}
	}

	function unpublish() {
		updateProject(
			{
				project: {
					title,
					description,
					is_published: false,
					cover_image_url: coverImage,
				},
			},
			() => {
				refetchUser({ ...user, projects: [...user?.projects, project] });
			},
			onError
		);
	}

	function onSuccess({ project }) {
		setError(null);
		if (redirect) {
			refetchUser({ ...user, projects: [...user?.projects, project] });
			history.push(redirect);
		} else {
			refetchUser({ ...user, projects: [...user?.projects, project] });
			history.push('/profile/portfolio');
		}
	}

	function onError() {
		setError('Sorry, we could not update your project right now');
	}

	function updateCoverImage(cover_image_url) {
		updateProject(
			{ project: { ...project, cover_image_url } },
			({ project }) => {
				setError(null);
				setCoverImage(cover_image_url);
			},
			() => setError('Sorry, we could not update the cover image right now')
		);
	}

	useEffect(() => {
		window.scroll(0, 0);
	}, [previewing]);
	const editOrProfile = previewing ? 'edit' : 'profile';
	return (
		<Container>
			{error && <Notification>{error}</Notification>}
			<Actions>
				<div style={{ display: 'flex' }}>
					<StyledLink
						onClick={() => (previewing ? togglePreviewing(false) : submit())}
					>
						<BackButtonText>
							<BackButton>
								<img src={require('Assets/Images/arrow-left.svg')} />
							</BackButton>
							<WidthDependentText>
								Back to {redirect ? 'verification' : editOrProfile}
							</WidthDependentText>
						</BackButtonText>
					</StyledLink>
				</div>

				<div>
					{!previewing && (
						<StyledButton
							onClick={() => togglePreviewing(true)}
							variant="white"
							padded
						>
							<Icon src={require('Assets/Images/eye.svg')} />
							<WidthDependentText>Preview</WidthDependentText>
						</StyledButton>
					)}
					<StyledButton onClick={submit} variant="white" padded>
						Save<WidthDependentText> & close</WidthDependentText>
					</StyledButton>
					{user?.approval_status === 'approved' && !project?.published && (
						<StyledButton onClick={publish} padded>
							Publish
						</StyledButton>
					)}
				</div>
			</Actions>
			{publishModal && (
				<Modal onClose={() => togglePublishModal(false)}>
					<h2>Before you publish...</h2>
					<span>
						Projects need to contain images before you publish them to your
						profile. It only takes a minute, just click the button below to add
						images to your project.
					</span>
					<Button size="small" onClick={() => togglePublishModal(false)}>
						Add images now
					</Button>
				</Modal>
			)}
			{noCoverModal && (
				<Modal onClose={() => toggleNoCoverModal(false)}>
					<h2>Before you publish...</h2>
					<span>Don't forget to add a cover image for your project.</span>
					<Button size="small" onClick={() => toggleNoCoverModal(false)}>
						OK
					</Button>
				</Modal>
			)}
			{previewing ? (
				<ProjectView
					project={{
						profile: user?.profile,
						title,
						description,
						images,
					}}
				/>
			) : (
				<FormContainer>
					<FormField errors={errors?.title} centre>
						<TextArea
							large
							placeholder="Add a project title..."
							value={title || ''}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</FormField>
					<FormField errors={errors?.description} centre>
						<TextArea
							placeholder="Include details about this project..."
							value={description || ''}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</FormField>
					<ProjectImages
						project={{ ...project, images, cover_image_url: coverImage }}
						setImages={setImages}
						setError={setError}
						updateCoverImage={updateCoverImage}
						unpublish={unpublish}
					/>
				</FormContainer>
			)}
		</Container>
	);
};

export default ProjectForm;
