import styled from 'styled-components';

import { positioning } from 'Helpers/Positioning';
import React from 'react';

export const GlobalWrapper = styled.div(
	({ theme, image }) => `
 background-color: ${theme.colors.grey('landing')};
 height: 100%;
 width: 100%;
 `
);

export const BrandCaseStudies = styled.div(
	({ theme, image, src }) => `
max-width: ${theme.maxWidth}px;
margin: auto;
padding: ${theme.spacing * 5}rem 0;
@media screen and (max-width: ${theme.breakpoints.small}px) {
	padding: ${theme.spacing}rem;
 }
 `
);

export const Title = styled.div(
	({ theme, image, src, largerFont, isHeading }) => `
color: white;
font-size: ${largerFont ? theme.fonts.size.large : `20px`};
font-weight: bold;
text-align: ${isHeading ? `center` : `left`};
margin-top: ${theme.spacing}rem;
 `
);

export const Description = styled.div(
	({ theme, image, src, isHeading, padded, alignLeft }) => `
	color: white;
	font-size: ${theme.fonts.size.normal};
	text-align: ${isHeading ? `center` : `left`};
	margin-top: ${theme.spacing / 2}rem;
	margin-bottom: ${theme.spacing * 1.5}rem;
	padding: ${padded ? '1rem 3rem' : '0'};
	@media screen and (max-width: ${theme.breakpoints.medium}px) {
	padding: 0;
	text-align: ${alignLeft ? 'left !important' : 'center'};
 }
 `
);

export const EnquiryDescription = styled.div(
	({ theme, isHeading }) => `
	color: white;
	font-size: ${theme.fonts.size.normal};
	text-align: ${isHeading ? `center` : `left`};
	margin-top: ${theme.spacing / 2}rem;
	margin-bottom: ${theme.spacing * 1.5}rem;
	width: 60%; 
	margin-left: 20%;
	@media screen and (max-width: ${theme.breakpoints.small}px) {
		width: 100%;
		margin-left: 0;
<<<<<<< HEAD
    text-align: center;
=======
		text-align: center;
>>>>>>> a3428be95309bb47700e06254ed30c29500c533c
 }
 `
);

export const EachCase = styled.div(
	({ theme, image, src, flexbox, flip }) => `
	display: ${flexbox ? 'flex' : 'block'};
	margin: ${theme.spacing * 3}rem;
	text-align: left;
	justify-content: center;
	align-items: top;
		@media screen and (max-width: ${theme.breakpoints.medium}px) {
			justify-content: center;
		display: flex;
		flex-direction: ${flip ? 'column-reverse' : 'column'};
	}
	@media screen and (max-width: ${theme.breakpoints.small}px) {
		margin: ${theme.spacing * 3}rem 0;
	}
 `
);

export const Image = styled.img(
	({ theme, image, src, largerImage }) => `
height:  ${largerImage ? '291px' : '235px'};
width: ${largerImage ? '440px' : '389px'};
object-fit: cover;
@media screen and (max-width: ${theme.breakpoints.small}px) {
	height: auto;
	width: 100%;
 }
 `
);

export const Cases = styled.div(
	({ theme, image, src, flexbox }) => `
display: ${flexbox === false ? 'block' : 'flex'};
margin: auto;
justify-content: center;
align-items: top;
@media screen and (max-width: ${theme.breakpoints.medium}px) {
  display: block;
 }

 `
);

export const FlashSale = styled.div(
	({ theme, image, src }) => `
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: auto;
background-color: #222222;
z-index: 0;
height: 30rem;
padding: ${theme.spacing * 5}rem 0;
p{
	margin: ${theme.spacing * 2}rem;
	color: white;
	font-weight: bold;
	font-size: ${theme.fonts.size.normal};
	text-align: center;
	max-width: 590px;
}
@media screen and (max-width: ${theme.breakpoints.medium}px) {
padding: ${theme.spacing * 3}rem ${theme.spacing}rem;
height: 35rem;
 }
 `
);

export const Text = styled.div(
	({ theme, image, src }) => `
color: white;
font-size: ${theme.fonts.size.large};
font-weight: bold;
}
 `
);

export const Introduction = styled.div(
	({ theme, image, src }) => `
	background-color: #222222;
 `
);

export const WrapIconText = styled.div(
	({ theme }) => `
		display: flex;
		 margin:auto;
		 justify-content: center;
		 align-items: center;
		 p{
			 font-weight: normal;
			 margin: 0.3rem 0.7rem;
			 text-align: left;
		 }
		 @media screen and (max-width: ${theme.breakpoints.medium}px) {
align-items: top;
 }
  `
);

export const Icon = styled.img(
	({ theme }) => `
    width:20px;
    height:20px;
    border : 0;
    text-decoration: none;
    outline: none;
  `
);
export const Enquiry = styled.div(
	({ theme }) => `
	margin-top: 3rem;
	background-color: ${theme.colors.grey('landing')};
 	height: 100%;
 	@media screen and (max-width: ${theme.breakpoints.small}px) {
		margin-top: .5rem;
	}
 `
);

export const Container = styled.div(
	(props) => `
  display: block;
  z-index: ${props.theme.zIndex.front};
  max-width: ${props.theme.maxWidth}px;
  margin: auto;
  align-items: center;
  flex-direction: ${positioning(props, 'row', 'row-reverse')}
  width: 100%;
  padding: ${props.theme.spacing * 2}rem ${props.theme.spacing}rem;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
   text-align: left;
   padding-bottom: ${props.theme.spacing}rem;
  }
`
);

export const EnquiryForm = styled.div(
	(props, theme) => `
  display: block;
  z-index: ${props.theme.zIndex.front};
  align-items: left;
  margin-top: 2rem;
  flex-direction: ${positioning(props, 'row', 'row-reverse')}
  padding: .2rem 4rem;
  @media screen and (max-width: ${props.theme.breakpoints.small}px) {
   text-align: left;
	 padding: 0 0 2rem 0;
  }
`
);

export const SectionTitle = styled.h2`
	font-size: 26px;
	font-weight: bold;
	padding-right: 1.5rem;
	text-align: center;
	color: ${(props) => props.theme.colors.white};
	@media screen and (max-width: ${(props) => props.theme.breakpoints.small}px) {
		font-size: 1.8rem;
		text-align: left;
	}
`;

export const FormColumn = styled.div`
	width: 50%;
	padding: 2rem;
	@media screen and (max-width: ${(props) => props.theme.breakpoints.small}px) {
		width: 100%;
		padding: 0;
	}
`;

export const Form = styled.form`
	width: 100%;
	display: inline-flex;
	padding: 0 3rem;
	@media screen and (max-width: ${(props) => props.theme.breakpoints.small}px) {
		padding: 0;
		display: block;
	}
`;

export const EmailAddress = styled.a`
	color: #fff200;
`;

export const WrapTitleDescription = styled.div(
	(theme) => `
	display: block;
	max-width: 470px;
	align-contents: top;
`
);

export const HighlightedTitle = styled.div(
	({ largerFont, isHeading, theme }) => `
	color: white;
	font-size: ${largerFont ? theme.fonts.size.large : `20px`};
	font-weight: bold;
	text-align: ${isHeading ? `center` : `left`};
	margin-top: ${theme.spacing}rem;	
	border-bottom: 2px solid yellow;	
	@media screen and (max-width: ${theme.breakpoints.medium}px) {
	text-align: left !important;
  border: 0;
	padding:0;
	}
	padding-left: 3rem;
	padding-right: 3rem;
	padding-bottom: 1rem;
`
);

export const SlashImage = styled.div(
	({ theme, src }) => `
  position: absolute;
	background-size: cover;
	background-color: black;
	opacity: 0.39;
	z-index: 1;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  clip-path: polygon(0% 0%, 56% 0%, 44% 100%, 0% 100%);
  width: 100%;
  height: 30rem;
    @media screen and (max-width: ${theme.breakpoints.medium}px) {
			height: 35rem;    
      clip-path: polygon(0% 0%, 56% 0%, 44% 100%, 0% 100%);
	}
	 @media screen and (max-width: ${theme.breakpoints.small}px) {
      position: absolute;
      background-size: initial;
      background-position: right;
      left: 0;
      padding-top: 25%;
			width: 300px;
			height: 35rem;    
      clip-path: polygon(0% 0%, 86% 0%, 34% 100%, 0% 100%);
  }
`
);
