// @flow
import styled from 'styled-components';

export const StyledImage = styled.img(
	({ theme }) => `
	height:20px;
	width: 20px;
`
);

export const AlignIcon = styled.div(
	({ theme }) => `
	width: 100%;
	display: flex;
	background-color: white;
	margin: auto;
	align-items: center;
	border: 1px solid ${theme.colors.grey()};
	padding-left: 10px;
	margin: ${theme.spacing / 4}rem 0;
	`
);
