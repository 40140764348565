// @flow
import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { formatSrc } from "Helpers/Image";
import { type Project } from "Types";
import IconButton from "Components/IconButton";
import ProjectButtons from "Components/ProjectButtons";
import Notification from "Components/Notification";

import {
  Container,
  AddButton,
  ProjectItem,
  LinkWrapper,
  Title,
  Cover,
  ButtonLabel
} from "./styles";

type Props = {
  isCurrentUser?: Boolean,
  isApproved?: Boolean,
  projects: Array<Project>
};

function PortfolioTab({ isCurrentUser, isApproved, projects }: Props) {
  let history = useHistory();
  const [data, setData] = useState(projects);
  const [error, setError] = useState(null);

  const [submit] = useLazyFetch({
    url: "/api/projects",
    method: "post"
  });

  function addNewProject() {
    submit(
      {
        project: {
          title: "",
          description: ""
        }
      },
      onSuccess,
      onError
    );
  }

  function onSuccess(res) {
    if (res?.project?.id) {
      setError(null);
      history.push(`/projects/${res.project.id}/edit`);
    }
  }

  function onError() {
    setError("Sorry, we could could create a new project right now");
  }

  function removeProject(id) {
    setData(data.filter(project => project?.id !== id));
  }

  function updateProject(item) {
    setData(data.map(project => (project?.id === item?.id ? item : project)));
  }

  return (
    <Container>
      {error && <Notification>{error}</Notification>}
      {isCurrentUser && (
        <AddButton onClick={addNewProject}>
          <IconButton yellow>
            <img src={require("Assets/Images/plus.svg")} />
          </IconButton>
          <ButtonLabel>Add a new project</ButtonLabel>
        </AddButton>
      )}
      {!isCurrentUser ? (
        <React.Fragment>
          {data
            ?.filter(project => ![false].includes(project.published))
            .map((project, index) => (
              <ProjectItem key={index} published={project?.published}>
                <LinkWrapper
                  to={`/projects/${
                    isCurrentUser ? `${project.id}/edit` : project.id
                  }`}
                >
                  <Title>{project?.title}</Title>
                  {project?.cover_image_url ? (
                    <Cover src={formatSrc(project?.cover_image_url, 540)} />
                  ) : (
                    <Cover
                      src={require("Assets/Images/default-cover-image.svg")}
                    />
                  )}
                </LinkWrapper>
                {isCurrentUser && (
                  <ProjectButtons
                    project={project}
                    isApproved={isApproved}
                    removeProject={removeProject}
                    updateProject={updateProject}
                    setError={setError}
                  />
                )}
              </ProjectItem>
            ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {data?.map((project, index) => (
            <ProjectItem key={index} published={project?.published}>
              <LinkWrapper
                to={`/projects/${
                  isCurrentUser ? `${project.id}/edit` : project.id
                }`}
              >
                <Title>{project?.title}</Title>
                {project?.cover_image_url ? (
                  <Cover src={formatSrc(project?.cover_image_url, 540)} />
                ) : (
                  <Cover
                    src={require("Assets/Images/default-cover-image.svg")}
                  />
                )}
              </LinkWrapper>
              {isCurrentUser && (
                <ProjectButtons
                  project={project}
                  isApproved={isApproved}
                  removeProject={removeProject}
                  updateProject={updateProject}
                  setError={setError}
                />
              )}
            </ProjectItem>
          ))}
        </React.Fragment>
      )}
    </Container>
  );
}

export default PortfolioTab;
