// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import BlockContainer from 'Components/BlockContainer';
import Feedback from 'Components/Blocks/Feedback';

function HowItWorks() {
	return (
		<>
			<Helmet>
				<title>ClickerMe | How Our Fashion Network Works</title>
				<meta
					name="description"
					content="ClickerMe is the fashion network helping creatives to connect and collaborate better"
				/>
				<htmlAttributes h1="The Free Network For Fashion Creatives"></htmlAttributes>
			</Helmet>
			<BlockContainer name="how-it-works" backgroundColor="#FFF" />
			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
		</>
	);
}

export default HowItWorks;
