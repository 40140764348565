import styled from "styled-components";

export const ImageContainer = styled.div(
  ({ reordering, theme }) => `
  width: ${theme.spacing * 17}rem;
  height: ${theme.spacing * 17}rem;
  margin: ${reordering ? 0 : theme.spacing}rem;
  border: 1px solid ${theme.colors.grey()};
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
);

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 6;
`;

export const Option = styled.button(
  ({ theme }) => `
  display: flex;
  flex-direction: row
  align-items: center;
  background-color: transparent;
  border: none;
  padding: ${theme.spacing / 2}rem;
  color: ${theme.colors.white};
  font-size: ${theme.fonts.size.small};
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  outline: none;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.yellow()};
  }
  img {
    margin-right: ${theme.spacing / 2}rem;
  }
`
);

export const MenuButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  background-color: transparent;
  border: none;
  z-index: 5;
  outline: none;
  padding: ${props => props.theme.spacing / 2}rem;
  color: ${props =>
    props.menuOpen ? props.theme.colors.white : props.theme.colors.black};
`;

export const ImageItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const VideoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Image = styled.img(
  ({ theme }) => `
  max-width: 100%;
  max-height: calc(${theme.spacing * 17}rem - 2px);
  object-fit: cover;
  display: flex;
  align-self: center;
`
);

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CoverStar = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  margin: 15px;
`;
