// @flow
import React from "react";
import { Container, StyledText as Text } from "./styles";

const ContentError = () => (
  // @TODO : Update This
  <Container>
    <Text>Sorry, there was an error.</Text>
  </Container>
);

export default ContentError;
