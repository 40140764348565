// @flow
import * as React from "react";
import { Player, BigPlayButton } from "video-react";
import Vimeo from "@u-wave/react-vimeo";
import "./video-react.css";
import ReactPlayer from "react-player";

type Props = {
  resourceType: String,
  url: String,
  file: any,
  cover: String,
  columns: Number
};

function ProjectVideo({
  resourceType,
  url,
  videoHeight,
  videoWidth,
  fluid
}: Props): React.Node {
  if (resourceType === "youtube") {
    return <ReactPlayer url={url} width={videoWidth} height={videoHeight} />;
  }

  if (resourceType === "vimeo") {
    return <Vimeo video={url} width={videoWidth} height={videoHeight} />;
  }

  return (
    <Player
      src={url}
      fluid={fluid}
      width={videoWidth}
      height={videoHeight}
      aspectRatio={"16:9"}
    >
      <BigPlayButton position="center" />
    </Player>
  );
}

export default ProjectVideo;
