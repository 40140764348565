// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { useFetch } from 'Hooks/useFetch';
import JobList from 'Components/JobList';
import AffiliateContents from 'Components/Blocks/AffiliateContents';
import Feedback from 'Components/Blocks/Feedback';

function Jobs() {
	const { data } = useFetch({ url: '/api/pages/jobs' });
	const affiliate_content = data?.page?.blocks.find(
		(block) => block.type === 'affiliate_content'
	);

	return (
		<>
			<Helmet>
				<title>ClickerMe | Fashion Jobs</title>
				<meta
					name="description"
					content="A curated selection of international fashion jobs and opportunities | Full-time and freelance fashion jobs at top fashion brands"
				/>
				<htmlAttributes h1="International Fashion Jobs & Opportunities"></htmlAttributes>
			</Helmet>
			<JobList />
			{affiliate_content && (
				<AffiliateContents {...affiliate_content}></AffiliateContents>
			)}
			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
		</>
	);
}

export default Jobs;
