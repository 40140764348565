// @flow
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import { useLazyFetch } from 'Hooks/useLazyFetch';
import ProjectFilters from 'Components/ProjectFilters';
import ProjectCard from 'Components/ProjectCard';
import ScrollLoad from 'Components/ScrollLoad';
import { Wrapper, Grid, Empty } from './styles';
import InfiniteLoading from '../../Components/InfiniteLoading';

const Projects = () => {
	const [featured, setFeatured] = useState(window.innerWidth >= 730);
	const [page, setPage] = useState(1);
	const [projects, setProjects] = useState([]);
	const [query, setQuery] = useState(featured ? 'q[featured_eq]=true' : '');
	const [last, setLast] = useState(false);
	const [selectedChip, selectChip] = useState(featured ? 'Featured' : 'All');
	const [specialism, setSpecialism] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [submit, { loading }] = useLazyFetch({
		url: `/api/projects/?page=${page}&per_page=6&${query}`,
		method: 'get',
	});

	const getProjects = async (callback) => {
		const res = await submit();
		if (res) callback(res);
	};

	useEffect(() => {
		window.scroll(0, 0);
		getProjects(({ projects, _links }) => {
			if (!_links.next) {
				setLast(true);
			} else {
				setLast(false);
			}
			setProjects(projects);
		});
		setPage(1);
	}, [query]);

	useEffect(() => {
		if (projects && !last && page > 1) {
			getProjects((res) => {
				if (!res._links.next) {
					setLast(true);
				} else {
					setLast(false);
				}
				setProjects([...projects, ...res?.projects]);
			});
		}
	}, [page]);

	useEffect(() => {
		const params = {
			...(specialism ? { 'q[specialisms_eq]': specialism.id } : {}),
			...(searchValue.length > 3 ? { 'q[location_cont]': searchValue } : {}),
			...(featured ? { 'q[featured_eq]': featured } : {}),
		};

		const query = qs.stringify(params, { encodeValuesOnly: true });
		setQuery(query);
		setPage(1);
	}, [searchValue, featured, specialism]);

	return (
		<>
			<Helmet>
				<title>ClickerMe | Discover</title>
				<meta
					name="description"
					content="Find new creatives to connect and collaborate with on ClickerMe"
				/>
			</Helmet>
			<ScrollLoad loadMore={() => setPage((p) => p + 1)}>
				<Wrapper>
					<ProjectFilters
						selectedChip={selectedChip}
						selectChip={selectChip}
						specialism={specialism}
						setSpecialism={setSpecialism}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						setFeatured={setFeatured}
					/>
					{projects.length ? (
						<>
							<Grid>
								{projects?.map((project) => (
									<ProjectCard project={project} />
								))}
							</Grid>
							{loading && <InfiniteLoading />}
						</>
					) : (
						<Empty>No projects found</Empty>
					)}
				</Wrapper>
			</ScrollLoad>
		</>
	);
};

export default Projects;
