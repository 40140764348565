import React, { useRef } from "react";
import {
  Wrapper,
  SearchContainer,
  SearchWrapper,
  SearchInput,
  SearchButton,
  Icon
} from "./styles";
import { MdClose } from "react-icons/md";

const SearchField = ({
  placeholder,
  value,
  onChange,
  clearValue,
  active,
  setActive
}) => {
  const searchInput = useRef();

  const mobileClose = e => {
    if (e.keyCode === 13) {
      setActive(null);
      searchInput.current.blur();
    }
  };
  return (
    <Wrapper>
      <SearchButton
        onClick={() => setActive(active ? null : "search")}
        active={active}
      >
        Search <Icon src={require("Assets/Images/search.svg")} />
      </SearchButton>
      <SearchWrapper active={active}>
        <SearchContainer>
          <SearchInput
            ref={searchInput}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={e => mobileClose(e)}
          />
          {value.length ? (
            <span onClick={clearValue}>
              <MdClose />
            </span>
          ) : (
            <Icon src={require("Assets/Images/search.svg")} />
          )}
        </SearchContainer>
      </SearchWrapper>
    </Wrapper>
  );
};

export default SearchField;
