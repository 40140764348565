// @flow
import React, { useEffect } from 'react';

import { WrapAffiliateSingle, CTA } from './styles';
import { Description } from '../VerificationSteps/styles';
import AffiliateSingle from '../Blocks/AffiliateSingle';
import Notification from 'Components/Notification';
import SectionHeader from '../SectionHeader';
import { Link } from 'react-router-dom';

type Props = {
	children: React.Node,
};

function SuccessPage(props: { error: null, affiliateSingle: any }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<WrapAffiliateSingle>
				{props.error && <Notification>{props.error}</Notification>}
				<SectionHeader text={'Thanks for submitting'} />
				<Description style={{ height: 'auto', maxWidth: '590px' }}>
					Your profile is now pending approval. Keep an eye out for a
					confirmation email from us soon.
					<br />
					<br />
					In the meantime, continue to add to, update and edit your profile
					until you receive full access to our community.
				</Description>
				<CTA>
					<Link style={{ color: 'black' }} to={'/profile'}>
						Back to profile
					</Link>
				</CTA>
				{props.affiliateSingle && (
					<AffiliateSingle {...props.affiliateSingle} layout={'horizontal'} />
				)}
			</WrapAffiliateSingle>
		</>
	);
}
export default SuccessPage;
