import React from "react";
import { StyleSocial, SocialLink } from "./styles";

const SocialLinks = () => {
  return (
    <StyleSocial>
      {[
        {
          url: "https://www.facebook.com/ClickerMeFashion/",
          icon: require("Assets/Images/footer-facebook-icon.svg")
        }
      ].map(link => {
        return (
          <a href={link.url} target="_blank">
            <SocialLink src={link.icon} />
          </a>
        );
      })}

      <a href="https://www.instagram.com/clickerme/" target="_blank">
        <SocialLink src={require("Assets/Images/footer-instagram-icon.svg")} />
      </a>
      <a href="https://www.linkedin.com/company/11701566" target="_blank">
        <SocialLink src={require("Assets/Images/footer-linkedin-icon.svg")} />
      </a>
    </StyleSocial>
  );
};

export default SocialLinks;
