import React from "react";
const Episodes = {
	episode7: {
		number: "Season 2 Episode 1",
		authorName: "Sylvie Macmillan",
		name: "Nail Technician / Artist / Consultant Sylvie Macmillan",
		date: "17.11.2020",
		additionalVideo: "false",
		description: (
			<>
				<p>
					`I've always - up until now, with social distancing - made a point,
					even if it's the simplest nail, to stay there all day. I'm part of the
					team, I want to be credited,` Nail Technician / Artist / Consultant{" "}
					<a
						href="https://clickerme.com/profiles/2c81c229-ae0d-44c1-bf0b-c4186628ff5f/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Sylvie Macmillan</span>
					</a>{" "}
					explains to Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
			</>
		),
		thumbnail: require("Assets/Images/201113-artwork-fashion-slashie-sylvie.jpg"),
		link: "/podcasts/the-fashion-slashie/sylvie-macmillan",
		linkToApple:
			"https://podcasts.apple.com/us/podcast/how-has-sylvie-macmillan-carved-out-unique-place-for/id1515273204?i=1000498947138",
		linkToSpotify:
			"https://open.spotify.com/episode/2a3C63E8uxsbstWdWhC7hB?si=gLgsKdCNSuymjRVinI9uLg",
		linkToGoogle:
			"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yMGU3Y2JjYy9wb2RjYXN0L3Jzcw/episode/ODcwODk0MTgtYTRiOC00MjUwLWFhYTMtMDYwMmE0MzdlMmRj",
		SocialShareUrl:
			"https://www.clickerme.com/podcasts/the-fashion-slashie/sylvie-macmillan",
		quote: "ClickerMe Podcasts / The Fashion Slashie / Sylvie Macmillan",
		videoURL: "",
		spotifyEmbedded:
			"https://open.spotify.com/embed-podcast/episode/2a3C63E8uxsbstWdWhC7hB",
		facebookTags: ["#clickerme, #thefashionslashie"],
		twitterTitle: "ClickerMe Podcasts / The Fashion Slashie / Sylvie Macmillan",
		via: "ClickerMe",
		twitterTags: ["clickerme,thefashionslashie"],
		related: "https://ww.linkedin.com/company/clickerme/",
		summary:
			"Everyone has a brand and it’s about making your brand as appealing and serviceable as possible. What are you going to be able to give them that they don't have?",
		source: "https://www.clickerme.com",
		notes: (
			<>
				<p>
					`I've always - up until now, with social distancing - made a point,
					even if it's the simplest nail, to stay there all day. I'm part of the
					team, I want to be credited,` Nail Technician / Artist / Consultant{" "}
					<a
						href="https://clickerme.com/profiles/2c81c229-ae0d-44c1-bf0b-c4186628ff5f/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Sylvie Macmillan</span>
					</a>{" "}
					explains to Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
				<p>
					From Mugler to Mowalola to Maximilian, Sylvie Macmillan creates nails
					for fashion's coolest clientele. Having started her practice as a
					model-maker, Sylvie tells Lara she wasn't seeing the kinds of nails
					she wanted in fashion and that's what inspired her to break into the
					industry. Well, now she's creating the kind of cutting-edge talons we
					all want. In this episode of The Fashion Slashie: A ClickerMe Podcast,
					Sylvie Macmillan talks about the ability to be selective when taking
					on work, the importance of crediting your team and the cruel irony of
					the lack of nail tones for darker-skinned people in an industry that -
					as she eloquently puts it - 'was built upon the time, money and
					creativity of Black women.'
				</p>
				<p>
					The Fashion Slashie: A ClickerMe podcast has been recorded remotely,
					due to circumstances inflicted by the COVID-19 pandemic.
				</p>
			</>
		),
	},
	episode6: {
		number: "Episode 6",
		authorName: "Tayler Prince-Fraser",
		name: "Creative Director / Business Owner Tayler Prince-Fraser",
		date: "11.08.2020",
		additionalVideo: "true",
		description: (
			<>
				<p>
					‘Everyone has a brand and it’s about making your brand as appealing
					and serviceable as possible. What are you going to be able to give
					them that they don't have?’ - Creative Director / Business Owner{" "}
					<a
						href="https://clickerme.com/profiles/eb0b2e5b-b2db-408b-87f2-ad3485848c90/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Tayler Prince-Fraser</span>
					</a>{" "}
					talks to Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
			</>
		),
		thumbnail: require("Assets/Images/200804-artwork-fashion-slashie-tayler.jpg"),
		link: "/podcasts/the-fashion-slashie/tayler-prince-fraser",
		linkToApple:
			"https://podcasts.apple.com/us/podcast/how-does-tayler-prince-fraser-ensure-his-career-is/id1515273204?i=1000487743685",
		linkToSpotify:
			"https://open.spotify.com/episode/5u3xcrPU2CCgp68jcJuhHb?si=VjKctgosSfiBPoiWxEidEg",
		linkToGoogle:
			"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yMGU3Y2JjYy9wb2RjYXN0L3Jzcw/episode/OWEwMGM2ZTEtMDY3NS00MDlmLTk2MWEtNGM5MDAyZjM1Mzlm?sa=X&ved=0CAUQkfYCahcKEwiglKb11YntAhUAAAAAHQAAAAAQAg",
		SocialShareUrl:
			"https://www.clickerme.com/podcasts/the-fashion-slashie/tayler-prince-fraser",
		quote: "ClickerMe Podcasts / The Fashion Slashie / Tayler Prince-Fraser",
		videoURL: "https://youtu.be/uSgdprXUYts",
		spotifyEmbedded:
			"https://open.spotify.com/embed-podcast/episode/5u3xcrPU2CCgp68jcJuhHb",
		facebookTags: ["#clickerme, #thefashionslashie"],
		twitterTitle:
			"ClickerMe Podcasts / The Fashion Slashie / Tayler Prince-Fraser",
		via: "ClickerMe",
		twitterTags: ["clickerme,thefashionslashie"],
		related: "https://ww.linkedin.com/company/clickerme/",
		summary:
			"Everyone has a brand and it’s about making your brand as appealing and serviceable as possible. What are you going to be able to give them that they don't have?",
		source: "https://www.clickerme.com",
		notes: (
			<>
				<p>
					‘Everyone has a brand and it’s about making your brand as appealing
					and serviceable as possible. What are you going to be able to give
					them that they don't have?’ - Creative Director / Business Owner{" "}
					<a
						href="https://clickerme.com/profiles/eb0b2e5b-b2db-408b-87f2-ad3485848c90/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Tayler Prince-Fraser</span>
					</a>{" "}
					talks to Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
				<p>
					Tayler Prince-Fraser’s keen interest in brands and the cultural
					commentary clothes can provide has led him, in partnership with his
					brother Koen, to work with a plethora of clients including Nike, Sony
					and Louis Vuitton. Marrying his interest in sports and style, as well
					as a desire to influence social change, Tayler co-founded Last Pick
					Athletics Club (LPAC) and has directed editorials, documentaries and
					music videos. In this episode, Lara and Tayler discuss his work on
					@originalshift, an ever-expanding platform that provides crucial
					commentary on fashion and its intersections and unpick the purchase of
					a Supreme hat that was a pivotal piece in Tayler's burgeoning fashion
					career.
				</p>
				<p>
					The Fashion Slashie: A ClickerMe podcast has been recorded remotely,
					due to circumstances inflicted by the COVID-19 pandemic.
				</p>
			</>
		),
	},
	episode5: {
		number: "Episode 5",
		authorName: "Kate Iorga",
		name: "Stylist / Creative Director / Consultant Kate Iorga",
		date: "28.07.2020",
		additionalVideo: "true",
		description: (
			<>
				<p>
					‘See if anyone knows a way in, or maybe they know an email address. It
					really does come from literally an email address - that's all you
					need.’ Stylist / Creative Director / Consultant{" "}
					<a
						href="https://clickerme.com/profiles/dd0e68e7-8109-49ae-88ef-0a5bfb766dc3/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Kate Iorga</span>
					</a>{" "}
					chats to Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
			</>
		),
		thumbnail: require("Assets/Images/200513-artwork-fashion-slashie-kate.jpg"),
		link: "/podcasts/the-fashion-slashie/kate-iorga",
		linkToApple:
			" https://podcasts.apple.com/us/podcast/how-does-kate-iorga-approach-her-dream-collaborators/id1515273204?i=1000486343703",
		linkToSpotify: "https://open.spotify.com/episode/0Z7OlI44YnE3baPhB426E2",
		linkToGoogle:
			"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yMGU3Y2JjYy9wb2RjYXN0L3Jzcw/episode/MWUzMDFjYzMtYjc1My00YzJjLWFiNWItNjVjN2JjYTk1MGE0?sa=X&ved=2ahUKEwjqgvyol_XqAhUL1IUKHfdZCzgQkfYCegQIARAF",
		SocialShareUrl:
			"https://www.clickerme.com/podcasts/the-fashion-slashie/kate-iorga",
		quote: "ClickerMe Podcasts / The Fashion Slashie / Kate Iorga",
		videoURL: "https://youtu.be/ipuwMxd9OOg",
		spotifyEmbedded:
			"https://open.spotify.com/embed-podcast/episode/0Z7OlI44YnE3baPhB426E2",
		facebookTags: ["#clickerme, #thefashionslashie"],
		twitterTitle: "ClickerMe Podcasts / The Fashion Slashie / Kate Iorga",
		via: "ClickerMe",
		twitterTags: ["clickerme,thefashionslashie"],
		related: "https://ww.linkedin.com/company/clickerme/",
		summary:
			"See if anyone knows a way in, or maybe they know an email address. It really does come from literally an email address - that's all you need.",
		source: "https://www.clickerme.com",
		notes: (
			<>
				<p>
					‘See if anyone knows a way in, or maybe they know an email address. It
					really does come from literally an email address - that's all you
					need.’ Stylist / Creative Director / Consultant{" "}
					<a
						href="https://clickerme.com/profiles/dd0e68e7-8109-49ae-88ef-0a5bfb766dc3/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Kate Iorga</span>
					</a>{" "}
					chats to Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
				<p>
					An alumni of London College of Fashion, Kate Iorga's styling career
					kicked off at i-D, working alongside fashion editor Jack Borkett. From
					there, she assisted renowned stylist Jane How. Now a fully-fledged
					freelancer, Kate brings a distinctive penchant for powerfully feminine
					looks and raw sex appeal to her collaborations, often styling those
					she feels a symbiosis with. This characteristic has seen her work with
					the likes of Aquaria, Di Petsa, Rina Sawayama and Jorja Smith. A
					self-confessed Google Drive addict, Kate tells Lara about the need for
					rich references and the power of an email in this episode.
				</p>
				<p>
					The Fashion Slashie: A ClickerMe podcast has been recorded remotely,
					due to circumstances inflicted by the COVID-19 pandemic.
				</p>
			</>
		),
	},
	episode4: {
		number: "Episode 4",
		authorName: "Theo White",
		name: "Artist / Publisher / Art Director / Stylist Theo White",
		date: "14.07.2020",
		additionalVideo: "false",
		description: (
			<>
				<p>
					‘Everything that I do, really, is activism. A lot of my work has a
					sense about retelling black stories, it has the black narrative. That
					in itself is activism.' Artist / Publisher / Art Director / Stylist{" "}
					<a
						href="https://clickerme.com/profiles/dab85a71-037d-448b-b72f-50990a661817/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Theo White</span>
					</a>{" "}
					speaks to Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
			</>
		),
		thumbnail: require("Assets/Images/200706-artwork-fashion-slashie-theo.jpg"),
		link: "/podcasts/the-fashion-slashie/theo-white",
		linkToApple:
			"https://podcasts.apple.com/us/podcast/how-is-theo-white-using-fashion-to-celebrate-qtibipoc/id1515273204?i=1000484818912",
		linkToSpotify:
			"https://open.spotify.com/episode/3sCsDh5Vxr4xgYbAJhfTiH?si=1cYnv4Y6R9Gkn5YRhxHViQ",
		linkToGoogle:
			"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yMGU3Y2JjYy9wb2RjYXN0L3Jzcw/episode/NDk0Y2M4YmItM2I4Yi00MWQ0LTgyZGUtYjA5YzFhMTczZjg2?sa=X&ved=2ahUKEwjz77fKl_XqAhUIcxoKHRcmBGEQkfYCegQIARAF",
		SocialShareUrl:
			"https://www.clickerme.com/podcasts/the-fashion-slashie/theo-white",
		quote: "ClickerMe Podcasts / The Fashion Slashie / Theo White",
		videoURL: "",
		spotifyEmbedded:
			"https://open.spotify.com/embed-podcast/episode/3sCsDh5Vxr4xgYbAJhfTiH?si=1cYnv4Y6R9Gkn5YRhxHViQ",
		facebookTags: ["#clickerme, #thefashionslashie"],
		twitterTitle: "ClickerMe Podcasts / The Fashion Slashie / Theo White",
		via: "ClickerMe",
		twitterTags: ["clickerme,thefashionslashie"],
		related: "https://ww.linkedin.com/company/clickerme/",
		summary:
			"Everything that I do, really, is activism. A lot of my work has a sense about retelling black stories, it has the black narrative.",
		source: "https://www.clickerme.com",
		notes: (
			<>
				<p>
					‘Everything that I do, really, is activism. A lot of my work has a
					sense about retelling black stories, it has the black narrative. That
					in itself is activism.' Artist / Publisher / Art Director / Stylist{" "}
					<a
						href="https://clickerme.com/profiles/dab85a71-037d-448b-b72f-50990a661817/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Theo White</span>
					</a>{" "}
					speaks to Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
				<p>
					Jamaican-born and London-based, Theo White is a fashion creative who
					uses imagery to represent intersectional experiences. Theo has shown
					work with Soft Opening and at NOW Gallery, as well as working with the
					likes of Cosmo Pyke, WizKid and Say Lou Lou. During isolation, he
					brought together work from the likes of Anish Kapoor, Edward Enninful,
					Ib Kamara and Kai-Isiah Jamal in his latest publishing project, 6ft
					Zine, to raise funds for the UK QTIBIPOC Emergency relief & Hardship
					Fund. In this episode of The Fashion Slashie, Theo tells Lara about
					his interest in publishing and printed matter, his ‘juicy education’
					and the importance - particularly as a black male stylist - of having
					a diverse portfolio.
				</p>
				<p>
					The Fashion Slashie: A ClickerMe podcast has been recorded remotely,
					due to circumstances inflicted by the COVID-19 pandemic.
				</p>
			</>
		),
	},
	episode3: {
		number: "Episode 3",
		authorName: "Eva Losada",
		name: "Photographer / Creative Director Eva Losada",
		date: "23.06.2020",
		additionalVideo: "true",
		description: (
			<>
				<p>
					‘Socialise. Go to fashion events, fashion parties, try to talk to as
					many people as possible. Fashion weeks are always a good moment,’
					Photographer / Creative Director{" "}
					<a
						href="https://clickerme.com/profiles/b6e7a415-2ef6-42a3-8ba2-7792c2ea6434/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Eva Losada</span>
					</a>{" "}
					tells Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
			</>
		),
		thumbnail: require("Assets/Images/200512-artwork-fashion-slashie-eva.jpg"),
		link: "/podcasts/the-fashion-slashie/eva-losada",
		linkToApple:
			"https://podcasts.apple.com/us/podcast/what-does-eva-losada-advise-to-further-your-fashion-career/id1515273204?i=1000479216637",
		linkToSpotify: "https://open.spotify.com/episode/5aogSRimud7TDxev4uAUO7",
		linkToGoogle:
			"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yMGU3Y2JjYy9wb2RjYXN0L3Jzcw/episode/Njk3NTlmYTQtNmMwMC00MjIwLThjZGItODFlYmYxY2Y3OTA4?ved=0CA8Q38oDahcKEwjQ24iCnprqAhUAAAAAHQAAAAAQAQ",
		SocialShareUrl:
			"https://www.clickerme.com/podcasts/the-fashion-slashie/eva-losada",
		quote: "ClickerMe Podcasts / The Fashion Slashie / Eva Losada",
		videoURL: "https://youtu.be/BRWyfmZz-E8",
		spotifyEmbedded:
			"https://open.spotify.com/embed-podcast/episode/5aogSRimud7TDxev4uAUO7",
		facebookTags: ["#clickerme, #thefashionslashie"],
		twitterTitle: "ClickerMe Podcasts / The Fashion Slashie / Eva Losada",
		via: "ClickerMe",
		twitterTags: ["clickerme,thefashionslashie"],
		related: "https://ww.linkedin.com/company/clickerme/",
		summary:
			"Socialise. Go to fashion events, fashion parties, try to talk to as many people as possible. Fashion weeks are always a good moment",
		source: "https://www.clickerme.com",
		notes: (
			<>
				<p>
					‘Socialise. Go to fashion events, fashion parties, try to talk to as
					many people as possible. Fashion weeks are always a good moment,’
					Photographer / Creative Director{" "}
					<a
						href="https://clickerme.com/profiles/b6e7a415-2ef6-42a3-8ba2-7792c2ea6434/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Eva Losada</span>
					</a>{" "}
					tells Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
				<p>
					While she wouldn’t refer to herself as a stylist, Eva Losada, also
					known as Eva Al Desnudo, has a distinctive style that has certainly
					shaped her career. Having started out in the industry as a street
					style photographer, Eva was snapped in her signature dark lip and
					stand-out selection of clothes as often as she herself was snapping.
					She now covers the global fashion weeks, working backstage at brands
					such as Dior, Raf Simons, Undercover and more. A regular contributor
					at Highsnobiety, Eva has been nominated within the British Fashion
					Awards’ New Wave: Creatives category twice. In this episode, she talks
					to Lara about her aesthetic, meditation in the face of rejection and
					building a fashion community.
				</p>
				<p>
					The Fashion Slashie: A ClickerMe podcast has been recorded remotely,
					due to circumstances inflicted by the COVID-19 pandemic.
				</p>
			</>
		),
	},
	episode2: {
		number: "Episode 2",
		authorName: "Rob Rusling",
		name: "Photographer / Artist Rob Rusling",
		date: "25.05.2020",
		additionalVideo: "true",
		description: (
			<>
				<p>
					'It doesn't mean that the idea has got to be any less, but there are
					cheap ways to do things,' Photographer / Artist{" "}
					<a
						href="https://clickerme.com/profiles/37deed00-4f82-475a-9f16-3ef233d4b3b8/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Rob Rusling</span>
					</a>{" "}
					says, in conversation with Writer / Editor / Broadcaster Lara
					Johnson-Wheeler.
				</p>
			</>
		),
		thumbnail: require("Assets/Images/200512-artwork-fashion-slashie-rob.jpg"),
		link: "/podcasts/the-fashion-slashie/rob-rusling",
		linkToApple:
			"https://podcasts.apple.com/us/podcast/how-does-rob-rusling-create-fashion-imagery-to-a-budget/id1515273204?i=1000475815338",
		linkToSpotify:
			"https://open.spotify.com/episode/5gy2Qv911DGn32OcT2AtRb?si=t23Y0NCVQAa3df57-kVPIQ",
		linkToGoogle:
			"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yMGU3Y2JjYy9wb2RjYXN0L3Jzcw/episode/OTIzZThlMGItYTE1Yy00OTM4LWEyZGItMjgzNjQxY2QyNWIx?ved=0CAcQ38oDahcKEwioiPyqo-PpAhUAAAAAHQAAAAAQAQ",
		SocialShareUrl:
			"https://www.clickerme.com/podcasts/the-fashion-slashie/rob-rusling",
		quote: "ClickerMe Podcasts / The Fashion Slashie / Rob Rusling",
		videoURL: "https://www.youtube.com/watch?v=N33B2LSneX0",
		spotifyEmbedded:
			"https://open.spotify.com/embed-podcast/episode/5gy2Qv911DGn32OcT2AtRb",
		facebookTags: ["#clickerme, #thefashionslashie"],
		twitterTitle: "ClickerMe Podcasts / The Fashion Slashie / Rob Rusling",
		via: "ClickerMe",
		twitterTags: ["clickerme,thefashionslashie"],
		related: "https://www.linkedin.com/company/clickerme/",
		summary:
			"It doesn't mean that the idea has got to be any less, but there are cheap ways to do things",
		source: "https://www.clickerme.com",
		notes: (
			<>
				<p>
					'It doesn't mean that the idea has got to be any less, but there are
					cheap ways to do things, ' Photographer / Artist{" "}
					<a
						href="https://clickerme.com/profiles/37deed00-4f82-475a-9f16-3ef233d4b3b8/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Rob Rusling</span>
					</a>{" "}
					says, in conversation with Writer / Editor / Broadcaster Lara Johnson
					- Wheeler.
				</p>
				<p>
					From shooting campaigns for A-COLD-WALL* to collaborating with
					Jazzelle Zanaughtti (@uglyworldwide), Rob's career spans the
					commercial as well as the cultural. Formerly Nick Knight's assistant,
					Rob worked at SHOWstudio for 3 years before branching out as a
					freelancer. In this episode, Lara and Rob chat from isolation,
					considering commercial fashion photography, the impact of visual
					culture on society and how to reduce costs for a shoot.
				</p>
				<p>
					The Fashion Slashie: A ClickerMe podcast has been recorded remotely,
					due to circumstances inflicted by the COVID-19 pandemic.
				</p>
			</>
		),
	},
	episode1: {
		number: "Episode 1",
		authorName: "Nassia Matsa",
		name: "Model / Writer / Social Media Coordinator Nassia Matsa",
		date: "25.05.2020",
		additionalVideo: "true",
		description: (
			<>
				<p>
					'We all struggle putting the correct price, but if you undersell
					yourself, you devalue your product, and your product is you and your
					strengths.' Model / Writer / Social Media Coordinator{" "}
					<a
						href="https://clickerme.com/profiles/57a30f85-01b2-4258-84af-9a21272c58a3/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Nassia Matsa</span>
					</a>{" "}
					talks to Writer / Editor / Broadcaster Lara Johnson- Wheeler.
				</p>
			</>
		),
		thumbnail: require("Assets/Images/200512-artwork-fashion-slashie-nassia.jpg"),
		link: "/podcasts/the-fashion-slashie/nassia-matsa",
		linkToApple:
			"https://podcasts.apple.com/us/podcast/how-does-nassia-matsa-place-value-on-her-work/id1515273204?i=1000475815337",
		linkToSpotify:
			"https://open.spotify.com/episode/3sZMFtcfeF2w9Lfxxrmzab?si=zhQnsy2ESXOA2kl2RK9PZQ",
		linkToGoogle:
			"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yMGU3Y2JjYy9wb2RjYXN0L3Jzcw/episode/ZDk2NTQ2NDgtOGU4Ni00ZTk5LTk4NGQtZTA1MWMwMWVkNGM4?ved=0CAkQzsICahcKEwioiPyqo-PpAhUAAAAAHQAAAAAQAQ",
		videoURL: "https://www.youtube.com/watch?v=nY3R8Q9XUbg&feature=youtu.be",
		spotifyEmbedded:
			"https://open.spotify.com/embed-podcast/episode/3sZMFtcfeF2w9Lfxxrmzab",
		SocialShareUrl:
			"https://www.clickerme.com/podcasts/the-fashion-slashie/nassia-matsa",
		quote: "ClickerMe Podcasts / The Fashion Slashie / Nassia Matsa",
		facebookTags: ["#clickerme, #thefashionslashie"],
		twitterTitle: "ClickerMe Podcasts / The Fashion Slashie / Nassia Matsa",
		via: "ClickerMe",
		twitterTags: ["clickerme,thefashionslashie"],
		related: "https://www.linkedin.com/company/clickerme/",
		summary:
			"We all struggle putting the correct price, but if you undersell yourself, you devalue your product, and your product is you and yourstrengths.",
		source: "https://www.clickerme.com",
		notes: (
			<>
				<p>
					'We all struggle putting the correct price, but if you undersell
					yourself, you devalue your product, and your product is you and your
					strengths.' Model / Writer / Social Media Coordinator{" "}
					<a
						href="https://clickerme.com/profiles/57a30f85-01b2-4258-84af-9a21272c58a3/portfolio"
						target="_blank"
						style={{ color: "black" }}
					>
						<span>Nassia Matsa</span>
					</a>{" "}
					talks to Writer / Editor / Broadcaster Lara Johnson-Wheeler.
				</p>
				<p>
					You may recognise Nassia's striking looks from Martine Rose's runway,
					Dilara Findikoglu's imagery, Prada's Cloudbust campaign video. Or
					perhaps from her bylines in the likes of Dazed, Wired and LOVE. Based
					in London, having originated from Athens, Nassia is currently Social
					Editorial Coordinator at Vogue. In this episode, Lara and Nassia meet
					via video call, from lockdown, to discuss competition, comparison and
					how to craft a unique personal brand.
				</p>
				<p>
					The Fashion Slashie: A ClickerMe podcast has been recorded remotely,
					due to circumstances inflicted by the COVID-19 pandemic.
				</p>
			</>
		),
	},
};
export default Episodes;
