import React, { useState, useEffect } from 'react';
import { NavLink, WrapIcon, WrapExpandedMenu } from './styles';
import IconButton from 'Components/IconButton';
import { Link, useHistory, useLocation } from 'react-router-dom';

const contractIcon = require('Assets/Images/contract-icon.svg');
const expandIcon = require('Assets/Images/expand-icon.svg');

function wrappedIcon(icon) {
	return icon;
}

function MenuLink({
	name,
	borderTop,
	expandedValue,
	sublinks,
	onClick,
	pathname,
	menuOpen,
	handleToggleMenu,
}) {
	return (
		<>
			<NavLink style={{ borderTop: borderTop }} onClick={onClick}>
				{name}
				<WrapIcon>
					<IconButton>
						<img
							src={
								expandedValue
									? wrappedIcon(contractIcon)
									: wrappedIcon(expandIcon)
							}
						></img>
					</IconButton>
				</WrapIcon>
			</NavLink>

			{expandedValue && (
				<WrapExpandedMenu>
					{sublinks.map((sublink) => {
						return (
							<NavLink
								key={sublink[0]}
								style={{ borderTop: 0, paddingLeft: '1rem', fontSize: '0.9em' }}
								to={'/' + sublink[0]}
								active={pathname?.includes('/' + sublink[0])}
								menuOpen={menuOpen}
								onClick={handleToggleMenu}
							>
								{sublink[1]}
							</NavLink>
						);
					})}
				</WrapExpandedMenu>
			)}
		</>
	);
}

const AccessibleLinks = ({
	firstRow,
	menuOpen,
	expanded,
	shandleToggleMenu,
	handleExpanded,
	pathname,
	handleToggleMenu,
	loggedIn,
}) => {
	let links = [
		['about', 'About'],
		['verification', 'Verification'],
		['how-it-works', 'How It Works'],
		['faqs', 'FAQs'],
	];

	return (
		<>
			<MenuLink
				name={'Opportunities'}
				borderTop={firstRow}
				expandedValue={expanded.opportunities}
				onClick={() => handleExpanded('opportunities')}
				pathname={pathname}
				handleToggleMenu={handleToggleMenu}
				menuOpen={menuOpen}
				sublinks={[
					['jobs', 'Jobs'],
					['competitions', 'Competitions'],
					['open-submissions', 'Live Briefs'],
				]}
			/>

			<MenuLink
				name={'Inspiration'}
				expandedValue={expanded.inspiration}
				onClick={() => handleExpanded('inspiration')}
				pathname={pathname}
				handleToggleMenu={handleToggleMenu}
				menuOpen={menuOpen}
				sublinks={[
					['podcasts/the-fashion-slashie', 'Podcasts'],
					['features', 'Features'],
					['gallery', 'Gallery'],
				]}
			/>

			<MenuLink
				name={'About ClickerMe'}
				expandedValue={expanded.about}
				onClick={() => handleExpanded('about')}
				pathname={pathname}
				handleToggleMenu={handleToggleMenu}
				menuOpen={menuOpen}
				sublinks={loggedIn ? [...links, ['feedback', 'Feedback']] : links}
			/>
		</>
	);
};
export default AccessibleLinks;
