// @flow
import React from "react";
import { useState, useEffect } from "react";
import CaptionForm from "./CaptionForm";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
  ImageContainer,
  LightboxImage,
  Caption,
  Close,
  NavIcon
} from "./styles";
import "./override-styles.css";

type Props = {
  onClose: Function,
  images: Array<Object>,
  lightboxIndex: Number | String,
  projectId?: Number | String,
  updateImages?: Function
};

function Lightbox({
  onClose,
  images,
  lightboxIndex,
  projectId,
  updateImages
}: Props) {
  const views = images
    .filter(
      image => !["video", "youtube", "vimeo"].includes(image.resource_type)
    )
    .map(image => {
      return {
        caption: image?.caption,
        src: image?.transformed_url || image?.url,
        resourceType: image.resource_type
      };
    });

  const [viewportWidth, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  function onSuccess(image) {
    updateImages(image);
    onClose();
  }

  return (
    <ModalGateway>
      <Modal
        onClose={onClose}
        styles={{
          blanket: base => ({
            ...base,
            zIndex: 1100,
            backdropFilter: "blur(10px)"
          }),
          positioner: base => ({ ...base, zIndex: 1110 }),
          dialog: base => ({ ...base, zIndex: 1120 })
        }}
      >
        <Carousel
          currentIndex={lightboxIndex}
          views={views}
          showNavigationOnTouchDevice={false}
          components={{
            HeaderClose: ({ innerProps }) => (
              <Close
                src={require("Assets/Images/lightbox-close-icon.svg")}
                {...innerProps}
              />
            ),
            HeaderFullscreen: () => null,
            View: ({ data }) => (
              <ImageContainer style={{ maxWidth: "30vw" }}>
                <LightboxImage {...data} />
              </ImageContainer>
            ),
            Footer: props =>
              projectId ? (
                <CaptionForm
                  {...props}
                  projectId={projectId}
                  images={images}
                  onSuccess={onSuccess}
                />
              ) : (
                <Caption>
                  {props.currentView?.caption === "false"
                    ? ""
                    : props.currentView?.caption}
                </Caption>
              ),
            NavigationPrev: ({ innerProps }) => (
              <NavIcon
                src={require("Assets/Images/previous-arrow.svg")}
                {...innerProps}
              />
            ),
            NavigationNext: ({ innerProps, currentIndex }) => (
              <>
                {currentIndex === 0 ? <div /> : null}
                <NavIcon
                  src={require("Assets/Images/next-arrow.svg")}
                  {...innerProps}
                />
              </>
            )
          }}
        />
      </Modal>
    </ModalGateway>
  );
}

export default Lightbox;
