// @flow
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "Hooks/useAuth";
import { useFetch } from "Hooks/useFetch";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import ContentError from "Components/ContentError";
import ContentLoading from "Components/ContentLoading";
import ProjectForm from "Components/ProjectForm";

const EditProject = props => {
  const params = new URLSearchParams(props.location.search);
  const redirect = params.get("redirect");
  let history = useHistory();
  const {
    auth: { user }
  } = useAuth();
  const { id } = useParams();
  const { data, loading, error } = useFetch({ url: `/api/projects/${id}` });

  useEffect(() => {
    if (data?.project?.profile?.id && user?.id !== data.project.profile.id)
      history.push(`/projects/${id}`);
  }, [data]);

  if (loading) return <ContentLoading />;
  if (error) return <ContentError />;

  return (
    <>
      <Helmet>
        <title>{data?.project?.title || "New Project"}</title>
      </Helmet>
      <ProjectForm project={data?.project} redirect={redirect} />
    </>
  );
};

export default EditProject;
