import React, { useState, useRef } from 'react';
import { useFetch } from 'Hooks/useFetch';
import { Link, useHistory } from 'react-router-dom';
import Button from 'Components/Button';
import {
	GlobalWrapper,
	BrandCaseStudies,
	Title,
	Description,
	EachCase,
	Image,
	Cases,
	FlashSale,
	Introduction,
	Enquiry,
	EnquiryDescription,
	Container,
	EnquiryForm,
	SectionTitle,
	FormColumn,
	Form,
	EmailAddress,
	WrapTitleDescription,
	HighlightedTitle,
	SlashImage,
} from './styles';
import BrandLogos from 'Components/BrandLogos';
import Notification from '../../Components/Notification';
import { useValidation, useTextInput, useCheckboxInput } from 'Hooks';
import FormField from '../../Components/FormField';
import Input from '../../Components/Input';
import { useLazyFetch } from '../../Hooks/useLazyFetch';
import TextBox from '../../Components/TextBox';
import ContentError from '../../Components/ContentError';

function Brands() {
	const { data, error: errorPage } = useFetch({
		url: `/api/pages/new-brand`,
	});

	let history = useHistory();
	const bothDiy = `/gallery/both-diy`;
	const bothAw20 = `/gallery/both-aw20`;
	const [error, setError] = useState(null);
	const [message, setMessage] = useState('');
	const name = useTextInput();
	const email = useTextInput();
	const phone = useTextInput();
	const jobTitle = useTextInput();
	const [validate, errors] = useValidation(
		{
			name: name.value,
			email: email.value,
			phone: phone.value,
			jobTitle: jobTitle.value,
			message: message,
		},
		{
			name: {
				presence: {
					allowEmpty: false,
				},
			},
			email: {
				email: true,
				presence: {
					allowEmpty: false,
				},
			},
			phone: {
				presence: {
					allowEmpty: true,
				},
			},
			jobTitle: {
				presence: {
					allowEmpty: false,
				},
			},
			message: {
				presence: {
					allowEmpty: false,
				},
			},
		}
	);

	const [submit, response] = useLazyFetch({
		url: '/api/brands',
		method: 'post',
	});

	function signUp(e) {
		e.preventDefault();
		const valid = validate();
		if (valid)
			submit(
				{
					brand: {
						name: name.value,
						email: email.value,
						phone: phone.value,
						job_title: jobTitle.value,
						message: message,
					},
				},
				onSuccess,
				onError
			);
	}

	function onSuccess() {
		setError(null);
		history.push('/thank-you');
	}

	function onError() {
		setError('Sorry, we could not send the enquiry');
	}

	const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop / 1.3);
	const myRef = useRef(null);

	if (error) return <ContentError />;

	if (data) {
		const sections = data?.page?.blocks;
		const topSection = sections[0];
		const contactSection = sections[1];
		const offerSection = sections[2];
		const streamlinesSection = sections[3];
		const clientsSection = sections[4];
		const testimonialsSection = sections[5];
		const saleSection = sections[6];

		return (
			<>
				<GlobalWrapper>
					<Introduction>
						<SlashImage style={{ height: '30rem' }} />
						<FlashSale
							style={{ maxWidth: '800px', height: '30rem', zIndex: '10' }}
						>
							<Title
								style={{
									fontSize: '14px',
									color: 'rgb(255, 242, 0)',
									zIndex: '10',
								}}
							>
								{topSection.subsections[0].title}
							</Title>
							<Title
								largerFont={true}
								isHeading={true}
								style={{ zIndex: '10' }}
							>
								{topSection.title}
							</Title>
							<Description isHeading={true} style={{ zIndex: '10' }}>
								{topSection.subsections[1].description}
								<br />
								<br />
								{topSection.subsections[2].description}
							</Description>
						</FlashSale>
					</Introduction>
					<Enquiry ref={myRef}>
						<Container>
							{error && <Notification>{error}</Notification>}
							<Title largerFont={true} isHeading={true}>
								{contactSection.title}
							</Title>
							<EnquiryDescription>
								{contactSection.description}{' '}
								<EmailAddress href={`mailto:${ contactSection.subsections[0].title }`}>
									{ contactSection.subsections[0].title }
								</EmailAddress>
							</EnquiryDescription>
							<EnquiryForm>
								<Form onSubmit={signUp}>
									<FormColumn>
										<FormField
											errors={errors?.name}
											label={'Name'}
											labelColor={'white'}
										>
											<Input placeholder="Name" {...name} />
										</FormField>
										<FormField
											errors={errors?.email}
											label={'Email address'}
											labelColor={'white'}
										>
											<Input type="email" placeholder="Email" {...email} />
										</FormField>
										<FormField
											errors={errors?.phone}
											label={'Phone number'}
											optionalLabel={'Optional'}
											labelColor={'white'}
										>
											<Input placeholder="Phone" {...phone} />
										</FormField>
										<FormField
											errors={errors?.jobTitle}
											label={'Job title'}
											labelColor={'white'}
										>
											<Input placeholder="Job Title" {...jobTitle} />
										</FormField>
									</FormColumn>
									<FormColumn>
										<FormField
											errors={errors?.message}
											label={'What are you looking for?'}
											labelColor={'white'}
										>
											<TextBox
												value={message}
												placeholder={''}
												onChange={(e) => setMessage(e.target.value)}
												style={{ height: '12rem', margin: '.2rem 0' }}
											/>
										</FormField>
										<Button
											type="submit"
											style={{ width: '70%', marginLeft: '15%' }}
										>
											{response?.loading ? 'Sending...' : contactSection.button}
										</Button>
									</FormColumn>
								</Form>
							</EnquiryForm>
						</Container>
					</Enquiry>
					<FlashSale style={{ height: '25rem' }}>
						<SlashImage style={{ height: '25rem' }} />
						<Title style={{ zIndex: '10' }} isHeading={true} largerFont={true}>
							{offerSection.title}
						</Title>
						<Description style={{ zIndex: '10' }}>
							{offerSection.description}
						</Description>
						<Title
							largerFont={true}
							style={{ color: 'rgb(255, 242, 0)', zIndex: '10' }}
						>
							{offerSection.subsections[0].title}
						</Title>
						<Button
							onClick={() => {
								scrollToRef(myRef);
							}}
							style={{ padding: '1rem 3rem', zIndex: '10' }}
						>
							{offerSection.button}
						</Button>
					</FlashSale>
					<BrandCaseStudies>
						<div style={{ maxWidth: `590px`, margin: 'auto' }}>
							<Title largerFont={true} isHeading={true}>
								{streamlinesSection.title}
							</Title>
						</div>
						<Cases flexbox={false}>
							<EachCase flexbox={true}>
								<Image
									largerImage={true}
									src={require('Assets/Images/brands1.jpg')}
								/>
								<WrapTitleDescription>
									<HighlightedTitle>
										{streamlinesSection.subsections[0].title}
									</HighlightedTitle>
									<Description alignLeft={true} padded={true}>
										{streamlinesSection.subsections[0].description}
									</Description>
								</WrapTitleDescription>
							</EachCase>
							<EachCase flexbox={true} flip={true}>
								<WrapTitleDescription>
									<HighlightedTitle style={{ textAlign: 'right' }}>
										{streamlinesSection.subsections[1].title}
									</HighlightedTitle>
									<Description
										alignLeft={true}
										padded={true}
										style={{ textAlign: 'right' }}
									>
										{streamlinesSection.subsections[1].description}
									</Description>
								</WrapTitleDescription>
								<Image
									largerImage={true}
									src={require('Assets/Images/brands2.jpg')}
								/>
							</EachCase>
							<EachCase flexbox={true}>
								<Image
									largerImage={true}
									src={require('Assets/Images/brands3.jpg')}
								/>
								<WrapTitleDescription>
									<HighlightedTitle>
										{streamlinesSection.subsections[2].title}
									</HighlightedTitle>
									<Description padded={true} alignLeft={true}>
										{streamlinesSection.subsections[2].description}
									</Description>
								</WrapTitleDescription>
							</EachCase>
						</Cases>
					</BrandCaseStudies>
					<BrandLogos />
					<BrandCaseStudies>
						<div style={{ maxWidth: `590px`, margin: 'auto' }}>
							<Title largerFont={true} isHeading={true}>
								{testimonialsSection.title}
							</Title>
							<Description isHeading={true}>
								{testimonialsSection.description}
								<br />
								<br />
								<span style={{ fontWeight: 'bold' }}>
									{testimonialsSection.subsections[0].title}
								</span>
							</Description>
						</div>
						<Cases>
							<EachCase>
								<Image
									largerImage={true}
									src={require('Assets/Images/both2x.png')}
								/>
								<Title>{testimonialsSection.subsections[1].title}</Title>
								<Description alignLeft={true}>
									{testimonialsSection.subsections[1].description}
								</Description>
								<Link to={bothDiy} style={{ color: 'rgb(255, 242, 0)' }}>
									View the collaboration
								</Link>
							</EachCase>
							<EachCase>
								<Image
									largerImage={true}
									src={require('Assets/Images/both2.png')}
								/>
								<Title>{testimonialsSection.subsections[2].title}</Title>
								<Description alignLeft={true}>
									{testimonialsSection.subsections[2].description}
								</Description>
								<Link to={bothAw20} style={{ color: 'rgb(255, 242, 0)' }}>
									View the collaboration
								</Link>
							</EachCase>
						</Cases>
					</BrandCaseStudies>
					<FlashSale style={{ height: '30rem' }}>
						<SlashImage style={{ height: '30rem' }}></SlashImage>
						<Title style={{ zIndex: '10' }} isHeading={true} largerFont={true}>
							{saleSection.title}
						</Title>
						<p style={{ zIndex: '10' }}>{saleSection.description}</p>
						<Title
							largerFont={true}
							style={{ color: 'rgb(255, 242, 0)', zIndex: '10' }}
						>
							<span style={{ textDecoration: 'line-through' }}>
								{saleSection.subsections[0].title}
							</span>{' '}
							{saleSection.subsections[1].title}
						</Title>
						<Button
							onClick={() => {
								scrollToRef(myRef);
							}}
							style={{ padding: '1rem 3rem', zIndex: '10' }}
						>
							{saleSection.button}
						</Button>
					</FlashSale>
				</GlobalWrapper>
			</>
		);
	}

	return null;
}

export default Brands;
