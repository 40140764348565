// @flow
import { createStore } from "redux";
import { persistStore } from "redux-persist";
import Middleware from "Store/Middleware";
import Reducers from "Reducers";
import { history } from "Store/Middleware/Router";

const store = createStore(Reducers(history), Middleware);

const configureStore = () => {
  let persistor = persistStore(store);
  return { persistor, store };
};

export default store;
export { configureStore };
