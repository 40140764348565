// @flow
import React from "react";
import { Helmet } from "react-helmet";
import BlockContainer from "Components/BlockContainer";

function AcceptableUse() {
  return (
    <>
      <Helmet>
        <title>Acceptable Use Policy</title>
      </Helmet>
      <BlockContainer name="acceptable-use-policy" />
    </>
  );
}

export default AcceptableUse;
