import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "Components/Button";

export const Heading = styled.h2(
  ({ theme }) => `
  padding: ${theme.spacing * 5}rem ${theme.spacing}rem ${theme.spacing}rem;
  font-size: 26px;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    text-align: center;
  }
`
);

export const Background = styled.div(
  ({ theme }) => `
  background: ${theme.colors.white};
  padding-bottom: ${theme.spacing * 4}rem;
  margin-top: ${theme.spacing * 3}rem;
`
);

export const List = styled.ul(
  ({ theme, featured }) => `
  max-width: ${theme.maxWidth}px;
  margin: 0;
  padding: 0;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin: ${featured ? theme.spacing : theme.spacing * 3}rem auto;
  }
`
);

export const ListItem = styled.li(
  ({ theme, featured }) => `
  display: flex;
  flex-direction: column;
  margin: ${featured ? theme.spacing * 1.0 : `0 ${theme.spacing}`}rem;
  border-bottom: ${featured ? 0 : `1px solid ${theme.colors.grey()}`};
  ${List} &:first-child {
    border-top: ${featured ? 0 : `1px solid ${theme.colors.grey()}`};
  };
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    flex-direction: row;
    margin-left: ${featured ? theme.spacing : theme.spacing * 8}rem;
    margin-right: ${theme.spacing / 2}rem;
    margin-bottom: ${featured ? "50px" : 0};
  }
`
);

export const Container = styled.div(
  ({ theme, featured }) => `
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${theme.colors.white};
  padding: ${theme.spacing}rem;
  padding-top: ${featured ? theme.spacing * 3 : theme.spacing * 2}rem;
  box-shadow: ${featured ? theme.shadow : "none"};
  position: relative;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    flex-direction: row;
    justify-content: space-between;
    margin-left: ${featured ? theme.spacing : 0}rem;
    padding: ${theme.spacing * 2}rem;
  }
`
);

export const Details = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    align-items: normal;
  }
`
);

export const Logo = styled.img(
  ({ theme }) => `
  width: 100px;
  height: 100px;
  box-shadow: ${theme.shadow};
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    width: 75px;
    height: 75px;
    margin-left: ${theme.spacing * 2}rem;
    margin-bottom: -${theme.spacing * 2}rem;
    z-index: ${theme.zIndex.middle};
  }
`
);

export const Title = styled.span(
  ({ theme }) => `
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: ${theme.spacing / 4}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    font-size: 22px;
  }
`
);

export const Info = styled.span(
  ({ theme }) => `
  font-size: ${theme.fonts.size.normal};
  font-weight: bold;
  line-height: 1.4;
`
);

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: black;
  min-width: 300px;
  text-align: left;
  width: 100%;
`;

export const Date = styled.span(
  ({ theme }) => `
  color: ${theme.colors.grey("mid")};
  font-size: ${theme.fonts.size.small};
  white-space: nowrap;
`
);

export const Summary = styled.p(
  ({ theme }) => `
  color: ${theme.colors.grey("mid")};
  font-size: ${theme.fonts.size.small};
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    max-width: 70%;
  }
`
);

export const ActionContainer = styled.div(
  ({ theme }) => `
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: ${theme.spacing * 2}rem;
  }
`
);

export const StyledButton = styled(Button)(
  ({ theme }) => `
  width: 200px;
  text-align: center;
  padding: ${theme.spacing / 2}rem ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin: 0;
    padding: ${theme.spacing / 2}rem ${theme.spacing * 2}rem;
  }
`
);

export const Badge = styled.div(
  ({ theme }) => `
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 72px;
`
);
