import styled from "styled-components";
import { Link } from "react-router-dom";

export const Columns = styled.div(
  ({ theme, display }) => `
  padding-bottom: ${theme.spacing * 3}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: ${display === "horizontally" ? "block" : "flex"};
    flex-direction: row;
    padding-bottom: ${display === "horizontally" ? "0" : "2rem"};
   }
`
);

export const StyledImage = styled.img(
  ({ theme, display }) => `
min-width: 285px;
width: 285px;
min-height: 285px;
margin-bottom: ${theme.spacing}rem;
margin-right: ${display === "horizontally" ? "0rem" : "2rem"};
`
);

export const Title = styled.div(
  ({ theme, display }) => `
display: flex;
h3{
  line-height: 1.21;
  font-size: ${
    display === "horizontally"
      ? theme.fonts.size.medium / 1.2
      : theme.fonts.size.medium
  };
  max-width: ${theme.maxWidth / 2}px;
  word-wrap: break-word;
  padding-right: ${theme.spacing}rem;
}
`
);

export const Icon = styled.img(
  ({ theme }) => `
padding-right: ${theme.spacing}rem;
`
);

export const Date = styled.p(
  ({ theme }) => `
font-size: ${theme.fonts.size.small};
color: ${theme.colors.grey("dark")};
line-height: 1.83;
`
);

export const Text = styled.p(
  ({ theme, display }) => `
line-height: 1.5;
max-width: ${theme.maxWidth / 2}px;
display: ${display === "horizontally" ? "none" : ""};
`
);

export const LinkThat = styled(Link)(
  ({ theme, display }) => `
display: ${display === "horizontally" ? "none" : ""};
`
);
