import styled from "styled-components";

export const StyledBanner = styled.div(
  ({ theme }) => `
    background: ${theme.colors.yellow("default")};
    height:auto;
    display:flex;
    flex-direction: row-reverse;
    margin:auto;
    justify-content:center;
    cursor: pointer;
    align-items:center;
  `
);
export const Heading = styled.h2(
  ({ theme }) => `
font-size: ${theme.fonts.size.small};
padding: ${theme.spacing / 2}rem;
`
);
