// @flow
import styled from "styled-components";

export const Background = styled.div(
  ({ theme, image }) => `
  background-image: ${image?.url ? `url(${image?.url})` : "none"};
  background-color: ${theme.colors.black};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: auto;
  min-height: 280px;
  display: flex;

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    min-height: 200px;
  }
`
);

export const TextContainer = styled.div(
  ({ theme, text_alignment }) => `
  font-weight: normal;
  padding: ${theme.spacing}rem;
  margin: auto;
  max-width: ${theme.maxWidth}px;
  width: 100%;
  display: flex;
  justify-content: ${
    text_alignment === "left"
      ? "flex-start"
      : text_alignment === "right"
      ? "flex-end"
      : text_alignment
  };
  align-items: center;
`
);

export const Text = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidth / 2.5}px;
`
);
