import React, { useState, useEffect, useRef } from "react";
import { StyledInput, StyledImage, AlignIcon } from "./styles";
import "./search.css";

function SearchLocationInput({ placeholder, setLocation, value }) {
	const [query, setQuery] = useState("");
	const autoCompleteRef = useRef(null);
	const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

	useEffect(() => {
		loadScript(
			`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=en`,
			() => handleScriptLoad(setQuery, autoCompleteRef)
		);
	}, []);

	let autoComplete;

	const loadScript = (url, callback) => {
		let script = document.createElement("script");
		script.type = "text/javascript";

		if (script.readyState) {
			script.onreadystatechange = function() {
				if (
					script.readyState === "loaded" ||
					script.readyState === "complete"
				) {
					script.onreadystatechange = null;
					callback();
				}
			};
		} else {
			script.onload = () => callback();
		}

		script.src = url;
		document.getElementsByTagName("head")[0].appendChild(script);
	};

	function handleScriptLoad(updateQuery, autoCompleteRef) {
		autoComplete = new window.google.maps.places.Autocomplete(
			autoCompleteRef.current,
			{ types: ["(cities)"] }
		);
		autoComplete.setFields(["formatted_address"]);
		autoComplete.addListener("place_changed", () =>
			handlePlaceSelect(updateQuery)
		);
	}

	async function handlePlaceSelect(updateQuery) {
		const addressObject = autoComplete.getPlace();
		const result = addressObject.formatted_address;
		updateQuery(result);
		setLocation(result);
	}

	return (
		<>
			<AlignIcon>
				<StyledImage src={require("Assets/Images/search.png")}></StyledImage>
				<StyledInput
					ref={autoCompleteRef}
					onChange={(event) => setQuery(event.target.value)}
					placeholder={placeholder}
					value={query}
				/>
			</AlignIcon>
		</>
	);
}

export default SearchLocationInput;
