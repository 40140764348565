// @flow
import React, { useState } from "react";
import {addIcon, editIcon, closedIcon, completeIcon} from '../../Helpers/Icons';

import {
  Header,
  Title,
  Description,
  OpenContainer,
} from "./styles";
import {useHistory} from "react-router-dom";

const Step = ({text, description, children, isDone, checkIfDone, linkTo, isOpen, setIsOpen} : Props): React.Node => {
  let history = useHistory();

  const toggle = () => {
    setIsOpen(!isOpen);
    if (checkIfDone) checkIfDone();
  };

  const redirectTo = () => {
    history.push(linkTo);
  };

  let icon = isOpen ? closedIcon : (isDone ? completeIcon : addIcon);

  return <>
    <Header onClick={linkTo ? redirectTo : toggle}>
      <img src={icon} />
      <Title>{text}</Title>
      {isDone && !linkTo && <img src={editIcon} />}
    </Header>

    {isOpen &&
      <OpenContainer>
        {description &&
          <Description>
            {description}
          </Description>
        }
        <div>{children}</div>
      </OpenContainer>
    }
  </>;
};

export default Step;
