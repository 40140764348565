// @flow
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useFetch } from "Hooks/useFetch";
import ProjectView from "Components/ProjectView";
import Screens from "../index";

const SingleProject = () => {
  const { id } = useParams();
  const { data, error, loading } = useFetch({ url: `/api/projects/${id}` });

  if (error) return <div>{error}</div>;
  if (loading) return <div>{loading}</div>;
  if (data?.status === 404) {
    return <Screens.NotFound />
  }

  return (
    <>
      <Helmet>
        <title>{data?.project?.title || "New Project"}</title>
      </Helmet>
      {data && <ProjectView project={data?.project} />}
    </>
  );
};

export default SingleProject;
