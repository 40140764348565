import styled from "styled-components";

export const Container = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  max-width: 100%;
  margin: auto;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    max-width: ${theme.maxWidth}px;
  }
`
);

export const Header = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing * 3}rem ${theme.spacing * 2}rem ${theme.spacing *
    2}rem;
  @media screen and (min-width: ${theme.breakpoints.xsmall}px) {
    flex-direction: row;
  }
`
);

export const SearchBar = styled.form(
  ({ theme }) => `
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 2px solid ${theme.colors.black};
`
);

export const StyledInput = styled.input(
  ({ theme }) => `
  display: flex;
  flex: 1;
  border: none;
  padding: ${theme.spacing}rem;
  font-size: ${theme.fonts.size.normal};
  font-family: ${theme.fonts.default};
`
);

export const SearchIcon = styled.img`
  width: 30px;
`;

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 600;
`;

export const Modal = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing}rem;
  width: 50vw;
  max-height: 80vh;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: ${theme.zIndex.front};
  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    width: 80vw;
  }
`
);

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  overflow-y: scroll;
`;

export const Item = styled.li(
  ({ theme }) => `
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing}rem 0;
  font-weight: bold;
  animation: fadeIn 0.8s ease-in-out;
`
);

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const StyledButton = styled.button(
  ({ theme }) => `
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${theme.fonts.default};
  font-size: ${theme.fonts.size.normal};
  font-weight: bold;
  text-transform: uppercase;
`
);

export const PlainButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
`;
