// @flow
import { connect } from "react-redux";
import StyleguideIndex from "Screens/Styleguide";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  requestExample() {
    dispatch({ type: "REQUEST_EXAMPLE" });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(StyleguideIndex);
