import React from "react";
import {
  Wrapper,
  ListContainer,
  List,
  ListItem,
  Selected,
  FilterButton,
  Icon,
  ClearButton
} from "./styles";

const Dropdown = ({
  items,
  defaultChoice,
  selectedItem,
  setSelectedItem,
  active,
  setActive
}) => {
  function onSelect() {
    setActive(active ? null : "filters");
  }

  const selectItem = specialism => {
    if (selectedItem === specialism) {
      setSelectedItem(null);
    } else {
      setSelectedItem(specialism);
    }
    setActive(null);
  };

  return (
    <Wrapper>
      <Selected selected={selectedItem}>
        <span onClick={onSelect} style={{ flex: 2 }}>
          {selectedItem ? selectedItem.label : defaultChoice}
        </span>
        {selectedItem && (
          <span
            onClick={() => selectItem(null)}
            style={{ fontWeight: "bold", flex: 0.5 }}
          >
            CLEAR
          </span>
        )}
        {active ? (
          <Icon
            src={require("Assets/Images/collapse-arrow.png")}
            onClick={onSelect}
          />
        ) : (
          <Icon
            src={require("Assets/Images/expand-arrow.png")}
            onClick={onSelect}
          />
        )}
      </Selected>
      <FilterButton onClick={onSelect} active={active || selectedItem}>
        <span>Filter</span>
        <Icon src={require("Assets/Images/filter.svg")} />
      </FilterButton>
      {active && (
        <ListContainer>
          <List>
            {items.map((item, key) => (
              <ListItem
                key={key}
                onClick={() => selectItem(item)}
                item={item}
                selected={selectedItem}
              >
                {item.label}
              </ListItem>
            ))}
          </List>
          {selectedItem && (
            <ClearButton onClick={() => selectItem(null)}>CLEAR</ClearButton>
          )}
        </ListContainer>
      )}
    </Wrapper>
  );
};

export default Dropdown;
