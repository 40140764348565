// @flow

import React, { useEffect, useState } from "react";

type Props = {
  children: React.Node,
  show: Boolean
};
const Fade = ({ children, show }: Props): React.Node => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    <>
      <div>
        {shouldRender && (
          <div
            style={{
              animation: `${show ? "fadeIn" : "fadeOut"} 0.4s ease-in-out`
            }}
            onAnimationEnd={onAnimationEnd}
          >
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export default Fade;
