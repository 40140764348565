// @flow
import styled from "styled-components";

export const Card = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing}rem;
  margin: ${theme.spacing}rem 0 ${theme.spacing / 2}rem;
  justify-content: center;
  box-shadow: ${theme.shadow};
  background: ${theme.colors.white};
  position: relative;
  max-width: 100%;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    max-width: ${theme.maxWidth}px;
  }
`
);

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Details = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacing}rem;
  flex: 1;
`
);

export const GreyText = styled.span(
  ({ theme }) => `
  color: ${theme.colors.grey("dark")};
  font-size: ${theme.fonts.size.xsmall};
`
);

export const Content = styled.div(
  ({ theme }) => `
  padding-bottom: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin-left: ${theme.spacing * 5}rem;
    padding: ${theme.spacing}rem;
    padding-top: 0;
  }
`
);

export const PostText = styled.p`
  white-space: pre-wrap;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  @media screen and (min-width: ${props => props.theme.breakpoints.small}px) {
    max-height: 360px;
    object-fit: contain;
  }
`;

export const CommentContainer = styled.div(
  ({ theme, showComments }) => `
  display: ${showComments ? "block" : "none"};
  padding-left: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: block;
    max-width: ${theme.maxWidth}px;
    padding-left: ${theme.spacing * 7}rem;
  }
`
);

export const DeleteButton = styled.button(
  ({ theme }) => `
  border: 2px solid ${theme.colors.black};
  font-family: ${theme.fonts.default};
  font-size: ${theme.fonts.size.normal};
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 ${theme.spacing / 2}rem 0 0;
  position: absolute;
  top: ${theme.spacing * 2}rem;
  right: ${theme.spacing}rem;
  z-index: ${theme.zIndex.middle};
`
);

export const ToggleButton = styled.button(
  ({ theme }) => `
  display: flex;
  border: none;
  background: transparent;
  font-size: ${theme.fonts.size.normal};
  padding: 0;
  margin-bottom: ${theme.spacing}rem;
  text-decoration: underline;
  outline: none;
`
);

export const CommentExpandButton = styled.button(
  ({ theme }) => `
  display: flex;
  border: none;
  background: transparent;
  font-size: ${theme.fonts.size.small};
  padding: 0;
  margin-bottom: ${theme.spacing}rem;
  text-decoration: underline;
  outline: none;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`
);

export const Name = styled.h4`
  cursor: pointer;
`;
