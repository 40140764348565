// @flow
import React from "react";
import parse from "html-react-parser";
import {
  Wrapper,
  Container,
  Image,
  Action,
  StyledText,
  StyledLink as Link,
  Group,
  StyledButton as Button
} from "./styles";
import type { ShowcaseItem } from "Types";

type Props = {
  background_color: String,
  columns: Number,
  showcaseItems: Array<ShowcaseItem>
};

const Showcase = ({ background_color, items, columns }: Props): React.Node => {
  const dimension = columns => {
    switch (parseInt(columns)) {
      case 1:
        return 520;
      case 2:
        return 430;
      case 3:
        return 350;
      case 4:
        return 275;
      default:
        return;
    }
  };

  return (
    <Wrapper background_color={background_color}>
      <Container background_color={background_color} columns={columns}>
        {items?.map((item, i) => (
          <Group
            key={i}
            align={item?.content_alignment}
            columns={columns}
            textOnly={!item.image}
          >
            <Link
              to={item?.button?.link ? item?.button?.link : null}
              dimension={dimension(columns)}
              columns={columns}
              textOnly={!item.image}
            >
              {item?.image && (
                <Image
                  src={item?.image}
                  dimension={dimension(columns)}
                  columns={columns}
                  border={item?.border}
                />
              )}
              {Boolean(item?.text?.length) && (
                <StyledText textOnly={!item.image}>
                  {!item.image && (
                    <img src={require("Assets/Images/collab-slash.png")} />
                  )}
                  {parse(item?.text)}
                </StyledText>
              )}
            </Link>
            {item?.button?.visible && (
              <Action>
                <Button button={item?.button} to={item?.button?.link}>
                  {item?.button?.text}
                </Button>
              </Action>
            )}
          </Group>
        ))}
      </Container>
    </Wrapper>
  );
};

export default Showcase;
