// @flow
import React from "react";
import { Container, HiddenCheckbox, StyledCheckbox } from "./styles";
import { MdDone } from "react-icons/md";

type Props = {
  checked?: boolean,
  label: string,
  onChange?: Function,
  halfWidth?: boolean,
  yellow?: boolean
};

function Checkbox({ checked, label, onChange, halfWidth, yellow }: Props) {
  return (
    <Container
      onChange={event => {
        if (onChange) onChange(event.target.checked);
      }}
      halfWidth={halfWidth}
    >
      <HiddenCheckbox type="checkbox" />
      <StyledCheckbox checked={checked} yellow={yellow} label={label}>
        {checked && <MdDone />}
      </StyledCheckbox>
      {label}
    </Container>
  );
}

export default Checkbox;
