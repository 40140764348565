// @flow
import styled from "styled-components";

export const StyledTag = styled.div(
  ({ theme, withIcon }) => `
  background-color: ${theme.colors.grey("light")};
  padding: ${!withIcon ? theme.spacing / 2 : 0}rem ${
    !withIcon ? theme.spacing : 0
  }rem;
  margin-bottom: ${theme.spacing}rem;
  margin-right: ${theme.spacing}rem;
  text-transform: uppercase;
  font-size: ${theme.fonts.size.xsmall};
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
);

export const StyledLabel = styled.span`
  margin-left: ${props => (props.withIcon ? props.theme.spacing : 0)}rem;
`;
