// @flow
import styled, { css } from "styled-components";

export const Line = styled.h2`
  margin-bottom: 2rem;
  margin-top: 3.5rem;
  width: 100%;
  border-bottom: solid 1px #000;
  line-height: 0.01em;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    line-height: 1.5;
    margin-bottom: 1rem;
    margin-top: 0;
    padding-bottom: .5rem;
  }
`;

export const SectionTitle = styled.span`
  font-size: 2rem;
  font-weight: bold;
  background: #f8f7f7;
  padding-right: 1.5rem;
  @media screen and (max-width: ${props => props.theme.breakpoints.small}px) {
    font-size: 1.8rem;
  }
`;