import React from "react";
import {AlignInstaHandle, Container, FormWrapper} from "./styles";
import Input from "../Input";
import SearchLocationInput from "../SearchLocationInput";
import FormField from "Components/FormField";
import Button from "Components/Button";

function ProfileStep(props: { errors: any, firstName: any, lastName: any, instagramHandle: any, location: any }) {
  return <Container>
    <h3>Let’s start with some basic information</h3>
    <FormWrapper
    >
      <FormField label="First Name" errors={props.errors?.firstName}>
        <Input
          placeholder="First Name"
          {...props.firstName}
        />
      </FormField>
      <FormField label="Last Name" errors={props.errors?.lastName}>
        <Input
          placeholder="Last Name"
          {...props.lastName}
        />
      </FormField>
      <FormField label="Instagram Handle" optional="Optional" errors={props.errors?.instagramHandle}>
        <AlignInstaHandle>
          @
          <Input
            style={{marginLeft: "0px", paddingLeft: "0", border: "0", height: '25px'}}
            {...props.instagramHandle}
          />
        </AlignInstaHandle>
      </FormField>
      <FormField label="Where are you based?" errors={props.errors?.location}>
        <SearchLocationInput
          placeholder={"Start typing your city..."}
          setLocation={props.location}
        />
      </FormField>
      <Button onClick={props.onSubmit}>Next</Button>
    </FormWrapper>
  </Container>;
}

export default ProfileStep;