// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useFetch } from 'Hooks/useFetch';
import ContentError from 'Components/ContentError';
import ContentLoading from 'Components/ContentLoading';
import Feedback from 'Components/Blocks/Feedback';
import {
	Container,
	Heading,
	Description,
	List,
	Item,
	Image,
	Title,
} from './styles';

const Gallery = () => {
	const { data, loading, error } = useFetch({
		url: '/api/collaborations',
	});

	if (loading) return <ContentLoading />;
	if (error) return <ContentError />;

	if (data) {
		return (
			<Container>
				<Helmet>
					<title>ClickerMe | Community Gallery</title>
					<meta
						name="description"
						content="Featured collaborations from the ClickerMe community | Connect and collaborate on the fashion network"
					/>
					<htmlAttributes h1="Featured Fashion Collaborations"></htmlAttributes>
				</Helmet>
				<Heading>Featured Collaborations</Heading>
				<Description>
					A selection of our favourite collaborations on ClickerMe
				</Description>
				<List>
					{data?.collaborations.map((collaboration, index) => (
						<Item to={`/gallery/${collaboration?.slug}`} key={index}>
							<Image src={collaboration?.details?.cover_image?.url} />
							{collaboration?.details?.collaborators && (
								<Title>
									{collaboration?.details?.collaborators
										?.map((i) => i?.name)
										.join(' × ')}
								</Title>
							)}
						</Item>
					))}
				</List>
				<Feedback
					hide_if_logged_in
					background_color="#dadada"
					text="<h2>Connect and collaborate with fashion creatives.</h2>"
					buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
				/>
			</Container>
		);
	}
	return <div>Error</div>;
};

export default Gallery;
