// @flow
import React from "react";
import { Wrapper, ImageContainer, Overlay, StyledButton } from "./styles";

type Props = {
  src: String,
  text: String,
  link: String
};

const Image = ({ src, text, link }: Props): React.Node => {
  return (
    <Wrapper>
      <ImageContainer src={src}>
        <Overlay />
        <StyledButton to={link}>{text}</StyledButton>
      </ImageContainer>
    </Wrapper>
  );
};

export default Image;
