// @flow
import React from "react";
import { Helmet } from "react-helmet";
import OnboardingForm from "Components/OnboardingForm";

function Onboarding() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Create Your Profile</title>
      </Helmet>
      <OnboardingForm />
    </React.Fragment>
  );
}

export default Onboarding;
