// @flow
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "Hooks/useAuth";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { timeAgo } from "Helpers/Date";
import Linkify from "react-linkify";

import {
  Card,
  CommentText,
  ImageContainer,
  Name,
  GreyText,
  DeleteButton
} from "./styles";
import { Comment as CommentType } from "Types";
import Avatar from "Components/Avatar";
import IconButton from "Components/IconButton";
import { MdMoreHoriz } from "react-icons/md";

type Props = {
  comment: CommentType,
  postId: Number | String,
  removeComment: Function,
  setError: Function
};

const Comment = ({ comment, postId, removeComment, setError }: Props) => {
  const { id, text, user, created_at } = comment;
  const { auth } = useAuth();
  const currentUser = auth?.user;
  let history = useHistory();
  const [menuOpen, toggleMenuOpen] = useState(false);
  const [deleteComment, response] = useLazyFetch({
    url: `/api/collaboration_posts/${postId}/comments/${id}`,
    method: "delete"
  });

  function onDelete() {
    deleteComment(null, onSuccess, () =>
      setError("Sorry, we could not delete this comment")
    );
  }

  function onSuccess() {
    removeComment(id);
    setError(null);
    toggleMenuOpen(false);
  }

  return (
    <Card>
      <ImageContainer>
        <Avatar
          src={user?.profile?.photo}
          onClick={() => history.push(`/profiles/${user?.id}/portfolio`)}
          style={{ cursor: "pointer " }}
        />
      </ImageContainer>
      <div>
        <Linkify>
          <CommentText>
            <Name
              onClick={() => history.push(`/profiles/${user?.id}/portfolio`)}
            >
              {user?.profile?.first_name} {user?.profile?.last_name}
            </Name>
            {text}
          </CommentText>
        </Linkify>
        <GreyText>{timeAgo(created_at)}</GreyText>
      </div>
      {user?.id === currentUser?.id && (
        <IconButton
          style={{ position: "absolute" }}
          onClick={() => toggleMenuOpen(!menuOpen)}
        >
          <MdMoreHoriz color="#a9a9a9" size="24" />
        </IconButton>
      )}
      {menuOpen && (
        <DeleteButton onClick={onDelete}>
          <img src={require("Assets/Images/trash-alt.svg")} />
          {response?.loading ? "Deleting" : "Delete Comment"}
        </DeleteButton>
      )}
    </Card>
  );
};

export default Comment;
