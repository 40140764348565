// @flow
import React from "react";
import { Helmet } from "react-helmet";
import { useFetch } from "Hooks/useFetch";
import { useParams } from "react-router-dom";
import ContentError from "Components/ContentError";
import ContentLoading from "Components/ContentLoading";
import SingleJobContent from "Components/SingleJobContent";

const SingleJob = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch({ url: `/api/jobs/${id}` });

  if (loading) return <ContentLoading />;
  if (error) return <ContentError />;

  if (data?.job) {
    return (
      <>
        <Helmet>
          <title>Jobs | {data?.job?.title}</title>
        </Helmet>
        <SingleJobContent job={data.job} />
      </>
    );
  } else return <div>Error</div>;
};

export default SingleJob;
