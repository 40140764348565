import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useFetch } from 'Hooks/useFetch';
import { useAuth } from 'Hooks/useAuth';
import { useLocation, useHistory } from 'react-router-dom';
import episodes from 'Components/Episodes/episodes';
import Text from 'Components/Blocks/Text';
import IndividualPodcast from 'Components/IndividualPodcast';
import Banner from 'Components/Blocks/Banner';
import EachEpisode from 'Components/EachEpisode';
import { Wrapper } from 'Screens/Podcasts/styles';
import { MdChevronLeft } from 'react-icons/md';
import Feedback from 'Components/Blocks/Feedback';
import Episodes from 'Components/Episodes';
import {
	Heading,
	StyledAvatar as Avatar,
	WrapperQuote,
	Description,
} from 'Screens/Podcasts/styles';
import {
	StyledLink as Link,
	Container,
	GlobalWrapper,
	VideoWrapper,
	MoreEpisodes,
} from './styles';

function FashionSlashie() {
	const { data } = useFetch({ url: '/api/pages/podcasts/the-fashion-slashie' });
	const banner = data?.page?.blocks[0];
	const [episode, loadEpisodes] = useState({ episode: episodes });
	const { pathname } = useLocation();
	const { auth } = useAuth();
	let history = useHistory();
	useEffect(() => {
		window.scroll(0, 0);
	}, [pathname]);

	return (
		<>
			<Helmet>
				<title>The Fashion Slashie | A ClickerMe Podcast</title>
				<meta
					name="description"
					content="The Fashion Slashie: A ClickerMe Podcast is centred around acclaimed members of the fashion industry who take on multiple roles"
				/>
			</Helmet>
			{banner && <Banner {...banner} />}
			<GlobalWrapper>
				<Container>
					<Link to="/podcasts/the-fashion-slashie">
						<MdChevronLeft size={24} /> View all episodes
					</Link>
				</Container>
			</GlobalWrapper>
			<div className="episodes">
				{pathname.includes('/podcasts/the-fashion-slashie/nassia-matsa') && (
					<IndividualPodcast
						episode={'episode1'}
						key={'episode1'}
						details={episodes['episode1']}
					/>
				)}
				{pathname.includes('/podcasts/the-fashion-slashie/rob-rusling') && (
					<IndividualPodcast
						episode={'episode2'}
						key={'episode2'}
						details={episodes['episode2']}
					/>
				)}
				{pathname.includes('/podcasts/the-fashion-slashie/eva-losada') && (
					<IndividualPodcast
						episode={'episode3'}
						key={'episode3'}
						details={episodes['episode3']}
					/>
				)}
				{pathname.includes('/podcasts/the-fashion-slashie/theo-white') && (
					<IndividualPodcast
						episode={'episode4'}
						key={'episode4'}
						details={episodes['episode4']}
					/>
				)}
				{pathname.includes('/podcasts/the-fashion-slashie/kate-iorga') && (
					<IndividualPodcast
						episode={'episode5'}
						key={'episode5'}
						details={episodes['episode5']}
					/>
				)}
				{pathname.includes(
					'/podcasts/the-fashion-slashie/tayler-prince-fraser'
				) && (
					<IndividualPodcast
						episode={'episode6'}
						key={'episode6'}
						details={episodes['episode6']}
					/>
				)}
				{pathname.includes(
					'/podcasts/the-fashion-slashie/sylvie-macmillan'
				) && (
					<IndividualPodcast
						episode={'episode7'}
						key={'episode7'}
						details={episodes['episode7']}
					/>
				)}
			</div>
			<Feedback
				hide_if_logged_in
				background_color="#dadada"
				text="<h2>Connect and collaborate with fashion creatives.</h2>"
				buttons={[{ link: '/', color: '#FFF200', text: 'Join for free' }]}
			/>
			<MoreEpisodes style={{ backgroundColor: 'rgb(248,247,247)' }}>
				<Heading>
					<img src={require('Assets/Images/black-slash.svg')} />
					<h3>MORE EPISODES</h3>
				</Heading>
				<Episodes />
			</MoreEpisodes>
			<WrapperQuote>
				<Avatar></Avatar>
				<Description>
					<h3>MEET OUR PODCAST HOST </h3>
					<p>
						Lara Johnson-Wheeler is a London-based writer, editor and
						broadcaster. Specialising in arts and fashion criticism, Lara writes
						for the likes of Vogue, LOVE and Twin Magazine. Formerly Features
						Editor at SHOWstudio, she is a Contributing Editor at Buffalo Zine,
						Tatler and Dapper Dan Magazine.
					</p>
				</Description>
			</WrapperQuote>
		</>
	);
}

export default FashionSlashie;
