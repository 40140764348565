import React from "react";
import Button from "Components/Button";
import { useFetch } from "Hooks/useFetch";
import ContentError from "Components/ContentError";
import parse from "html-react-parser";
import {
	GlobalWrapper,
	Description,
	FlashSale,
	Text,
	SlashImage
} from "./styles";

function BrandSubmitted() {
	const { data, error } = useFetch({
		url: `/api/pages/brand-success-page`
	});

	if (error) return <ContentError />;

	if (data) {
		const sections = data?.page?.blocks;
		const { title, button, button_url, subsections } = sections[0];
		const {description: firstParagraph } = subsections[0];
		const {description: secondParagraph } = subsections[1];

		return (
			<>
				<GlobalWrapper>
					<FlashSale>
						<SlashImage/>
						<Text style={{zIndex: "10"}}>{title}</Text>
						<Description style={{fontWeight: "400", zIndex: "10"}}>
							{ parse(firstParagraph) }
						</Description>
						<Description style={{fontWeight: "400", zIndex: "10"}}>
							{ parse(secondParagraph) }
						</Description>
						<Button
							variant={"white"}
							style={{padding: "1rem 3rem", zIndex: "10"}}
							to={button_url}>{button}</Button>
					</FlashSale>
				</GlobalWrapper>
			</>
		);
	}
	return null;
}

export default BrandSubmitted;
