import styled from "styled-components";
import Input from "Components/Input";

export const ImageContainer = styled.div(
  ({ viewportWidth }) => `
	max-width: ${(70 / 100) * window.innerWidth + 50}px;
	height: 70vh;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  margin-top: 10vh;
`
);

export const LightboxImage = styled.img(
  ({ viewportWidth }) => `
	max-width: ${(70 / 100) * window.innerWidth}px;
	max-height: 70vh;
  object-fit: cover;
`
);

export const Caption = styled.p(
  ({ theme }) => `
  text-align: center;
  color: ${theme.colors.white};
  margin-top: ${theme.spacing}rem;
`
);

export const StyledInput = styled(Input)(
  ({ caption, theme }) => `
  background: transparent;
  color: white;
  border: none;
  outline: none;
  width: 100%;
  text-align: center;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
   width: ${caption?.length ? caption?.length / 2 + `rem` : "30%"}
   text-align: left;
   min-width: 15vw;
   }

`
);

export const Form = styled.form(
  ({ theme }) => `
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
   flex-direction: row;
  }
`
);

export const Close = styled.img(
  ({ theme }) => `
  transform: scale(0.5);
  margin: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin: ${theme.spacing * 2}rem;
  }
`
);

export const NavIcon = styled.img(
  ({ theme }) => `
  transform: translateY(-40vh);
  margin: ${theme.spacing}rem;
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin: ${theme.spacing * 2}rem;
  }
`
);
