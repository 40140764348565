// @flow
import styled, { css } from "styled-components";

export const Text = styled.div(
  props => `
  font-weight: normal;
  max-width: ${props.theme.maxWidth}px;
  margin: auto;
  background-color: ${props.theme.colors.white};
  ${props.padded &&
    css`
      padding: ${props.theme.spacing}rem;
    `}
`
);
