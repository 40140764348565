// @flow
import React, { useState } from "react";
import { connect } from "react-redux";
import ActionCreator from "Actions";
import { useHistory } from "react-router-dom";
import { useAuth } from "Hooks/useAuth";
import { useValidation, useTextInput } from "Hooks";
import { useLazyFetch } from "Hooks/useLazyFetch";
import { useFetch } from "Hooks/useFetch";
import Layout from "Components/Layout";
import Button from "Components/Button";
import IconButton from "Components/IconButton";
import Modal from "Components/Modal";
import Notification from "Components/Notification";

import {
  ProgressHeader,
  BackButton,
  StepLabels,
  StepLabel,
  ProgressContainer,
  ProgressBar,
} from "./styles";
import { MdClose } from "react-icons/md";
import { StyledTag } from "../VerificationForms/styles";
import ProfileStep from "./ProfileStep";
import PrimarySpecialismsStep from "./PrimarySpecialismsStep";
import SecondarySpecialismsStep from "./SecondarySpecialismsStep";
import InterestsStep from "./InterestsStep";

type Props = {
	trackNewUser: Function,
};

function OnboardingForm({ trackNewUser }) {
  const { auth: { user } } = useAuth();
  let history = useHistory();
  const { data } = useFetch({ url: "/api/specialisms" });
  const specialisms = data?.specialisms || [];

  const [step, setStep] = useState(0);
  const firstName = useTextInput();
  const lastName = useTextInput();
  const instagramHandle = useTextInput();
  const [primarySpecialisms, setPrimarySpecialisms] = useState([]);
  const [secondarySpecialisms, setSecondarySpecialisms] = useState([]);
  const [location, setLocation] = useState(null);
  const [userInterests, setUserInterests] = useState([]);
  const [warningMessage, setWarningMessage] = useState(null);
  const [userAvailability, setUserAvailability] = useState(true);
  const [availabilityVisibleFor, setAvailabilityVisibleFor] = useState("all");
  const availability = [{ label: "Open to chat", value: true }, { label: "Unavailable", value: false }];
  const availabilityStatus = [
    { label: "All ClickerMe members", value: "all" },
    { label: "Business only", value: "business_only"},
    { label: "Nobody (hidden)", value: "hidden"},
  ];
  const [error, setError] = useState(null);
  const [validate, errors] = useValidation(
    {
      firstName: firstName.value,
      lastName: lastName.value,
      instagramHandle: instagramHandle.value,
      location: location
    },
    {
      firstName: {
        presence: {
          allowEmpty: false
        }
      },
      lastName: {
        presence: {
          allowEmpty: false
        }
      },
      location: {
        presence: {
          allowEmpty: false
        }
      }
    }
  );
  const [submitProfile, profileResponse] = useLazyFetch({
    url: "/api/me/profile",
    method: "put"
  });
  const [submitSpecialisms, specialismResponse] = useLazyFetch({
    url: "/api/me/specialisms",
    method: "put"
  });
  const [submitInterests, interestResponse] = useLazyFetch({
    url: "/api/me/interests",
    method: "put"
  });

  function addPrimarySpecialism(specialism) {
    if (primarySpecialisms.includes(specialism)) return;

    setPrimarySpecialisms([...primarySpecialisms, specialism])
  }

  function addSecondarySpecialism(specialism) {
    if (secondarySpecialisms.includes(specialism)) return;

    setSecondarySpecialisms([...secondarySpecialisms, specialism])
  }

  function onSuccess() {
    setError(null);
    if (step < 3) {
      setStep(step + 1);
    } else {
      trackNewUser(user?.id);
      history.push(`/profile/portfolio`);
    }
  }

	function onError() {
		setError("Sorry, we could not update this information right now");
	}

	function submitSpecialism(names, priority) {
    return (
      submitSpecialisms(
        {
          specialisms: {
            friendly_names: names,
            priority: priority
          },
        },
        onSuccess,
        onError
      )
    )
  }

  function specialismTags(items, setItems) {
    return items?.map((specialism, index) => (
      <StyledTag closingIcon={true} key={index}>
        {specialism}
        <IconButton style={{
          color: '#a9a9a9',
          fontSize: '11px',
          height: '1.2rem',
          width: '1.2rem',
          margin: 0}}
          onClick={e => {
            e.preventDefault();
            setItems(items.filter(s => s !== specialism));
          }}
        >
          <MdClose/>
        </IconButton>
      </StyledTag>
    ))
  }

  const steps = [
    <ProfileStep
      errors={errors}
      firstName={firstName}
      lastName={lastName}
      instagramHandle={instagramHandle}
      location={setLocation}
      onSubmit={e => {
      e.preventDefault();
      const valid = validate();
      if (valid) {
        submitProfile(
          {
            first_name: firstName.value,
            last_name: lastName.value,
            instagram_handle: instagramHandle.value,
            location: location
          },
          onSuccess,
          onError
        );
      }
    }}
    />,
    <PrimarySpecialismsStep
      errors={errors}
      primarySpecialisms={primarySpecialisms}
      setPrimarySpecialisms={setPrimarySpecialisms}
      specialisms={specialisms}
      handleSuggestionSelected={specialism => addPrimarySpecialism(specialism)}
      specialismResponse={specialismResponse}
      specialismTags={specialismTags}
      onClick={() => {
        if (primarySpecialisms.length > 0) {
          submitSpecialism(primarySpecialisms, "primary");
        } else {
          setWarningMessage(
            "Please select at least one specialism. You’re only one step away from joining the network."
          );
        }
      }}
    />,
    <SecondarySpecialismsStep
      errors={errors}
      secondarySpecialisms={secondarySpecialisms}
      setSecondarySpecialisms={setSecondarySpecialisms}
      specialisms={
        specialisms.filter(s => !primarySpecialisms.includes(s.friendly_name))
      }
      handleSuggestionSelected={specialism => addSecondarySpecialism(specialism)}
      specialismResponse={specialismResponse}
      specialismTags={specialismTags}
      onClick={() => {
        if (secondarySpecialisms.length > 0) {
          submitSpecialism(secondarySpecialisms, "secondary");
        } else {
          setStep(step + 1);
        }
      }}
    />,
    <InterestsStep
      userInterests={userInterests}
      onChange={(values) => setUserInterests(values)}
      selectedItem={userAvailability}
      values={availability}
      selectedItem1={setUserAvailability}
      selectedItem2={availabilityVisibleFor}
      values1={availabilityStatus}
      selectedItem3={setAvailabilityVisibleFor}
      interestResponse={interestResponse}
      onClick={() =>
      userInterests.length > 0
        ? submitInterests(
        {
          interests: {
            friendly_names: userInterests.map((item) => item),
            available: userAvailability,
            availability_visible_for: availabilityVisibleFor
          },
        },
        onSuccess,
        onError
        )
        : setWarningMessage('Please select at least one interest.')}/>,
  ];

  return (
    <React.Fragment>
      {error && (
        <Notification>
          Sorry, we could not submit your details right now
        </Notification>
      )}
      <ProgressHeader>
        <BackButton step={step} onClick={() => setStep(step - 1)}>
          <img src={require("Assets/Images/arrow-left.svg")} />
          Back
        </BackButton>
        <StepLabels step={step}>
          <StepLabel
            active={step === 0}
            completed={Boolean(profileResponse?.data)}
            onClick={() => setStep(0)}
          >
            Profile
          </StepLabel>
          <StepLabel
            active={step === 1 || step === 2}
            completed={Boolean(specialismResponse?.data)}
            onClick={() => validate() && setStep(1)}
          >
            Specialism
          </StepLabel>
          <StepLabel
            active={step === 3}
            completed={Boolean(interestResponse?.data)}
            onClick={() => validate() && specialismResponse?.data && setStep(3)}
          >
            Interests
          </StepLabel>
        </StepLabels>
        <IconButton
          onClick={() =>
            setWarningMessage(`You need to create your profile first. It takes less than 1 minute to
          finish these quick steps.`)
					}
					style={{ color: "white", marginLeft: "4rem" }}
				>
					<MdClose />
				</IconButton>
			</ProgressHeader>
			<ProgressContainer>
				<ProgressBar step={step} />
			</ProgressContainer>
			<Layout.Center>{steps[step]}</Layout.Center>
			{ warningMessage && (
				<Modal black onClose={() => setWarningMessage(null)}>
					<p>{warningMessage}</p>
					<Button
						variant="white"
						size="small"
						onClick={() => setWarningMessage(null)}
					>
						Okay
					</Button>
				</Modal>
			)}
		</React.Fragment>
	);
}

const mapDispatchToProps = dispatch => ({
  trackNewUser(id: Number | String) {
    dispatch(ActionCreator.trackNewUser(id));
  }
});

export default connect(null, mapDispatchToProps)(OnboardingForm);
