// @flow
import React from "react";
import parse from "html-react-parser";

import {
  Container,
  StyledText as Text,
  Action,
  StyledLink as Link,
  StyledButton as Button
} from "./styles";
import type { Button as ButtonType } from "Types";
import { useAuth } from "Hooks/useAuth";

type Props = {
  background_color: String,
  buttons: Array<ButtonType>,
  text: String,
  hide_if_logged_in: Boolean
};

const Feedback = ({
  background_color,
  buttons,
  text,
  hide_if_logged_in
}: Props): React.Node => {
  const { auth } = useAuth();
  return !auth.access_token && hide_if_logged_in ? (
    <Container background_color={background_color}>
      <Text>{parse(text)}</Text>
      <Action>
        {buttons &&
          buttons.map((button, index) => (
            <Button
              onClick={() => window.scroll(0, 0)}
              key={index}
              button={button}
              padded
            >
              <Link to={button?.link}>{button?.text}</Link>
            </Button>
          ))}
      </Action>
    </Container>
  ) : null;
};

export default Feedback;
