// @flow
import styled from "styled-components";

export const Container = styled.div(
  props =>
    `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;  
  background-color: ${props.theme.colors.white};

`
);
