import React from "react";
import parse from "html-react-parser";
import { formatDate } from "Helpers/Date";
import {
  Wrapper,
  Container,
  Logo,
  Description,
  JobDetails,
  Date,
  JobDescription,
  Apply,
  Button,
  StyledLink as Link
} from "./styles";
import { MdChevronLeft } from "react-icons/md";

const SingleJobContent = ({ job }) => {
  const {
    title,
    created_at,
    company,
    location,
    type,
    link,
    deadline,
    instructions,
    description,
    logo
  } = job;
  return (
    <Wrapper>
      <Link to="/jobs">
        <MdChevronLeft size={24} /> All Jobs
      </Link>
      <Container>
        <Logo src={logo?.url} />
        <Description>
          <div style={{ marginBottom: "1rem" }}>
            {created_at && <Date>Posted {formatDate(created_at)}</Date>}
          </div>
          <JobDetails>
            <div>
              <h2>{title}</h2>
              <h3>{`${company}, ${location}`}</h3>
              <h4>{type}</h4>
              {deadline && <h3>Apply before: {formatDate(deadline)}</h3>}
            </div>
          </JobDetails>
          <Apply>
            <h3>How do I apply?</h3>
            <p>{instructions}</p>
            {link && (
              <Button target="_blank" href={link}>
                Apply Now
              </Button>
            )}
          </Apply>
          {description && <JobDescription>{parse(description)}</JobDescription>}
        </Description>
      </Container>
    </Wrapper>
  );
};

export default SingleJobContent;
