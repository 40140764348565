// @flow
import React from "react";
import parse from "html-react-parser";

import {
  Container,
  StyledText as Text,
  QuoteMark,
  StyledAvatar as Avatar,
  Credit,
  Name,
  Wrapper
} from "./styles";

type Props = {
  background_color: String,
  text: String,
  image_alignment: String
};

const Quote = ({
  background_color,
  text,
  credit,
  image_alignment
}: Props): React.Node => {
  return (
    <Wrapper background_color={background_color}>
      <Container
        background_color={background_color}
        alignment={image_alignment}
      >
        {text && (
          <Text alignment={image_alignment}>
            <QuoteMark align="start">
              <img src={require("Assets/Images/open-quote.svg")} />
            </QuoteMark>
            {parse(text)}
            <QuoteMark align="end">
              <img src={require("Assets/Images/close-quote.svg")} />
            </QuoteMark>
          </Text>
        )}
        <Credit alignment={image_alignment}>
          <Avatar credit={credit} alignment={image_alignment}></Avatar>
          <Name alignment={image_alignment}>{credit?.text}</Name>
        </Credit>
      </Container>
    </Wrapper>
  );
};

export default Quote;
